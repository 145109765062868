import { Box } from '@mui/material';
import React from 'react'
import TableFilters from '../charging-sessions/TableFilters';
import CustomTable from '../custom/CustomTable';

const OcpiTariffBox = ({  columns,
    rows,
    isRowClickable,
    showAddButton,
  
    customAction,}) => {

    return (
        <Box
          sx={{
            padding: "24px",
            borderRadius: "8px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TableFilters />
          </Box>
          <CustomTable
            columns={columns}
            rows={rows}
            isRowClickable={isRowClickable}
            clickableColumns={["name", "network", "operatorName"]}
            actionType={
              customAction === "tariff"
                ? "view"
                : customAction === "ocpi"
                ? "qr-copy"
                : customAction === "network"
                ? "edit-delete"
                : null
            }
            //To be changed on checks
            rowClickPath="/charging_points"
            columnClickPaths={{
              // name: "/charging_points",
              //To Be Changed
              network: "/ocpi_management_details",
              name: "/ocpi_management_details",
            }}
            columnKeyMappings={{
              name: "deviceID",
              network: "deviceID",
              name: "operatorId",
            }}

            modalHeading={"3rd Party Tariffs"}
          />
        </Box>
      );
}

export default OcpiTariffBox
