import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../custom/CustomTable";
import TableFilters from "../charging-sessions/TableFilters";
import { useNavigate } from "react-router-dom";
import { operators } from "ajv/dist/compile/codegen";

const TableBox = ({
  columns,
  rows,
  isRowClickable,
  tableNoOfDataHeading,
  customAction,
}) => {

console.log("ooooooo",rows )

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters />
        {/* {showAddButton && (
          <Button
            onClick={handleAddNewChargingPoints}
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "160px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              textWrap: "nowrap",
              ":hover": {
                background: "#222",
              },
            }}
          >
            + ADD NEW
          </Button>
        )} */}
      </Box>
      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        clickableColumns={["name", "network", "operatorName", "nestedOperatorId",]}
        actionType={
          customAction === "tariff"
            ? "view"
            : customAction === "ocpi"
            ? "qr-copy"
            : customAction === "network"
            ? "edit-delete"
            : null
        }
        //To be changed on checks
        rowClickPath="/ocpi_management_details"
        columnClickPaths={{
          // name: "/charging_points",
          //To Be Changed
          network: "/ocpi_management_details",
          name: "/ocpi_management_details",
          nestedOperatorId: "/ocpi_management_details",
         
        }}
        columnKeyMappings={{
          name: "deviceID",
          network: "deviceID",
          name: "operatorId",
        }}
        modalHeading={tableNoOfDataHeading}
      />
    </Box>
  );
};

export default TableBox;
