import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import { Box } from "@mui/material";
import CustomTabs from "../custom/CustomTabs";
import Breadcrumbs from "../breadcrumbs/BreadCrumbs";
import SevereAlertsBox from "./SevereAlertsBox";
import HighAlertsBox from "./HighAlertsBox";
import MediumAlertsBox from "./MediumAlertsBox";
import LowAlertsBox from "./LowAlertsBox";
import AlertsHistory from "./AlertsHistory";
import {
  getAlertsHistoryColumnData,
  getAlertsHistoryRowData,
} from "../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAlertCounts,
  fetchAlertsHistory,
  fetchAvgResolutionAlerts,
  fetchByGroupAlerts,
} from "../../redux/action/alertsSlice/alertsSlice";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";
import NewCustomTabs from "../custom/NewCustomTabs";

const calculateDaysAndHoursDifference = (timestamp1, timestamp2) => {
  // Parse the timestamps into Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Calculate the difference in milliseconds
  const diffInMillis = Math.abs(date1 - date2);

  // Calculate the difference in days (divide by milliseconds in a day)
  const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));

  // Calculate the remaining hours after removing the full days
  const diffInHours = Math.floor(
    (diffInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  // Return the difference as a formatted string like "6d 4h"
  return `${diffInDays}d ${diffInHours}h`;
};

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTabContent, setActiveTabContent] = useState(null);
  // const [durationType, setDurationType] = useState("");
  // const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  // //added for previous time
  // const [prevStartTime, setPrevStartTime] = useState(null);
  // const [prevEndTime, setPrevEndTime] = useState(null);

  const dispatch = useDispatch();

  //Pollig for Alert Count Added
  useEffect(() => {
    dispatch(fetchAlertCounts());

    const intervalId = setInterval(() => {
      dispatch(fetchAlertCounts());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  // Access state from the Redux store
  const {
    alertsHistory,
    alertCounts,
    alertsByGroup,
    alertsByAvgResolution,
    status,
    error,
    loading,
  } = useSelector((state) => state.alerts);
  console.log("alertsHistory", alertsByAvgResolution);

  // Fetch alert counts when the component mounts
  useEffect(() => {
    dispatch(fetchAlertsHistory());
    dispatch(fetchByGroupAlerts());
    dispatch(fetchAvgResolutionAlerts());
  }, [dispatch]);

  // Ensure alertsHistory is always an array before using .map()
  const nestedAlertsHistory = Array.isArray(alertsHistory)
    ? alertsHistory.map((form) => {
        const { alertsHistory, ...rest } = form;

        // Assuming calculateDaysAndHoursDifference is a function you've defined
        const resolvedDatCalculated = calculateDaysAndHoursDifference(
          form?.resolvedDate,
          form?.created_at
        );

        console.log("tt66", resolvedDatCalculated);

        return {
          ...rest,
          resolveTime: resolvedDatCalculated,
          resolvedBy: form?.resolvedByUser?.name,
        };
      })
    : [];

  const getAlertCount = (type) => {
    if (!alertCounts?.data || !Array.isArray(alertCounts.data)) {
      return 0;
    }
    const alert = alertCounts.data.find((item) => item._id === type);
    return alert ? alert.count : 0;
  };

  console.log("tytytyt", alertCounts);

  const tabsData = [
    {
      label: `Overview`,
      content: (
        <Box>
          <OverviewBox
            alertCounts={alertCounts}
            alertsByGroup={alertsByGroup}
            alertsByAvgResolution={alertsByAvgResolution}
          />
        </Box>
      ),
    },
    // {
    //   label: "Informative Alerts",
    //   content: (
    //     <Box>
    //       <AlertsHistory
    //       // columns={getAlertsHistoryColumnData()}
    //       // // row={alertsHistory}
    //       // row={nestedAlertsHistory}
    //       />
    //     </Box>
    //   ),
    // },
    {
      label: `Critical (${getAlertCount("CRITICAL")})`,
      content: (
        <Box>
          <SevereAlertsBox alertCounts={alertCounts} />
        </Box>
      ),
    },
    {
      label: `High (${getAlertCount("HIGH")})`,
      content: (
        <Box>
          <HighAlertsBox alertCounts={alertCounts} />
        </Box>
      ),
    },
    {
      label: `Medium (${getAlertCount("MEDIUM")})`,
      content: (
        <Box>
          <MediumAlertsBox alertCounts={alertCounts} />
        </Box>
      ),
    },
    {
      label: `Low (${getAlertCount("LOW")})`,
      content: (
        <Box>
          <LowAlertsBox alertCounts={alertCounts} />
        </Box>
      ),
    },
    {
      label: "Alert History",
      content: (
        <Box>
          <AlertsHistory
          // columns={getAlertsHistoryColumnData()}
          // // row={alertsHistory}
          // row={nestedAlertsHistory}
          />
        </Box>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        {/* Tabs */}
        <NewCustomTabs
          tabs={tabsData}
          renderContentExternally={true} // Ensure content is NOT rendered inside the tabs
          onTabContentChange={(content) => setActiveTabContent(content)} // Capture active tab content
        />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            /> */}
          </Box>
        )}
      </Box>
      <Box>{activeTabContent || "No Content Available"}</Box>
    </React.Fragment>
  );
};

export default TopBar;
