import React, { useEffect } from "react";

import { Box } from "@mui/material";
import AlertsBox from "../../alerts_and_faults/AlertsBox";
import { alertsMediumColumnData, alertsMediumRowData } from "../../../utils/DummyData";

const PaymentsMediumAlertsBox = ({ alertCounts }) => {
  return (
    <Box sx={{}}>
      <AlertsBox
        column={alertsMediumColumnData}
        data={alertsMediumRowData}
        alertType="medium"
        // alertCountsForTable={getAlertCount("MEDIUM")}
        modalHeading={"Medium Alerts"}
      />
    </Box>
  );
};

export default PaymentsMediumAlertsBox;
