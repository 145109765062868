import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const AlertsLineGraph = ({
  graphTitle,
  data,
  selectedTime,
  startTime,
  endTime,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  // Function to generate the list of dates based on selected time range (last7Days, currentMonth, etc.)
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "last7Days") {
      for (let i = 0; i < 7; i++) {
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: dayjs().subtract(i, "day").format("ddd"),
        });
      }
      dates.reverse(); // Reverse to have the dates in correct order
    } else if (selectedTime === "currentMonth") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (currentDate.isBefore(endOfMonth) || currentDate.isSame(endOfMonth)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "currentYear") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs().startOf("day").add(i, "hour").format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().subtract(1, "day").startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs().subtract(1, "day").startOf("day").add(i, "hour").format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  // Map the response data to the chart format
  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    // Convert the raw data into a format that matches the x-axis labels
    const combinedData = dates.map(({ date, label }) => {
      // Find the corresponding data for the current date
      const dailyData = data?.data?.find((item) => item?.timeLabel === date) || {};

      // Map to the correct data structure for the graph
      return {
        date: label,
        count: dailyData.count || 0,  // Set the count of alerts for the specific day
      };
    });

    return combinedData;
  };

  const chartData = getData();

  return (
    <Box sx={{ width: "100%", height: 270, padding: "16px 0" }}>
      <Typography
        sx={{
          textAlign: "center",
          marginBottom: "8px",
          fontFamily: "myThirdFont",
          fontWeight: "600",
          fontSize: "14px",
          color: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
      >
        {graphTitle}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 10,
            right: 24,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="5 3" vertical={false} />
          <YAxis
            tick={{
              fontSize: "12px",
              fontFamily: "myThirdFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
          <XAxis
            dataKey="date"
            tick={{
              fontSize: "14px",
              fontFamily: "mySecondFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#2271E8"
            activeDot={{ r: 3 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AlertsLineGraph;
