import { Box, Typography } from "@mui/material";
import React from "react";
import ValueCard from "../../dashboard/ValueCard";
import ValueChip from "../../Chips/ValueChip";

const LeftSideCustomerGraph = ({ data, selectedTime, customerManagement }) => {
  // const getComparisonText = (change) => {
  //   return change !== undefined
  //     ? `${change > 0 ? "+" : ""}${change} than ${getComparisonTime()}`
  //     : "N/A";
  // };

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "last7Days":
        return "Last Week";
      case "currentMonth":
        return "Last Month";
      case "currentYear":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  // Function to format the comparison value (positive/negative)
  const formatValue = (value) => {
    const valueStr = String(value); // Convert value to string
    if (valueStr.startsWith("-")) {
      return valueStr;
    }
    return `+${valueStr}`;
  };

  const getComparisonTime = () => {
    switch (selectedTime) {
      case "today":
        return "yesterday";
      case "yesterday":
        return "previous day";
      case customerManagement ? "last7Days" : "week":
        return "last week";
      case customerManagement ? "currentMonth" : "month":
        return "last month";
      case customerManagement ? "currentYear" : "year":
        return "last year";
      case "Custom Range":
        return "previous range";
      default:
        return "N/A";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <ValueCard
        title={"New Customers"}
        topGraphBox="true"
        data={data}
        customerManagement={customerManagement}
        changeValueData={data?.data?.customerChange}
        valuChipData={data?.data?.percentageChange}
        selectedTime={selectedTime}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "start" }}>
            <Box
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "16px",
                background: "#69A5FF",
              }}
            ></Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#666"}
              >
                Through app
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"18px"}
                textAlign={"start"}
              >
                {data?.data?.appUsers || "0"}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: "flex-end",
              }}
            >
              {selectedTime === "today" ||
              selectedTime === "year" ||
              selectedTime === "currentYear" ? (
                ""
              ) : (
                <ValueChip
                  fixSize={true}
                  title={"Through app"}
                  valuChipData={data?.data?.appUserPercentageChange}
                />
              )}
              {selectedTime === "today" ||
              selectedTime === "year" ||
              selectedTime === "currentYear" ? (
                ""
              ) : (
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#666"}
                >
                  {/* {getComparisonText(data?.data?.appUsersChange)} */}
                  <span
                    style={{
                      color: getCompareColor(data?.data?.appUsersChange || 0),
                      fontFamily: "mySecondFont",
                    }}
                  >
                    {formatValue(data?.data?.appUsersChange || 0)}
                  </span>{" "}
                  than {getComparisonText()}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "start" }}>
            <Box
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "16px",
                background: "#FF9F69",
              }}
            ></Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#666"}
              >
                Direct pay customers
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fy
                ontSize={"18px"}
                textAlign={"start"}
              >
                {data?.data?.directUsers}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: "flex-end",
              }}
            >
              {selectedTime === "today" ||
              selectedTime === "year" ||
              selectedTime === "currentYear" ? (
                ""
              ) : (
                <ValueChip
                  title={"Direct pay customers"}
                  fixSize={true}
                  valuChipData={data?.data?.directUserPercentageChange}
                />
              )}
              {selectedTime === "today" ||
              selectedTime === "year" ||
              selectedTime === "currentYear" ? (
                ""
              ) : (
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#666"}
                >
                  {/* {getComparisonText(data?.data?.directUsersChange)} */}
                  <span
                    style={{
                      color: getCompareColor(
                        data?.data?.directUsersChange || 0
                      ),
                      fontFamily: "mySecondFont",
                    }}
                  >
                    {formatValue(data?.data?.directUsersChange || 0)}
                  </span>{" "}
                  than {getComparisonText()}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSideCustomerGraph;
