import { Box, Breadcrumbs } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTabs from "../custom/CustomTabs";
import MainHeading from "../../utils/MainHeading";
import TableBox from "./RfidRequest/TableBox";
import {
  rejectedRFID,
  rejectedRFIDData,
  retailData,
  retailRFID,
  rfidColumns,
  rfidData,
} from "../../utils/DummyData";
import RetailTableBox from "./RetailRFID/RetailTableBox";
import RejectedRFIDTableBox from "./RejectedRequest/RejectedRFIDTableBox";
import { useSelector } from "react-redux";
import {
  fetchRFIDDetails,
  getAllRFIDRejectRequests,
  getAllRFIDRequests,
} from "../../redux/action/RFID/rfidSlice";
import { useDispatch } from "react-redux";
import { formatPhoneNumber } from "../../utils/countryCode";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const dispatch = useDispatch();
  const { rfidRequests, rejectedRFIDRequests, rfidDetails, loading, error } =
    useSelector((state) => state.rfid);

  useEffect(() => {
    dispatch(getAllRFIDRequests());
    dispatch(getAllRFIDRejectRequests());
    dispatch(fetchRFIDDetails());
  }, [dispatch]);

  const data = rfidRequests.map((form) => {
    const rawPhone = form.customer.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form._id,
      address: form.address,
      city: form.city,
      country: form.country,
      createdAt: form.createdAt,
      customer: form.customer, // Whole customer object
      customerName: form.customer.name,
      phone: formattedPhone,
      email: form.customer.email,
      locality: form.locality,
      pincode: form.pincode,
      state: form.state,
      status: form.status,
      updatedAt: form.updatedAt,
      serialNumber: form.serialNumber,
    };
  });

  const rejectedData = rejectedRFIDRequests.map((form) => {
    const rawPhone = form.customer.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form._id,
      address: form.address,
      city: form.city,
      country: form.country,
      createdAt: form.createdAt,
      customer: form.customer, // Whole customer object
      rejectedBy: form.rejectedBy,
      phone: formattedPhone,
      email: form.customer.email,
      rejectReason: form?.rejectReason,
      locality: form.locality,
      pincode: form.pincode,
      state: form.state,
      status: form.status,
      updatedAt: form.updatedAt,
    };
  });

  const assignedData = rfidDetails.map((form) => {
    const rawPhone = form.customer.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form._id,
      rfid: form.rfid,
      customer_id: form.customer_id,
      assignedBy: form.assignedBy,
      assignedDate: form.assignedDate,
      customer: form.customer,
      customerName: form.customer.name,
      phone: formattedPhone, // Use the formatted phone number
      assignedByName: form.assignedBy.name,
      formDetails: form.formDetails,
      lastUpdatedBy: form.lastUpdatedBy,
      createdAt: form.createdAt,
      updatedAt: form.updatedAt,
      status: form.status,
      serialNumber: form.serialNumber,
      expiry_date: form.expiry_date,
    };
  });

  const tabsData = [
    {
      label: "RFID Requests",
      content: <TableBox columns={rfidColumns} row={data} />,
    },
    {
      label: "Assigned RFIDs",
      content: <RetailTableBox columns={retailRFID} row={assignedData} />,
    },
    {
      label: "Rejected Requests",
      content: (
        <RejectedRFIDTableBox columns={rejectedRFID} row={rejectedData} />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "8px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          mb: "20px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Box sx={{ display: "none" }}>
              <MainHeading text="RFID Management" />
            </Box>
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>

      {/* Tab content rendering */}
      <Box>{tabsData[selectedTab]?.content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
