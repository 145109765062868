import { Box } from "@mui/material";
import React, { useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";
import HubTimeFilter from "../../Filters/HubTimeFilter";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import { TimeProvider } from "../../../Hooks/TimeContext";

const AllSession = ({ columns, row, isActive, stationId, tabTitle }) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter rows based on search query
  const filteredRows = row.filter((item) => {
    return Object.keys(item).some((key) =>
      String(item[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <StationProvider>
      <TimeProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {/* filters */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <HubTableFilter
              tabTitle={"HubAllSessions"}
              setSearchQuery={setSearchQuery}
              isActive={isActive}
              stationId={stationId}
            />
            <HubTimeFilter
              tabTitle={tabTitle ? tabTitle : "HubAllSessions"}
              isActive={isActive}
              stationId={stationId}
            />
          </Box>

          {/* Table */}
          <CustomTable
            columns={columns}
            rows={filteredRows}
            modalHeading={"Hub All Sessions"}
            isRowClickable={true}
            clickableColumns={["station"]}
            columnClickPaths={{
              station: "/hub_management/charging_sessions",
            }}
            columnKeyMappings={{
              station: "_id",
            }}
            hubSession={true}
          />
        </Box>
      </TimeProvider>
    </StationProvider>
  );
};

export default AllSession;
