import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../../routes/routes";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Active" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const SettlementsBoxDetails = ({ settlementDetails }) => {
  const currencySymbol = GetCurrencySymbol();
  const navigate = useNavigate();

  const handlePageChange = () => {
    navigate(MyRoutes?.accounts_settlements_details);
  };

  const handleSettled = () => {
    navigate(MyRoutes?.settled_amounts, { state: { data: settlementDetails } });
  };

  if (!settlementDetails) {
    return (
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#666"}
        textAlign={"center"}
        mt={10}
      >
        Select an alert to view details
      </Typography>
    );
  }
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span className="material-symbols-outlined">description</span>
            <Heading text={"Settlement Details"} />
          </Box>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <InfoBox
          label={"Company Name"}
          value={settlementDetails?.partnerCompanyName}
        />

        <InfoBox
          label={"Total Energy"}
          value={`${settlementDetails?.totalEnergyConsumed} kWh`}
        />
        <Grid container spacing={2}>
          <Grid item md={6}>
            <InfoBox
              label={"Total Revenue Generate"}
              value={`${currencySymbol}${settlementDetails?.totalRevenueGenerated}`}
            />
          </Grid>
          <Grid item md={6}>
            <InfoBox
              label="Total 1C Share"
              value={`${currencySymbol}${settlementDetails?.total1CShare}`}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <InfoBox
              label={"Software Cost (Per Unit) "}
              value={`${currencySymbol}${settlementDetails?.softwareCostPerUnit}`}
            />
          </Grid>
          <Grid item md={6}>
            <InfoBox
              label={"Hardware Cost (Per Unit) "}
              value={`${currencySymbol}${settlementDetails?.hardwareCostPerUnit}`}
            />
          </Grid>
        </Grid>
        <InfoBox
          label="Pending Settlement Amount "
          value={`${currencySymbol}${settlementDetails?.pendingSettlementAmount}`}
        />

        <InfoBox
          label="Last Settlement Date"
          value={settlementDetails?.lastSettlementDate}
        />
        <InfoBox
          label="Settlement Status"
          value={settlementDetails?.settlementStatus}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "16px",
          }}
        >
          <Button
            sx={{
              width: "50%",
              background: "#003f79",
              color: "#fff",
              fontFamily: "mySecondFont",
              ":hover": {
                background: "#003f79",
              },
            }}
            onClick={handlePageChange}
          >
            More Details
          </Button>
          <Button
            sx={{
              width: "50%",
              background: "#333",
              color: "#fff",
              fontFamily: "mySecondFont",
              ":hover": {
                background: "#333",
              },
            }}
            onClick={handleSettled}
          >
            Settle
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SettlementsBoxDetails;
