import { Box } from '@mui/material'
import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import AlertsBox from '../../alerts_and_faults/AlertsBox';
import { alertsLowColumnData, alertsLowRowData } from '../../../utils/DummyData';

const PaymentsLowAlertsBox = ({alertCounts}) => {

  const dispatch = useDispatch();

  return (
    <Box sx={{}}>
    <AlertsBox
      column={alertsLowColumnData}
      data={alertsLowRowData}
      alertType="low"
      // alertCountsForTable={getAlertCount("LOW")}
      modalHeading={"Low Alerts"}
    />
  </Box>
  )
}

export default PaymentsLowAlertsBox
