import { Box, Typography } from "@mui/material";
import React from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";

const Customers = ({ columns, rows, isRowClickable, loading }) => {
  if (loading) {
    return <Typography>loading</Typography>;
  }
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters />
      </Box>
      <CustomTable
        columns={columns}
        rows={Array.isArray(rows) ? rows : []}
        isRowClickable={isRowClickable}
        clickableColumns={["customerName"]}
        columnClickPaths={{
          customerName: "/customer_management",
        }}
        columnKeyMappings={{
          customerName: "_id", // Use 'sessionID' key for 'sessionID' column
        }}
        actionType={"view"}
        modalHeading={"Customers"}
        customerstatheading={true}
        loading={loading}
      />
    </Box>
  );
};

export default Customers;
