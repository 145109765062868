import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";

const SessionTableBox = ({ columns, row = [], isRowClickable, loading }) => {
  const [filteredStations, setFilteredStations] = useState([]);

  // Handle side effects
  useEffect(() => {
    if (Array.isArray(row) && row.length > 0) {
      setFilteredStations(row);
    } else {
      setFilteredStations([]);
    }
  }, [row]);

  // Render logic
  if (loading) {
    return (
      <Box
        sx={{
          padding: "24px",
          borderRadius: "8px",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!Array.isArray(row) || row.length === 0) {
    return (
      <Box
        sx={{
          padding: "24px",
          borderRadius: "8px",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No Data Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {/* filters */}
      <TableFilters
        data={row}
        onFilterChange={(filteredData, sortColumn) => {
          setFilteredStations(filteredData);
        }}
        filters={[
          {
            value: "createdAt",
            title: "Request date",
          },
        ]}
      />

      {/* Table */}
      <CustomTable
        columns={columns}
        rows={filteredStations}
        modalHeading={"Customer Sessions"}
        sortColumn={null}
        isRowClickable={isRowClickable}
        clickableColumns={["_id"]}
        columnClickPaths={{
          _id: "/charging_sessions",
        }}
        columnKeyMappings={{
          _id: "_id",
        }}
        ocpiCheckHeading={"true"}
      />
    </Box>
  );
};

export default SessionTableBox;
