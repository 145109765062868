import { Box, Grid } from "@mui/material";
import React from "react";
import ValueCard from "../../dashboard/ValueCard";
import CustomerProgressBar from "../../Graphs/CustomerManagement/CustomerProgressBar.jsx";
import CustomerLineGraph from "../../Graphs/CustomerManagement/CustomerLineGraph.jsx";

const WalletRechargeBox = ({ data, selectedTime, startTime, endTime, }) => {

  console.log("fhfhosis", data)
  return (
    <Box
      sx={{
        background: "#fff",
        padding: "24px",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={8} sx={12}>
          <Box>
            {/* <LineGraph
              graphTitle="Wallet Recharge"
              data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            /> */}
            <CustomerLineGraph
              graphTitle="Wallet Recharge"
              data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
          {/* <Box sx={{}}>
            <GraphZoomButton GraphComponent={LineGraph} />
          </Box> */}
        </Grid>

        <Grid item md={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <ValueCard
                customerManagement={true}
                data={data}
                changeValueData={data?.data?.walletRecharge?.countChange}
                valuChipData={data?.data?.walletRecharge?.percentageChange}
                title={"Wallet Recharge"}
                topGraphBox="true"
                selectedTime={selectedTime}
              />
              <ValueCard
                customerManagement={true}
                title={"Cashbacks"}
                topGraphBox="true"
                data={data}
                changeValueData={data?.data?.cashbackData?.countChange}
                valuChipData={data?.data?.cashbackData?.percentageChange}
                selectedTime={selectedTime}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Box>ProgressBar</Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomerProgressBar dataType="walletRecharge" data={data} />
      </Box>
    </Box>
  );
};

export default WalletRechargeBox;
