import React from "react";
import { Typography, Box } from "@mui/material";

const OCPICustomerTreeData = ({ data }) => {
  const totalCustomers = data.reduce((sum, item) => sum + item.size, 0);

  return (
    <Box sx={{ overflow: "auto", height: "400px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"24px"}
        textAlign={"start"}
        color={"0C0C0C"}
      >
        OCPI customers:{" "}
        <span style={{ fontFamily: "mySecondFont" }}>{totalCustomers}</span>
      </Typography>
      <Box sx={{ mt: "12px" }}>
        <ul
          style={{
            textAlign: "start",
            margin: "0px",
            padding: "0px 32px",
            fontFamily: "myThirdFont",
          }}
        >
          {data.map((item) => (
            <li key={item.name}>
              {item.name}{" "}
              <span style={{ fontFamily: "mySecondFont" }}>
                ({item.size}/{((item.size / totalCustomers) * 100).toFixed(2)}%)
              </span>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default OCPICustomerTreeData;
