import { Box, Button } from "@mui/material";
import React from "react";

const NewSearchFilterBox = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
      <input
        style={{
          padding: "10px 16px",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #c0c0c0",
          color: "#9C9C9B",
          fontFamily: "myThirdFont",
          height: "40px",
        }}
        type="text"
        className="chargingSessionTable"
        placeholder="Search by column name"
      />
      <Button
        sx={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          border: "1px solid #ccc",
          padding: "12px",
          borderRadius: "4px",
          height: "40px",
          fontFamily: "mySecondFont",
          fontSize: "14px",
          textTransform: "uppercase",
          cursor: "pointer",
          color: "#333",
        }}
      >
        Filters<span className="material-symbols-outlined">filter_list</span>
      </Button>
    </Box>
  );
};

export default NewSearchFilterBox;
