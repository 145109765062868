import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const ALL_PASS_TYPES = ["1hr", "2hr", "3hr", "4hr", "12hr", "24hr", "weekly"];
const CustomerProgressBar = ({ dataType, data, flagType }) => {
  console.log("hubdataaaghtrtaaaa", data);

  function getPassTypeColor(passType) {
    const colorMap = {
      "1hr": "#68B7F9", // Light blue
      "2hr": "#1D4F9A", // Dark blue
      "3hr": "#8D6AF0", // Purple
      "4hr": "#ffc14d", // Yellow
      "12hr": "#F86C1D", // Orange
      "24hr": "#22C55E", // Green
      weekly: "#EF4444", // Red
    };
    return colorMap[passType] || "#6B7280"; // Default gray color if pass type not found
  }

  // Map the response data to the component structure
  const inactiveWallets = {
    total: data?.inactiveWallet?.count, // Total inactive wallet count (17 in this case)
    groups: [
      {
        label: ">50 days",
        count: data?.inactiveWallet?.moreThan50Days?.count,
        percentage: data?.inactiveWallet?.moreThan50Days?.percentage,
        color: "#E27F7F", // Color for >50 days inactive wallets
      },
      {
        label: ">100 days",
        count: data?.inactiveWallet?.moreThan100Days?.count,
        percentage: data?.inactiveWallet?.moreThan100Days?.percentage,
        color: "#A83333", // Color for >100 days inactive wallets
      },
    ],
  };

  const inactiveActiveCustomer = {
    total: data?.data?.totalInactiveUser,
    groups: [
      {
        label: "Android",
        count: data?.data?.inactiveAndroidUsersCount,
        percentage: data?.data?.inactiveAndroidUsersPercentage,
        color: "#68B7F9", // Color for >50 days inactive wallets
      },
      {
        label: "Apple",
        count: data?.data?.inactiveIosUsersCount,
        percentage: data?.data?.inactiveIosUsersPercentage,
        color: "#1D4F9A", // Color for >100 days inactive wallets
      },
      {
        label: "Direct Pay",
        count: data?.data?.inactiveDirectUsersCount,
        percentage: data?.data?.inactiveDirectUsersPercentage,
        color: "#FF9F69", // Color for >100 days inactive wallets
      },
    ],
  };

  const allInactiveWallets = {
    total: data?.inactiveWallet?.count, // Total inactive wallet count (17 in this case)
    groups: [
      {
        label: "Android",
        count: data?.inactiveWallet?.moreThan50Days?.count,
        percentage: data?.inactiveWallet?.moreThan50Days?.percentage,
        color: "#68B7F9", // Color for >50 days inactive wallets
      },
      {
        label: "Apple",
        count: data?.inactiveWallet?.moreThan100Days?.count,
        percentage: data?.inactiveWallet?.moreThan100Days?.percentage,
        color: "#1D4F9A", // Color for >100 days inactive wallets
      },
      {
        label: "Direct Pay",
        count: data?.inactiveWallet?.moreThan100Days?.count,
        percentage: data?.inactiveWallet?.moreThan100Days?.percentage,
        color: "#FF9F69", // Color for >100 days inactive wallets
      },
    ],
  };

  // Map the walletRecharge data to progress bar format
  // const rechargeWalletData = {
  //   total: data?.data?.walletRecharge?.amountRangeStats?.totalCount,
  //   groups: (data?.data?.walletRecharge?.amountRangeStats?.distributions || []).map((distribution, index) => {

  //     const label = `${distribution.lowerRange ? `₹${distribution.lowerRange}` : ''} ${
  //       distribution.upperRange ? `- ₹${distribution.upperRange}` : ''
  //     }`.trim() || `Range ${index + 1}`;

  //     // Ensure percentage is properly formatted and fallback to 0 if not available
  //     const percentage = distribution.percentage
  //       ? distribution.percentage.toFixed(2)
  //       : 0;

  //     // Ensure upperRange and lowerRange are available to avoid any undefined issues
  //     const count = distribution.count || 0;
  //     const color = getColorForRange(distribution.lowerRange, distribution.upperRange); // You can define a function to get color dynamically based on range

  //     return {
  //       label,
  //       count,
  //       percentage,
  //       color,
  //     };
  //   }),
  // };

  // Map the walletRecharge data to progress bar format
  const rechargeWalletData = {
    total: data?.data?.walletRecharge?.amountRangeStats?.totalCount,
    groups: (
      data?.data?.walletRecharge?.amountRangeStats?.distributions || []
    ).map((distribution, index, array) => {
      // Build the label based on the lower and upper ranges, and handle zero values explicitly
      let label = "";

      if (distribution.lowerRange === 0 && distribution.upperRange === 0) {
        label = "No Range"; // Handle the case when both are zero
      } else {
        label = `${
          distribution.lowerRange || distribution.lowerRange === 0
            ? `₹${distribution.lowerRange}`
            : ""
        } 
      ${
        distribution.upperRange || distribution.upperRange === 0
          ? `- ₹${distribution.upperRange}`
          : ""
      }`.trim();
      }

      // If no label was created (e.g., due to missing data), use a fallback
      label = label || `Range ${index + 1}`;

      // Ensure percentage is properly formatted and fallback to 0 if not available
      const percentage = distribution.percentage
        ? distribution.percentage.toFixed(2)
        : 0;

      // Ensure upperRange and lowerRange are available to avoid any undefined issues
      const count = distribution.count || 0;

      // Dynamically assign color based on the number of items in the distributions array
      const color = getColorForRange(index, array.length); // Dynamic color based on index and total number of ranges

      return {
        label,
        count,
        percentage,
        color,
      };
    }),
  };

  // Helper function to dynamically determine color based on the number of items in the array
  function getColorForRange(index, total) {
    const colorPalette = [
      "#88E27F", // Green for first range
      "#56BC4D", // Slightly darker green for second range
      "#3B8635", // Dark green for third range
      "#22531E", // Even darker green for fourth range
      "#9C27B0", // Purple for fifth range
      "#FF5722", // Red-orange for sixth range
      "#FF9800", // Orange for seventh range
      "#2196F3", // Blue for eighth range
    ];

    // If the number of ranges is more than the available colors, cycle through them
    const colorIndex = index % colorPalette.length;
    return colorPalette[colorIndex];
  }

  // Map the response data to the component structure
  const appCustomersData = {
    total: data?.appCustomer?.totalCount, // Example total count for the calculation
    groups: [
      {
        label: "Android users",
        count: data?.appCustomer?.android?.count,
        percentage: data?.appCustomer?.android?.percentage,
        color: "#68B7F9",
      },
      {
        label: "IOS users",
        count: data?.appCustomer?.ios?.count,
        percentage: data?.appCustomer?.ios?.percentage,
        color: "#1D4F9A",
      },
    ],
  };

  // App Customers Data Dummy
  const ocpiSessionStatsDataFunction = {
    total: data?.graphData?.totalEnergyConsumed, // Example total count for the calculation
    groups: [
      {
        label: "OCPI",
        count: data?.graphData?.totalOcpiEnergy,
        percentage: data?.graphData?.totalOcpiSessionPercentage,
        color: "#69A5FF",
      },
      {
        label: "OCPP",
        count: data?.graphData?.totalOcppEnergy,
        percentage: data?.graphData?.totalOcppSessionPercentage,

        color: "#E41E26",
      },
    ],
  };

  // Function to get color based on the plan type (You can modify it as needed)
  const getColorForPlan = (label) => {
    switch (label) {
      case "1 Month plan":
        return "#EF7559"; // Color for 1 Month plan
      case "6 months plan":
        return "#F3B79F"; // Color for 6 months plan
      case "Annual Plan":
        return "#240D66"; // Color for Annual Plan
      default:
        return "#000"; // Default color if no match
    }
  };

  // Extract superData for 'superCustomers' from the API response
  const superCustomersData = {
    total: data?.superData?.totalCount, // Start with 0 and we will update this dynamically
    groups: [],
  };

  // Checking if superData exists in the response
  if (data?.superData) {
    // Create an array of plans from the response
    const plans = [
      {
        label: "1 Month plan",
        count: data.superData?.plan30?.count,
        percentage: data.superData?.plan30?.percentage,
      },
      {
        label: "6 months plan",
        count: data.superData?.plan180?.count,
        percentage: data.superData?.plan180?.percentage,
      },
      {
        label: "Annual Plan",
        count: data.superData?.plan365?.count,
        percentage: data.superData?.plan365?.percentage,
      },
    ];

    // Populate the groups array
    superCustomersData.groups = plans.map((plan) => ({
      label: plan.label,
      count: plan.count,
      percentage: plan.percentage,
      color: getColorForPlan(plan.label), // Assign a color dynamically
    }));
  }

  // Hub Management Visit Data
  const hubVisitData = {
    total:
      data?.hubChargingSessionsStats?.frequentCustomersPrecentage
        ?.allUsersCount || 0,
    groups: [
      {
        label: "≤ 2 visits",
        count:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.lessThanTwoVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.lessThanTwoVisitsPrecent,
        color: "#68B7F9",
      },
      {
        label: "≥ 3 visits",
        count:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.moreThanTwoVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.moreThanTwoVisitsPrecent,

        color: "#1D4F9A",
      },
      {
        label: "≥ 5 visits",
        count:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.moreThanFourVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.moreThanFourVisitsPrecent,

        color: "rgb(248, 97, 12)",
      },
      {
        label: "≥ 7 visits",
        count:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.moreThanSixVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentCustomersPrecentage
            ?.moreThanSixVisitsPrecent,

        color: "#ffc14d",
      },
    ],
  };

  const hubVehicleVisit = {
    total:
      data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
        ?.allVehiclesCount || 0,
    groups: [
      {
        label: "≤ 2 visits",
        count:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.lessThanTwoVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.lessThanTwoVisitsPrecent,
        color: "#68B7F9",
      },
      {
        label: "≥ 3 visits",
        count:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.moreThanTwoVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.moreThanTwoVisitsPrecent,

        color: "#1D4F9A",
      },
      {
        label: "≥ 5 visits",
        count:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.moreThanFourVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.moreThanFourVisitsPrecent,

        color: "rgb(248, 97, 12)",
      },
      {
        label: "≥ 7 visits",
        count:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.moreThanSixVisits || 0,
        percentage:
          data?.hubChargingSessionsStats?.frequentVehicleVisitPrecentage
            ?.moreThanSixVisitsPrecent,

        color: "#ffc14d",
      },
    ],
  };

  const hubPassHolderData = {
    total: data?.hubCustomerStats?.overallHubCustomers?.passHoldersCount || 0,
    groups: ALL_PASS_TYPES.map((passType) => {
      // Find the matching pass type from data, if it exists
      const passData = data?.hubCustomerStats?.hubCustomersByPassType?.find(
        (item) => item.passType === passType
      );

      return {
        label: `${passType} Pass`,
        count: passData?.passHoldersCount || 0,
        percentage: passData?.percentage || 0,
        color: getPassTypeColor(passType),
      };
    }),
  };

  // Set data based on dataType
  const updatedData =
    dataType === "walletStats"
      ? inactiveWallets
      : dataType === "inactiveCustomers"
      ? inactiveActiveCustomer
      : dataType === "walletRecharge"
      ? rechargeWalletData
      : dataType === "appCustomers"
      ? appCustomersData
      : dataType === "superCustomers"
      ? superCustomersData
      : dataType === "allInactiveUserData"
      ? allInactiveWallets
      : dataType === "ocpiSessionStats"
      ? ocpiSessionStatsDataFunction
      : dataType === "totalUserVisit"
      ? hubVisitData
      : dataType === "totalVehicleVisit"
      ? hubVehicleVisit
      : dataType === "totalPassHolder"
      ? hubPassHolderData
      : null;

  const totalCount = updatedData?.groups.reduce(
    (total, group) => total + group.count,
    0
  );

  // Handle cases where data is not available or invalid
  if (!updatedData) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography sx={{ color: "red" }}>
          No valid data available for this type.
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      {dataType === "superCustomers" && (
        <img style={{ marginBottom: "8px" }} src="/images/superlogo.png" />
      )}
      {/* dataType Based Overall Data */}
      {flagType === "remove" ? null : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {dataType === "walletRecharge" ? (
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "myThirdFont",
              }}
            >
              No. of Wallet Recharges: {totalCount}
            </Typography>
          ) : dataType === "walletStats" ? (
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "myThirdFont",
              }}
            >
              Inactive wallets: {totalCount}
            </Typography>
          ) : dataType === "appCustomers" ? (
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "myThirdFont",
              }}
            >
              App customers: {totalCount}
            </Typography>
          ) : dataType === "superCustomers" ? (
            <>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "myThirdFont",
                }}
              >
                Current subscribers: {totalCount}
              </Typography>
            </>
          ) : dataType === "totalUserVisit" ? (
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "myThirdFont",
              }}
            >
              Total User Visit: {totalCount}
            </Typography>
          ) : dataType === "totalVehicleVisit" ? (
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "myThirdFont",
              }}
            >
              Total Vehicle Visits: {totalCount}
            </Typography>
          ) : dataType === "totalPassHolder" ? (
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "myThirdFont",
              }}
            >
              Total Pass Holders: {totalCount}
            </Typography>
          ) : null}
        </Box>
      )}

      {/* Bar Section */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: dataType === "walletRecharge" ? "24px" : "12px",
          borderRadius: "4px",
          overflow: "hidden",
          marginTop: 1,
          backgroundColor: "#e0e0e0",
        }}
      >
        {updatedData?.groups.map((group, index) => (
          <Box
            key={index}
            sx={{
              width: `${group.percentage}%`,
              backgroundColor: group.color,
            }}
          />
        ))}
      </Box>
      {/* Legend */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection:
            dataType === "walletRecharge" ||
            dataType === "allInactiveUserData" ||
            dataType === "inactiveCustomers"
              ? "row"
              : "column",
          gap:
            dataType === "walletRecharge" ||
            dataType === "allInactiveUserData" ||
            dataType === "inactiveCustomers"
              ? "16px"
              : null,
        }}
      >
        {updatedData?.groups.map((group, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: group.color,
                marginRight: 1,
              }}
            />
            <Typography
              sx={{
                color: "#2F2F2F",
                fontSize: "14px",
                lineHeight: "16px",
                fontFamily: "myThirdFont",
              }}
            >
              {group.label} ({group.count}/{group.percentage}%)
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomerProgressBar;
