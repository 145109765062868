import { Box, Grid } from "@mui/material";
import React from "react";
import OcpiGraphBoxTwo from "./OcpiGraphBoxTwo";
import OcpiGraphBoxThree from "./OcpiGraphBoxThree";
import OcpiGraphBoxOne from "./OcpiGraphBoxOne";

const OcpiMainGraphBox = ({
  operatorId,
  deviceTimeEnergyStatsOneNetwork,
  get1cTimeEnergyStatsOneNetwork,
  deviceRevenueEnergyStatsOneNetwork,
  get1cRevenueEnergyStatsOneNetwork,
  networkDetail,
  selectedTime,
  startTime,
  endTime,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      <Box>
        <OcpiGraphBoxOne
          deviceTimeEnergyStatsOneNetwork={deviceTimeEnergyStatsOneNetwork}
          get1cTimeEnergyStatsOneNetwork={get1cTimeEnergyStatsOneNetwork}
          selectedTime={selectedTime}
          startTime={startTime}
          endTime={endTime}
          networkDetail={networkDetail}
        />
      </Box>
      <Box>
        <OcpiGraphBoxTwo
          deviceRevenueEnergyStatsOneNetwork={deviceRevenueEnergyStatsOneNetwork}
          get1cRevenueEnergyStatsOneNetwork={get1cRevenueEnergyStatsOneNetwork}
          selectedTime={selectedTime}
          startTime={startTime}
          endTime={endTime}
          networkDetail={networkDetail}
        />
      </Box>
      {/* <Box>
        <OcpiGraphBoxThree />
      </Box> */}
    </Box>
  );
};

export default OcpiMainGraphBox;
