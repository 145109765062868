import { Box } from "@mui/material";
import React, { useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";
import HubTimeFilter from "../../Filters/HubTimeFilter";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import { TimeProvider } from "../../../Hooks/TimeContext";
import TempCustomTable from "../../custom/TempCustomTable";
import TempTableFilters from "../../charging-sessions/TempTableFilters";

const AllSession = ({
  columns,
  rows,
  isActive,
  chargingPointId,
  tabTitle,
  totalCounts,
  page,
  setPage,
  limit,
  search,
  setSearch,
  hubStationSession,
  filters,
  setFilters,
}) => {
  return (
    <StationProvider>
      <TimeProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {/* filters */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {filters ? (
              <TempTableFilters
                isActive={isActive}
                chargingPointId={chargingPointId}
                filters={filters}
                setFilters={setFilters}
                tabTitle={tabTitle}
              />
            ) : (
              ""
            )}
          </Box>

          {/* Table */}
          {filters ? (
            <TempCustomTable
              columns={columns}
              rows={rows}
              modalHeading={"All Sessions"}
              actionType={""}
              totalCounts={totalCounts}
              filters={filters}
              setFilters={setFilters}
              isRowClickable={true}
              clickableColumns={["_id"]}
              columnClickPaths={{
                _id: hubStationSession
                  ? "/hub_management/charging_sessions"
                  : "/charging_stations/charging_sessions",
              }}
              columnKeyMappings={{
                station: "_id",
              }}
              hubSession={hubStationSession}
            />
          ) : (
            ""
          )}
        </Box>
      </TimeProvider>
    </StationProvider>
  );
};

export default AllSession;
