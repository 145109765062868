import { Box, Typography } from "@mui/material";
import React from "react";
import ValueChip from "../Chips/ValueChip";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";

const ValueCardSmall = ({
  data,
  topGraphBox,
  title,
  previousDashboardData,
  selectedTime,
}) => {
  // const profitColor = data.profit.startsWith("-") ? "#790000" : "#027900";
  // const compareColor = data.compare.startsWith("-") ? "#790000" : "#027900";

  const currencySymbol = GetCurrencySymbol();

  // Helper function to format value with + sign for positive values
  const formatValue = (value) => {
    if (typeof value === "string" && value.startsWith("-")) {
      return value;
    }
    return `+${value}`;
  };

  const changeIcons = () => {
    if (title === "1C Wallet Balance") {
      return "wallet";
    } else {
      return "info"; // Default icon if not provided
    }
  };

  const changevalue = () => {
    if (title === "1C Wallet Balance") {
      return `${currencySymbol} ${data?.totalWalletBalance}`;
    } else {
      return data?.value || "N/A";
    }
  };

  const compareResultWalletRecharge =
    data?.totalWalletAmountAdded -
    previousDashboardData?.totalWalletAmountAdded;

  const changeCompare = () => {
    switch (title) {
      case "Wallet Recharge (INR)":
        return compareResultWalletRecharge;

      default:
        return "No Data" || "N/A";
    }
  };

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  return (
    <Box
      sx={{
        padding: topGraphBox === "true" ? "12px" : "24px",
        borderRadius: "8px",
        background: "#fff",
        border: topGraphBox === "true" ? "1px solid #ddd" : "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Box=1 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(41, 45, 51, 0.08)",
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              marginRight: "8px",
            }}
          >
            <span
              style={{ color: "#292D33" }}
              className="material-symbols-outlined"
            >
              {changeIcons()}
            </span>
          </Box>
          <Box>
            <Typography
              textAlign={"start"}
              fontSize={"14px"}
              fontFamily={"mySecondFont"}
              color={"#666"}
            >
              {title}
            </Typography>
            <Typography
              textAlign={"start"}
              fontSize={"clamp(1.25rem, 1.1981rem + 0.2516vw, 1.5rem)"}
              color={"#0C0C0C"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
            >
              {changevalue()}
            </Typography>
          </Box>
        </Box>
        {/* Box=2 */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            alignItems: "flex-end",
          }}
        >
          <ValueChip
            data={data}
            previousPaymentWalletData={previousDashboardData}
            title="Wallet Recharge"
          />
          <Typography
            fontFamily={"myThirdFont"}
            color={"#666"}
            sx={{ textAlign: "right",fontSize:"12px", lineHeight:"14px" }}
          >
            <span style={{  color: getCompareColor(changeCompare()), fontFamily: "mySecondFont" }}>
              {changeCompare()}
            </span>{" "}
            than {getComparisonText()}
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default ValueCardSmall;
