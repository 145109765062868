import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import TopGraphBox from "../../dashboard/TopGraphBox";
import VisitUserGraph from "../../Graphs/HubManagementGraphs/VisitUserGraph";
import ValueCard from "../../dashboard/ValueCard";
import PassValueGraph from "../../Graphs/HubManagementGraphs/PassValueGraph";
import DoubleLineGraph from "../../Graphs/DoubleLineGraph";
import GraphZoomButton from "../../../utils/GraphZoomButton";
import TopPerformingHubCard from "./TopPerformingHubCard";
import ComposedGraphSkeleton from "../../Skeleton/ComposedGraphSkeleton";
import SkeletonValueCard from "../../Skeleton/SkeletonValueCard";
import TopPerformingSkeleton from "../../Skeleton/TopPerformingSkeleton";

const HubOverviewBox = ({
  previousDashboardData,
  data,
  selectedTime,
  startTime,
  endTime,
  loading,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* section */}
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item md={8.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TopGraphBox
              hubSession={true}
              data={data}
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
              loading={loading}
            />
          </Box>
        </Grid>
        <Grid item md={3.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <VisitUserGraph
              data={data}
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item md={8.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              gap: "16px",
              padding: "24px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Grid item md={8} sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                {loading ? (
                  <ComposedGraphSkeleton /> // Show Skeleton when loading is true
                ) : (
                  <DoubleLineGraph
                    data={data}
                    selectedTime={selectedTime}
                    startTime={startTime}
                    endTime={endTime}
                    graphTitle="Energy vs Hub Session"
                  />
                )}
              </Box>
              <Box sx={{}}>
                <GraphZoomButton
                  GraphComponent={DoubleLineGraph}
                  graphProps={{ data, selectedTime, startTime, endTime }}
                />
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              {loading ? (
                <SkeletonValueCard /> // Show Skeleton when loading is true
              ) : (
                <ValueCard
                  title="Total Energy Consumed (kWh)"
                  topGraphBox="true"
                  selectedTime={selectedTime}
                  data={data}
                  previousDashboardData={previousDashboardData}
                />
              )}
              {loading ? (
                <SkeletonValueCard /> // Show Skeleton when loading is true
              ) : (
                <ValueCard
                  title="Total Hub Sessions"
                  topGraphBox="true"
                  selectedTime={selectedTime}
                  data={data}
                  previousDashboardData={previousDashboardData}
                />
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item md={3.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <PassValueGraph data={data} passType={true} />
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {loading ? (
              <TopPerformingSkeleton /> // Show Skeleton when loading is true
            ) : (
              <TopPerformingHubCard
                data={data}
                title={"Top Station (Duration)"}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {loading ? (
              <TopPerformingSkeleton /> // Show Skeleton when loading is true
            ) : (
              <TopPerformingHubCard
                data={data}
                title={"Top Station (Energy)"}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {loading ? (
              <TopPerformingSkeleton /> // Show Skeleton when loading is true
            ) : (
              <TopPerformingHubCard
                data={data}
                title={"Top Station (Pass Sold)"}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {loading ? (
              <TopPerformingSkeleton /> // Show Skeleton when loading is true
            ) : (
              <TopPerformingHubCard
                data={data}
                title={"Top Pass Holder (Duration)"}
                passHolderCard={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {loading ? (
              <TopPerformingSkeleton /> // Show Skeleton when loading is true
            ) : (
              <TopPerformingHubCard
                data={data}
                title={"Top Pass Holder (Frequent Visit)"}
                passHolderCard={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {loading ? (
              <TopPerformingSkeleton /> // Show Skeleton when loading is true
            ) : (
              <TopPerformingHubCard
                data={data}
                title={"Top Pass Holder (Energy)"}
                passHolderCard={true}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HubOverviewBox;
