import React from "react";
import SettlementsBox from "./SettlementsBox";
import { Box } from "@mui/material";
import { settlementColumns, settlementsData } from "../../../utils/DummyData";

const Settlements = () => {
  return (
    <Box>
      <SettlementsBox column={settlementColumns()} row={settlementsData} />
    </Box>
  );
};

export default Settlements;
