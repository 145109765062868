import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import GalleryCard from "./GalleryCard";
import { useImageZoomIn } from "../../../utils/useImageZoomIn";

const GalleryBox = ({ data }) => {
  const { handleImageClick, ImageDialog } = useImageZoomIn(); // Use the custom hook

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "200" }}
            className="material-symbols-outlined"
          >
            gallery_thumbnail
          </span>
          <Heading text={"Gallery"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* Gallery - Cards */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {data.stationImgs.map((imgData, index) => (
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            sx={{ display: "flex", flexDirection: "column" }}
            key={imgData._id} // Use a unique key from imgData
          >
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <GalleryCard
                item={imgData.image.url}
                onClick={() => handleImageClick(imgData.image.url, imgData.imageTag)} // Pass the image tag
              />
            </Box>
            <Typography
              fontFamily={"myThirdFont"}
              fontSize={"14px"}
              color={"#2F2F2F"}
              textAlign={"start"}
              sx={{ marginTop: "4px" }}
            >
              {imgData?.imageTag}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Image Dialog */}
      <ImageDialog />
    </Box>
  );
};

export default GalleryBox;
