import React from "react";
import AlertsOrderComponent from "./AlertsOrderComponent";
import { Box, Divider } from "@mui/material";
import Heading from "../../../utils/Heading";

const AvgResolutionTimeAlerts = ({
  totalType,
  alertsByGroup,
  alertsByAvgResolution,
}) => {
  console.log("1111qytrytqqq", alertsByAvgResolution);
 
  
//    // Extract data from the response
//    const avgResolutionData = alertsByAvgResolution || [];

//    // Initialize an empty object for severity data
//    const severityData = {
//      CRITICAL: "0",
//      HIGH: "0",
//      MEDIUM: "0",
//      LOW: "0",
//    };
 
//    // Populate severityData based on the incoming response
//    avgResolutionData.forEach((item) => {
//      const { _id, timeTaken } = item;
//      if (_id && timeTaken && timeTaken.formattedTime) {
//        severityData[_id] = timeTaken.formattedTime; // Set the formatted time for each severity level
//      }
//    });
 
//    // Prepare the final data object to send to the AlertsOrderComponent
//    const avgResolutionTime = {
//      severityData,
//      totalCount: avgResolutionData.length, // Example: set the total count as the length of the array (adjust as needed)
//    };

const avgResolutionData = alertsByAvgResolution || [];

// Initialize an empty object for severity data
const severityData = {
  CRITICAL: "0",
  HIGH: "0",
  MEDIUM: "0",
  LOW: "0",
};

// Initialize variables to accumulate total days and hours
let totalDays = 0;
let totalHours = 0;

// Populate severityData and accumulate total time based on the incoming response
avgResolutionData.forEach((item) => {
  const { _id, timeTaken } = item;
  if (_id && timeTaken && timeTaken.formattedTime) {
    severityData[_id] = timeTaken.formattedTime; // Set the formatted time for each severity level

    // Accumulate total days and hours
    totalDays += timeTaken.days || 0;
    totalHours += timeTaken.hours || 0;
  }
});

// Convert total hours into days if they exceed 24
const additionalDays = Math.floor(totalHours / 24);
totalDays += additionalDays;
totalHours = totalHours % 24; // Get the remainder of hours

// Prepare the final data object to send to the AlertsOrderComponent
const avgResolutionTime = {
  severityData,
  totalCount: `${totalDays}d ${totalHours}h`, // Format the total time as "X d Y h"
};

console.log("Final avgResolutionTime:", avgResolutionTime);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/ev_charger_icon.png" alt="" />
            <Heading text={"Avg. resolution time"} />
          </Box>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>
      <AlertsOrderComponent totalType={totalType} data={avgResolutionTime} />
    </Box>
  );
};

export default AvgResolutionTimeAlerts;
