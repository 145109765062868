import {
  Box,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import StatusChip from "../Chips/StatusChip";
import ActionIcons from "../../utils/ActionIcons";
import { useDispatch } from "react-redux";
import { getOneOrg } from "../../redux/action/companyManagement/companyManagementSlice";
import { FormatTimestamp } from "../../utils/FormatDateTime";
import PaymentModal from "../Modals/PaymentModal";
import ReviewModal from "../Modals/ReviewModal";
import CircularProgress from "@mui/material/CircularProgress";
import { useTablePage } from "../../Hooks/TablePageContext";
import RFIDConfirmModal from "../Modals/RFID/RFIDConfirmModal";
import RFIDUpdateModal from "../Modals/RFID/RFIDUpdateModal";
import RejectedRFIDModal from "../Modals/RFID/RejectedRFIDModal";
import { setSelectedRowDataRedux } from "../../redux/action/RFID/rfidSlice";
import { formatPhoneNumber } from "../../utils/countryCode";
import TablePaginationActions from "../../utils/TablePagination";
import ImageModal from "../Modals/HubManagement/ImageModal";
import ChargingPassDetailModal from "../Modals/HubManagement/ChargingPassDetailModal";
import GuardShiftModal from "../Modals/HubManagement/GuardShiftModal";
import { useSelector } from "react-redux";
import { countryCodeToCountry } from "../../utils/currency/countryCodeToCountry";
import TableSkeleton from "../Skeleton/TableSkeleton";
import { getDisplayLabel } from "../../utils/Enum/enumFormator";

export const DateTimeDisplay = ({ dateTime }) => {
  const date = new Date(dateTime);

  // Format the date and time
  const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // This ensures the AM/PM format
  });

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        color={"#2F2F2F"}
        fontSize={"14px"}
        fontFamily={"mySecondFont"}
        textAlign={"start"}
      >
        {formattedDate}
      </Typography>
      <Typography
        color={"#666"}
        fontSize={"14px"}
        fontFamily={"myThirdFont"}
        textAlign={"start"}
      >
        {formattedTime} {/* AM/PM will be displayed */}
      </Typography>
    </Box>
  );
};

const TempCustomTable = ({
  columns,
  rows,
  isRowClickable,
  actionType,
  clickableColumns = [],
  rowClickPath,
  columnClickPaths,
  onRowClick,
  selectedRow,
  columnKeyMappings,
  specialColumns = [],
  modalHeading,
  statsHeading,
  userRoleProp,
  hubSession,
  loading,
  filters,
  setFilters,
  totalCounts,
}) => {
  const { page, setPage, limit } = filters;
  // const { page, setPage } = useTablePage();
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);
  const [tableData, setTableData] = React.useState(rows);
  const [selectedRowData, setSelectedRowData] = React.useState(null); // State for the selected row data
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [openChargingSessionDetails, setOpenChargingSessionDetails] =
    React.useState(false);

  const [openGuardCollectionModal, setOpenGuardCollectionModal] =
    React.useState(false);

  // guard - image - modal
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(true);
  };

  const { profile } = useSelector((state) => state.profile);

  React.useEffect(() => {
    setTableData(rows);
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    // setPage(newPage + 1);
    setFilters((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(limit);
    // setPage(page);
    setFilters((prev) => ({ ...prev, page: page }));
  };

  // Calculate the rows to be displayed on the current page
  const displayRows = tableData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const getCountryCode = (phoneNumber) => {
    if (!phoneNumber) return ""; // Check if phoneNumber is defined
    const match = phoneNumber.match(/^(\+\d{1,2})/); // Match the country code
    return match ? match[1] : ""; // Return the country code or an empty string
  };

  // Example: This will check and map the country code dynamically
  const countyCheck = profile?.phoneNumber;
  const countryCode = getCountryCode(countyCheck);
  const countryCodeMapped =
    countryCodeToCountry[countryCode] || countryCode || "IN"; // Change +91 to IN

  const handleRowClick = (row, rowIndex) => {
    if (!row) {
      console.error("Row is undefined or null");
      return <Typography>No Data Available</Typography>;
    }
    // if (
    //   !row ||
    //   (modalHeading === "Payments" &&
    //     clickableColumns.includes("paymentId") &&
    //     !row.paymentId)
    // ) {
    //   console.error("Row or paymentId is undefined");
    //   return;
    // }

    // if (modalHeading === "Payments" && clickableColumns.includes("paymentId")) {
    //   setSelectedRowData(row); // Set selected row data for modal
    //   setIsModalOpen(true); // Open the modal
    // }
    if (modalHeading === "Reviews") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setIsModalOpen(true);
    } else if (modalHeading === "Retail RFID") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setIsModalOpen(true);
    } else if (modalHeading === "Rejected RFID") {
      // Set selected row data for modal
      setSelectedRowData(row);
      dispatch(setSelectedRowDataRedux(row));
      // Open the modal
      setIsModalOpen(true);
    } else if (modalHeading === "Hub Charging Passes") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setOpenChargingSessionDetails(true);
    } else if (modalHeading === "Hub Daily Sheet") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setOpenGuardCollectionModal(true);
    } else {
      // Existing navigation logic
      const clickableColumnIds = clickableColumns.filter((col) => row[col]);

      if (clickableColumnIds.length === 1) {
        const column = clickableColumnIds[0];
        const path = columnClickPaths[column];
        const key = columnKeyMappings[column] || column;
        navigate(`/${countryCodeMapped}${path}/${row[key]}`, {
          replace: true,
          ...(hubSession ? { state: { hubSession: true } } : {}),
        });
      } else if (clickableColumnIds.length > 1) {
        const firstColumn = clickableColumnIds[0];
        const path = columnClickPaths[firstColumn];
        const key = columnKeyMappings[firstColumn] || firstColumn;
        navigate(`/${countryCodeMapped}${path}/${row[key]}`);
      } else if (isRowClickable && rowClickPath) {
        navigate(`/${countryCodeMapped}${rowClickPath}/${row?.code}`, {
          replace: true,
        });
      }
    }

    // Call the onRowClick callback if provided
    if (onRowClick) {
      onRowClick(row);
    }
  };

  // const handleColumnClick = (column, row) => {
  //   const path = columnClickPaths[column.id];
  //   const key = columnKeyMappings[column.id] || column.id;
  //   if (path) {
  //     navigate(`${path}/${row?.[key]}`, { replace: true });
  //   }
  // };
  const handleColumnClick = (column, row) => {
    const path = columnClickPaths[column.id];
    const key = columnKeyMappings[column.id] || column.id;

    if (path) {
      // Extract the ID from nested objects
      const value = row[key];
      const idToNavigate =
        (key === "station" && value ? value._id : row[key]) || "";
      navigate(`/${countryCodeMapped}${path}/${idToNavigate}`, {
        replace: true,
      });
    }
  };

  // const handleConditionalDispatch = (columnId, key) => {
  //   if (modalHeading === "Companies Management" || columnId === "name") {
  //     dispatch(getOneOrg(key));
  //   }
  // };

  const getSpecialBackground = (columnId, row) => {
    if (columnId === "message") {
      return row?.direction === "Incoming" ? "#027900" : "#E50000";
    }
    if (columnId === "_id") {
      return row.status === true ? "#027900" : "#790000";
    }
    if (columnId === "paymentId") {
      return row.status === "Success" ? "#027900" : "#790000";
    }
    if (columnId === "paymentID") {
      return row.paymentStatus === "credited" ? "#027900" : "#790000";
    }
    if (columnId === "name") {
      return row.active === true ? "#027900" : "#790000";
    }
    return "transparent";
  };

  // Format value to two decimal places if it has more than two decimal places
  const formatValue = (value, columnId, row) => {
    if (typeof value === "object" && value !== null) {
      // Handle specific cases for nested objects
      if (columnId === "org" || "station") {
        return value.name || ""; // Assuming you want to display the name field
      }
      if (columnId === "customer") {
        // Assuming customer is an object containing name and phone
        return `${value.name || ""} (${value.phone || ""})`; // Format both name and phone
      }
      if (columnId === "createdBy") {
        return value.name || ""; // Assuming you want to display the name field
      }
      if (columnId === "stationId") {
        return value.name || ""; // Assuming you want to display the name field
      }
    }

    // Handle special case for energyConsumed
    if (columnId === "energyConsumed") {
      if (row.energyConsumed !== undefined) {
        return (parseFloat(row.energyConsumed) || 0).toFixed(2);
      }
      const stopValue = parseFloat(row.stopMeterValue) || 0;
      const startValue = parseFloat(row.startMeterValue) || 0;
      return ((stopValue - startValue) / 1000).toFixed(2);
    }

    // Handle expiry-date formatting
    if (columnId === "expiry_date") {
      const date = new Date(value); // Assuming value is a valid date string
      const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }); // Format: hh:mm AM/PM
      return (
        <Box>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
          >
            {formattedDate}
          </Typography>
        </Box>
      );
    }

    // Handle expiry-date formatting
    if (columnId === "assignedDate") {
      const date = new Date(value); // Assuming value is a valid date string
      const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }); // Format: hh:mm AM/PM
      return (
        <Box>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
          >
            {formattedDate}
          </Typography>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
          >
            {formattedTime}
          </Typography>
        </Box>
      );
    }

    // Check if value is a number or a string that represents a number
    if (
      typeof value === "number" ||
      (!isNaN(parseFloat(value)) && isFinite(value))
    ) {
      const numberValue = parseFloat(value);

      // Only format if the number has more than two decimal places
      if (
        numberValue.toString().includes(".") &&
        numberValue.toString().split(".")[1].length > 2
      ) {
        return numberValue.toFixed(2);
      }
      return numberValue.toString(); // Return the value as is if no formatting is needed
    }

    // Return non-numeric values unchanged
    return value;
  };

  const formatSpecialValue = (value, columnId) => {
    if (columnId === "sessionStart" || columnId === "sessionStop") {
      // Ensure value is treated as a number (milliseconds) and convert to Date
      const date = new Date(parseInt(value, 10)); // Convert milliseconds to Date
      const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }); // Format: hh:mm AM/PM
      return (
        <Box>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
          >
            {formattedDate}
          </Typography>
          <Typography
            color={"#666"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
          >
            {formattedTime}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const formatRating = (rating) => {
    switch (rating) {
      case 5:
        return "Ok";
      case 10:
        return "Great";
      case 3:
        return "Bad";
      default:
        return ""; // Return an empty string for other ratings
    }
  };

  if (!rows) {
    return <Typography>hoo</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign={"start"}
          fontSize={"14px"}
          fontFamily={"myThirdFont"}
          color="#2F2F2F"
        >
          Showing{" "}
          {modalHeading === "Transactions" || "Payment History" ? "all" : ""}{" "}
          {totalCounts} {modalHeading}
        </Typography>
        {statsHeading === "true" ? (
          <Typography
            textAlign={"start"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
            color="#2F2F2F"
          >
            *stats from created date to present
          </Typography>
        ) : (
          ""
        )}
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#292D33", padding: "8px 16px" }}>
              {columns
                .filter(
                  (item) => !(item.id === "action" && actionType.length === 0)
                )
                ?.map((item) => {
                  return (
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "myFirstFont",
                        textWrap: "nowrap",
                      }}
                      key={item.id}
                      align={item.align}
                    >
                      {item.label}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <TableSkeleton rows={rows} columns={columns} />
                </TableCell>
              </TableRow>
            ) : tableData?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <Typography sx={{ fontFamily: "mySecondFont" }}>
                    No Data Available
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              tableData?.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: isRowClickable ? "pointer" : "default",
                    backgroundColor:
                      selectedRow === rowIndex ? "red" : "transparent",
                    "&:hover": {
                      backgroundColor: isRowClickable ? "#f5f5f5" : "inherit",
                    },
                  }}
                  onClick={
                    isRowClickable ? () => handleRowClick(row) : undefined
                  }
                >
                  {columns
                    .filter(
                      (item) =>
                        !(item.id === "action" && actionType.length === 0)
                    )
                    .map((column) => (
                      <TableCell
                        sx={{
                          color: "#2F2F2F",
                          padding:
                            column.id === "createdAt" ||
                            column.id === "created_at" ||
                            column.id === "sessionStart" ||
                            column.id === "sessionStop" ||
                            column.id === "updatedAt" ||
                            column.id === "updated_at" ||
                            column.id === "purchaseDate" ||
                            column.id === "validTill" ||
                            column.id === "vehicle_entry_time" ||
                            column.id === "purchase_date"
                              ? "4px 16px"
                              : "8px 16px",
                          fontFamily: "mySecondFont",
                          textTransform: "capitalize",
                          textWrap: "nowrap",
                          textDecoration:
                            isRowClickable &&
                            clickableColumns.includes(column.id) &&
                            column.id !== "action"
                              ? "underline"
                              : "none",
                          cursor:
                            isRowClickable &&
                            clickableColumns.includes(column.id) &&
                            column.id !== "action"
                              ? "pointer"
                              : "default",
                        }}
                        key={column.id}
                        align={column.align}
                        onClick={(e) => {
                          if (
                            isRowClickable &&
                            clickableColumns.includes(column.id) &&
                            column.id !== "action"
                          ) {
                            e.stopPropagation();
                            handleColumnClick(column, row);
                          }
                        }}
                      >
                        {column.id === "action" ? (
                          <ActionIcons
                            actions={actionType}
                            row={row}
                            heading={modalHeading}
                            setTableData={setTableData}
                          />
                        ) : column.id === "sr" ? (
                          <Typography
                            sx={{
                              textAlign: "start",
                              fontFamily: "mySecondFont",
                              color: "#2F2F2F",
                              fontSize: "14px",
                            }}
                          >
                            {(page - 1) * rowsPerPage + rowIndex + 1}
                          </Typography>
                        ) : column.id === "status" ? (
                          <Box>
                            <StatusChip text={row[column.id]} />
                          </Box>
                        ) : column.id === "rating" ? (
                          <Box>
                            <StatusChip text={formatRating(row[column.id])} />
                          </Box>
                        ) : column.id === "mode" ||
                          column.id === "from" ||
                          column.id === "type" ||
                          column.id === "passType" ? (
                          <Box>
                            {getDisplayLabel(column.id, row[column.id])}
                          </Box>
                        ) : column.id === "deviceId" ? (
                          <Box>
                            {row[column.id] === null ? "N/A" : row[column.id]}
                          </Box>
                        ) : column.id === "ratingReviewImages" ? (
                          <Box>{row[column.id].length}</Box>
                        ) : column.id === "description" ? (
                          <Box
                            sx={{
                              width: "100px", // Set the width of the Box
                              overflow: "hidden", // Hide overflow content
                              textOverflow: "ellipsis", // Show ellipsis for overflowing text
                              whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                            }}
                          >
                            {row[column.id].length > 0 ? row[column.id] : "N/A"}
                          </Box>
                        ) : column.id === "userId" ? (
                          <Box>
                            {typeof row[column.id] === "string"
                              ? // Apply formatting to insert a space after the country code
                                row[column.id].replace(
                                  /(\+\d{2})(\d+)/,
                                  "$1 $2"
                                )
                              : row[column.id]}
                          </Box>
                        ) : column.id === "Reviewed On" ? (
                          <Box>
                            {typeof row[column.id] === "string"
                              ? // Apply formatting to insert a space after the country code
                                row[column.id].replace(
                                  /(\+\d{2})(\d+)/,
                                  "$1 $2"
                                )
                              : row[column.id]}
                          </Box>
                        ) : column.id === "phoneNumber" ? (
                          <Box>{formatPhoneNumber(row.phoneNumber)}</Box>
                        ) : specialColumns.includes(column.id) ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "4px",
                                height: "16px",
                                borderRadius: "50px",
                                background: getSpecialBackground(
                                  column.id,
                                  row
                                ),
                                marginRight: "8px",
                              }}
                            />
                            {formatSpecialValue(row[column.id], column.id) ||
                              formatValue(row[column.id])}
                          </Box>
                        ) : column.id === "name" &&
                          modalHeading === "Guards" ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {row?.profilePic && (
                              <img
                                src={row?.profilePic}
                                alt="Guard"
                                style={{
                                  width: "56px",
                                  height: "56px",
                                  borderRadius: "50%",
                                  marginRight: "4px", // Gap of 4px
                                  padding: "1px",
                                  background: "#ccc",
                                  cursor: "pointer",
                                  objectFit: "contain",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents the click from bubbling up to the row
                                  handleImageClick(row?.profilePic);
                                }}
                              />
                            )}
                            <Typography
                              sx={{
                                textAlign: "start",
                                fontFamily: "mySecondFont",
                                color: "#2F2F2F",
                                fontSize: "14px",
                              }}
                            >
                              {row.name}
                            </Typography>
                          </Box>
                        ) : column.id === "createdAt" ||
                          column.id === "updatedAt" ||
                          column.id === "updated_at" ||
                          column.id === "purchaseDate" ||
                          column.id === "validTill" ||
                          column.id === "last_updated" ||
                          column.id === "vehicle_entry_time" ||
                          column.id === "nestedLastUpdated" ||
                          column.id === "purchase_date" ? (
                          <DateTimeDisplay dateTime={row[column.id]} />
                        ) : column.id === "sessionStart" ? (
                          <FormatTimestamp timestamp={row.sessionStart} />
                        ) : column.id === "sessionStop" ? (
                          <FormatTimestamp timestamp={row.sessionStop} />
                        ) : column.id === "checkInTime" ? (
                          <FormatTimestamp timestamp={row.checkInTime} />
                        ) : column.id === "checkOutTime" ? (
                          <FormatTimestamp timestamp={row.checkOutTime} />
                        ) : column.id === "vehicle_out_time" ? (
                          <FormatTimestamp timestamp={row.vehicle_out_time} />
                        ) : column.id === "txnTime" ? (
                          <FormatTimestamp timestamp={row[column.id]} />
                        ) : (
                          formatValue(row[column.id], column.id, row)
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalCounts > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalCounts}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ "& .MuiTablePagination-toolbar": { fontSize: "0.8rem" } }}
        />
      )}

      {/* Modal to display row details */}
      {selectedRowData && isModalOpen && (
        <PaymentModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          row={selectedRowData}
        />
      )}
      {selectedRowData && isModalOpen && (
        <ReviewModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          row={selectedRowData}
        />
      )}
      {selectedRowData && isModalOpen && modalHeading === "Retail RFID" && (
        <RFIDUpdateModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          data={selectedRowData}
        />
      )}
      {selectedRowData && isModalOpen && modalHeading === "Rejected RFID" && (
        <RFIDConfirmModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          data={selectedRowData}
        />
      )}
      {selectedRowData &&
        openChargingSessionDetails &&
        modalHeading === "Hub Charging Passes" && (
          <ChargingPassDetailModal
            open={openChargingSessionDetails}
            handleClose={() => setOpenChargingSessionDetails(false)}
            data={selectedRowData}
          />
        )}

      {selectedRowData &&
        openGuardCollectionModal &&
        modalHeading === "Hub Daily Sheet" && (
          <GuardShiftModal
            open={openGuardCollectionModal}
            handleClose={() => setOpenGuardCollectionModal(false)}
            data={selectedRowData}
          />
        )}

      {/* guard - image */}
      <ImageModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        imageSrc={selectedImage}
      />
    </Box>
  );
};

export default TempCustomTable;
