// import React from 'react';
// import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// // Predefined list of valid _id values
// const validIds = [
//   "EmergencyStop",
//   "EVDisconnected",
//   "HardReset",
//   "Local",
//   "Charger Not Responding",
//   "Other",
//   "PowerLoss",
//   "Reboot",
//   "Remote",
//   "SoftReset",
//   "UnlockCommand",
// ];

// const StopReasonBarGraph = ({ data }) => {
//   console.log("hhggg", data)
//   // If no data is passed, return null or an empty chart
//   if (!data || data.length === 0) return null;

//   // Initialize chartData with all valid _id values and default percentage of 0
//   const chartData = validIds.map(id => {
//     const item = data.find(d => d._id === id);
//     return {
//       name: id, // X-axis label (_id value)
//       percentage: item ? item?.percentage.toFixed(2) : 0, // Set percentage to 0 if not found
//     };
//   });

//   // Custom tick component to wrap long X-axis labels
// const CustomXAxisTick = ({ x, y, payload }) => {
//     const label = payload.value;
//     const words = label.split(' '); // Split the label by spaces

//     // You can adjust the maximum number of words per line here
//     const maxWordsPerLine = 2;

//     // Wrap the text into multiple lines
//     const lines = [];
//     for (let i = 0; i < words.length; i += maxWordsPerLine) {
//       lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
//     }

//     return (
//       <g transform={`translate(${x},${y})`}>
//         {lines.map((line, index) => (
//           <text key={index} x={0} y={index * 15} textAnchor="middle" fontSize="12px" fill="#666">
//             {line}
//           </text>
//         ))}
//       </g>
//     );
//   };

//   return (
//     <div style={{ width: '100%', height: 400 }}>
//       <ResponsiveContainer width="100%" height="90%">
//         <BarChart data={chartData} margin={{ top: 20, right: 20, bottom: 50, left: 10 }}>
//           {/* Adding a dotted horizontal grid */}
//           <CartesianGrid stroke="#DDDDDD" strokeDasharray="5 3" vertical={false} />

//           {/* X Axis - Display the '_id' field from data */}
//           <XAxis
//             dataKey="name"
//             tick={{
//               fontSize: "12px",
//               fontFamily: "mySecondFont",
//               fill: "#666",
//               fontWeight: "500",
//               lineHeight: "16px",

//             }}
//             // axisLine={false}
//             // padding={{ left: 0 }}

//             axisLine={false}
//             padding={{ left: 0, right: 0 }}
//             tickMargin={5}  // Ensure the ticks are not too close to the axis
//             angle={-25}  // Rotate labels to avoid overlap
//             textAnchor="end"  // Align the text after rotation
//             interval={0}  // Ensure that all labels are shown
//           />

//           {/* Y Axis - Show data points but remove the line */}
//           <YAxis
//             tick={{
//               fontSize: "12px",
//               fontFamily: "myThirdFont",
//               fill: "#666",
//               fontWeight: "500",
//               lineHeight: "16px",
//             }}
//             axisLine={false}
//             tickLine={false}
//             domain={[0, 100]} // Percentage from 0 to 100
//             ticks={[0, 20, 40, 60, 80, 100]} // Predefined ticks for Y Axis
//             tickFormatter={(tick) => `${tick}%`} // Format ticks as percentages
//           />

//           {/* Bar Chart Component with Solid Red Color */}
//           <Bar
//             dataKey="percentage"
//             barSize={40}  // Reduced bar width for better spacing
//             fill="#E50000" // Solid color red for the bars
//             radius={2} // Rounded corners on top of bars
//           />

//           {/* Tooltip to show the data on hover */}
//           <Tooltip
//           cursor={{fill: 'transparent'}}
//             wrapperStyle={{
//               fontSize: '14px',
//               fontFamily: 'mySecondFont',
//               color: '#333',
//             }}
//           />

//           {/* Legend */}
//           <Legend
//             wrapperStyle={{
//               padding: 10,
//               fontSize: "14px",
//               fontFamily: "mySecondFont",
//               color: "#666",
//             }}
//             verticalAlign="top"
//             align="center"
//             iconSize={20}
//           />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default StopReasonBarGraph;

////-------------Bar graph empty fix
import { Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

// Predefined list of valid _id values
const validIds = [
  "EmergencyStop",
  "EVDisconnected",
  "HardReset",
  "Local",
  "Charger Not Responding",
  "Other",
  "PowerLoss",
  "Reboot",
  "Remote",
  "SoftReset",
  "UnlockCommand",
];

const StopReasonBarGraph = ({ data }) => {
  console.log("hhggg", data);

  // If no data is passed, we proceed with empty chart data where count and percentage are 0 for all
  const chartData = validIds.map((id) => {
    const item = data.find((d) => d._id === id) || {}; // Find the matching data item
    return {
      name: id, // X-axis label (_id value)
      count: item.count || 0, // Set count to 0 if not found
      percentage: item.percentage || 0, // Set percentage to 0 if not found
    };
  });

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 20, bottom: 50, left: 10 }}
        >
          {/* Adding a dotted horizontal grid */}
          <CartesianGrid
            stroke="#DDDDDD"
            strokeDasharray="5 3"
            vertical={false}
          />

          {/* X Axis - Display the '_id' field from data */}
          <XAxis
            dataKey="name"
            tick={{
              fontSize: "12px",
              fontFamily: "mySecondFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            // axisLine={false}
            // padding={{ left: 0 }}

            axisLine={false}
            padding={{ left: 0, right: 0 }}
            tickMargin={5} // Ensure the ticks are not too close to the axis
            angle={-25} // Rotate labels to avoid overlap
            textAnchor="end" // Align the text after rotation
            interval={0} // Ensure that all labels are shown
          />

          {/* Y Axis - Show data points but remove the line */}
          <YAxis
            tick={{
              fontSize: "12px",
              fontFamily: "myThirdFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
            tickLine={false}
          />

          {/* Bar Chart Component with Solid Red Color */}
          <Bar
            dataKey="count"
            barSize={40} // Reduced bar width for better spacing
            fill="#E50000" // Solid color red for the bars
            radius={2} // Rounded corners on top of bars
          />

          {/* Tooltip to show the data on hover */}
          <Tooltip
            cursor={{ fill: "transparent" }}
            formatter={(value, name, props) => {
              const count = props.payload ? props.payload.count : 0;
              const percentage = props.payload ? props.payload.percentage : 0;
              return [
                <>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "mySecondFont",
                      color: "#E50000",
                      textAlign: "left",
                    }}
                  >
                    Count: {count}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "mySecondFont",
                      color: "#333",
                      textAlign: "left",
                    }}
                  >
                    Percentage: {percentage.toFixed(2)}%
                  </Typography>
                </>,
              ];
            }}
            wrapperStyle={{
              fontSize: "14px",
              fontFamily: "mySecondFont",
              color: "#333",
            }}
          />

          {/* Legend */}
          <Legend
            wrapperStyle={{
              padding: 10,
              fontSize: "14px",
              fontFamily: "mySecondFont",
              color: "#666",
            }}
            verticalAlign="top"
            align="center"
            iconSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StopReasonBarGraph;
