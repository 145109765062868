import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TableBox from "./TableBox";
import {
  ActiveSessionsColumns,
  AllSessionsColumns,
} from "../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSessions } from "../../redux/action/sessions/sessionsSlice";
import { useTablePage } from "../../Hooks/TablePageContext";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    limit: 10,
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    setFilters({
      search: "",
      page: 1,
      limit: 10,
      startTime: null,
      endTime: null,
    });
  }, [selectedTab]);

  const dispatch = useDispatch();
  const { sessions, sessionsCount, loading, error } = useSelector(
    (state) => state.sessions
  );

  useEffect(() => {
    if (selectedTab === 1) {
      const now = new Date();
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - 6);
      const startTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
      const endTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

      setFilters((prev) => ({
        ...prev,
        startTime: startTime,
        endTime: endTime,
      }));
    }
  }, [selectedTab]);

  useEffect(() => {
    const payload = selectedTab === 0 ? true : false;
    dispatch(
      fetchAllSessions({
        ...filters,
        isActive: payload,
      })
    );
  }, [dispatch, selectedTab, filters]);

  const tabsData = [
    {
      label: "Active Sessions",
      content: (
        <Box>
          <TableBox
            columns={ActiveSessionsColumns()}
            rows={sessions}
            totalCounts={sessionsCount}
            selectedTab={selectedTab}
            isRowClickable={false}
            loading={loading}
            filters={filters}
            setFilters={setFilters}
            isActive={true}
          />
        </Box>
      ),
    },
    {
      label: "Last 7 Days Sessions",
      content: (
        <Box>
          <TableBox
            columns={AllSessionsColumns()}
            rows={sessions}
            totalCounts={sessionsCount}
            selectedTab={selectedTab}
            isRowClickable={true}
            loading={loading}
            filters={filters}
            setFilters={setFilters}
            isAllSessions={true}
            tabTitle={"Last7DaysSessions"}
            isActive={false}
          />
        </Box>
      ),
    },
    {
      label: "All Sessions",
      content: (
        <Box>
          <TableBox
            columns={AllSessionsColumns()}
            rows={sessions}
            totalCounts={sessionsCount}
            selectedTab={selectedTab}
            isRowClickable={true}
            loading={loading}
            filters={filters}
            setFilters={setFilters}
            isAllSessions={true}
            tabTitle={"ChargingSessions"}
            isActive={false}
          />
        </Box>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "8px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "20px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box sx={{ display: "none" }}>
          <MainHeading text={"Charging Sessions"} />
        </Box>
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
