import React, { useEffect, useState } from "react";

const TableSearchFilter = ({ filters, setFilters }) => {
  const { search } = filters;
  const [searchQuery, setSearchQuery] = useState(search);

  // Sync searchQuery with parent search only on search reset (e.g., selectedTab change)
  useEffect(() => {
    setSearchQuery(search);
  }, [search]);

  // Update parent search state when searchQuery changes (debounced)
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search !== searchQuery) {
        // setSearch(searchQuery);
        setFilters((prev) => ({ ...prev, search: searchQuery }));
      }
    }, 500); // Debounce delay

    return () => clearTimeout(timeout); // Cleanup timeout on component unmount or dependency change
  }, [searchQuery, search, filters]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update local state immediately
  };

  return (
    <>
      <input
        style={{
          padding: "10px 16px",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #c0c0c0",
          color: "#9C9C9B",
          fontFamily: "myThirdFont",
          height: "40px",
        }}
        value={searchQuery}
        onChange={handleSearchChange}
        type="text"
        placeholder="Search by columns name"
        className="chargingSessionTable"
      />
    </>
  );
};

export default TableSearchFilter;
