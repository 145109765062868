import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../../components/FinanceManager/Transactions/TopBar";

const Transactions = () => {
  return (
    <Box>
      <TopBar />
    </Box>
  );
};

export default Transactions;
