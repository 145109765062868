import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import FinanceSemiDoughnutChart from "../../Graphs/FinanceManager/FinanceSemiDoughnutChart";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

// Dummy data to be used if no data is provided via props
const dummyData = {
  refundAmount: 4500,
  refundPercentage: 15,
  totalPaidAmount: 4211,
  totalPaidAmountPercentage: 85,
};

const RefundDataBox = () => {

  const currencySymbol = GetCurrencySymbol();
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span className="material-symbols-outlined">sync_alt</span>
            <Heading text={"Refunds"} />
          </Box>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      <Grid container columnSpacing={2}>
        <Grid item md={8} sx={12}>
          <FinanceSemiDoughnutChart
            dataSet={"refundData"}
            centerText={"Refund %"}
            centerValue={`${dummyData?.refundPercentage}%`}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              width: "100%",
              mt: "12px",
            }}
          >
            <Typography
              color={"#666"}
              fontFamily={"mySecondFont"}
              fontSize={"16px"}
              lineHeight={"24px"}
              sx={{}}
            >
              Total Paid Amount
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"myFirstFont"}
              fontSize={"20px"}
              lineHeight={"28px"}
            >
              {currencySymbol} 2290
            </Typography>
          </Box>
        </Grid>
        <Grid item md={4} sx={12}>
          {/* value cards */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                mt: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    justifyContent: "center",
                    alignItems: "flex-start",

                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#790000",
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Typography
                      color={"#666"}
                      fontFamily={"mySecondFont"}
                      fontSize={"16px"}
                      lineHeight={"24px"}
                      sx={{}}
                    >
                      Refund Amount
                    </Typography>
                  </Box>

                  <Typography
                    color={"#2F2F2F"}
                    fontFamily={"myFirstFont"}
                    fontSize={"20px"}
                    lineHeight={"28px"}
                  >
                    {currencySymbol} 290
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    color={"#666"}
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    sx={{}}
                  >
                    No. of Refunds
                  </Typography>
                  <Typography
                    color={"#2F2F2F"}
                    fontFamily={"myFirstFont"}
                    fontSize={"20px"}
                    lineHeight={"28px"}
                  >
                    12
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RefundDataBox;
