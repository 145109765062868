import React, { useState } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Button,
} from "@mui/material";
import {
  settlementDetailsColumnData,
  settlementDetailsColumnData2,
  settlementDetailsRowData,
  settlementDetailsRowData2,
} from "../../../../utils/DummyData";
import CustomTable from "../../../custom/CustomTable";
import NewSearchFilterBox from "../../../Filters/NewSearchFilterBox";

const SettlementDetailsBox = () => {
  // State to toggle between Transaction and Charge Points view
  const [isTransactionView, setIsTransactionView] = useState(true);

  // Toggle handler
  const handleSwitchChange = (event) => {
    setIsTransactionView(event.target.checked);
  };

  // Reusable function to render toggle switch
  const renderToggleSwitch = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "4px 12px",
        border: "2px solid #BEBEBC",
        borderRadius: "8px",
        gap: "16px",
      }}
    >
      <Typography sx={textStyle}>Transaction</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isTransactionView}
              onChange={handleSwitchChange}
              aria-label="Toggle between Transaction and Charge Points view"
            />
          }
        />
      </FormGroup>
      <Typography sx={textStyle}>Charge Points</Typography>
    </Box>
  );

  // Styles for text
  const textStyle = {
    color: "#2F2F2F",
    fontFamily: "mySecondFont",
    fontSize: "14px",
    lineHeight: "20px",
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {/* Header with Search and Actions */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NewSearchFilterBox />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "24px",
          }}
        >
          {renderToggleSwitch()}

          <Button
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              background: "#333",
              padding: "12px",
              borderRadius: "4px",
              height: "40px",
              fontFamily: "mySecondFont",
              fontSize: "14px",
              textTransform: "uppercase",
              cursor: "pointer",
              color: "#fff",
              ":hover": {
                background: "#333",
              },
            }}
          >
            Export
            <span className="material-symbols-outlined">file_export</span>
          </Button>
        </Box>
      </Box>

      {/* Custom Table with Conditional Data */}
      <CustomTable
        columns={
          isTransactionView
            ? settlementDetailsColumnData()
            : settlementDetailsColumnData2()
        }
        rows={
          isTransactionView
            ? settlementDetailsRowData
            : settlementDetailsRowData2
        }
        modalHeading="Settlement Details"
      />
    </Box>
  );
};

export default SettlementDetailsBox;
