import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TimeFilter from "../Filters/TimeFilter";
import ChargingOverview from "./ChargingOverview";
import Customers from "./customers/Customers";
import {
  CustomerDetailsColumns,
  customerDetailsRows,
  CustomerFeedbackColumns,
  customerFeedbackRows,
  CustomerWalletDataColumns,
  customerWalletDataRows,
} from "../../utils/DummyData";
import WalletDataTableBox from "./WalletData/WalletDataTableBox";
import FeedbackTableBox from "./feedback/FeedbackTableBox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchCustomerFeedbackData,
  fetchCustomerManagementAllCustomers,
  fetchCustomerWalletData,
} from "../../redux/action/customerManagement/customerManagementSlice";
import { formatPhoneNumber } from "../../utils/countryCode";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);
  const dispatch = useDispatch();

  const { allCustomers, customerWalletData, feedback, loading, error } =
    useSelector((state) => state.customerWallet);

  useEffect(() => {
    dispatch(fetchCustomerManagementAllCustomers());
    dispatch(fetchCustomerWalletData());
    dispatch(fetchCustomerFeedbackData());
  }, [dispatch]);

  const allCustomersData = allCustomers?.customers?.map((form) => {
    const rawPhone = form?.phone;

    // Ensure rawPhone is a valid string before formatting
    const formattedPhone =
      typeof rawPhone === "string" && rawPhone.trim() !== ""
        ? formatPhoneNumber(rawPhone)
        : "N/A"; // Provide a fallback value if rawPhone is invalid

    // Extract model and brand, and combine them
    const defaultVehicleModel =
      form?.userVehicleData?.[0]?.vehicleData?.model || "-";
    const defaultVehicleBrand =
      form?.userVehicleData?.[0]?.vehicleData?.brand || "-";
    const defaultVehicle = `${defaultVehicleModel}, ${defaultVehicleBrand}`;

    return {
      _id: form?._id,
      customerName: form?.name,
      phone: formattedPhone,
      userType: form?.userType,
      sessions: form?.sessionStats?.totalSessions,
      avgDuration: `${(form?.sessionStats?.avgDuration / 60)?.toFixed(2)} hrs`,
      createdAt: form?.createdAt,
      isCustomerActive: form?.sessionStats?.isActive,
      lastChargingDate: form?.sessionStats?.lastChargingDate,
      defaultVehicle: defaultVehicle,
      totalEnergy: (form?.sessionStats?.totalEnergy / 1000)?.toFixed(3),
      is_registered: form?.is_registered,
      is_super: form?.is_super,
    };
  });

  const allCustomerWalletData = customerWalletData?.data;
  const allWalletData = allCustomerWalletData?.map((wallet) => {
    const customer = wallet?.customerId; // Extract the nested customer object
    const rawPhone = customer?.phone; // Access the phone number from customerId
    const transactionStats = wallet?.transactionStats;
    const cashbacks = wallet?.cashbacks;

    // Ensure rawPhone is a valid string before formatting
    const formattedPhone =
      typeof rawPhone === "string" && rawPhone.trim() !== ""
        ? formatPhoneNumber(rawPhone)
        : "N/A"; // Provide a fallback value if rawPhone is invalid

    return {
      _id: wallet?._id, // Wallet ID
      customerName: customer?.name || "N/A", // Customer name with fallback
      phone: formattedPhone, // Formatted phone number
      transaction: transactionStats?.totalTransactions,
      cashback: cashbacks?.totalCashbacks || "-",
      currentBalance: wallet?.balance,
      topUp: transactionStats?.averageFrequencyDays,
      totalRecharge: transactionStats?.totalRechargeAmount,
      lastRecharge: transactionStats?.lastTransaction,
      createdAt: customer?.createdAt,
      totalBalance: customerWalletData?.totalBalance,
      isWalletActive: transactionStats?.isActive,
      customerId: customer?._id,
    };
  });

  const allFeedbackData = feedback?.map((feedback) => {
    const user = feedback?.userDetails; // Extract nested user details
    const station = feedback?.stationDetails; // Extract nested station details

    return {
      _id: feedback?._id, // Feedback ID
      customer: user?.name || "N/A", // Customer name with fallback
      station: station?.name || "N/A", // Station name with fallback
      rating: feedback?.ratingStatus || "N/A", // Rating status
      likes: feedback?.likes || 0, // Total likes
      dislikes: feedback?.dislikes || 0, // Total dislikes
      likedByUser: feedback?.likedByUser || false, // If liked by the user
      dislikedByUser: feedback?.dislikedByUser || false, // If disliked by the user
      adminComments: feedback?.adminComments || [], // Admin comments
      created_at: feedback?.created_at, // Feedback creation time
      updatedAt: feedback?.updated_at, // Feedback update time
      description: feedback?.description || "", // Feedback description
      title: feedback?.title || "", // Feedback title
      isActive: feedback?.isActive,
      stationId: feedback?.stationDetails?._id,
      userId: feedback?.userDetails?._id,
      user: user,
    };
  });

  const tabsData = [
    {
      label: "Overview",
      content: (
        <ChargingOverview
          durationType={durationType}
          startTime={startTime}
          endTime={endTime}
        />
      ),
    },
    {
      label: "Customers",
      content: (
        <Customers
          columns={CustomerDetailsColumns()}
          rows={allCustomersData}
          isRowClickable={true}
          loading={loading}
        />
      ),
    },
    {
      label: "Wallet Data",
      content: (
        <WalletDataTableBox
          columns={CustomerWalletDataColumns()}
          rows={allWalletData}
          isRowClickable={true}
          data={customerWalletData}
          loading={loading}
        />
      ),
    },
    {
      label: "Feedback",
      content: (
        <FeedbackTableBox
          columns={CustomerFeedbackColumns()}
          rows={allFeedbackData}
          isRowClickable={true}
        />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <MainHeading text={"Customer Management"} />

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* newDurationType= last7Days, currentMonth, currentYear */}
            <TimeFilter
              isNewDurationType={true}
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
