import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useRef } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NavItems from "./NavItems";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { useSelector } from "react-redux";
import ProfileModal from "../Modals/ProfileModal";
import { MyRoutes } from "../../routes/routes";

const drawerWidth = 264;

const getRoleDisplayName = (role) => {
  switch (role) {
    case "Superadmin":
      return "Super Admin";
    case "1C_Admin":
      return "1C Admin";
    case "1C_User":
      return "1C User";
    case "CPO_Admin":
      return "CPO Admin";
    case "CPO_User":
      return "CPO User";
    case "1C_AccountsManager":
      return "Finance Manger";
    default:
      return "role";
  }
};

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const user = useSelector((state) => state?.profile?.profile);

  const dashboardOptions = [
    {
      name: "Dashboard",
      icon: "dashboard",
      path: MyRoutes.dashboard,
      children: false,
    },
    {
      name: "Charging Sessions",
      icon: "offline_bolt",
      path: MyRoutes.chargingSessions,
      children: false,
    },

    {
      name: "Charging Stations",
      icon: "electric_car",
      path: MyRoutes.charging_stations,
      children: false,
    },
    {
      name: "Charging Points",
      icon: "ev_station",
      path: MyRoutes.charging_points,
      children: false,
    },
    {
      name: "RFID Management",
      icon: "credit_card",
      path: MyRoutes.rfid_management,
      children: false,
    },
    {
      name: "Stations Map",
      icon: "map",
      path: MyRoutes.stations_map,
      children: false,
    },
    {
      name: "Earnings",
      icon: "assured_workload",
      path: MyRoutes.payment,
      children: false,
      // childs: [
      //   {
      //     title: "User list",
      //     path: "/all_users",
      //   },
      //   {
      //     title: "Manage Reviews",
      //     path: "/all_reviews",
      //   },
      // ],
    },
    {
      name: "Statistics",
      icon: "finance",
      path: MyRoutes.statistics,
      children: false,
    },
    {
      name: "Companies Management",
      icon: "apartment",
      path: MyRoutes.companies_management,
      children: false,
    },
    {
      name: "Tariff Management",
      icon: "receipt_long",
      path: MyRoutes.tariff_management,
      children: false,
    },
    {
      name: "OCPI Management",
      icon: "contract",
      path: MyRoutes.ocpi_management,
      children: false,
    },
    {
      name: "Hub Management",
      icon: "hub",
      path: MyRoutes.hub_management,
      children: false,
    },
    {
      name: "Customer Management",
      icon: "groups",
      path: MyRoutes.customer_management,
      children: false,
    },
    // {
    //   name: "Notifications",
    //   icon: "contract",
    //   path: "/all_leases",
    //   children: false,
    // },
    {
      name: "Admin Management",
      icon: "groups",
      path: MyRoutes.admin_management,
      children: false,
    },
    {
      name: "Alerts & Faults",
      icon: "offline_bolt",
      path: MyRoutes.alerts_and_faults,
      children: false,
    },
    // {
    //   name: "Home",
    //   icon: "home",
    //   path: MyRoutes.financeHome,
    //   children: false,
    // },
    // {
    //   name: "Payment History",
    //   icon: "history",
    //   path: MyRoutes.finance_payment_history,
    //   children: false,
    // },

    //Accounts SideMenu Items
    {
      name: "Accounts Dashboard",
      icon: "dashboard",
      path: MyRoutes.accounts_dashboard,
      children: false,
    },
    {
      name: "Transactions",
      icon: "swap_horiz",
      path: MyRoutes.accounts_transactions,
      children: false,
    },
    {
      name: "Settlements",
      icon: "receipt_long",
      path: MyRoutes.accounts_settlements,
      children: false,
    },
    {
      name: "Payment Alerts",
      icon: "notifications_active",
      path: MyRoutes.accounts_alerts,
      children: false,
    },
    {
      name: "Ledger",
      icon: "history",
      path: MyRoutes.accounts_ledger,
      children: false,
    },
    {
      name: "Companies",
      icon: "history",
      path: MyRoutes.accounts_companies,
      children: false,
    },
  ];

  const filteredDashboardOptions =
    user?.role === "CPO_Admin"
      ? dashboardOptions.filter(
          (item) =>
            item.name !== "Companies Management" &&
            item.name !== "Admin Management" &&
            item.name !== "Payment History" &&
            item.name !== "Home" &&
            item.name !== "Stations Map" &&
            item.name !== "Tariff Management" &&
            item.name !== "OCPI Management" &&
            item.name !== "RFID Management" &&
            item.name !== "Customer Management" &&
            item.name !== "Hub Management" &&
            //accounts
            item.name !== "Accounts Dashboard" &&
            item.name !== "Transactions" &&
            item.name !== "Settlements" &&
            item.name !== "Payment Alerts" &&
            item.name !== "Ledger" &&
            item.name !== "Companies"
        )
      : user?.role === "CPO_User"
      ? dashboardOptions.filter(
          (item) =>
            item.name !== "Companies Management" &&
            item.name !== "Admin Management" &&
            item.name !== "Payment History" &&
            item.name !== "Home" &&
            item.name !== "Stations Map" &&
            item.name !== "Tariff Management" &&
            item.name !== "OCPI Management" &&
            item.name !== "RFID Management" &&
            item.name !== "Customer Management" &&
            item.name !== "Hub Management" &&
            //accounts
            item.name !== "Accounts Dashboard" &&
            item.name !== "Transactions" &&
            item.name !== "Settlements" &&
            item.name !== "Payment Alerts" &&
            item.name !== "Ledger" &&
            item.name !== "Companies"
        )
      : user?.role === "Superadmin"
      ? dashboardOptions.filter(
          (item) =>
            item.name !== "Payment History" &&
            item.name !== "Home" &&
            //accounts
            item.name !== "Accounts Dashboard" &&
            item.name !== "Transactions" &&
            item.name !== "Settlements" &&
            item.name !== "Payment Alerts" &&
            item.name !== "Ledger" &&
            item.name !== "Companies"
        )
      : user?.role === "1C_Admin"
      ? dashboardOptions.filter(
          (item) =>
            item.name !== "Admin Management" &&
            item.name !== "Payment History" &&
            item.name !== "Home" &&
            //accounts
            item.name !== "Accounts Dashboard" &&
            item.name !== "Transactions" &&
            item.name !== "Settlements" &&
            item.name !== "Payment Alerts" &&
            item.name !== "Ledger" &&
            item.name !== "Companies"
        )
      : user?.role === "1C_User"
      ? dashboardOptions.filter(
          (item) =>
            item.name !== "Admin Management" &&
            item.name !== "Payment History" &&
            item.name !== "Home" &&
            item.name !== "OCPI Management" &&
            item.name !== "Tariff Management" &&
            item.name !== "Companies Management" &&
            item.name !== "Customer Management" &&
            item.name !== "RFID Management" &&
            //accounts
            item.name !== "Accounts Dashboard" &&
            item.name !== "Transactions" &&
            item.name !== "Settlements" &&
            item.name !== "Payment Alerts" &&
            item.name !== "Ledger" &&
            item.name !== "Companies"
        )
      : user?.role === "1C_AccountsManager"
      ? dashboardOptions.filter(
          (item) =>
            item.name === "Accounts Dashboard" ||
            item.name === "Transactions" ||
            item.name === "Settlements" ||
            item.name === "Payment Alerts" ||
            item.name === "Ledger" ||
            item.name === "Companies"
        )
      : dashboardOptions;

  const [currentMenuItem, setCurrentMenuItem] = useState(
    localStorage.getItem("menuItem") || filteredDashboardOptions[0]?.name || ""
  );

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleOpenProfileModal = (e) => {
    e?.stopPropagation();
    setOpenProfileModal(true);
  };

  const handleCloseProfileModal = (e) => {
    e?.stopPropagation();
    setOpenProfileModal(false);
  };

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Toolbar /> */}
      <Box
        sx={{
          minHeight: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "40px",
          marginTop: "24px",
        }}
      >
        <img src="/images/mainlogo.png" alt="" />
      </Box>
      {/* <Divider /> */}
      {filteredDashboardOptions?.map((item, index) => {
        return (
          <>
            <NavItems
              icon={item.children ? true : false}
              key={item.name}
              item={item}
              setCurrentMenuItem={setCurrentMenuItem}
              currentMenuItem={currentMenuItem}
            />
          </>
        );
      })}

      <Box
        sx={{
          flexGrow: 1, // Pushes the version info to the bottom
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          padding: "0 16px", // Add padding to the sides for better alignment
          marginTop: "auto", // Pushes it to the bottom
          textAlign: "center",
          fontFamily: "myThirdFont",
          fontSize: "12px",
          color: "gray",
          marginBottom: "8px",
        }}
      >
        Version 2.1.2
      </Box>
      {/* <Box
        sx={{ padding: "0px 32px", marginTop: "24px", marginBottom: "24px" }}
      >
        <Divider />
      </Box>
      <Box
        sx={{
          padding: "12px 16px",
          marginLeft: "12px",
          marginRight: "16px",
          borderRadius: "8px",
          background:
            currentMenuItem === "Settings" ? "#292D33" : "transparent",
        }}
        onClick={() => {
          setCurrentMenuItem("Settings");
          localStorage.setItem("menuItem", "Settings");
          navigate("/setting");
        }}
        tabIndex={1}
      >
        <Typography
          color={currentMenuItem === "Settings" ? "#FCFCFC" : "#2f2f2f"}
          fontSize={"14px"}
          fontFamily={"myThirdFont"}
          textAlign={"start"}
          display={"flex"}
          alignItems={"center"}
          gap={"8px"}
          fontWeight={currentMenuItem === "Settings" ? "600" : "500"}
          tabIndex={1}
        >
          <span style={{ fontWeight: "300" }} class="material-symbols-outlined">
            settings
          </span>
          Settings
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "12px 16px",
          marginLeft: "12px",
          marginRight: "16px",
          borderRadius: "8px",
          background: currentMenuItem === "Logout" ? "#FCFCFC" : "transparent",
        }}
        onClick={() => {
          setCurrentMenuItem("Logout");
          localStorage.setItem("menuItem", "Logout");
        }}
        tabIndex={1}
      >
        <Typography
          color={currentMenuItem === "Logout" ? "#2F2F2F" : "#2f2f2f"}
          fontSize={"14px"}
          fontFamily={"myThirdFont"}
          textAlign={"start"}
          display={"flex"}
          alignItems={"center"}
          gap={"8px"}
          fontWeight={currentMenuItem === "Logout" ? "600" : "500"}
          tabIndex={1}
        >
          <span style={{ fontWeight: "300" }} class="material-symbols-outlined">
            Logout
          </span>
          Logout
        </Typography>
      </Box> */}
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "#fff !important",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          // boxShadow: "0px 4px 4px 0px rgba(41, 45, 51, 0.05)",
          boxShadow: "none",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Toolbar>
          <IconButton
            color="#000"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: "1",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    width: "48px",
                    height: "48px",
                    borderRadius: "48px",
                    border: "1px solid rgba(41, 45, 51, 0.25)",
                    overflow: "hidden",
                    // objectFit: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={
                      user?.org?.companyLogo?.url
                        ? user?.org?.companyLogo?.url
                        : "/images/1C_logo.png"
                    }
                    alt=""
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#0C0C0C",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  {user?.org?.name ? user?.org?.name : "1C EV CHARGING"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                padding: "12px 32px",
                cursor: "pointer",
              }}
            >
              {/* <Badge
                badgeContent={3}
                color="primary"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#e50000",
                    color: "#fff",
                    fontSize: "0.75rem",
                    minWidth: "22px",
                    height: "22px",
                    borderRadius: "50%",
                    border: "2px solid white",
                    top: "6px",
                    right: "6px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    background: "rgba(41, 45, 51, 0.08)",
                    borderRadius: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ color: "#2f2f2f", fontWeight: "300" }}
                    className="material-symbols-outlined"
                  >
                    notifications
                  </span>
                </Box>
              </Badge> */}
              <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    background: "rgba(41, 45, 51, 0.08)",
                    borderRadius: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img src="/images/profileImg.png" alt="" />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography
                    color={"#2f2f2f"}
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    textAlign={"start"}
                    textTransform={"capitalize"}
                  >
                    {user ? user.name : "Guest"}
                  </Typography>
                  <Typography
                    color={"#2f2f2f"}
                    fontFamily={"myThirdFont"}
                    fontSize={"14px"}
                    textAlign={"start"}
                  >
                    {user ? getRoleDisplayName(user?.role) : "role"}
                  </Typography>
                </Box>
              </Box>
              <Box onClick={(e) => handleOpenProfileModal(e)}>
                <span
                  style={{ color: "#292D33", fontWeight: "300" }}
                  className="material-symbols-outlined"
                >
                  expand_circle_down
                </span>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: { md: "32px 24px 32px 32px", xs: "32px 16px 32px 16px" },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          overflow: "auto",
        }}
      >
        <Toolbar />
        {children}
      </Box>
      <ProfileModal
        open={openProfileModal}
        handleClose={handleCloseProfileModal}
      />
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
