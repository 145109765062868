import { Box, Grid, Typography } from "@mui/material";
import React from "react";

// LeftText Component
const AlertCountTextBox = ({ label, value, bgColor }) => {
  return (
    <Box
      sx={{
        background: bgColor || "#292D33", // Default background color if not passed
        padding: "12px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: label === "Low Alerts" ? "#382600" : "#FCFCFC",
          fontFamily: "myThirdFont",
          fontSize: "16px",
          lineHeight: "24px", // 150% of the font size
          textTransform: "capitalize",
        }}
      >
        {label}
      </Typography>

      <Typography
        sx={{
          color: label === "Low Alerts" ? "#382600" : "#FCFCFC",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "32px",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export { AlertCountTextBox };

const AlertCountBox = ({ data, isDash }) => {

    console.log("44444444444",data)

    const getAlertCount = (type) => {
        // Check if data exists and has the data property that contains the array
        if (!data?.data || !Array.isArray(data.data)) {
          return 0;
        }
        const alert = data.data.find((item) => item._id === type);
        return alert ? alert.count : 0;
      };
    
      // Calculate total alerts
      const getTotalAlerts = () => {
        if (!data?.data || !Array.isArray(data.data)) {
          return 0;
        }
        return data.data.reduce((total, item) => total + item.count, 0);
      };

  console.log("jjjjjdd", data);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={2.4}>
          <AlertCountTextBox
            label="Total live alerts"
            value={getTotalAlerts()}
            bgColor="#292D33"
          />
        </Grid>
        <Grid item md={2.4}>
          <AlertCountTextBox
            label="Critical Alerts"
            value={getAlertCount("CRITICAL")}
            bgColor="#790000"
          />
        </Grid>
        <Grid item md={2.4}>
          <AlertCountTextBox label="High Alerts"     value={getAlertCount("HIGH")} bgColor="#D31818" />
        </Grid>
        <Grid item md={2.4}>
          <AlertCountTextBox
            label="Medium Alerts"
            value={getAlertCount("MEDIUM")}
            bgColor="#E86100"
          />
        </Grid>
        <Grid item md={2.4}>
          <AlertCountTextBox label="Low Alerts"     value={getAlertCount("LOW")} bgColor="#E29B00" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertCountBox;
