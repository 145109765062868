import React, { useEffect } from "react";
import AlertsBox from "./AlertsBox";
import { Box } from "@mui/material";
import {
    alertsHighColumnData,
  alertsHighRowData,
  alertsSevereColumnData,
  alertsSevereRowData,
} from "../../utils/DummyData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAlertsOfSeverity } from "../../redux/action/alertsSlice/alertsSlice";

const HighAlertsBox = ({alertCounts}) => {

  const dispatch = useDispatch();
  const { highAlerts, status, error, loading } = useSelector(
    (state) => state.alerts
  );

  console.log("khkhkhkhkh",highAlerts )

  useEffect(() => {
    if (!highAlerts) {
      dispatch(fetchAlertsOfSeverity("HIGH"));
    }
  }, [dispatch, highAlerts]); // Only re-run when criticalAlerts or status change

  console.log("aszzzz", alertCounts);
  const getAlertCount = (type) => {
    if (!alertCounts?.data || !Array.isArray(alertCounts.data)) {
      return 0;
    }
    const alert = alertCounts.data.find((item) => item._id === type);
    return alert ? alert.count : 0;
  };

  if (loading && !highAlerts) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;


  return (
    <Box sx={{}}>
      <AlertsBox
        column={alertsHighColumnData}
        data={highAlerts || []}
        alertType="high"
        alertCountsForTable={getAlertCount("HIGH")}
        modalHeading={"High Alerts"}
      />
    </Box>
  );
};

export default HighAlertsBox;
