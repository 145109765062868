import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import CustomerTopGraphBox from "./overview/CustomerTopGraphBox";
import CustomerTotalUser from "./overview/CustomerTotalUser";
import WalletRechargeBox from "./overview/WalletRechargeBox";
import WalletStats from "./overview/WalletStats";
import { useDispatch } from "react-redux";
import {
  fecthAppUserStatsForSubscribers,
  fetchActiveInactiveCustomers,
  fetchCustomerRangeAndroidIOS,
  fetchCustomersCurrentRFID,
  fetchCustomersRFIDUsers,
  fetchCustomerStatsData,
  fetchCustomersTotalUserStats,
  fetchCustomerSuperChargerStats,
  fetchCustomerWalletRecharge,
  fetchCustomerWalletStats,
} from "../../redux/action/customerManagement/customerManagementSlice";
import { useSelector } from "react-redux";
import SuperSubscription from "./overview/SuperSubscription";
import AppSuperCustomers from "./overview/AppSuperCustomers";
import ValueCard from "../dashboard/ValueCard";
import OCPICustomers from "./overview/OCPICustomers";
import SemiDoughnutChart from "../Graphs/SemiDoughnutChart";
import InactiveCustomer from "./overview/InactiveCustomer";
import NewAppCustomerBox from "./overview/NewAppCustomerBox";

const ChargingOverview = ({ durationType, startTime, endTime }) => {
  const dispatch = useDispatch();
  const {
    stats,
    rfidUsers,
    walletRecharge,
    totalUserStats,
    superChargerCustomer,
    customerRfidUsers,
    customerStatsData,
    customerAppUsersForSubscribersData,
    activeInactiveCustomers,
    customerRange,
    loading,
    error,
  } = useSelector((state) => state.customerWallet);

  useEffect(() => {
    dispatch(fetchCustomerWalletStats());
    dispatch(fetchCustomersCurrentRFID());
    dispatch(fetchCustomersTotalUserStats());
    dispatch(fecthAppUserStatsForSubscribers());
    dispatch(fetchActiveInactiveCustomers());

    if (durationType && startTime && endTime) {
      dispatch(
        fetchCustomerWalletRecharge({ durationType, startTime, endTime })
      );
      dispatch(
        fetchCustomerSuperChargerStats({ durationType, startTime, endTime })
      );
      dispatch(fetchCustomersRFIDUsers({ durationType, startTime, endTime }));
      dispatch(fetchCustomerStatsData({ durationType, startTime, endTime }));
      dispatch(
        fetchCustomerRangeAndroidIOS({ durationType, startTime, endTime })
      );
    }
  }, [durationType, startTime, endTime, dispatch]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Top Graph and alert box */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <CustomerTopGraphBox
                data={customerStatsData}
                selectedTime={durationType}
                customerManagement={true}
                startTime={startTime}
                endTime={endTime}
              />
            </Box>
          </Grid>

          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <CustomerTotalUser totalUserStats={totalUserStats} />
            </Box>
          </Grid>
        </Grid>

        {/* Wallet Recharge Box */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <WalletRechargeBox
                data={walletRecharge}
                selectedTime={durationType}
                startTime={startTime}
                endTime={endTime}
              />
            </Box>
          </Grid>

          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <WalletStats data={stats} />
            </Box>
          </Grid>
        </Grid>

        {/* subscription */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "16px",
                  width: "100%",
                }}
              >
                <NewAppCustomerBox
                  selectedTime={durationType}
                  data={customerRange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={5} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <SuperSubscription
                superChargerCustomer={superChargerCustomer}
                selectedTime={durationType}
              />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <AppSuperCustomers
                customerAppUsersForSubscribersData={
                  customerAppUsersForSubscribersData
                }
              />
            </Box>
          </Grid>
        </Grid>

        {/* value card and progress */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ width: "100%" }}>
                <ValueCard
                  title={"RFID customers"}
                  customerManagement={true}
                  data={customerRfidUsers}
                  changeValueData={customerRfidUsers?.data?.countChange}
                  valuChipData={customerRfidUsers?.data?.percentageChange}
                  selectedTime={durationType}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ width: "100%" }}>
                <ValueCard
                  customerManagement={true}
                  liveValueCard={true}
                  data={rfidUsers}
                  title={"Current RFID customers"}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <InactiveCustomer data={activeInactiveCustomers} />
            </Box>
          </Grid>
        </Grid>

        {/* OCPI Customers */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <OCPICustomers />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChargingOverview;
