import React from "react";
import DoughnutChart from "../Graphs/DoughnutChart";
import { Box } from "@mui/material";
import { appStatsData } from "../../utils/DummyData";
import ValueCardSmall from "./ValueCardSmall";
import StatusChip from "../Chips/StatusChip";

const AppStats = ({
  customers,
  data,
  walletData,
  previousWalletData,
  previousDashboardData,
  selectedTime,
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          gap: "16px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <ValueCardSmall
            data={data}
            previousDashboardData={previousDashboardData}
            topGraphBox="true"
            title={"1C Wallet Balance"}
            selectedTime={selectedTime}
          />
        </Box>

        <Box sx={{}}>
          <StatusChip text={"Live"} chipStatus={"Live"} />
        </Box>
      </Box>
      <Box>
        <DoughnutChart
          customers={customers}
          data={appStatsData}
          centerData=""
          donut_type="dashboard"
        />
      </Box>
    </Box>
  );
};

export default AppStats;
