import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

// Custom Tooltip Content
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <Typography variant="body2" sx={{ color: "#666" }}>
          {label}
        </Typography>
        {payload.map((entry, index) => (
          <Typography
            key={index}
            sx={{ color: entry.stroke, fontSize: "14px" }}
          >
            <strong>{getCustomLabel(entry.dataKey)}: </strong>
            {entry.value}
          </Typography>
        ))}
      </Box>
    );
  }

  return null;
};

const getCustomLabel = (dataKey) => {
  switch (dataKey) {
    case "walletRechargeData":
      return "Wallet Recharge";
   
    default:
      return dataKey;
  }
};

const CustomerLineGraph = ({
  graphTitle,
  data,
  selectedTime,
  startTime,
  endTime,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  console.log("hhhhddd", data?.data?.graphData)

  // Generate date range based on selectedTime
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];

    // Extract the year dynamically from the data
    const extractedYear = (() => {
      if (data?.appUserGraphData?.length > 0) {
        const time = data.appUserGraphData[0]?.time;
        // Ensure time exists and is a string
        if (typeof time === "string" && time.includes("-")) {
          const parts = time.split("-");
          return parts[2]; // Assuming the year is the third part
        }
      }
      // Default to current year (last two digits)
      return dayjs().format("YY");
    })();

    if (selectedTime === "last7Days") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "currentMonth") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;
      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "currentYear") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    }
    // else if (selectedTime === "customRange" && startTime && endTime) {
    //   let currentDate = dayjs(startTime);
    //   const end = dayjs(endTime);
    //   while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    //     dates.push({
    //       date: currentDate.format("YYYY-MM-DD"),
    //       label: currentDate.format("YYYY-MM-DD"),
    //     });
    //     currentDate = currentDate.add(1, "day");
    //   }
    // }
    else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format(`DD-MM-${extractedYear}`),
          label: currentDate.format(`DD-MM-${extractedYear}`),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .add(i, "hour")
          .format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    // Handle "today" or "yesterday" data
    if (selectedTime === "today" || selectedTime === "yesterday") {
      return dates.map(({ date, label }) => {
        // Extract the hour from the label (HH:00)
        const hour = parseInt(label.split(":")[0]);

        const hourAppData =
          data?.data?.graphData?.find((item) => item.time === hour) || {};

        const walletRechargeData = hourAppData.amount || 0;

        return {
          date: label,
          walletRechargeData:
            walletRechargeData === 0 ? 0 : walletRechargeData?.toFixed(2),
        };
      });
    }

    // Handle "last7Days" data
    if (selectedTime === "last7Days") {
      return dates.map(({ date, label }) => {
        // Find the data for the given day (label is the day of the week)
        const sevenDaysData =
          data?.data?.graphData?.find((item) => item.time === label) || {};

        // Get the counts for walletRechargeData and directUsers (default to 0 if not found)
        const walletRechargeData = sevenDaysData.amount || 0;

        return {
          date: label, // The label will be the day of the week (Mon, Tue, etc.)
          walletRechargeData:
          walletRechargeData === 0 ? 0 : walletRechargeData?.toFixed(2),        };
      });
    }

    if (selectedTime === "currentMonth") {
      return dates.map(({ date, label }) => {
        // Parse the day number from the label (1 to 31)
        const day = parseInt(label);

        // Find the data for the specific day
        const dayAppData =
          data?.data?.graphData?.find((item) => item.time === day) || {};

        // Get the counts for walletRechargeData and directUsers (default to 0 if not found)
        const walletRechargeData = dayAppData.amount || 0;

        return {
          date: label, // Day of the month (1, 2, ..., 31)
          walletRechargeData:
          walletRechargeData === 0 ? 0 : walletRechargeData?.toFixed(2),
        };
      });
    }

    // Handle "currentYear" data
    if (selectedTime === "currentYear") {
      return dates.map(({ date, label }) => {
        // Find the data for the specific month (e.g., "Jan", "Feb", ..., "Dec")
        const monthData =
          data?.data?.graphData?.find((item) => item.time === label) || {};

        // Get the counts for walletRechargeData and directUsers (default to 0 if not found)
        const walletRechargeData = monthData?.amount || 0;

        return {
          date: label, // Month name (e.g., "Jan", "Feb", ..., "Dec")
          walletRechargeData:
          walletRechargeData === 0 ? 0 : walletRechargeData?.toFixed(2),
        };
      });
    }

    // // Handle "customRange" data
    if (selectedTime === "customRange") {
      return dates.map(({ date, label }) => {
        const dateAppData =
          data?.data?.graphData?.find((item) => item.time === label) || {};

        const walletRechargeData = dateAppData.amount || 0;

        return {
          date: label,
          walletRechargeData:
          walletRechargeData === 0 ? 0 : walletRechargeData?.toFixed(2),
        };
      });
    }
    return [];
  };

  const chartData = getData();

    // Calculate the max and min of the chart data for dynamic Y-axis domain
    const maxValue = Math.max(...chartData.map((item) => parseFloat(item.walletRechargeData)));
    const minValue = Math.min(...chartData.map((item) => parseFloat(item.walletRechargeData)));
  

  console.log("ggjjgg", chartData);

  return (
    <Box>
            <Typography
        sx={{
          textAlign: "center",
          marginBottom: "8px",
          fontFamily: "myThirdFont",
          fontWeight: "600",
          fontSize: "14px",
          color: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
      >
        {graphTitle}
      </Typography>
      <Box sx={{ width: "100%", height: 312, padding: "16px 0" }}>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 10,
            right: 24,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="5 3" vertical={false} />
          <YAxis
            tick={{
              fontSize: "12px",
              fontFamily: "myThirdFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
            // domain={[0, "auto"]}
            domain={[minValue - 0, maxValue + 1]}
          />
          <XAxis
            dataKey="date"
            tick={{
              fontSize: "14px",
              fontFamily: "mySecondFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
     <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="walletRechargeData" // Using 'amount' from graphData
            stroke="#2271E8"
            activeDot={{ r: 3 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
    </Box>
    
  );
};

export default CustomerLineGraph;
