import React, { useEffect, useState } from "react";
import AlertsCustomTimeFilter from "../../Filters/AlertsCustomTimeFilter";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import LineGraph from "../../Graphs/LineGraph";
import AlertsLineGraph from "../../Graphs/AlertsGraphs/AlertsLineGraph";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAlertStatsWithTime } from "../../../redux/action/alertsSlice/alertsSlice";

const AlertsMiddleLineGraphBox = () => {
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  // State to manage selected severity
  const [severity, setSeverity] = useState("ALL");

  const dispatch = useDispatch();

  const { alertStatsByTimePeriod, status, error, loading } = useSelector(
    (state) => state.alerts
  );

  console.log("ghfjfhf", alertStatsByTimePeriod);

  const handleSeverityChange = (event) => {
    setSeverity(event.target.value);
  };

  useEffect(() => {
    if (durationType && severity) {
      dispatch(fetchAlertStatsWithTime({ durationType, category: severity }));
    }
  }, [durationType, severity, dispatch]);

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#666",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "16px",
                  ml:"28px"
                }}
              >
                New alerts with respect to time period
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Box>
                <AlertsCustomTimeFilter
                  setDurationType={setDurationType}
                  setStartTime={setStartTime}
                  setEndTime={setEndTime}
                  setPrevStartTime={setPrevStartTime}
                  setPrevEndTime={setPrevEndTime}
                  isTodayYesterdayRemoved={true}
                />
              </Box>
              <Box>
                {/* MUI Select Dropdown for Severity */}
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="severity-label">Severity</InputLabel>
                  <Select
                    labelId="severity-label"
                    value={severity}
                    onChange={handleSeverityChange}
                    label="Severity"
                    sx={{
                      height: "40px", // Adjust the height of the select box
                      paddingTop: 0,  // Optional: Reduce top padding to fit in the box
                      paddingBottom: 0, // Optional: Reduce bottom padding
                      fontSize: '14px',  // Optional: Adjust font size to fit the smaller select box
                    }}
                  >
                    <MenuItem value="ALL">ALL</MenuItem>
                    <MenuItem value="HIGH">HIGH</MenuItem>
                    <MenuItem value="MEDIUM">MEDIUM</MenuItem>
                    <MenuItem value="LOW">LOW</MenuItem>
                    <MenuItem value="CRITICAL">CRITICAL</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item md={12}>
          <AlertsLineGraph
            severity={severity}
            data={alertStatsByTimePeriod}
            selectedTime={durationType}
            startTime={startTime}
            endTime={endTime}
          />{" "}
          {/* Pass the selected severity */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertsMiddleLineGraphBox;
