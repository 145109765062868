import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SemiDoughnutChart from "../../Graphs/SemiDoughnutChart";
import ValueChip from "../../Chips/ValueChip";
import CistomersSemiDoughnutChart from "../../Graphs/CustomerManagement/CistomersSemiDoughnutChart";

const NewAppCustomerBox = ({
  data,
  compareDirectSession,
  compareAppSession,
  selectedTime,
}) => {
  // Map the data dynamically for Android and iOS categories
  const dummyData = [
    {
      label: "Android Users",
      collectedAmount: data?.data?.totalAndroid || 0,
      color: "#68B7F9",
      changeValue: data?.data?.totalAndroidChange || 0,
      changePercentage:
        data?.data?.totalAndroidPercentageChange?.toFixed(2) || 0,
    },
    {
      label: "IOS Users",
      collectedAmount: data?.data?.totalIos || 0,
      color: "#1D4F9A",
      changeValue: data?.data?.totalIosChange || 0,
      changePercentage: data?.data?.totalIosPercentageChange?.toFixed(2) || 0,
    },
  ];

  // Predefined colors for segments, can be passed as props if needed
  const predefinedColors = ["#1D4F9A", "#68B7F9"];

  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "last7Days":
        return "Last Week";
      case "month":
        return "Last Month";
      case "currentMonth":
        return "Last Month";
      case "year":
        return "Last Year";
      case "currentYear":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  const getCompareColor = (profit) => {
    if (typeof profit === "string") {
      // If profit is a string and starts with a '-' for negative profit
      return profit.startsWith("-") ? "#790000" : "#027900"; // Red for negative, green for positive
    }

    if (typeof profit === "number") {
      // If profit is a number, check if it's negative or positive
      return profit < 0 ? "#790000" : "#027900"; // Red for negative, green for positive
    }

    // Default to green for any other case (null, undefined, etc.)
    return "#027900";
  };

  // Function to format the comparison value (positive/negative)
  const formatValue = (value) => {
    const valueStr = String(value); // Convert value to string
    if (valueStr.startsWith("-")) {
      return valueStr;
    }
    return `+${valueStr}`;
  };

  // Modularized User Box rendering (Android/IOS User Boxes)
  const renderUserBox = (
    userType,
    totalSessions,
    changeValue,
    label,
    bgColor
  ) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
        padding: "12px",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            width: "16px",
            height: "16px",
            backgroundColor: bgColor,
            borderRadius: "50%",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            sx={{
              color: "#666",
              fontSize: "14px",
              fontFamily: "myThirdFont",
              textAlign: "left",
            }}
          >
            {label}
          </Typography>
          <Typography
            sx={{
              color: "#2F2F2F",
              fontSize: "18px",
              fontFamily: "mySecondFont",
              textAlign: "left",
            }}
          >
            {totalSessions}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "4px",
        }}
      >
        {selectedTime === "today" ||
        selectedTime === "year" ||
        selectedTime === "currentYear" ? (
          ""
        ) : (
          <>
            <Typography sx={{ color: "#666", fontSize: "12px" }}>
              <ValueChip title={label} data={data} />
            </Typography>
            <Typography sx={{ color: "#666", fontSize: "12px" }}>
              <span style={{ color: getCompareColor(changeValue) }}>
                {formatValue(changeValue)}
              </span>{" "}
              than {getComparisonText()}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        padding: "12px",
        background: "#fff",
        borderRadius: "8px",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={12} sx={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              position: "relative",
            }}
          >
            {/* Render SemiDoughnutChart */}
            <CistomersSemiDoughnutChart data={data} />

            <Box>
              {/* Dynamically render Android and iOS user boxes */}
              {dummyData.map((item, index) => (
                <React.Fragment key={index}>
                  {renderUserBox(
                    item.label,
                    item.collectedAmount,
                    item.changeValue,
                    item.label,
                    item.color
                  )}
                </React.Fragment>
              ))}
            </Box>

            {/* INSIDE DONUT TEXT */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  left: "50%",
                  top: "30%",
                  transform: "translate(-50%, -50%)",
                  gap: "8px",
                }}
              >
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <ValueChip title={"Total Users"} data={data} />
                )}
                <Box>
                  <Typography
                    sx={{
                      color: "#666",
                      textAlign: "center",
                      fontFamily: "myThirdFont",
                      fontSize: { xs: "12px", sm: "14px" },
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    Total Users
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "20px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "32px",
                    }}
                  >
                    {data?.data?.totalUsers}
                  </Typography>
                </Box>
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(
                          data?.data?.totalUsersPercentageChange
                        ),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(
                        data?.data?.totalUsersPercentageChange?.toFixed(2)
                      )}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewAppCustomerBox;
