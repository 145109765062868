import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import CustomersDoughnutChart from "../../Graphs/CustomerManagement/CustomersDoughnutChart";
import StatusChip from "../../Chips/StatusChip";
import ValueChip from "../../Chips/ValueChip";

const SuperSubscription = ({ superChargerCustomer, selectedTime }) => {
  const getComparisonText = (change) => {
    return change !== undefined
      ? `${change > 0 ? "+" : ""}${change} than ${getComparisonTime()}`
      : "N/A";
  };

  const getComparisonTime = () => {
    switch (selectedTime) {
      case "today":
        return "yesterday";
      case "yesterday":
        return "previous day";
      case "last7Days":
        return "last week";
      case "currentMonth":
        return "last month";
      case "currentYear":
        return "last year";
      case "Custom Range":
        return "previous range";
      default:
        return "N/A";
    }
  };

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img src="/images/superlogo.png" alt="" />
          <Typography
            fontSize={"18px"}
            color={"#1c1c1c"}
            fontFamily={"mySecondFont"}
          >
            Subscriptions
          </Typography>
        </Box>
        <Divider />
      </Box>
      <Grid
        container
        spacing={2}
        display={"flex"}
        alignItems={"center"}
        mt={"16px"}
      >
        <Grid item md={6}>
          <CustomersDoughnutChart
            data={superChargerCustomer}
            centerText={"New subscribers"}
            iconText={"groups"}
            centerValue={superChargerCustomer?.stats?.totalCount}
          />
        </Grid>
        <Grid item md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", flexShrink: 0 }}>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "12px",
                    background: "#EF7559",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexShrink: 0,
                  }}
                >
                  <Typography
                    fontSize={"14px"}
                    color={"#666"}
                    fontFamily={"myThirdFont"}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    1 Month plan
                  </Typography>
                  <Typography
                    fontSize={"18px"}
                    color={"#2F2F2F"}
                    fontFamily={"mySecondFont"}
                    textAlign={"start"}
                  >
                    {superChargerCustomer?.stats?.plan30}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <>
                    <ValueChip
                      title={"superOneMonth"}
                      valuChipData={
                        superChargerCustomer?.stats?.plan30PercentageChange
                      }
                    />
                    <Typography fontSize={"16px"} fontFamily={"mySecondFont"}>
                      <span
                        style={{
                          color: getCompareColor(
                            superChargerCustomer?.stats?.plan30Change
                          ),
                          fontFamily: "mySecondFont",
                        }}
                      >
                        {superChargerCustomer?.stats?.plan30Change}
                      </span>{" "}
                      than {getComparisonTime()}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", flexShrink: 0 }}>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "12px",
                    background: "#F3B79F",
                  }}
                ></Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    fontSize={"14px"}
                    color={"#666"}
                    fontFamily={"myThirdFont"}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    6 months plan
                  </Typography>
                  <Typography
                    fontSize={"18px"}
                    color={"#2F2F2F"}
                    fontFamily={"mySecondFont"}
                    textAlign={"start"}
                  >
                    {superChargerCustomer?.stats?.plan180}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <>
                    <ValueChip
                      title={"superSixMonth"}
                      valuChipData={
                        superChargerCustomer?.stats?.plan180PercentageChange
                      }
                    />
                    <Typography fontSize={"16px"} fontFamily={"mySecondFont"}>
                      <span
                        style={{
                          color: getCompareColor(
                            superChargerCustomer?.stats?.plan180Change
                          ),
                          fontFamily: "mySecondFont",
                        }}
                      >
                        {superChargerCustomer?.stats?.plan180Change}
                      </span>{" "}
                      than {getComparisonTime()}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", flexShrink: 0 }}>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "12px",
                    background: "#240D66",
                  }}
                ></Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    fontSize={"14px"}
                    color={"#666"}
                    fontFamily={"myThirdFont"}
                  >
                    Annual Plan
                  </Typography>
                  <Typography
                    fontSize={"18px"}
                    color={"#2F2F2F"}
                    fontFamily={"mySecondFont"}
                    textAlign={"start"}
                  >
                    {superChargerCustomer?.stats?.plan365}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <>
                    <ValueChip
                      title={"superYear"}
                      valuChipData={
                        superChargerCustomer?.stats?.plan365PercentageChange
                      }
                    />
                    <Typography fontSize={"16px"} fontFamily={"mySecondFont"}>
                      <span
                        style={{
                          color: getCompareColor(
                            superChargerCustomer?.stats?.plan365Change
                          ),
                          fontFamily: "mySecondFont",
                        }}
                      >
                        {superChargerCustomer?.stats?.plan365Change}
                      </span>{" "}
                      than {getComparisonTime()}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuperSubscription;
