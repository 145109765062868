import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTabs from "../../custom/CustomTabs";
import ActiveSession from "./ActiveSession";
import AllSession from "./AllSession";
import {
  HubAllSessionsColumns,
  HubAllSessionsData,
  HubSessionsColumns,
  HubSessionsData,
} from "../../../utils/DummyData";
import { useDispatch } from "react-redux";
import { getAllHubSessions } from "../../../redux/action/hubManagement/hubManagementSlice";
import { useSelector } from "react-redux";

const SessionTableBox = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [isActive, setIsActive] = useState(false);

  const { allSessions } = useSelector((state) => state.hubManagement);

  const dispatch = useDispatch();

  useEffect(() => {
    const activeCheck = selectedTab === 0 ? true : false;
    setIsActive(activeCheck);

    if (selectedTab == 1) {
      const now = new Date();
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - 6);
      const startTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
      const endTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

      dispatch(
        getAllHubSessions({
          activeCheck: activeCheck,
          startDate: startTime,
          endDate: endTime,
        })
      );
    } else {
      dispatch(getAllHubSessions({ activeCheck: activeCheck }));
    }
  }, [dispatch, selectedTab]);

  const allHubSessions = allSessions.map((form) => {
    return {
      _id: form?._id,
      station: form?.station.name,
      city: form?.station?.city,
      deviceId: form?.deviceId,
      connectorId: form?.connectorId,
      phoneNumber: form?.hubEntry?.passHolder?.phone,
      parking_num: form?.parkingNumber,
      passNumber: form?.hubEntryDetails?.passNumber,
      started_by: form?.guardDetails?.name,
      vehicle_number: form?.hubEntryDetails?.vehicleNumber,
      charging_pass_type: form?.hubEntryDetails?.hubPassPlan?.passType,
      vehicle_entry_time: form?.hubEntryDetails?.vehicleEntryTime,
      vehicle_out_time: form?.hubEntryDetails?.vehicleExitTime,
      purchase_date: form?.hubEntryDetails?.passPuchaseDate,
      validTill: form?.hubEntryDetails?.passExpiryDate,
      sessionDuration: form?.sessionDuration,
      energyConsumed: form?.energyConsumed,
      stopReason: form?.stopReason,
      sessionStart: form?.sessionStart,
      sessionStop: form?.sessionStop,
      vehicleDuration: form?.hubEntryDetails?.vehicleDuration,
      pointsEarned: form?.hubEntry?.pointsEarned,
      // objects
      connectorDetails: form?.connector,
      chargingPointAllDetails: form?.chargingPoint,
      stationAllDetails: form?.station?.name,
      hubEntryAllDetails: form?.hubEntryDetails,
      hubPassPlanAllDetails: form?.hubPassPlan,
      guardAllDetails: form?.guardDetails,
    };
  });

  const tabsData = [
    {
      label: "Active Hub Sessions",
      content: (
        <ActiveSession
          columns={HubSessionsColumns}
          row={allHubSessions}
          isActive={isActive}
        />
      ),
    },
    {
      label: "Last 7 Days Hub Sessions",
      content: (
        <AllSession
          columns={HubAllSessionsColumns}
          row={allHubSessions}
          isActive={isActive}
          tabTitle={"Last7DaysSessions"}
        />
      ),
    },
    {
      label: "All Hub Sessions",
      content: (
        <AllSession
          columns={HubAllSessionsColumns}
          row={allHubSessions}
          isActive={isActive}
          tabTitle={"HubAllSessions"}
        />
      ),
    },
  ];

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      {/* Custom Tabs */}
      <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />

      {/* Tab content displayed below the tabs */}
      <Box sx={{ mt: 3 }}>{tabsData[selectedTab].content}</Box>
    </Box>
  );
};

export default SessionTableBox;
