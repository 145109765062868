//  <Grid item md={4}>
//             <SelectField
//               name="org"
//               value={formData.org}
//               label="Organization"
//               handleChange={handleChange}
//               menuItems={orgs.map((item) => ({
//                 value: item._id,
//                 label: item.name,
//               }))}
//             />
//           </Grid>

import {
  Box,
  Divider,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Heading from "../../utils/Heading";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminOrgs } from "../../redux/action/adminManagement/adminSlice";
import { fetchAllStations } from "../../redux/action/stations/stationSlice";
import { getTariffs } from "../../redux/action/tariff/createTariffSlice";
import {
  createChargingPoint,
  createConnector,
  deleteConnector,
  fetchOneChargingPoint,
  updateChargingPoint,
} from "../../redux/action/chargingPointSlice/chargingPointSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InputField = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  type = "text",
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="#2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "48px",
        outline: "none",
        // Remove spinner arrows for number inputs
        WebkitAppearance: "none",
        MozAppearance: "textfield",
      }}
      placeholder={placeholder}
    />
  </Box>
);

const SelectField = ({
  label,
  value,
  handleChange,
  menuItems,
  name,
  disabled,
  CpStationData,
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="#2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <FormControl fullWidth>
      <InputLabel sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled}
        sx={{ height: "48px", fontFamily: "myThirdFont !important" }}
      >
        {CpStationData ? (
          // When CpStationData is provided, show it as the only option
          <MenuItem value={CpStationData}>{CpStationData}</MenuItem>
        ) : (
          // Ensure menuItems is an array before mapping
          Array.isArray(menuItems) &&
          menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  </Box>
);

const Section = ({ icon, title, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
        <Heading text={title} />
      </Box>
      <Divider sx={{ mt: "8px" }} />
    </Box>
    {children}
  </Box>
);

const AddChargingPointForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { CPData } = location.state || {};
  const { CpStationData } = location.state || {};
  const [chargingData, setChargingData] = useState(null);

  const [selectedConnectorId, setSelectedConnectorId] = useState("");
  const [usedConnectorIds, setUsedConnectorIds] = useState([]);

  const availableConnectorIds = [1, 2, 3].filter(
    (id) => !usedConnectorIds.includes(id)
  );

  console.log("cpdatastation", CpStationData);

  const { orgs } = useSelector((state) => state.admin);
  const { stations } = useSelector((state) => state.stations);
  const { tariffs } = useSelector((state) => state.tariff);
  console.log(tariffs);
  const { chargingPoints, fetchedChargingPoint, loading, error } = useSelector(
    (state) => state.chargingPoints
  );
  const [connector, setConnector] = useState({
    connectorType: "",
    power: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    org: "",
    station: "",
    tariff: "",
    chargingPoints: "",
    chargingPointType: "",
    maxPower: "",
    firmwareVersion: "",
  });

  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    dispatch(fetchAdminOrgs());
    dispatch(fetchAllStations({}));
    dispatch(getTariffs({}));

    if (CPData) {
      dispatch(fetchOneChargingPoint(CPData)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setChargingData(response.payload);
          const data = response.payload;
          setChargingData(data);

          // Extract used connector IDs
          const usedIds = data.connectors.map(
            (connector) => connector.connectorId
          );
          setUsedConnectorIds(usedIds);
        } else {
          toast.error("Failed to fetch organization details");
        }
      });
    }
  }, [dispatch, CPData]);

  console.log("huuuuu", chargingData);

  useEffect(() => {
    if (chargingData) {
      setFormData({
        name: chargingData.name || "",
        org: chargingData.org?._id || "",
        station: chargingData.station?._id || "",
        tariff: chargingData.tariff?._id || "", // Ensure tariff _id is being set
        chargingPointType: chargingData.chargingPointType || "", // Ensure chargingPointType is being set
        maxPower: chargingData.maxPower || "",
        firmwareVersion: chargingData.firmwareVersion || "",
      });
      setConnectors(chargingData.connectors || []);
    }
  }, [chargingData]);

  const [chargingPointType, setChargingPointType] = useState("");

  const handleChargingPointTypeChange = (event) => {
    setChargingPointType(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      chargingPointType: event.target.value,
    }));
  };

  const chargingPointTypesMenuItems = [
    { value: "AC", label: "AC" },
    { value: "DC", label: "DC" },
  ];

  const handleConnectorChange = (e) => {
    const { name, value } = e.target;
    setConnector((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleConnectorSubmit = () => {
    if (connector.connectorType && connector.power && selectedConnectorId) {
      // Add selectedConnectorId to the connector object
      const newConnector = {
        ...connector,
        connectorId: selectedConnectorId,
      };

      // Add new connector to the connectors list
      setConnectors((prevData) => [...prevData, newConnector]);

      // Update usedConnectorIds
      setUsedConnectorIds((prevIds) => [...prevIds, selectedConnectorId]);

      // Reset form fields
      setConnector({
        connectorType: "",
        power: "",
      });
      setSelectedConnectorId(""); // Reset selected connector ID
    } else {
      toast.error("Please fill in all fields including connector ID.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateFormData = (formData) => {
    const requiredFields = [
      "name",
      "station",
      "tariff",
      "chargingPointType",
      "maxPower",
      // No need to include connectorType and connectorPower here, as they are part of connectors array
    ];

    // Check for empty required fields
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].toString().trim() === "") {
        toast.error(
          `Please fill in the ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`
        );
        return false;
      }
    }

    // Validate maxPower to be a positive number
    const maxPower = parseFloat(formData.maxPower);
    if (isNaN(maxPower) || maxPower <= 0) {
      toast.error("Power capacity must be a positive number");
      return false;
    }

    // Validate firmwareVersion to be a number if provided
    if (formData.firmwareVersion && isNaN(formData.firmwareVersion)) {
      toast.error("Firmware version must be a number");
      return false;
    }

    // Ensure at least one connector exists and each has a connectorType and power
    if (connectors.length === 0) {
      toast.error("At least one connector must be added");
      return false;
    }

    for (const connector of connectors) {
      if (!connector.connectorType || !connector.power) {
        toast.error("Each connector must have a type and power");
        return false;
      }
    }

    // If all checks pass, return true
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFormData(formData)) {
      return; // Stop submission if validation fails
    }

    try {
      const data = { ...formData, connectors }; // Include connectors in the form data
      if (chargingData) {
        connectors.forEach((connector, index) => {
          if (!connector?._id) {
            const connectorData = {
              connectorId: connector?.connectorId,
              deviceId: chargingData?.device_id,
              connectorType: connector.connectorType,
              power: +connector.power,
            };
            dispatch(createConnector(connectorData)).unwrap();
          }
        });

        // Update existing charging point
        const response = await dispatch(
          updateChargingPoint({
            id: chargingData._id,
            data,
          })
        ).unwrap();
        if (response) {
          toast.success("Updated successfully.");
          navigate(-1);
        } else {
          throw new Error("Update failed. No response received.");
        }
      } else {
        // Create new charging point
        const createdChargingPoint = await dispatch(
          createChargingPoint(data)
        ).unwrap();
        // Create connectors for the new charging point
        connectors.forEach((connector, index) => {
          const connectorData = {
            connectorId: connector?.connectorId,
            deviceId: createdChargingPoint.device_id,
            connectorType: connector.connectorType,
            power: +connector.power,
          };
          dispatch(createConnector(connectorData)).unwrap();
        });
        toast.success("Charging point created successfully.");
        navigate(-1);
      }
    } catch (err) {
      toast.error(err.message || "An error occurred.");
    }
  };

  const handleDeleteConnector = (index) => {
    const connectorId = connectors[index]?._id;
    const selectedConnectorId = connectors[index]?.connectorId;

    if (connectorId) {
      dispatch(deleteConnector({ connectorId: connectorId }));
    }

    setUsedConnectorIds((prevIds) =>
      prevIds.filter((id) => id !== selectedConnectorId)
    );

    // Create a new array excluding the connector at the specified index
    const updatedConnectors = connectors.filter((_, i) => i !== index);
    setConnectors(updatedConnectors);
  };

  const allConnectorType = [
    {
      id: 1,
      connectorImg: "/images/connector-icon.png",
      connectorType: "Type 2",
    },
    {
      id: 2,
      connectorImg: "/images/type2.png",
      connectorType: "CCS 2",
    },
    {
      id: 3,
      connectorImg: "/images/type3.png",
      connectorType: "Type 1",
    },
    {
      id: 4,
      connectorImg: "/images/type4.png",
      connectorType: "GB/T",
    },
    {
      id: 5,
      connectorImg: "/images/type5.png",
      connectorType: "NACS",
    },
    {
      id: 6,
      connectorImg: "/images/type6.png",
      connectorType: "AC Socket",
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px 24px 78px 24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {/* Basic Details */}
      <Section icon="ev_station" title="Basic details">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <InputField
                label="Device name*"
                placeholder="Enter the name here"
                value={formData.name}
                onChange={handleChange}
                name="name"
              />
            </Grid>

            <Grid item md={4}>
              <SelectField
                name="station"
                value={
                  CpStationData
                    ? (formData.station = CpStationData?.id)
                    : formData.station
                }
                label="Charging Station"
                handleChange={handleChange}
                menuItems={stations.map((item) => ({
                  value: item._id,
                  label: item.stationName,
                }))}
                disabled={CPData || CpStationData ? true : false}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={2}>
            <Grid item md={4}>
              <SelectField
                name="tariff"
                value={formData.tariff}
                label="Tariff*"
                handleChange={handleChange}
                menuItems={tariffs.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
              />
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/* Specifications */}
      <Section icon="page_info" title="Specifications">
        <Grid container columnSpacing={2}>
          <Grid item md={4}>
            <SelectField
              name="chargingPointType"
              label="Charger Type*"
              value={formData.chargingPointType}
              handleChange={handleChargingPointTypeChange}
              menuItems={chargingPointTypesMenuItems}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Power capacity (kW)*"
              placeholder="Enter power capacity here"
              value={formData.maxPower}
              onChange={handleChange}
              name="maxPower"
              type="number"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Firmware version (optional)"
              placeholder="Enter firmwareVersion here"
              value={formData.firmwareVersion}
              onChange={handleChange}
              name="firmwareVersion"
              type="number"
            />
          </Grid>
        </Grid>
      </Section>

      <Box
        sx={{
          backgroundColor: "rgba(41, 45, 51, 0.05)",
          padding: "24px 16px",
          marginBottom: "16px",
        }}
      >
        <Section title={`Add Connector`}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
              color={"#2F2F2F"}
              textAlign={"center"}
            >
              Please choose the connector type*
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                ml: "12px",
                mb: "24px",
              }}
            >
              {/* Radio button in each box */}
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={connector.connectorType}
                  onChange={handleConnectorChange}
                  name="connectorType"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      mt: "8px",
                    }}
                  >
                    {allConnectorType.map((item) => (
                      <Box
                        key={item}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="4px"
                        padding="8px"
                        borderRadius="8px"
                        border="1px solid #292D33"
                        backgroundColor="#FCFCFC"
                        justifyContent="end"
                        width={80}
                        height={96}
                        position="relative"
                        paddingTop={"36px"}
                      >
                        <Radio
                          value={item?.connectorType}
                          sx={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          }}
                        />
                        <img
                          src={item?.connectorImg}
                          alt=""
                          width={32}
                          height={32}
                        />
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#2f2f2f",
                            fontFamily: "myThirdFont",
                            outline: "none",
                            textWrap: "nowrap",
                          }}
                        >
                          {item?.connectorType}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>

            <Grid container columnSpacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <InputField
                  label="Connector Power(kW)*"
                  placeholder="Enter Connector Power here"
                  value={connector?.power}
                  onChange={handleConnectorChange}
                  name="power"
                  type="number"
                />
              </Grid>

              <Grid
                item
                md={4}
                sm={6}
                xs={12}
                sx={{ width: "100%", paddingTop: "6px", textAlign: "left" }}
              >
                <InputLabel id="connector-id-label">Connector ID*</InputLabel>
                <Select
                  labelId="connector-id-label"
                  value={selectedConnectorId}
                  onChange={(e) => setSelectedConnectorId(e.target.value)}
                  name="connectorId"
                  displayEmpty
                  sx={{
                    height: "46px",
                    width: "50%",
                    fontFamily: "myThirdFont",
                    background: "#FFF",
                  }}
                >
                  <MenuItem value="">
                    <em>Select One</em>
                  </MenuItem>
                  {availableConnectorIds.map((id) => (
                    <MenuItem key={id} value={id}>
                      {id}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Button
              onClick={handleConnectorSubmit}
              sx={{
                background: "#222",
                padding: "8px 24px",
                marginTop: "12px",
                borderRadius: "4px",
                color: "#FCFCFC",
                fontFamily: "mySecondFont",
                letterSpacing: "1.12px",
                textWrap: "nowrap",
                ":hover": {
                  background: "#222",
                },
              }}
            >
              Add Connector
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                mt: "12px",
              }}
            ></Box>
          </Box>
        </Section>

        {connectors.length > 0 && (
          <Grid container sx={{ mt: 3 }}>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  background: "rgba(41, 45, 51, 0.10)",
                }}
              >
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"10%"}
                >
                  S.No
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"30%"}
                >
                  Connector Type
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"30%"}
                >
                  Power
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"20%"}
                >
                  Connnector ID
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"10%"}
                >
                  Action
                </Typography>
              </Box>

              {connectors.map((item, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      padding: "12px 16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"10%"}
                    >
                      {index + 1}
                    </Typography>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"30%"}
                    >
                      {`${item.connectorType}`}
                    </Typography>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"30%"}
                    >
                      {`${item.power}`}
                    </Typography>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"20%"}
                    >
                      {`${item.connectorId}`}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteConnector(index)}
                    >
                      <span
                        style={{ color: "#790000", fontWeight: "300" }}
                        className="material-symbols-outlined"
                      >
                        delete
                      </span>
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Add Another Connector */}
      {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={handleAddConnector}
          sx={{
            border: "1px solid #E50000",
            borderRadius: "4px",
            padding: "8px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "mySecondFont",
            fontSize: "14px",
            color: "#e50000",
          }}
        >
          <span
            style={{ paddingRight: "8px" }}
            className="material-symbols-outlined"
          >
            add
          </span>
          Add another connector
        </Button>
      </Box> */}

      {/* Add Station Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={handleSubmit}
          sx={{
            padding: "12px 24px",
            background: "#E50000",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontSize: "16px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "700",
            letterSpacing: "1.28px",
            ":hover": {
              background: "#E50000",
            },
          }}
        >
          {CPData ? "Update" : "SAVE & ADD CHARGE POINT"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddChargingPointForm;
