import React from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { AreaChart } from "recharts";
import dayjs from "dayjs";

const data = [
  { name: "Page A", uv: 4000, pv: 2400 },
  { name: "Page B", uv: 3000, pv: 1398 },
  { name: "Page C", uv: 2000, pv: 9800 },
  { name: "Page D", uv: 2780, pv: 3908 },
  { name: "Page E", uv: 1890, pv: 4800 },
  { name: "Page F", uv: 2390, pv: 3800 },
  { name: "Page G", uv: 3490, pv: 4300 },
];

const ShadedDoubleLineGraph = ({
  barGraphType,
  data,
  startTime,
  endTime,
  selectedTime,
}) => {
  //with today as distributed data
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];

        // Extract the year dynamically from the data
        const extractedYear = (() => {
          if (data?.appUserGraphData?.length > 0) {
            const time = data.appUserGraphData[0]?.time;
            // Ensure time exists and is a string
            if (typeof time === "string" && time.includes("-")) {
              const parts = time.split("-");
              return parts[2]; // Assuming the year is the third part
            }
          }
          // Default to current year (last two digits)
          return dayjs().format("YY");
        })();

        
    if (selectedTime === "last7Days") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "currentMonth") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "currentYear") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    }
    // else if (selectedTime === "customRange" && startTime && endTime) {
    //   let currentDate = dayjs(startTime);
    //   const end = dayjs(endTime);
    //   while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    //     dates.push({
    //       date: currentDate.format("YYYY-MM-DD"),
    //       label: currentDate.format("YYYY-MM-DD"),
    //     });
    //     currentDate = currentDate.add(1, "day");
    //   }
    // }
    else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format(`DD-MM-${extractedYear}`),
          label: currentDate.format(`DD-MM-${extractedYear}`),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      // Generate hour-wise data for yesterday
      for (let i = 0; i < 24; i++) {
        const hour = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .add(i, "hour")
          .format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    if (selectedTime === "today" || selectedTime === "yesterday") {
      return dates.map(({ date, label }) => {
        const hourData =
          data?.data.find(
            (item) => item.time === parseInt(label.split(":")[0])
          ) || {};

        const totalEnergy = hourData.total_energy || 0;
        const avgDuration = hourData.avg_duration || 0;

        return {
          date: label,
          totalEnergy: totalEnergy === 0 ? 0 : totalEnergy?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }
    if (selectedTime === "last7Days") {
      return dates.map(({ date, label }) => {
        const dayData = data?.data.find((item) => item.time === label) || {};

        const totalEnergy = dayData.total_energy || 0;
        const avgDuration = dayData.avg_duration || 0;

        return {
          date: label, // Day of the week label (Mon, Tue, etc.)
          totalEnergy: totalEnergy === 0 ? 0 : totalEnergy?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }
    if (selectedTime === "currentMonth") {
      return dates.map(({ date, label }) => {
        const dayData =
          data?.data.find((item) => item.time === parseInt(label)) || {};

        const totalEnergy = dayData.total_energy || 0;
        const avgDuration = dayData.avg_duration || 0;

        return {
          date: label, // Day of the month as label
          totalEnergy: totalEnergy === 0 ? 0 : totalEnergy?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }
    if (selectedTime === "currentYear") {
      return dates.map(({ date, label }) => {
        const monthData = data?.data.find((item) => item.time === label) || {};

        const totalEnergy = monthData.total_energy || 0;
        const avgDuration = monthData.avg_duration || 0;

        return {
          date: label, // Month name (e.g., "Jan", "Feb", ...)
          totalEnergy: totalEnergy === 0 ? 0 : totalEnergy?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }

// Custom Range Logic
if (selectedTime === "customRange") {
  return dates.map(({ date, label }) => {
    const dateData = data?.data.find((item) => item.time === label) || {};

    const totalEnergy = dateData.total_energy || 0;
    const avgDuration = dateData.avg_duration || 0;

    return {
      date: label, // The specific date label (e.g., "21-11-24")
      totalEnergy: totalEnergy === 0 ? 0 : totalEnergy?.toFixed(2),
      avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
    };
  });
}

    return [];
  };

  const chartData = getData();

  console.log("hghghsksks", data);
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={chartData}
        margin={{
          top: 20,
          right: 10,
          bottom: 20,
          left: 10,
        }}
      >
        <CartesianGrid
          stroke="#DDDDDD"
          strokeDasharray="5 3"
          vertical={false}
        />

        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={barGraphType === "network" ? "#2F78E6" : "#7F73C6"}
              stopOpacity={0.25}
            />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={barGraphType === "network" ? "#FF5454" : "#F8610C"}
              stopOpacity={0.25}
            />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="date" // Changed to "date" to match the hourly data
          stroke="#AFAFAF" // Axis color
          tick={{
            fontSize: 14, // Font size for X-axis labels
            fontFamily: "mySecondFont", // Font family for X-axis labels
            fill: "#666", // Color for X-axis labels
          }}
        />
        <YAxis
          yAxisId="left"
          stroke="#AFAFAF" // Axis color
          tick={{
            fontSize: 14, // Font size for Y-axis labels
            fontFamily: "mySecondFont", // Font family for Y-axis labels
            fill: "#666", // Color for Y-axis labels
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#AFAFAF" // Axis color
          tick={{
            fontSize: 14, // Font size for Y-axis labels
            fontFamily: "mySecondFont", // Font family for Y-axis labels
            fill: "#666", // Color for Y-axis labels
          }}
        />
        <Tooltip />
        <Legend />

        <Area
          type="monotone"
          dataKey="totalEnergy" // Use "totalEnergy" for the Area graph
          stroke={barGraphType === "network" ? "#2F78E6" : "#7F73C6"}
          fill="url(#colorPv)"
          fillOpacity={1}
          yAxisId="left" // Associate this Area with the left Y axis
        />
        <Area
          type="monotone"
          dataKey="avgDuration" // Use "totalRevenue" for the other Area graph
          stroke={barGraphType === "network" ? "#F8610C" : "#FF5454"}
          fill="url(#colorUv)"
          fillOpacity={1}
          yAxisId="right" // Associate this Area with the right Y axis
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ShadedDoubleLineGraph;
