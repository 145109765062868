import React from "react";
import TopGraph from "./Overview/TopGraph";
import { Box, Grid } from "@mui/material";
import TimeUpCard from "./Overview/TimeUpCard";
import DirectSession from "../charging_stations_details/Overview/DirectSession";
import AlertBoxCard from "./Overview/AlertBoxCard";
import ValueCardBoxUpDownTime from "../dashboard/ValueCardBoxUpDownTime";

const OverviewBox = ({
  data,
  cmStatsData,
  selectedTime,
  startTime,
  endTime,
  previousDashboardData,
  getUpDownOneCompany
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item md={12} spacing={2}>
              <TopGraph
                data={cmStatsData}
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            </Grid>
            <Grid item md={12}>
              <DirectSession
                data={cmStatsData}
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            {/* <Grid item md={12}>
              <TimeUpCard
                data={cmStatsData}
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
              />
            </Grid> */}

            <Grid
                  item
                  md={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box sx={{ width: "100%" }}>
                    <ValueCardBoxUpDownTime
                      //Current data
                      data={getUpDownOneCompany}
                      //Previous data
                      // previousDashboardData={data}
                      //Customers Data
                      selectedTime={selectedTime}
    
                      showCardInRow={false}
                    />
                  </Box>
                </Grid>
            {/* <Grid item md={12}>
              <AlertBoxCard />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBox;
