import { Box } from "@mui/material";
import React from "react";
import Settlements from "../../../components/FinanceManager/Settlements/Settlements";

const SettlementsPage = () => {
  return (
    <Box>
      <Settlements />
    </Box>
  );
};

export default SettlementsPage;
