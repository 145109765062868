

import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";

const calculateDaysAndHoursDifference = (timestamp1, timestamp2) => {
  // Parse the timestamps into Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  const diffInMillis = Math.abs(date1 - date2);

  const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));

  const diffInHours = Math.floor(
    (diffInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  // Return the difference as a formatted string like "6d 4h"
  return `${diffInDays}d ${diffInHours}h`;
};



const PaymentsAlertsHistory = ({columns, row}) => {
  // console.log("fffgg", columns);

  const dispatch = useDispatch()
 
  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff", display:"flex", flexDirection:"column", gap:"24px" }}>
         <Box><TableFilters/></Box>
      {/* Table */}
      <CustomTable
        // columns={columns}
        // rows={row}

        columns={columns}
        rows={row}
        isRowClickable={true}
        modalHeading={"Alerts History"}
        // actionType={"edit-delete"}
        // rowClickPath={"/charging_stations"}
        // clickableColumns={["stationName"]}
        // columnClickPaths={{
        //   stationName: "/charging_stations",
        // }}
        // columnKeyMappings={{
        //   stationName: "_id",
        // }}
        // statsHeading={"true"}
        // loading={loading}
        // sortColumn={sortColumn}
      />
    </Box>
  );
};

export default PaymentsAlertsHistory;

