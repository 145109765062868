import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import TableFilters from '../charging-sessions/TableFilters';
import CustomTable from '../custom/CustomTable';
import { useNavigate } from 'react-router-dom';
import { MyRoutes } from '../../routes/routes';

const AssignedAllTariffs = ({  columns,
  rows,
  isRowClickable,
  showAddButton,
  networkDetail,

  customAction,}) => {
  const navigate = useNavigate();
  const [filteredChPoints, setFilteredChPoints] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const handlePageChange = () => {
    // navigate("/add_new_charging_point");
    navigate(MyRoutes.addNewOcpiTariff, {
      state: {
        networkDetail: networkDetail,
        // edit: true
      },
    });
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
<Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          // data={data}
          // onFilterChange={handleFilterChange}
          // filters={myFilters}
        />
        <Button
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "120px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "'Montserrat', sans-serif",
            textWrap: "nowrap",
            letterSpacing: "1.12px",
            ":hover": {
              background: "#222",
            },
          }}
          onClick={handlePageChange}
        >
          + ADD NEW
        </Button>
      </Box>

      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        clickableColumns={["name", "network", "operatorName"]}
        actionType={
          customAction === "tariff"
            ? "view"
            : customAction === "ocpi"
            ? "qr-copy"
            : customAction === "network"
            ? "edit-delete"
            : null
        }
        //To be changed on checks
        rowClickPath="/charging_points"
        columnClickPaths={{
          // name: "/charging_points",
          //To Be Changed
          network: "/ocpi_management_details",
          name: "/ocpi_management_details",
        }}
        columnKeyMappings={{
          name: "deviceID",
          network: "deviceID",
          name: "operatorId",
        }}
        modalHeading={"Assigned Tariffs"}
      />
    </Box>
  );
};

export default AssignedAllTariffs;
