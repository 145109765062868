import { Box, Grid } from "@mui/material";
import React from "react";
import ValueCard from "./ValueCard";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import SkeletonValueCard from "../Skeleton/SkeletonValueCard";

const ValueCardBox = ({
  data,
  customers,
  previousDashboardData,
  selectedTime,
  customerData,
  previousCustomerData,
  cardTypeProp,
  walletData,
  previousWalletData,
  hubSession,
  loading,
  showCardInRow, // Controls layout direction
}) => {
  const currencySymbol = GetCurrencySymbol();

  return showCardInRow ? (
    // Grid layout for row display
    <Grid
      container
      spacing={2}
      sx={{ flexGrow: 1, height: "100%", width: "100%" }}
    >
      <Grid item xs={12} md={4}>
        {loading ? (
          <SkeletonValueCard />
        ) : (
          <ValueCard
            title={
              hubSession ? "Active Hub Sessions" : "Total Utilization (hrs)"
            }
            data={data}
            previousDashboardData={previousDashboardData}
            selectedTime={selectedTime}
            cardTypeProp={cardTypeProp}
            hubSession={hubSession}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {loading ? (
          <SkeletonValueCard />
        ) : (
          <ValueCard
            title={hubSession ? "Total Hub Passes Sold" : "New customers"}
            data={data}
            previousDashboardData={previousDashboardData}
            previousCustomersData={previousCustomerData}
            selectedTime={selectedTime}
            customers={customerData}
            cardTypeProp={cardTypeProp}
            hubSession={hubSession}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {loading ? (
          <SkeletonValueCard />
        ) : (
          <ValueCard
            title={
              hubSession
                ? "Total Value of Passes Sold"
                : `Wallet Recharge (${currencySymbol})`
            }
            data={hubSession ? data : walletData}
            previousDashboardData={
              hubSession ? previousDashboardData : previousWalletData
            }
            selectedTime={selectedTime}
            cardTypeProp={cardTypeProp}
            hubSession={hubSession}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    // Box layout for column display
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flexGrow: 1,
        height: "100%",
        width: "100%",
      }}
    >
      {loading ? (
        <SkeletonValueCard />
      ) : (
        <>
           {hubSession && <ValueCard
          title={hubSession ? "Active Hub Sessions" : "Total Utilization (hrs)"}
          data={data}
          previousDashboardData={previousDashboardData}
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />}
        </>
     
      )}
      {loading ? (
        <SkeletonValueCard />
      ) : (
        <ValueCard
          title={hubSession ? "Total Hub Passes Sold" : "New customers"}
          data={data}
          previousDashboardData={previousDashboardData}
          previousCustomersData={previousCustomerData}
          selectedTime={selectedTime}
          customers={customerData}
          // cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
      {loading ? (
        <SkeletonValueCard />
      ) : (
        <ValueCard
          title={
            hubSession
              ? "Total Value of Passes Sold"
              : `Wallet Recharge (${currencySymbol})`
          }
          data={hubSession ? data : walletData}
          previousDashboardData={
            hubSession ? previousDashboardData : previousWalletData
          }
          selectedTime={selectedTime}
          // cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
    </Box>
  );
};

export default ValueCardBox;
