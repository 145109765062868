import { Box } from "@mui/material";
import React from "react";
import CashTableBox from "./CashTableBox";

const CashBox = ({ row, column }) => {
  return (
    <Box>
      <CashTableBox row={row} column={column} />
    </Box>
  );
};

export default CashBox;
