import { Box, Typography } from "@mui/material";
import React from "react";
import StatusChip from "../../Chips/StatusChip";
import CustomerProgressBar from "../../Graphs/CustomerManagement/CustomerProgressBar.jsx";

const InactiveCustomer = ({ data }) => {
  return (
    <Box
      sx={{
        padding: "12px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <StatusChip text={"Live"} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              background: "rgba(41, 45, 51, 0.08)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "300" }}
              className="material-symbols-outlined"
            >
              group_off
            </span>
          </Box>
          <Typography
            fontFamily={"myThirdFont"}
            fontSize={"18px"}
            color={"#2F2F2F"}
          >
            Inactive customers:{" "}
            <span style={{ fontFamily: "mySecondFont" }}>
              {data?.data?.totalInactiveUser}{" "}
              {`(${data?.data?.totalInactiveUserPercentage}%)`}
            </span>
          </Typography>
        </Box>
        <CustomerProgressBar dataType="inactiveCustomers" data={data} />
      </Box>
    </Box>
  );
};

export default InactiveCustomer;
