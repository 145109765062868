import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateOneStation } from "../../../redux/action/stations/stationSlice";
import { updateOneStationReward } from "../../../redux/action/hubManagement/hubManagementSlice";

const Rewards = ({ data }) => {
  const [stationId, setStationId] = useState(null);
  const [rewardPoints, setRewardPoints] = useState(data?.perUnitPoints || "");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();

  // Extract station ID from URL
  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setStationId(id);
    }
  }, []);

  // Update button state based on reward points change
  useEffect(() => {
    setIsButtonDisabled(rewardPoints === data?.perUnitPoints);
  }, [rewardPoints, data?.perUnitPoints]);

  const handleInputChange = (e) => {
    setRewardPoints(e.target.value);
  };

  const handleClickUpdate = () => {
    if (stationId) {
      dispatch(
        updateOneStationReward({
          stationId,
          perUnitPoints: parseInt(rewardPoints),
        })
      )
        .then((data) => {
          console.log(data);
          toast.success("Update Successful");
        })
        .catch((error) => {
          toast.error("Update Failed: " + error.message);
        });
    }
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Typography
        textAlign={"start"}
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
      >
        Enter Reward Points/kWh
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <input
          style={{
            padding: "10px 16px",
            outline: "none",
            borderRadius: "4px",
            border: "1px solid #c0c0c0",
            color: "#9C9C9B",
            fontFamily: "myThirdFont",
            height: "40px",
            width: "300px",
          }}
          type="number"
          value={rewardPoints}
          onChange={handleInputChange}
        />
        <Button
          sx={{
            background: isButtonDisabled ? "#555" : "#222",
            padding: "8px 24px",
            width: "120px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
            ":hover": {
              background: isButtonDisabled ? "#555" : "#333",
            },
          }}
          disabled={isButtonDisabled}
          onClick={handleClickUpdate}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default Rewards;
