import { useState } from "react";
import { 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  IconButton, 
  Typography,
  Fade,
  Paper,
  useTheme,
  useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const useImageZoomIn = () => {
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [imageTag, setImageTag] = useState("");
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleImageClick = (url, tag) => {
    setImageSrc(url);
    setImageTag(tag);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setImageSrc("");
      setImageTag("");
    }, 300);
  };

  const ImageDialog = () => (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          boxShadow: theme.shadows[24],
          borderRadius: '12px',
          overflow: 'hidden'
        }
      }}
    >
      <Paper 
        elevation={0}
        sx={{
          position: 'relative',
          backgroundColor: 'transparent'
        }}
      >
        <DialogActions 
          sx={{ 
            position: "absolute", 
            top: 0, 
            right: 0, 
            padding: "12px", 
            zIndex: 1
          }}
        >
          <IconButton 
            edge="end" 
            onClick={handleClose}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 1)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>

        <DialogContent 
          sx={{ 
            padding: theme.spacing(3),
            overflow: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: 'hidden'
              }}
            >
              <img
                src={imageSrc}
                alt="Enlarged"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  maxHeight: "80vh",
                  borderRadius: "8px",
                  userSelect: 'none'
                }}
              />
            </Box>
            {imageTag && (
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  marginTop: theme.spacing(2),
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                  opacity: 0.87
                }}
              >
                {imageTag}
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Paper>
    </Dialog>
  );

  return { handleImageClick, ImageDialog };
};