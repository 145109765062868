import { Box } from "@mui/material";
import React from "react";
import DoughnutCharHub from "./DoughnutCharHub";
import CustomerProgressBar from "../CustomerManagement/CustomerProgressBar.jsx";

const PassValueGraph = ({ data, passType }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      {/* <DoughnutChart /> */}
      {/* <DoughnutCharHub
        data={data}
        centerData=""
        passType={passType}
        title="Total Pass Holders"
      /> */}
      <CustomerProgressBar dataType="totalPassHolder" data={data} />
    </Box>
  );
};

export default PassValueGraph;
