import { Box } from '@mui/material'
import React from 'react'
import PositiveNegativeBarGraph from '../../Graphs/AlertsGraphs/PositiveNegativeBarGraph'

const MiddleTopGraphBox = ({data}) => {
  return (
    <Box>
      <PositiveNegativeBarGraph data={data}/>
    </Box>
  )
}

export default MiddleTopGraphBox
