import React from "react";
import { Box, Grid } from "@mui/material";
import TopBox from "./Overview/TopBox";
import BottomBox from "./Overview/BottomBox";
import { useSelector } from "react-redux";

const OverviewBox = ({
  data,
  selectedTime,
  startTime,
  endTime,
  orgData,
  previousPaymentOrgData,
  previousPaymentWalletData,
}) => {
  const user = useSelector((state) => state?.profile?.profile);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TopBox
              // data={data}
              // orgData={orgData}

              //wallte data
              data={data}
              previousPaymentWalletData={previousPaymentWalletData}
              //org data
              orgData={orgData}
              previousPaymentOrgData={previousPaymentOrgData}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
              user={user}
            />
          </Box>
        </Grid>
      </Grid>

      {/* mid - graph - component */}

      {user?.role === "Superadmin" ||
      user?.role === "1C_Admin" ||
      user?.role === "1C_User" ? (
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <BottomBox
                // data={data}

                //wallte data
                data={data}
                previousPaymentWalletData={previousPaymentWalletData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default OverviewBox;
