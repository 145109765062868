import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import Heading from "../../../utils/Heading";
import { formatDateTime } from "../../../utils/FormatDateTime";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
          textTransform={"capitalize"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const RFIDDetails = ({ data }) => {
  const superStartDate = data?.super_start_date
    ? formatDateTime(data.super_start_date)
    : {};
  const superEndDate = data?.super_end_date
    ? formatDateTime(data.super_end_date)
    : {};
  return (
    <Box
      sx={{
        padding: "16px 16px 24px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span className="material-symbols-outlined">badge</span>
          <Heading text={"RFID & 1C SUPER"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="RFID" value={data?.rfidData?.serialNumber} />
        <InfoBox
          label="Current super subscription"
          value={
            data?.super_duration === 365
              ? "1 Year Plan"
              : data?.super_duration === 180
              ? "6 Months Plan"
              : data?.super_duration === 30
              ? "1 Month Plan"
              : ""
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Subscribed on" value={superStartDate.date} />
            <Typography
              fontSize={"12px"}
              fontFamily={"myThirdFont"}
              color={"#666"}
              textAlign={"start"}
            >
              {superStartDate.time}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Valid till" value={superEndDate.date} />
            <Typography
              fontSize={"12px"}
              fontFamily={"myThirdFont"}
              color={"#666"}
              textAlign={"start"}
            >
              {superEndDate.time}
            </Typography>
          </Box>
        </Box>
        <InfoBox
          label="Savings from current subscription"
          value={`₹${data?.cashback?.totalCashback?.toFixed(2) ?? "N/A"}`}
        />
      </Box>
    </Box>
  );
};

export default RFIDDetails;
