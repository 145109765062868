import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Dropbox from "../../dropbox/Dropbox";
import { useDropzone } from "react-dropzone";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "8px",
  outline: "none",
};

const SettleModal = ({ open, handleClose, data, handleOpen }) => {
  const [formData, setFormData] = useState({
    pendingAmount: data?.pendingSettlementAmount || "",
    confirmAmount: "",
    utrNo: "",
    invoiceNo: "",
    companyLogo: [],
    isChecked: false,
  });

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
  });

  const acceptedFilesType = {
    "image/jpeg": [],
    "image/png": [],
    "image/jpg": [],
  };

  const isSettleEnabled =
    formData.isChecked &&
    formData.confirmAmount === formData.pendingAmount.toString();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSettle = () => {
    console.log("Settlement Data:", formData);
    handleClose();
    setFormData("");
    handleOpen();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontFamily={"mySecondFont"} fontSize={"16px"}>
                  Settlement Amount
                </Typography>
                <input
                  type="text"
                  value={formData.pendingAmount}
                  readOnly
                  style={{
                    border: "1px solid rgb(192, 192, 192)",
                    padding: "8px",
                    borderRadius: "4px",
                    outline: "none",
                    backgroundColor: "#f5f5f5",
                    fontFamily: "myThirdFont",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontFamily={"mySecondFont"} fontSize={"16px"}>
                  Confirm Settle Amount
                </Typography>
                <input
                  name="confirmAmount"
                  style={{
                    border: "1px solid rgb(192, 192, 192)",
                    padding: "8px",
                    borderRadius: "4px",
                    outline: "none",
                    fontFamily: "myThirdFont",
                  }}
                  type="text"
                  value={formData.confirmAmount}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontFamily={"mySecondFont"} fontSize={"16px"}>
                  UTR No.
                </Typography>
                <input
                  name="utrNo"
                  style={{
                    border: "1px solid rgb(192, 192, 192)",
                    padding: "8px",
                    borderRadius: "4px",
                    outline: "none",
                    fontFamily: "myThirdFont",
                  }}
                  type="text"
                  value={formData.utrNo}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontFamily={"mySecondFont"} fontSize={"16px"}>
                  Invoice No.
                </Typography>
                <input
                  name="invoiceNo"
                  style={{
                    border: "1px solid rgb(192, 192, 192)",
                    padding: "8px",
                    borderRadius: "4px",
                    outline: "none",
                    fontFamily: "myThirdFont",
                  }}
                  type="text"
                  value={formData.invoiceNo}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography fontFamily={"mySecondFont"} fontSize={"16px"}>
                    Upload Invoice
                  </Typography>
                  <Typography fontFamily={"myThirdFont"} fontSize={"14px"}>
                    (Optional)
                  </Typography>
                </Box>
                <Dropbox
                  acceptedFiles={acceptedFiles}
                  setFormData={setFormData}
                  files={formData.companyLogo}
                  acceptedFilesType={acceptedFilesType}
                />
              </Box>

              {/* Buttons and checks */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Checkbox
                  {...label}
                  checked={formData.isChecked}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      isChecked: e.target.checked,
                    }))
                  }
                />
                <Button
                  sx={{
                    width: "150px",
                    background: isSettleEnabled ? "#333" : "#999",
                    color: "#fff",
                    fontFamily: "mySecondFont",
                    ":hover": {
                      background: isSettleEnabled ? "#333" : "#999",
                    },
                  }}
                  disabled={!isSettleEnabled}
                  onClick={handleSettle}
                >
                  Settle
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default SettleModal;
