import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ValueChip from "../../Chips/ValueChip";

const AlertsDataBox = ({ data, selectedTime }) => {
  // Extract severity count from data
  const severityCount = data?.severityCount || {};

  // Function to format the comparison value (positive/negative)
  const formatValue = (value) => {
    const valueStr = String(value); // Convert value to string
    if (valueStr.startsWith("-")) {
      return valueStr;
    }
    return `+${valueStr}`;
  };

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#027900" : "#790000";
  };

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "last7Days":
        return "Last Week";
      case "currentMonth":
        return "Last Month";
      case "currentYear":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  // Severity order to control the rendering order
  const severityOrder = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];

  // Render each severity level (e.g., CRITICAL, HIGH, MEDIUM, LOW)
  const renderAlertBox = (severity, data) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#666",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              {`${severity} Alerts`}
            </Typography>
            <Typography
              sx={{
                color: "#2F2F2F",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              {data.total || 0}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              color: "#666",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "myThirdFont",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16px",
            }}
          >
            <ValueChip
              title={severity}
              data={data}
              chipType={"alerts"}
              // Pass any relevant data or previous data for comparison if needed
            />
          </Typography>
          <Typography
            fontFamily={"myThirdFont"}
            color={"#666"}
            fontWeight={"400"}
            fontSize={"12px"}
          >
            <span
              style={{
                color: getCompareColor(data.numberChange || 0),
                fontFamily: "mySecondFont",
              }}
            >
              {formatValue(data.numberChange || 0)}
            </span>{" "}
            than {getComparisonText()}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "12px",
        background: "#fff",
        borderRadius: "8px",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={12} sx={12}>
          {/* Render the alert boxes dynamically for each severity */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            {severityOrder.map((severity) => (
              <React.Fragment key={severity}>
                {renderAlertBox(severity, severityCount[severity] || {})}
              </React.Fragment>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertsDataBox;
