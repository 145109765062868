import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, Button, Slide } from "@mui/material";
import dayjs from "dayjs"; // Ensure you have dayjs installed
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  getAllChargingPasses,
  getAllGuards,
  getAllHubSessions,
  getAllPassHolders,
  getAllStationsHub,
  getAllTransaction,
  getShiftCollection,
} from "../../redux/action/hubManagement/hubManagementSlice";
import { useDispatch } from "react-redux";
import { useStationContext } from "../../Hooks/StationContext";
import { useTimeContext } from "../../Hooks/TimeContext";

const HubTimeFilter = ({ tabTitle, isActive, stationId }) => {
  const dispatch = useDispatch();
  const { stationIds } = useStationContext();
  const { startTime, setStartTime, endTime, setEndTime } = useTimeContext();
  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const maxDate = dayjs();

  // Function to dispatch API calls based on the current tab and time filters
  const fetchData = (startMillis = null, endMillis = null) => {
    const requestPayload = {
      stationIds: stationId ? { stationIds: [stationId] } : { stationIds },
      // stationId && stationId.length > 0 ?  : null,
      startDate: startMillis,
      endDate: endMillis,
    };

    switch (tabTitle) {
      case "PassHolder":
        dispatch(getAllPassHolders(requestPayload));
        break;
      case "HubStations":
        dispatch(getAllStationsHub(requestPayload));
        break;
      case "HubAllSessions":
        dispatch(
          getAllHubSessions({ ...requestPayload, activeCheck: isActive })
        );
        break;
      case "Last7DaysSessions":
        if (requestPayload?.startDate && requestPayload?.endDate) {
          dispatch(
            getAllHubSessions({ ...requestPayload, activeCheck: isActive })
          );
        }
        break;
      case "Guard":
        dispatch(getAllGuards(requestPayload));
        break;
      case "ChargingPass":
        dispatch(getAllChargingPasses(requestPayload));
        break;
      case "AllTransaction":
        dispatch(getAllTransaction(requestPayload));
        break;
      case "ShiftCollection":
        dispatch(getShiftCollection(requestPayload));
        break;
      default:
        console.log("Unknown tab title.");
    }
  };

  // Handles the 'APPLY' button click
  const handleApply = () => {
    const startMillis = startTime ? startTime.valueOf() : null;
    const endMillis = endTime ? endTime.valueOf() : null;

    if (startMillis && endMillis) {
      fetchData(startMillis, endMillis); // Make API call with filters
      setFiltersApplied(true); // Mark filters as applied
    } else {
      console.log("Please select both start and end dates.");
    }
  };

  // Handles the 'RESET' button click
  const handleReset = () => {
    if (tabTitle === "Last7DaysSessions") {
      const now = new Date();
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - 6);
      const last7DaysStartTime = new Date(
        startOfWeek.setHours(0, 0, 0, 0)
      ).valueOf();
      const last7DaysEndTime = new Date(
        now.setHours(23, 59, 59, 999)
      ).valueOf();
      dispatch(
        getAllHubSessions({
          activeCheck: isActive,
          startDate: last7DaysStartTime,
          endDate: last7DaysEndTime,
        })
      );
    }

    setStartTime(null);
    setEndTime(null);
    setFiltersApplied(false);
    fetchData(); // Fetch data without any filters
  };

  // Effect to reset the filters when the tab title changes
  React.useEffect(() => {
    if (filtersApplied) {
      // Reset time filters and the applied state
      setStartTime(null);
      setEndTime(null);
      setFiltersApplied(false);
    }

    // If filters have not been applied, fetch data for the tab without filters
    if (!filtersApplied) {
      fetchData(); // Fetch without filters
    }
  }, [tabTitle, dispatch, stationIds, isActive]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="From"
          value={startTime}
          maxDate={maxDate}
          onChange={(newValue) => {
            console.log("New Start Time:", newValue); // Log new start time
            setStartTime(newValue);
            // If the new start date is after the current end date, reset it
            if (newValue && endTime && newValue.isAfter(endTime)) {
              setEndTime(null);
            }
          }}
          sx={{
            width: "144px",
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiInputAdornment-root": {
              height: "48px",
            },
          }}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="To"
          value={endTime}
          maxDate={maxDate}
          onChange={(newValue) => {
            console.log("New End Time:", newValue); // Log new end time
            setEndTime(newValue);
          }}
          minDate={startTime} // Set minimum date for the end date
          sx={{
            width: "144px",
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiInputAdornment-root": {
              height: "48px",
            },
          }}
        />
      </LocalizationProvider>

      {/* Show Reset Button only when filters are applied */}
      {filtersApplied && (
        <Slide direction="down" in={filtersApplied} mountOnEnter unmountOnExit>
          <Button
            sx={{
              padding: "8px 12px",
              width: "70px",
              borderRadius: "4px",
              fontSize: "12px",
              color: "#790000",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleReset} // Reset filters on click
          >
            <RestartAltIcon />
            RESET
          </Button>
        </Slide>
      )}

      <Button
        sx={{
          background: "#222",
          padding: "8px 24px",
          width: "120px",
          height: "42px",
          borderRadius: "4px",
          color: "#FCFCFC",
          fontFamily: "mySecondFont",
          letterSpacing: "1.12px",
          ":hover": {
            background: "#222",
          },
          "&.Mui-disabled": {
            backgroundColor: "#555",
            color: "#999",
            cursor: "not-allowed",
            opacity: 0.6,
          },
        }}
        onClick={handleApply}
      >
        APPLY
      </Button>
    </Box>
  );
};

export default HubTimeFilter;
