import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../custom/CustomTable";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import TempCustomTable from "../../custom/TempCustomTable";
import TempTableFilters from "../../charging-sessions/TempTableFilters";

const ActiveSession = ({
  columns,
  rows,
  isActive,
  totalCounts,
  page,
  setPage,
  limit,
  search,
  setSearch,
  filters,
  setFilters,
}) => {
  return (
    <StationProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {/* filters */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <TableFilters /> */}

          {filters ? (
            <TempTableFilters filters={filters} setFilters={setFilters} />
          ) : (
            ""
          )}
        </Box>

        {/* Table */}
        {filters ? (
          <TempCustomTable
            columns={columns}
            rows={rows}
            totalCounts={totalCounts}
            filters={filters}
            setFilters={setFilters}
            modalHeading={"Active Sessions"}
          />
        ) : (
          ""
        )}
      </Box>
    </StationProvider>
  );
};

export default ActiveSession;
