import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, showContent, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && showContent && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  showContent: PropTypes.bool,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewCustomTabs = ({
  tabs,
  onTabChange,
  renderContentExternally,
  onTabContentChange,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get tab index from query params
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = parseInt(queryParams.get("tab"), 10) || 0;

  // Single state for current tab value
  const [value, setValue] = React.useState(tabFromQuery);

  // Single useEffect to handle URL changes
  React.useEffect(() => {
    const newTabValue = parseInt(queryParams.get("tab"), 10) || 0;
    setValue(newTabValue);

    // Update external content if needed
    if (renderContentExternally && onTabContentChange && tabs[newTabValue]) {
      onTabContentChange(tabs[newTabValue].content);
    }
  }, [location.search, renderContentExternally, onTabContentChange, tabs]);

  const handleChange = (event, newValue) => {
    // Only update if value is different
    if (value !== newValue) {
      setValue(newValue);
      navigate(`?tab=${newValue}`);

      if (onTabChange) {
        onTabChange(event, newValue);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="custom tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            minHeight: "unset",
            "& .MuiTabs-indicator": {
              backgroundColor: "#e50000 !important",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              icon={tab.icon}
              label={tab.label}
              {...a11yProps(index)}
              sx={{
                color: "#2F2F2F",
                fontFamily: "mySecondFont",
                fontSize: "16px",
                minHeight: "unset",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                "& .MuiTab-wrapper": {
                  flexDirection: "row",
                },
                "&.Mui-selected": {
                  color: "#2F2F2F",
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {!renderContentExternally &&
        tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.content}
          </CustomTabPanel>
        ))}
    </Box>
  );
};

NewCustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func,
  renderContentExternally: PropTypes.bool,
  onTabContentChange: PropTypes.func,
};

export default NewCustomTabs;
