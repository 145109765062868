import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
} from "recharts";

const PositiveNegativeBarGraph = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  // Severity levels ordered in the specific sequence
  const severityOrder = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];

  // Color mapping for severity levels
  const severityColors = {
    CRITICAL: "#8b0000", // dark red
    HIGH: "#ff0000", // red
    MEDIUM: "#ff4500", // orange-red
    LOW: "#ffa500", // orange
  };

  useEffect(() => {
    // Transform the data into the format needed for the chart
    if (data?.severityCount) {
      const transformedData = severityOrder.map((severity) => {
        const severityData = data.severityCount[severity] || {}; // Handle undefined severity data
        return {
          name: severity,
          resolved: (severityData.resolved || 0), // Make resolved positive
          unresolved: -(severityData.unresolved || 0), // Make unresolved negative
          color: severityColors[severity] || "#ff0000", // Default to red if severity not found
        };
      });

      setChartData(transformedData);
    }
  }, [data]);

  return (
    <div style={{ width: "100%", height: "305px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
          barGap={0}
        >
          {/* Background areas */}
          <ReferenceArea x1={0} x2={50} fill="#e6ffe6" />
          <ReferenceArea x1={-50} x2={0} fill="#ffe6e6" />

          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            type="number"
            domain={[-50, 50]}
            tickFormatter={(value) => Math.abs(value)}
            tick={{
              fontSize: "14px",
              fill: "#666",
              fontWeight: "500",
            }}
            axisLine={false}
          />

          <YAxis
            type="category"
            dataKey="name"
            tick={{
              fontSize: "14px",
              fill: "#666",
              fontWeight: "500",
            }}
            axisLine={false}
            tickLine={false}
          />

          <Tooltip formatter={(value, name) => [Math.abs(value), name]} />

          <Legend
            payload={[
              { value: "Resolved Alerts", type: "rect", color: "#027900" },
              { value: "Unresolved Alerts", type: "rect", color: "#790000" },
            ]}
            wrapperStyle={{
              padding: 10,
              fontSize: "14px",
              color: "#666",
            }}
            verticalAlign="bottom"
            align="center"
          />

          {/* Bar for Resolved */}
          <Bar
            dataKey="resolved"
            fill="#027900"
            name="Resolved Alerts"
            label={{
              position: "left",
              formatter: (value) => Math.abs(value),
              fill: "#027900",
            }}
          />

          {/* Bar for Unresolved */}
          <Bar
            dataKey="unresolved"
            fill="#790000"
            name="Unresolved Alerts"
            label={{
              position: "right",
              formatter: (value) => value,
              fill: "#800000",
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PositiveNegativeBarGraph;


// import React, { useEffect, useState } from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ReferenceArea,
//   ResponsiveContainer,
// } from "recharts";

// const PositiveNegativeBarGraph = ({ data }) => {
//   const [chartData, setChartData] = useState([]);

//   // Color mapping for severity levels
//   const severityColors = {
//     Critical: "#8b0000", // dark red
//     High: "#ff0000", // red
//     Medium: "#ff4500", // orange-red
//     Low: "#ffa500", // orange
//   };
//   console.log("ffhfddf", data)

//   useEffect(() => {
//     // Transform the data into the format needed for the chart
//     if (data?.severityCount) {
//       const transformedData = Object.keys(data.severityCount).map(
//         (severity) => {
//           const severityData = data.severityCount[severity];
//           return {
//             name: severity,
//             resolved: (severityData.resolved || 0), // Make resolved negative
//             unresolved: -(severityData.unresolved || 0),
//             color: severityColors[severity] || "#ff0000", // Default to red if severity not found
//           };
//         }
//       );

//       setChartData(transformedData);
//     }
//   }, [data]);

//   return (
//     <div style={{ width: "100%", height: "305px" }}>
//       <ResponsiveContainer width="100%" height="100%">
//         <BarChart
//           data={chartData}
//           layout="vertical"
//           margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
//           barGap={0}
//         >
//           {/* Background areas */}


//           <ReferenceArea x1={0} x2={50} fill="#e6ffe6" />
//           <ReferenceArea x1={-50} x2={0} fill="#ffe6e6" />

//           <CartesianGrid strokeDasharray="3 3" />

//           <XAxis
//             type="number"
//             domain={[-50, 50]}
//             tickFormatter={(value) => Math.abs(value)}
//             tick={{
//               fontSize: "14px",
//               fill: "#666",
//               fontWeight: "500",
//             }}
//             axisLine={false}
//           />

//           <YAxis
//             type="category"
//             dataKey="name"
//             tick={{
//               fontSize: "14px",
//               fill: "#666",
//               fontWeight: "500",
//             }}
//             axisLine={false}
//             tickLine={false}
//           />

//           <Tooltip formatter={(value, name) => [Math.abs(value), name]} />

//           <Legend
//             payload={[
//               { value: "Resolved Alerts", type: "rect", color: "#027900" },
//               { value: "Unresolved Alerts", type: "rect", color: "#790000" },
//             ]}
//             wrapperStyle={{
//               padding: 10,
//               fontSize: "14px",
//               color: "#666",
//             }}
//             verticalAlign="bottom"
//             align="center"
//           />

//           {/* Bar for Resolved */}
//           <Bar
//             dataKey="resolved"
//             fill="#027900"
//             name="Resolved Alerts"
//             label={{
//               position: "left",
//               formatter: (value) => Math.abs(value),
//               fill: "#027900",

          
//             }}
//           />

//           {/* Bar for Unresolved */}
//           <Bar
//             dataKey="unresolved"
//             // fill={(data) => data.color}
//             fill="#790000"
//             name="Unresolved Alerts"
//             label={{
//               position: "right",
//               formatter: (value) => value,
//               fill: "#800000",
//             }}
//           />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default PositiveNegativeBarGraph;
