import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import LiveLogTable from "../../custom/LiveLogTable";
import AlertsCustomTable from "../../custom/AlertsCustomTable";
import TableFilters from "../../charging-sessions/TableFilters";

const AlertsTableBox = ({ rows, onRowClick, columns, alertType, alertCountsForTable , modalHeading}) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData, rowIndex) => {
    setSelectedRow(rowIndex);
    if (onRowClick) {
      onRowClick(rowData);
    }
  };



  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            background:
              alertType === "critical"
                ? "rgba(121, 0, 0, 0.15)"
                : alertType === "high"
                ? "rgba(211, 24, 24, 0.15)"
                : alertType === "medium"
                ? "rgba(232, 97, 0, 0.15)"
                : alertType === "low"
                ? "rgba(226, 155, 0, 0.15)"
                : null,
            borderRadius: "4px",
            padding: "4px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",


          }}
        >
          <Typography
            sx={{
              color: alertType === "critical"
              ? "#790000"
              : alertType === "high"
              ? "#D31818"
              : alertType === "medium"
              ? "#E86100"
              : alertType === "low"
              ? "#382600"
              : null,
              fontFamily: "mySecondFont",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Current {alertType} alerts: {alertCountsForTable}
          </Typography>
        </Box>
        <Box><TableFilters/></Box>
      </Box>
      {/* Table */}
      <Box sx={{ flex: 1, mt: "12px", overflowY: "auto",  }}>
        <AlertsCustomTable
          columns={columns}
          rows={rows}
          // logsCount={logsCount}
          // liveLogs={liveLogs ? liveLogs : []}
          isRowClickable={true}
          onRowClick={handleRowClick}
          selectedRow={selectedRow}
          specialColumns={["message"]}
          alertType={alertType}
          // page={page}
          // limit={limit}
          // setPage={setPage}
          // setLimit={setLimit}
          // onPageChange={(newPage) => setPage(newPage)}
          // onRowsPerPageChange={(newLimit) => {
          //   setLimit(newLimit);
          //   setPage(0); // Reset to first page on rows per page change
          // }}
          modalHeading={modalHeading}
          // loading={loading}
          alertCountsForTable={alertCountsForTable}
        />
      </Box>
    </Box>
  );
};

export default AlertsTableBox;
