import { Box, Button } from "@mui/material";
import React from "react";
import CustomTable from "../../../custom/CustomTable";
import NewSearchFilterBox from "../../../Filters/NewSearchFilterBox";

const CashTableBox = ({ column, row }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NewSearchFilterBox />
        <Button
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            background: "#333",
            padding: "12px",
            borderRadius: "4px",
            height: "40px",
            fontFamily: "mySecondFont",
            fontSize: "14px",
            textTransform: "uppercase",
            cursor: "pointer",
            color: "#fff",
            ":hover": {
              background: "#333",
            },
          }}
        >
          Export<span className="material-symbols-outlined">file_export</span>
        </Button>
      </Box>

      {/* Custom Table */}
      <CustomTable rows={row} columns={column} modalHeading={"Cash Payments"} />
    </Box>
  );
};

export default CashTableBox;
