import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../custom/CustomTable";
import TableFilters from "../charging-sessions/TableFilters";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";
import { defaultRoles } from "../../utils/Roles";
import { useSelector } from "react-redux";
import TempCustomTable from "../custom/TempCustomTable";
import TempTableFilters from "../charging-sessions/TempTableFilters";

const TableBox = ({
  columns,
  rows,
  isRowClickable,
  loading,
  tabTitle,
  totalCounts,
  page,
  setPage,
  limit,
  search,
  setSearch,
  filters,
  setFilters,
}) => {
  const navigate = useNavigate();
  const [filteredChargingPoints, setFilteredChargingPoints] = useState(rows);
  const [sortColumn, setSortColumn] = useState(null);

  const user = useSelector((state) => state?.profile?.profile);

  const handleAddNewChargingPoints = () => {
    navigate(MyRoutes.addNewChargingPoint);
  };

  useEffect(() => {
    setFilteredChargingPoints(rows);
  }, [rows]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredChargingPoints(filteredData);
  };

  const myFilters = [
    { value: "name", title: "Name" },
    { value: "station", title: "Station" },
    { value: "status", title: "Status" },
    { value: "device_id", title: "DEVICE ID" },
    { value: "chargingPointType", title: "Charger Type" },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {filters ? (
          <TempTableFilters
            data={rows}
            filters={filters}
            setFilters={setFilters}
            tabTitle={tabTitle}
          />
        ) : (
          ""
        )}
        {defaultRoles?.includes(user?.role) ? (
          <Button
            onClick={handleAddNewChargingPoints}
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "160px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#222",
              },
            }}
          >
            + ADD NEW
          </Button>
        ) : (
          ""
        )}
      </Box>
      {filters ? (
        <TempCustomTable
          columns={columns}
          rows={filteredChargingPoints}
          totalCounts={totalCounts}
          page={page}
          setPage={setPage}
          limit={limit}
          isRowClickable={isRowClickable}
          loading={loading}
          clickableColumns={["name", "station"]}
          actionType={
            // defaultRoles?.includes(user?.role) ? "copy-edit-delete" : "copy"
            defaultRoles?.includes(user?.role) ? "copy-edit" : "copy"
          }
          rowClickPath="/charging_points"
          columnClickPaths={{
            name: "/charging_points",
            station: "/charging_stations",
          }}
          modalHeading={"Charging Points"}
          columnKeyMappings={{
            name: "_id",
            station: "station",
          }}
          statsHeading={"true"}
          sortColumn={sortColumn}
          filters={filters}
          setFilters={setFilters}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default TableBox;
