import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/alerts_and_faults/TopBar";
import useScrollToTop from "../../Hooks/useScrollToTop";

const AlertsAndFaults = () => {
    const navigate = useNavigate();
    useScrollToTop(navigate);

  return (
    <Box>
      {/* Top - Bar */}
      <TopBar />
    </Box>
  );
};

export default AlertsAndFaults;
