import { Box } from "@mui/material";
import SettlementDetailsBox from "../../../components/FinanceManager/Settlements/SettlementDetailsComponent/SettlementDetailsBox";

const SettlementsDetailsPage = () => {
  return (
    <Box>
      <SettlementDetailsBox />
    </Box>
  );
};

export default SettlementsDetailsPage;
