import React from "react";
import DoubleBarGraph from "../../Graphs/DoubleBarGraph";
import { Box, Divider, Grid } from "@mui/material";
import Heading from "../../../utils/Heading";
import DoubleStackedBarGraph from "../../Graphs/DoubleStackedBarGraph";
import TrippleBarGraph from "../../Graphs/TrippleBarGraph";
import ShadedDoubleLineGraph from "../../Graphs/ShadedDoubleLineGraph";

const OcpiGraphBoxOne = ({
  deviceTimeEnergyStatsOneNetwork,
  get1cTimeEnergyStatsOneNetwork,
  startTime,
  endTime,
  selectedTime,
  networkDetail
}) => {

  console.log("ghfhdkdk", get1cTimeEnergyStatsOneNetwork)
  return (
    <Box
      sx={{
        background: "#fff",
        padding: "24px",
        borderRadius: "8px",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box>
            <Heading text={"1C devices time consumed VS energy"} />
            <Divider
              sx={{ background: "#ddd", margin: "8px 0px", mb: "16px" }}
            />
            <ShadedDoubleLineGraph
              barGraphType="chargingOne"
              data={get1cTimeEnergyStatsOneNetwork}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box>
            <Heading text={`${networkDetail?.name}'s devices time consumed VS energy`} />
            <Divider
              sx={{ background: "#ddd", margin: "8px 0px", mb: "16px" }}
            />
            <ShadedDoubleLineGraph
              barGraphType="network"
              data={deviceTimeEnergyStatsOneNetwork}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcpiGraphBoxOne;
