import React, { useEffect } from 'react'
import AlertsBox from './AlertsBox';
import { alertsMediumColumnData, alertsMediumRowData } from '../../utils/DummyData';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchAlertsOfSeverity } from '../../redux/action/alertsSlice/alertsSlice';

const MediumAlertsBox = ({alertCounts}) => {

  const dispatch = useDispatch();
  const { mediumAlerts, status, error, loading } = useSelector(
    (state) => state.alerts
  );

  console.log("khkhkhkhkh",mediumAlerts )

  useEffect(() => {
    if (!mediumAlerts) {
      dispatch(fetchAlertsOfSeverity("MEDIUM"));
    }
  }, [dispatch, mediumAlerts]); // Only re-run when criticalAlerts or status change

  console.log("aszzzz", alertCounts);
  const getAlertCount = (type) => {
    if (!alertCounts?.data || !Array.isArray(alertCounts.data)) {
      return 0;
    }
    const alert = alertCounts.data.find((item) => item._id === type);
    return alert ? alert.count : 0;
  };

  if (loading && !mediumAlerts) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;


    return (
        <Box sx={{}}>
          <AlertsBox
            column={alertsMediumColumnData}
            data={mediumAlerts || []}
            alertType="medium"
            alertCountsForTable={getAlertCount("MEDIUM")}
            modalHeading={"Medium Alerts"}
          />
        </Box>
      );
}

export default MediumAlertsBox
