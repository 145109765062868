import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Modal,
  Backdrop,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import Heading from "../../../utils/Heading"; // Assuming Heading is a component you have
import CustomTransition from "../../custom/CustomTransition";
import StatusChip from "../../Chips/StatusChip";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAlertCounts, fetchAlertsOfSeverity, resolveAlertPost } from "../../../redux/action/alertsSlice/alertsSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const InfoBox = ({ label, value, icon,  }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Active" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const ResolveAlertModal = ({ open, onClose, row, onAlertResolved }) => {
  console.log("aaaaaaaassss", row);
  useEffect(() => {
    console.log("Log Data in Modal:", row);
  }, [row]);

  const [comment, setComment] = useState("");  // Store the comment input
  const dispatch = useDispatch();
  const { resolveAlertStatus } = useSelector((state) => state.alerts); // Get resolve alert status from redux

  useEffect(() => {
    console.log("Log Data in Modal:", row);
  }, [row]);

  // Handle comment change
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  console.log("hhh", row?.severity)

  // Handle resolve alert click
  const handleResolveAlert = () => {
    if (comment.trim()) {
      // Dispatch the resolveAlert action with alertId and comment
      dispatch(resolveAlertPost({ alertId: row._id, comment }));
  
      setComment(''); 
      dispatch(fetchAlertsOfSeverity(row?.severity));
      dispatch(fetchAlertCounts());
      onClose(); 
 
    } else {
      alert("Please add a comment to resolve the alert.");
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      TransitionComponent={CustomTransition}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/reviews.png" alt="" />
            <Heading text={"Alert resolution"} />
          </Box>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>
        <Divider sx={{ mt: "8px", mb: "48px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <InfoBox
                label={"Severity"}
                value={<StatusChip text={row?.severity} />}
              />
            </Grid>
            <Grid item md={4}>
              <InfoBox label={"Alert ID"} value={row?._id} />
            </Grid>
            <Grid item md={4}>
              <InfoBox label={"Alert code"} value={row?.alertCode} />
            </Grid>
          </Grid>

          <Box>
            <InfoBox label={"Alert"} value={row?.alertName} />
          </Box>

          {/* TextField with multiline for textarea */}
          <Box>
            <Typography
              fontFamily={"myThirdFont"}
              fontSize={"14px"}
              color={"#2F2F2F"}
              textAlign={"start"}
              mb={"8px"}
            >
              Please add resolution comments*
            </Typography>

            <TextField
              placeholder="Enter the full comment here"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={comment}
              onChange={handleCommentChange}  // Handle input change
            />
          </Box>

          <Button
            variant="contained"
            color="error"
            sx={{ marginTop: 2, width: "177px" }}
            onClick={handleResolveAlert}  // Handle button click
            disabled={resolveAlertStatus === "loading"}  // Disable button while loading
          >
            {resolveAlertStatus === "loading" ? "Resolving..." : "RESOLVE ALERT"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResolveAlertModal;
