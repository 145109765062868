import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import StatusChip from "../../Chips/StatusChip";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

const RequiredActionBox = () => {

  const currencySymbol = GetCurrencySymbol(); 
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span className="material-symbols-outlined">lightning_stand</span>
            <Heading text={"Action"} />
          </Box>
          <Box>
            <StatusChip text={"Live"} chipStatus={"Live"} />
          </Box>
        </Box>
        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          mt: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              justifyContent: "center",
              alignItems: "flex-start",

              width: "100%",
            }}
          >
            <Typography
              color={"#666"}
              fontFamily={"mySecondFont"}
              fontSize={"16px"}
              lineHeight={"24px"}
              sx={{}}
            >
              Pending Refunds
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"myFirstFont"}
              fontSize={"20px"}
              lineHeight={"28px"}
            >
              {currencySymbol} 6969
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              color={"#666"}
              fontFamily={"mySecondFont"}
              fontSize={"16px"}
              lineHeight={"24px"}
              sx={{}}
            >
              Pending Settlements
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"myFirstFont"}
              fontSize={"20px"}
              lineHeight={"28px"}
            >
             {currencySymbol} 632
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RequiredActionBox;
