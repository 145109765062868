import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Heading from "../../../utils/Heading";
import StatusChip from "../../Chips/StatusChip";
import StartChargingModal from "../../Modals/StartChargingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAvailabilityConfiguration,
  fetchChargingPointConnectors,
  fetchConnectorQr,
  updateConnector,
} from "../../../redux/action/chargingPointSlice/chargingPointSlice";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { toPng } from "html-to-image";
import { defaultRoles } from "../../../utils/Roles";

const label = { inputProps: { "aria-label": "Switch demo" } };

// Component for displaying connector details
const DetailBox = ({ label, value, isChip = false }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      alignItems: "start",
    }}
  >
    <Typography color="#2F2F2F" fontSize="14px" fontFamily="myThirdFont">
      {label}
    </Typography>
    {isChip ? (
      <StatusChip connectorStatus={true} text={value} />
    ) : (
      <Typography color="#2F2F2F" fontSize="16px" fontFamily="mySecondFont">
        {value}
      </Typography>
    )}
  </Box>
);

const Connectors = ({ item, loading, chargingPointId }) => {
  const user = useSelector((state) => state?.profile?.profile);

  const [isOperative, setIsOperative] = useState(
    item?.availability === "Operative" || false
  );
  const elementRef = useRef(null);
  const [isCharging, setIsCharging] = useState(
    item?.status === "Charging" || item?.status === "charging"
  );
  const [qrLoading, setQrLoading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [openStartCharging, setOpenStartCharging] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsCharging(item?.status === "Charging" || item?.status === "charging");
  }, [item?.status]);

  const handleSwitchChange = async (event) => {
    const newStatus = event.target.checked ? "Operative" : "Inoperative";

    const payload = {
      deviceId: item?._id,
      availability: newStatus,
      // status: "Offline",
    };

    // Call the updateConnector thunk with the connectorId
    // dispatch(updateConnector(payload));
    // setIsOperative(event.target.checked);
    // dispatch(fetchChargingPointConnectors(chargingPointId));

    const ocppPayload = {
      deviceId: item?.deviceId,
      connectorId: item?.connectorId?.toString(),
      status: newStatus,
    };

    try {
      // Call the updateConnector thunk and wait for its completion
      dispatch(changeAvailabilityConfiguration(ocppPayload)).then((data) => {
        if (data?.payload?.success) {
          dispatch(updateConnector(payload));

          // Once the update is successful, dispatch fetchChargingPointConnectors
          setIsOperative(event.target.checked);
          dispatch(fetchChargingPointConnectors(chargingPointId));
        } else {
          toast.error("Error while updating connector");
        }
      });
    } catch (error) {
      console.error("Error updating connector:", error);
    }
  };

  const handleOpenStartCharging = (e) => {
    setOpenStartCharging(true);
  };

  const handleCloseStartCharging = (e) => {
    setOpenStartCharging(false);
  };

  let deviceId = item?.deviceId;
  let connectorId = item?.connectorId.toString();

  const { connectorQrCode } = useSelector((state) => state.chargingPoints);

  // useEffect(() => {
  //   if (connectorQrCode) {
  //     setQrCodeData(connectorQrCode);
  //   }
  // }, [connectorQrCode]);

  const GetQrCodeAndDownload = async () => {
    if (deviceId && connectorId) {
      const qrImgsData = await dispatch(
        fetchConnectorQr({ deviceId, connectorId })
      ).unwrap();
      let finalData = {
        qrCodeImg: qrImgsData,
        deviceId: connectorId + deviceId,
      };
      // htmlToImageConvert(finalData);
      setQrCodeData(finalData);
      // if (qrImgsData) {
      //   downloadQRCode(qrImgsData, `qrcode.png`);
      // } else {
      //   console.error("Failed to fetch QR code:");
      //   toast.error("Failed to fetch QR codes.");
      // }
    } else {
      toast.error("Device ID or Connector ID is missing.");
    }
  };

  const downloadQRCode = (base64String, fileName = "qrcode.png") => {
    if (base64String && base64String.startsWith("data:image/png;base64,")) {
      const link = document.createElement("a");
      link.href = base64String;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("Invalid QR code data or format.");
    }
  };

  const downloadZipWithQRCodes = (qrCodes) => {
    const zip = new JSZip();

    qrCodes.forEach((qrCode, index) => {
      zip.file(`qrcode${index + 1}.png`, qrCode.split(",")[1], {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "qrcodes.zip");
    });
  };

  const handleChargingButtonClick = async () => {
    if (isCharging) {
      handleOpenStartCharging();
    } else {
      handleOpenStartCharging();
    }
  };

  useEffect(() => {
    if (elementRef.current) {
      setTimeout(() => {
        htmlToImageConvert(qrCodeData);
      }, 2000);
    }
  }, [qrCodeData]);

  // const htmlToImageConvert = async (qrCodes) => {
  //   const zip = new JSZip();

  //   for (const qrCode of qrCodes) {
  //     const element = elementRef.current;
  //     element.style.display = "block"; // Temporarily make the element visible

  //     try {
  //       const dataUrl = await toPng(element, { cacheBust: false });
  //       element.style.display = "none"; // Hide the element again

  //       // Add the image to the ZIP file
  //       const imgData = dataUrl.split(",")[1]; // Remove data:image/png;base64,
  //       zip.file(`${qrCode?.deviceId}.png`, imgData, { base64: true });
  //     } catch (err) {
  //       element.style.display = "none"; // Hide the element if there's an error
  //       console.log(err);
  //     }
  //   }

  //   // Generate the ZIP file and save it
  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "qrCodes.zip");
  //   });
  // };

  // const htmlToImageConvert = async (qrCodeData) => {
  //   const element = elementRef.current;

  //   if (element) {
  //     console.log(element);
  //     element.style.display = "block"; // Temporarily make the element visible

  //     console.log("ookkk", qrCodeData);
  //     toPng(element, { cacheBust: false })
  //       .then((dataUrl) => {
  //         element.style.display = "none"; // Hide the element again
  //         const link = document.createElement("a");
  //         console.log("ookkk", link);
  //         link.download = `${qrCodeData?.deviceId}.png`;
  //         link.href = dataUrl;
  //         link.click();
  //       })
  //       .catch((err) => {
  //         element.style.display = "none"; // Hide the element if there's an error
  //         console.log(err);
  //       });
  //   }
  // };

  const htmlToImageConvert = async (qrCodeData) => {
    const zip = new JSZip();

    const qrCodes = [qrCodeData];

    for (const qrCode of qrCodes) {
      // Update the content of the elementRef with the current QR code data
      if (elementRef.current) {
        // elementRef.current.querySelector(".qrCodeImg1").src =
        //   "/images/qrCode.png";
        // elementRef.current.querySelector(".qrCodeImg2").src = qrCode?.qrCodeImg;
        // elementRef.current.querySelector(".deviceName").textContent =
        //   qrCode?.deviceId;

        elementRef.current.querySelector(".qrCodeImg").src = qrCode?.qrCodeImg;
        elementRef.current.querySelector(".qrCodeTitle").textContent =
          qrCode?.deviceId;

        // Generate the image
        elementRef.current.style.display = "block"; // Temporarily make the element visible
        try {
          const dataUrl = await toPng(elementRef.current, { cacheBust: false });
          elementRef.current.style.display = "none"; // Hide the element again

          // Add the image to the ZIP file with the correct name
          const imgData = dataUrl.split(",")[1]; // Remove data:image/png;base64,
          const fileName = `${qrCode?.deviceId}.png`; // Correct file name based on deviceId
          zip.file(fileName, imgData, { base64: true });
        } catch (err) {
          elementRef.current.style.display = "none"; // Hide the element if there's an error
          console.log(err);
        }
      }
    }
    setQrLoading(false);

    // Generate the ZIP file and save it
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${item?.deviceId || "qrCodes"}.zip`);
    });
  };

  return (
    <>
      <Box
        sx={{ padding: "16px 24px", borderRadius: "8px", background: "#fff" }}
      >
        {loading ? (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton variant="text" width="200px" height="32px" />
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Skeleton variant="rectangular" width="142px" height="34px" />
                <Box
                  sx={{
                    padding: "4px 8px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    border: "1px solid #c0c0c0",
                    borderRadius: "4px",
                    height: "34px",
                  }}
                >
                  <Skeleton variant="text" width="50px" height="20px" />
                  <Skeleton variant="rectangular" width="40px" height="20px" />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {["qr_code_scanner", "edit"].map((icon, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width="34px"
                      height="34px"
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ margin: "16px 0px" }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: "48px" }}>
              {Array(7)
                .fill(0)
                .map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      alignItems: "start",
                    }}
                  >
                    <Skeleton variant="text" width="100px" height="20px" />
                    <Skeleton variant="text" width="150px" height="20px" />
                  </Box>
                ))}
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Heading text={`Connector ${item?.connectorId}`} />
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                {defaultRoles?.includes(user?.role) ? (
                  <>
                    <Button
                      onClick={handleChargingButtonClick}
                      sx={{
                        padding: "4px 12px",
                        border: "1px solid #292D33",
                        borderRadius: "4px",
                        maxWidth: "142px",
                        width: "100%",
                        textWrap: "nowrap",
                        fontSize: "14px",
                        color: "#292D33",
                        fontFamily: "mySecondFont",
                        height: "34px",
                        textTransform: "unset",
                        display: "flex",
                        alignItems: "center",
                      }}
                      disabled={item?.status === "Processing"}
                    >
                      {isCharging ? "Stop Charging" : "Start Charging"}
                      <span
                        style={{
                          paddingLeft: "4px",
                          color: isCharging ? "#e50000" : "#292D33",
                        }}
                        className="material-symbols-outlined"
                      >
                        {isCharging ? "stop_circle" : "not_started"}
                      </span>
                    </Button>
                    <Box
                      sx={{
                        padding: "4px 8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        border: "1px solid #c0c0c0",
                        borderRadius: "4px",
                        height: "34px",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#2F2F2F"
                        fontFamily="myThirdFont"
                      >
                        {"Operative"}
                      </Typography>
                      <Switch
                        sx={{
                          "& .MuiSwitch-track": {
                            backgroundColor: isOperative
                              ? "#027900"
                              : "#9e9e9e",
                            opacity: 1, // Ensure color is applied correctly
                          },
                          "& .Mui-checked": {
                            color: "#fff !important",
                          },
                          "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track":
                            {
                              backgroundColor: "#9e9e9e", // Gray color when disabled
                            },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#027900", // Force checked background color
                            },
                        }}
                        {...label}
                        checked={isOperative}
                        onChange={handleSwitchChange}
                      />
                    </Box>
                  </>
                ) : (
                  ""
                )}

                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {["qr_code_scanner"].map((icon, index) => (
                    <Box
                      onClick={GetQrCodeAndDownload}
                      key={index}
                      sx={{
                        padding: "4px",
                        border: "1px solid #c0c0c0",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: "34px",
                        width: "34px",
                      }}
                    >
                      <span className="material-symbols-outlined">{icon}</span>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ margin: "16px 0px" }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: "48px" }}>
              {[
                { label: "Connector ID", value: item?.connectorId },
                { label: "Connector type", value: item?.connectorType },

                {
                  label: "Availability",
                  value:
                    item?.availability === "Operative"
                      ? "Operative"
                      : "Inoperative",
                  isChip: true,
                },
                {
                  label: "Status",
                  value:
                    item?.status === "Available" ? "Available" : item?.status,
                  isChip: true,
                },
                { label: "Power Supply (kWh)", value: `${item?.power}` },

                { label: "Error", value: "-" },
                { label: "Vendor Error", value: "-" },
              ].map((detail, index) => (
                <DetailBox
                  key={index}
                  label={detail.label}
                  value={detail.value}
                  isChip={detail.isChip}
                />
              ))}
            </Box>
          </>
        )}
      </Box>
      <StartChargingModal
        open={openStartCharging}
        handleClose={handleCloseStartCharging}
        data={item}
        onChargingStarted={() => setIsCharging(true)}
        onChargingStoped={() => setIsCharging(false)}
        isCharging={isCharging}
      />
      {qrCodeData ? (
        <>
          <Box sx={{ width: "740px", display: "none" }} ref={elementRef}>
            <Box
              sx={{
                width: "100%",
                minWidth: "740px",
                backgroundColor: "#E50000",
                padding: "80px",
              }}
              download="w3logo"
            >
              <Box
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  padding: "48px 76px",
                }}
              >
                <img src="/images/qrCodeImgs/logo.svg" alt="Img not found" />
                <hr />
                <Typography
                  sx={{
                    color: "#1C1C1C",
                    textAlign: "center",
                    fontFamily: "myFirstFont",
                    fontSize: "32px",
                    fontStyle: "normal",
                    paddingTop: "32px",
                    paddingBottom: "16px",
                    lineHeight: "40px",
                  }}
                >
                  Scan and Pay using any app
                </Typography>
                {/* <Box
                    sx={{
                      padding: "14px 23px",
                      width: "100%",
                      height: "274px",
                      backgroundColor: "#D9D9D9",
                    }}
                  ></Box> */}

                <Box
                  sx={{
                    width: "100%",
                    // height: "274px",
                    // backgroundColor: "#D9D9D9",
                  }}
                >
                  <img
                    className="qrCodeImg"
                    src={qrCodeData?.qrCodeImg}
                    width="100%"
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#1C1C1C",
                      textAlign: "center",
                      fontFamily: "myFirstFont",
                      fontSize: "36px",
                      fontStyle: "normal",
                      lineHeight: "48px",
                    }}
                    className="qrCodeTitle"
                  >
                    {qrCodeData?.deviceId}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#1C1C1C",
                      textAlign: "center",
                      fontFamily: "myThirdFont",
                      fontSize: "32px",
                      fontStyle: "normal",
                      lineHeight: "40px",
                    }}
                  >
                    Scan and Pay using any app
                  </Typography>
                </Box>
                <hr />
                <Box sx={{ width: "100%", paddingTop: "24px" }}>
                  <img
                    src="/images/qrCodeImgs/paymentLogos.svg"
                    width="100%"
                    alt=""
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default Connectors;
