import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SpecificationBox from "./Details/SpecificationBox";
import LocationBox from "./Details/LocationBox";
import OtherDetails from "./Details/OtherDetails";
import Ameneties from "./Details/Ameneties";
import GalleryBox from "./Details/GalleryBox";

const DetailsBox = ({ data }) => {

  console.log("dnhff, data" , data)
  if (!data) {
    return <Typography>No data available</Typography>;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - Box - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <SpecificationBox data={data} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <LocationBox data={data} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <OtherDetails data={data} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Ameneties data={data} />
          </Box>
        </Grid>
      </Grid>

      {/* Gallery - Box */}
      <Box
        sx={{
          padding: "16px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <GalleryBox data={data} />
      </Box>
    </Box>
  );
};

export default DetailsBox;
