import React from 'react'
import TopBar from '../../../components/FinanceManager/PaymentAlerts/TopBar'

const PaymentAlerts = () => {
  return (
    <div>
<TopBar/>
    </div>
  )
}

export default PaymentAlerts
