import React, { useEffect } from "react";
import { Box } from "@mui/material";
import CustomTable from "../custom/CustomTable";
import { fetchAlertsHistory } from "../../redux/action/alertsSlice/alertsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableFilters from "../charging-sessions/TableFilters";
import { getAlertsHistoryColumnData } from "../../utils/DummyData";

const calculateDaysAndHoursDifference = (timestamp1, timestamp2) => {
  // Parse the timestamps into Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  const diffInMillis = Math.abs(date1 - date2);

  const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));

  const diffInHours = Math.floor(
    (diffInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  // Return the difference as a formatted string like "6d 4h"
  return `${diffInDays}d ${diffInHours}h`;
};



const AlertsHistory = ({columns, row}) => {
  // console.log("fffgg", columns);

  const dispatch = useDispatch()
  // Access state from the Redux store
  const { alertsHistory, status, error, loading } = useSelector(
    (state) => state.alerts
  );

    useEffect(() => {
    dispatch(fetchAlertsHistory());
  }, [dispatch]);

  const nestedAlertsHistory = Array.isArray(alertsHistory)
    ? alertsHistory.map((form) => {
        const { alertsHistory, ...rest } = form;

        // Assuming calculateDaysAndHoursDifference is a function you've defined
        const resolvedDatCalculated = calculateDaysAndHoursDifference(
          form?.resolvedDate,
          form?.created_at
        );

        console.log("tt66", resolvedDatCalculated);

        return {
          ...rest,
          resolveTime: resolvedDatCalculated,
          resolvedBy: form?.resolvedByUser?.name,
        };
      })
    : [];
  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff", display:"flex", flexDirection:"column", gap:"24px" }}>
         <Box><TableFilters/></Box>
      {/* Table */}
      <CustomTable
        // columns={columns}
        // rows={row}

        columns={getAlertsHistoryColumnData()}
        rows={nestedAlertsHistory}
        isRowClickable={true}
        modalHeading={"Alerts History"}
        // actionType={"edit-delete"}
        // rowClickPath={"/charging_stations"}
        // clickableColumns={["stationName"]}
        // columnClickPaths={{
        //   stationName: "/charging_stations",
        // }}
        // columnKeyMappings={{
        //   stationName: "_id",
        // }}
        // statsHeading={"true"}
        // loading={loading}
        // sortColumn={sortColumn}
      />
    </Box>
  );
};

export default AlertsHistory;
