import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Divider,
  Modal,
  Backdrop,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import Heading from "../../../utils/Heading"; // Assuming Heading is a component you have
import CustomTransition from "../../custom/CustomTransition";
import StatusChip from "../../Chips/StatusChip";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../../routes/routes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const InfoBox = ({ label, value, icon, onInfoClick }) => {
  // Function to handle the click navigation
  const handleBoxClick = () => {
    if (onInfoClick) {
      onInfoClick();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: icon ? "8px" : "",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={value === "Active" ? "#027900" : "#2F2F2F"}
          textAlign={"start"}
          onClick={handleBoxClick} // Pass the click handler
          sx={{
            textDecoration:
              label === "Session ID" && value !== "N/A" ? "underline" : "none",
            pointer: label === "Session ID" ? "cursor" : "none",
          }}
        >
          {value}
        </Typography>

        {icon && (
          <span
            style={{
              fontSize: "24px",
              color: "#292D33",
              fontWeight: "200",
              cursor: "pointer",
            }}
            className="material-symbols-outlined"
          >
            {icon}
          </span>
        )}
      </Box>
    </Box>
  );
};

// Utility function to format date and time
const formatDateTime = (isoDateString) => {
  const date = new Date(isoDateString);

  // Format date as "dd-mm-yyyy"
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Format time as "hh:mm:ss AM/PM"
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
};

const AlertHistoryViewModal = ({ open, onClose, row }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  const modalRef = useRef(null); // Create ref to capture modal content

  useEffect(() => {
    console.log("Log Data in Modal:", row);
  }, [row]);

  const formatDateTime = (isoDateString) => {
    const date = new Date(isoDateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const commentsToShow = row?.commentedBy?.slice(0, 3) || [];

  const download = (image) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName("jpg", "alert-screenshot");
    a.click();
  };

  const handleDownload = () => {
    takeScreenshot(modalRef.current).then(download);
  };

  console.log("ffff44ff", row);

  // Function to handle navigation on click
  const handleBoxClick = (id) => {
    navigate(MyRoutes.chargingSessions + `/${id}`);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      TransitionComponent={CustomTransition}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style} ref={modalRef}>
        {" "}
        {/* Set ref to modal content */}
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/reviews.png" alt="Review Icon" />
            <Heading text={"Alert resolution"} />
          </Box>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>
        <Divider sx={{ mt: "8px", mb: "24px" }} />
        {/* Grid for Basic Information */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "28px",
            mt: "32px",
          }}
        >
          <InfoBox
            label="Severity"
            value={<StatusChip text={row?.severity} />}
          />
          <InfoBox label="Alert ID" value={row?._id} />
          <InfoBox
            label="Resolved by"
            value={
              row?.resolvedByUser?.name
                ? row?.resolvedByUser?.name
                : "Automatic"
            }
          />
          <InfoBox
            label="Resolved on"
            value={formatDateTime(row?.resolvedDate)}
          />
          <InfoBox label="Alert date" value={formatDateTime(row?.created_at)} />
        </Box>
        {/* Address Row */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            mt: "24px",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <InfoBox label="Device ID" value={row?.deviceId} />
            </Grid>
            <Grid item md={4} xs={12}>
              <InfoBox label="Station" value={row?.station?.name} />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <InfoBox label="Alert code" value={row?.alertCode} />
            </Grid>
            <Grid item md={4} xs={12}>
              <InfoBox label="Alert" value={row?.alertName} />
            </Grid>
            <Grid item md={4} xs={12}>
              <InfoBox
                label="Session ID"
                value={row?.sessionId ? row?.sessionId : "N/A"}
                // Only attach onInfoClick if sessionId is valid (not "N/A")
                onInfoClick={
                  row?.sessionId && row?.sessionId !== "N/A"
                    ? () => handleBoxClick(row?.sessionId)
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Box>
        {/* Assigned and Last Updated Information */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            mt: "12px",
          }}
        >
          <InfoBox label="Alert Description" value={row?.description} />
          <Typography
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
            color={"#2F2F2F"}
            textAlign={"start"}
            mb={"8px"}
          >
            Last Resolution Comments
          </Typography>

          {commentsToShow.length > 0 ? (
            commentsToShow.map((comment, index) => (
              <Box key={index} sx={{ marginBottom: 1 }}>
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"16px"}
                  color={"#2F2F2F"}
                  textAlign={"start"}
                  mb={"8px"}
                  lineHeight={"20px"}
                >
                  {comment.comment}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography>No comments available.</Typography>
          )}
        </Box>
        {/* Download Button */}
        <Box sx={{ mt: "24px" }}>
          <Button onClick={handleDownload} sx={{ marginTop: 0 }}>
            <span class="material-symbols-outlined">download</span>
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlertHistoryViewModal;
