import { Box, Typography } from "@mui/material";
import React from "react";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

const AccountDataCard = ({ onlineStatus, title, icon, isBorder }) => {
  const currencySymbol = GetCurrencySymbol();

  return (
    <Box
      sx={{
        background: "#fff",
        padding: "16px",
        borderRadius: "12px",
        border: isBorder ? "1px solid rgb(221, 221, 221)" : "",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box>
            {onlineStatus ? (
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "24px",
                  background: "#ccc",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "8px",
                    background: "#008000",
                  }}
                ></Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  background: "rgba(41, 45, 51, 0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className="material-symbols-outlined">{icon}</span>
              </Box>
            )}
          </Box>

          <Typography
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
            color={"rgb(102, 102, 102)"}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          textAlign={"start"}
          fontFamily={"mySecondFont"}
          color={"rgb(12, 12, 12)"}
        >
          {currencySymbol}5000
        </Typography>
        <Typography textAlign={"start"} fontFamily={"mySecondFont"}>
          Σ 50
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountDataCard;
