import React from 'react'

const Ledger = () => {
  return (
    <div>
      Ledger Page
    </div>
  )
}

export default Ledger
