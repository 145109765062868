import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

const initialState = {
  alertCounts: null,
  highAlerts: null,
  criticalAlerts: null, // Changed from [] to null for proper initialization check
  lowAlerts: null,
  mediumAlerts: null,
  alertsHistory: null,
  resolveAlertStatus: "idle",
  alertsByGroup: null,
  alertsByAvgResolution: null,
  alertsOverallData: null,
  alertStatsByTimePeriod: null,
  status: "idle",
  topAlerts: null,
  alertStatsTotalHeatData:null,
  error: null,
  loading: false,
};

export const fetchAlertCounts = createAsyncThunk(
  "alerts/fetchAlertCounts",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getalertCounts, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchAlertsOfSeverity = createAsyncThunk(
  "alerts/fetchAlertsOfSeverity",
  async (severityLevel, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAlertsOfEachSeverityLevel}/${severityLevel}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return {
        severity: severityLevel,
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchAlertsHistory = createAsyncThunk(
  "alerts/fetchAlertsHistory",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAlertsHistory, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for resolving an alert
export const resolveAlertPost = createAsyncThunk(
  "alerts/resolveAlertPost",
  async ({ alertId, comment }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.resolveAlertPost}/${alertId}`, // Using alertId in URL
        { comment }, // comment is sent in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming the response contains the updated alert data
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchByGroupAlerts = createAsyncThunk(
  "alerts/fetchByGroupAlerts",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getByGroupAlerts, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchAvgResolutionAlerts = createAsyncThunk(
  "alerts/fetchAvgResolutionAlerts",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAverageResolutionAlerts, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchAlertsOverallData = createAsyncThunk(
  "dashboard/fetchAlertsOverallData",
  async (durationType, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAlertsStatsOverall}/${durationType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.result;
    } catch (error) {
      // Improved error handling
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// CreateAsyncThunk for fetching alert stats by time period and category
export const fetchAlertStatsWithTime = createAsyncThunk(
  "alerts/fetchAlertStatsWithTime",
  async ({ durationType, category }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getNewAlertsStatsWithTime}/${durationType}/${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Returning the response data
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);


export const fetchTopAlerts = createAsyncThunk(
  "alerts/fetchTopAlerts",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getTopAlerts, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);


export const fetchAllAlertsDataForHeatMap = createAsyncThunk(
  "alerts/fetchAllAlertsDataForHeatMap",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAlertStatisticsTotalHeatMap, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("resoponseee",response.data )
      return response.data;
      
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);





const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertCounts.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlertCounts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertCounts = action.payload;
        state.loading = false;
      })
      .addCase(fetchAlertCounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })
      .addCase(fetchAlertsOfSeverity.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlertsOfSeverity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;

        // Direct assignment based on severity
        switch (action.payload.severity) {
          case "HIGH":
            state.highAlerts = action.payload.data;
            break;
          case "CRITICAL":
            state.criticalAlerts = action.payload.data;
            break;
          case "LOW":
            state.lowAlerts = action.payload.data;
            break;
          case "MEDIUM":
            state.mediumAlerts = action.payload.data;
            break;
        }
      })
      .addCase(fetchAlertsOfSeverity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })
      .addCase(fetchAlertsHistory.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlertsHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertsHistory = action.payload;
        state.loading = false;
      })
      .addCase(fetchAlertsHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })
      // Handle resolveAlert
      .addCase(resolveAlertPost.pending, (state) => {
        state.resolveAlertStatus = "loading";
        state.loading = true;
        state.resolveAlertError = null;
      })
      .addCase(resolveAlertPost.fulfilled, (state, action) => {
        state.resolveAlertStatus = "succeeded";
        state.loading = false;
        // You can update the state with the resolved alert if needed
        // Example: updating a specific alert in the state
      })
      .addCase(resolveAlertPost.rejected, (state, action) => {
        state.resolveAlertStatus = "failed";
        state.resolveAlertError = action.payload || action.error.message;
        state.loading = false;
      })
      .addCase(fetchByGroupAlerts.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchByGroupAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertsByGroup = action.payload;
        state.loading = false;
      })
      .addCase(fetchByGroupAlerts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })     

      .addCase(fetchAvgResolutionAlerts.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvgResolutionAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertsByAvgResolution = action.payload;
        state.loading = false;
      })
      .addCase(fetchAvgResolutionAlerts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })    
      
      .addCase(fetchAlertsOverallData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAlertsOverallData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertsOverallData = action.payload;
      })
      .addCase(fetchAlertsOverallData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      }).addCase(fetchAlertStatsWithTime.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlertStatsWithTime.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.alertStatsByTimePeriod = action.payload; // Store the fetched data in the state
      })
      .addCase(fetchAlertStatsWithTime.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      }) 
      
      
      .addCase(fetchTopAlerts.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTopAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.topAlerts = action.payload;
        state.loading = false;
      })
      .addCase(fetchTopAlerts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })   
      
      
      .addCase(fetchAllAlertsDataForHeatMap.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAlertsDataForHeatMap.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertStatsTotalHeatData = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllAlertsDataForHeatMap.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.loading = false;
      })  ;
  },
});

export default alertsSlice.reducer;
