import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

// Dummy data (replace with actual data if needed)
const dummyData = {
  wallet: { amount: 12000, count: 150 },
  directPayment: { amount: 4000, count: 80 },
  ocpi: { amount: 5000, count: 120 },
  Super1cAmount: { amount: 8000, count: 200 },
  cash: { amount: 8000, count: 200 },
  totalAmount: 29000, // Not used in the chart
  totalCount: 550, // Not used in the chart
};

const FinanceDoughnutChart = ({ dataSet, centerText, centerValue }) => {
  // Default gray chart data for empty or invalid data
  const defaultChartData = [
    { name: "No Data", value: 100, color: "#D3D3D3" }, // Gray color for no data
  ];

  // Function to map dummy data to pie chart segments (Cash vs Online)
  const mapDataToSegmentDummyData = (data) => {
    const walletAmount = data?.wallet?.amount || 0;
    const directPaymentAmount = data?.directPayment?.amount || 0;
    const ocpiAmount = data?.ocpi?.amount || 0;
    const Super1cAmount = data?.Super1cAmount?.amount || 0;
    const cashAmount = data?.cash?.amount || 0;

    // If the totalAmount is 0, return the default chart data
    if (
      walletAmount +
        directPaymentAmount +
        ocpiAmount +
        Super1cAmount +
        cashAmount ===
      0
    ) {
      return defaultChartData;
    }

    // Return chart segments with amounts (excluding totalAmount and totalCount)
    return [
      {
        name: "Direct Payment",
        value: directPaymentAmount,
        color: "#F06292", // Color for direct payment segment
      },
      {
        name: "Wallet",
        value: walletAmount,
        color: "#E91E63", // Color for wallet segment
      },

      {
        name: "OCPI",
        value: ocpiAmount,
        color: "#CE93D8", // Color for OCPI segment
      },
      {
        name: "1C Super Users",
        value: Super1cAmount,
        color: "#AB47BC", // Color for Super1cAmount segment
      },
      {
        name: "Cash",
        value: cashAmount,
        color: "#6A1B9A", // Color for cash segment
      },
    ];
  };

  // Map the passed data into chart segments based on dataSet
  const chartData =
    dataSet === "cashVsOnline"
      ? mapDataToSegmentDummyData(dummyData)
      : defaultChartData;

  // Filter out chart data if `dataSet` is "totalUsers" to show only Direct Pay and Mobile App
  const filteredChartData =
    dataSet === "totalUsers"
      ? chartData.filter(
          (item) => item.name === "Direct Pay" || item.name === "Mobile App"
        )
      : chartData;

  const isDefaultChartData =
    chartData.length === 1 && chartData[0].name === "No Data";

  return (
    <Box
      sx={{
        width: "100%",
        padding: "12px 16px 24px 12px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ height: 250, position: "relative" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={120}
              innerRadius={95}
              stroke="none"
              startAngle={0}
              endAngle={360}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {/* Center text display */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Box sx={{ fontWeight: 300 }} className="material-symbols-outlined">
            {/* {iconText} */}bolt
          </Box>
          <Typography
            color={"#666"}
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
          >
            {centerText}
          </Typography>
          <Typography
            color={"#2F2F2F"}
            fontFamily={"mySecondFont"}
            fontSize={"24px"}
          >
            {centerValue}
          </Typography>
        </Box>
      </Box>
      {/* Legend Section for Dashboard */}
    </Box>
  );
};

export default FinanceDoughnutChart;
