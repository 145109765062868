import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import AlertsBox from "./AlertsBox";
import {
  alertsSevereColumnData,
  alertsSevereRowData,
} from "../../utils/DummyData";
import { fetchAlertsOfSeverity } from "../../redux/action/alertsSlice/alertsSlice";
import { useDispatch, useSelector } from "react-redux";

const SevereAlertsBox = ({alertCounts}) => {
  const dispatch = useDispatch();
  const { criticalAlerts, status, error, loading } = useSelector(
    (state) => state.alerts
  );

  console.log("khkhkhkhkh", criticalAlerts);

  useEffect(() => {
    if (!criticalAlerts) {
      dispatch(fetchAlertsOfSeverity("CRITICAL"));
    }
  }, [dispatch, criticalAlerts]);

  console.log("aszzzz", alertCounts);
  const getAlertCount = (type) => {
    if (!alertCounts?.data || !Array.isArray(alertCounts.data)) {
      return 0;
    }
    const alert = alertCounts.data.find((item) => item._id === type);
    return alert ? alert.count : 0;
  };

  if (loading && !criticalAlerts) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;

  return (
    <Box

    >
      <AlertsBox
        column={alertsSevereColumnData}
        data={criticalAlerts || []}
        alertType="critical"
        alertCountsForTable={getAlertCount("CRITICAL")}
        modalHeading={"Critical Alerts"}
      />
    </Box>
  );
};

export default SevereAlertsBox;
