import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import NewCustomTabs from "../../custom/NewCustomTabs";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../../routes/routes";
import AllTransaction from "./All/AllTransaction";
import OnlineTableBox from "./Online/OnlineTableBox";
import {
  accountOnlinePaymentColumns,
  accountOnlinePaymentData,
  accountTransactionColumns,
  accountTransactionData,
  cashColumns,
  cashData,
  pgSettlementColumns,
  pgSettlementsData,
  refundsColumns,
  refundsData,
} from "../../../utils/DummyData";
import CashBox from "./Cash/CashBox";
import RefundTableBox from "./Refunds/RefundTableBox";
import PGSettlementBox from "./PG Settlement/PGSettlementBox";

const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = () => {
  const [activeTabContent, setActiveTabContent] = useState(null);
  const navigate = useNavigate();

  const handlePageChange = () => {
    navigate(MyRoutes.accounts_alerts);
  };

  const tabsData = React.useMemo(
    () => [
      {
        label: "All",
        icon: "",
        content: <AllTransaction />,
      },
      {
        label: "Online",
        icon: "",
        content: (
          <OnlineTableBox
            row={accountOnlinePaymentData}
            column={accountOnlinePaymentColumns()}
          />
        ),
      },
      {
        label: "Cash",
        icon: "",
        content: <CashBox row={cashData} column={cashColumns()} />,
      },
      {
        label: "Refunds",
        content: <RefundTableBox row={refundsData} column={refundsColumns()} />,
      },
      {
        label: "PG Settlements",
        icon: "",
        content: (
          <PGSettlementBox
            row={pgSettlementsData}
            column={pgSettlementColumns()}
          />
        ),
      },
    ],
    []
  );
  return (
    <React.Fragment>
      {/* TopBar Box */}
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        {/* Tabs */}
        <NewCustomTabs
          tabs={tabsData}
          renderContentExternally={true} // Ensure content is NOT rendered inside the tabs
          onTabContentChange={(content) => setActiveTabContent(content)} // Capture active tab content
        />
      </Box>

      {/* External Content Rendering */}
      <Box>{activeTabContent || "No Content Available"}</Box>
    </React.Fragment>
  );
};

export default TopBar;
