import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import AlertsTableBox from "./AlertMainBox/AlertsTableBox";
import AlertsDetails from "./AlertMainBox/AlertsDetails";

const AlertsBox = ({
  column,
  data,
  alertType,
  alertCountsForTable,
  modalHeading,
}) => {
  const [selectedAlert, setSelectedAlert] = useState(null);

  console.log("yyyddd", alertType);

  return (
    <Box>
      <Grid container spacing={2} sx={{ flex: 1, overflow: "hidden" }}>
        <Grid item md={8}>
          <Box
            sx={{
              padding: "24px",
              borderRadius: "8px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <AlertsTableBox
              columns={column}
              rows={data}
              onRowClick={setSelectedAlert}
              alertType={alertType}
              alertCountsForTable={alertCountsForTable}
              modalHeading={modalHeading}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",

              background: "#fff",
              borderRadius: "8px",
            }}
          >
            <AlertsDetails logData={selectedAlert} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertsBox;
