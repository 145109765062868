import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";

const AcDcBox = ({ chargingPoints, previousChargingPoints, selectedTime }) => {
  const currencySymbol = GetCurrencySymbol();
  // Helper function to calculate percentage change
  const calculatePercentageChange = (current, previous, fallbackValue = 0) => {
    // Convert values to numbers to avoid issues with type coercion
    const currentValue = Number(current);
    const previousValue = Number(previous);

    // Check if previous data is missing or zero
    if (isNaN(previousValue) || previousValue === 0) {
      if (isNaN(currentValue) || currentValue === 0) {
        return "0.00%"; // Both are zero
      }
      return "N/A"; // Previous data is missing or zero but current data is available
    }

    // Calculate percentage change
    const percentageChange =
      ((currentValue - previousValue) / previousValue) * 100;

    // Return formatted result
    return percentageChange.toFixed(2) + "%";
  };

  // Calculate percentage changes
  const percentageChangeDCFast = calculatePercentageChange(
    chargingPoints?.fastDCData?.energyConsumed || 0,
    previousChargingPoints?.fastDCData?.energyConsumed || 0
  );
  const percentageChangeDCSlow = calculatePercentageChange(
    chargingPoints?.slowDCData?.energyConsumed || 0,
    previousChargingPoints?.slowDCData?.energyConsumed || 0
  );
  const percentageChangeACFast = calculatePercentageChange(
    chargingPoints?.fastACData?.energyConsumed || 0,
    previousChargingPoints?.fastACData?.energyConsumed || 0
  );
  const percentageChangeACSlow = calculatePercentageChange(
    chargingPoints?.slowACData?.energyConsumed || 0,
    previousChargingPoints?.slowACData?.energyConsumed || 0
  );

  // Data for cards including percentage change
  const AcDcCardData = [
    {
      id: 1,
      title: "AC Slow",
      icon: "bolt",
      profit: chargingPoints?.slowACData?.billedAmount,
      energy: chargingPoints?.slowACData?.energyConsumed || 0,
      percentageChange: percentageChangeACSlow,
    },
    {
      id: 2,
      title: "AC Fast",
      icon: (
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            border: "1px solid #666",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "myFirstFont",
          }}
        >
          {currencySymbol}
        </Box>
      ),
      profit: chargingPoints?.fastACData?.billedAmount,
      energy: chargingPoints?.fastACData?.energyConsumed || 0,
      percentageChange: percentageChangeACFast,
    },
    {
      id: 3,
      title: "DC Slow",
      icon: "groups",
      profit: chargingPoints?.slowDCData?.billedAmount,
      energy: chargingPoints?.slowDCData?.energyConsumed || 0,
      percentageChange: percentageChangeDCSlow,
    },

    {
      id: 4,
      title: "DC Fast",
      icon: "pace",
      profit: chargingPoints?.fastDCData?.billedAmount,
      energy: chargingPoints?.fastDCData?.energyConsumed || 0,
      percentageChange: percentageChangeDCFast,
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {AcDcCardData.map((item) => {
          // Determine the color based on the profit value
          const profitColor = item.profit < 0 ? "#790000" : "#027900";
          const profitBackground =
            item.profit < 0 ? "rgba(121, 0, 0, 0.10)" : "rgba(2, 121, 0, 0.10)";

          // Determine the color based on percentage change
          const percentageColor = item.percentageChange.startsWith("-")
            ? "#790000"
            : "#027900";
          const percentageBackground = item.percentageChange.startsWith("-")
            ? "rgba(121, 0, 0, 0.10)"
            : "rgba(2, 121, 0, 0.10)";

          return (
            <Grid item md={6} key={item.id}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Box
                    sx={{
                      background: "rgba(41, 45, 51, 0.08)",
                      width: "24px",
                      height: "24px",
                      borderRadius: "24px",
                      padding: "3px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ color: "#292D33", fontSize: "18px" }}
                      className="material-symbols-outlined"
                    >
                      {item.icon}
                    </span>
                  </Box>
                  <Typography
                    fontSize={"18px"}
                    fontFamily={"mySecondFont"}
                    color={"#1c1c1c"}
                  >
                    {item.title}
                  </Typography>
                </Box>
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <Box
                    sx={{
                      padding: "2px 8px",
                      background: percentageBackground,
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      maxWidth: "90px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontFamily={"mySecondFont"}
                      color={percentageColor}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {item.percentageChange}
                      <span
                        style={{
                          fontSize: "16px",
                          marginLeft: "4px",
                          fontWeight: "600",
                        }}
                        className="material-symbols-outlined"
                      >
                        {item.percentageChange.startsWith("-")
                          ? "trending_down"
                          : "trending_up"}
                      </span>
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{ display: "flex", gap: "4px", flexDirection: "column" }}
                >
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <span
                      style={{ color: "#666666", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      bolt
                    </span>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      {item.energy}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        width: "15px",
                        marginLeft: "5px",
                        height: "15px",
                        borderRadius: "50%",
                        border: "1px solid #666",
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {currencySymbol}
                    </Box>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"myThirdFont"}
                      fontSize={"14px"}
                    >
                      {`${currencySymbol}${item.profit}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AcDcBox;
