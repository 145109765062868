import { Box, Divider, Typography } from "@mui/material";
import React from "react";

// Reusable AlertRowBox component
const AlertRowBox = ({
  bgColor,
  textColorLeft,
  textColorRight,
  label,
  value,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        background: bgColor,
        padding: "12px",
      }}
    >
      {/* Left side text */}
      <Typography
        sx={{
          color: textColorLeft,
          fontFamily: "myThirdFont",
          fontSize: "16px",
          lineHeight: "24px",
          textTransform: "capitalize",
          textAlign:"left"
        }}
      >
        {label}
      </Typography>

      {/* Right side text */}
      <Typography
        sx={{
          color: textColorRight,
          fontFamily: "mySecondFont",
          fontSize: "18px",
          lineHeight: "24px",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export { AlertRowBox };

const AlertsOrderComponent = ({totalType,data}) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", my: "16px" }}>
        <AlertRowBox
          bgColor="rgba(121, 0, 0, 0.15)"
          textColorLeft="#790000"
          textColorRight="#790000"
          label="Critical Alerts"
          value={data?.severityData?.CRITICAL}
        />

        <AlertRowBox
          bgColor="rgba(211, 24, 24, 0.15)"
          textColorLeft="#D31818"
          textColorRight="#D31818"
          label="High Alerts"
          value={data?.severityData?.HIGH}
        />

        <AlertRowBox
          bgColor="rgba(232, 97, 0, 0.15)"
          textColorLeft="#E86100"
          textColorRight="#E86100"
          label="Medium Alerts"
          value={data?.severityData?.MEDIUM}
        />

        <AlertRowBox
          bgColor="rgba(226, 155, 0, 0.15)"
          textColorLeft="#8C6000"
          textColorRight="#8C6000"
          label="Low Alerts"
          value={data?.severityData?.LOW}
        />

        <Divider sx={{ borderStyle: "dashed" }} />
        <AlertRowBox
          bgColor="#FFF"
          textColorLeft="#292D33"
          textColorRight="#292D33"
          label={`Total ${totalType} Alerts`}
          value={data?.totalCount}
        />
        <Divider sx={{ borderStyle: "dashed", background: "#ddd" }} />
      </Box>
    </Box>
  );
};

export default AlertsOrderComponent;
