import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, Button, Slide } from "@mui/material";
import dayjs from "dayjs"; // Ensure you have dayjs installed
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  getAllChargingPasses,
  getAllGuards,
  getAllHubSessions,
  getAllPassHolders,
  getAllStationsHub,
  getAllTransaction,
  getShiftCollection,
} from "../../redux/action/hubManagement/hubManagementSlice";
import { useDispatch } from "react-redux";
import { useStationContext } from "../../Hooks/StationContext";
import { useTimeContext } from "../../Hooks/TimeContext";
import { fetchAllSessions } from "../../redux/action/sessions/sessionsSlice";
import {
  fetchAllStations,
  fetchStationChargingPoint,
  fetchStationSessions,
} from "../../redux/action/stations/stationSlice";
import {
  fetchChargingPoints,
  fetchOneSessionData,
} from "../../redux/action/chargingPointSlice/chargingPointSlice";
import { getAllPayments } from "../../redux/action/payments/paymentSlice";
import {
  getAllOrg,
  getCMChargingStation,
} from "../../redux/action/companyManagement/companyManagementSlice";
import { getTariffs } from "../../redux/action/tariff/createTariffSlice";
import { fetchAllUsers } from "../../redux/action/adminManagement/adminSlice";

const TableTimeFilter = ({
  tabTitle,
  isActive,
  orgId,
  chargingPointId,
  stationId,
  page,
  limit,
}) => {
  const dispatch = useDispatch();
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const maxDate = dayjs();

  // Function to dispatch API calls based on the current tab and time filters
  const fetchData = (startMillis = null, endMillis = null) => {
    const timeFilter = {
      startTime: startMillis,
      endTime: endMillis,
    };

    switch (tabTitle) {
      case "ChargingSessions":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(
            fetchAllSessions({
              page: page,
              limit: limit,
              isActive: isActive,
              ...timeFilter,
            })
          );
        }
        break;

      case "Last7DaysSessions":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(
            fetchAllSessions({
              page: page,
              limit: limit,
              isActive: isActive,
              ...timeFilter,
            })
          );
        }
        break;
      case "ChargingStations":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(fetchAllStations(timeFilter));
        }
        break;
      case "ChargingPoints":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(fetchChargingPoints(timeFilter));
        }

        break;
      case "AllPayments":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(getAllPayments(timeFilter));
        }

        break;
      case "CompanyManagement":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(getAllOrg(timeFilter));
        }

        break;

      case "TariffManagement":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(getTariffs(timeFilter));
        }

        break;

      case "AdminManagement":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(fetchAllUsers(timeFilter));
        }
        break;

      case "CompanyChargingStations":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(getCMChargingStation({ orgId, ...timeFilter }));
        }
        break;

      case "ChargingPointSessions":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(fetchOneSessionData({ chargingPointId, ...timeFilter }));
        }
        break;

      case "ChargingStationSessions":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(
            fetchStationSessions({
              stationId,
              isActive: isActive,
              ...timeFilter,
            })
          );
        }
        break;

      case "Last7DaysChargingStationSessions":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(
            fetchStationSessions({
              stationId: stationId,
              isActive: isActive,
              ...timeFilter,
            })
          );
        }
        break;

      case "StationChargingPoints":
        if (timeFilter?.startTime && timeFilter?.endTime) {
          dispatch(fetchStationChargingPoint({ stationId, ...timeFilter }));
        }
        break;

      default:
        console.log("Unknown tab title.");
    }
  };

  // Function to dispatch API calls based on the current tab and time filters
  const resetData = (startMillis = null, endMillis = null) => {
    const timeFilter = {
      startTime: startMillis,
      endTime: endMillis,
    };

    const now = new Date();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - 6);
    const last7DaysStartTime = new Date(
      startOfWeek.setHours(0, 0, 0, 0)
    ).valueOf();
    const last7DaysEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

    switch (tabTitle) {
      case "ChargingSessions":
        dispatch(
          fetchAllSessions({
            page: page,
            limit: limit,
            isActive: isActive,
            ...timeFilter,
          })
        );
        break;
      case "Last7DaysSessions":
        dispatch(
          fetchAllSessions({
            page: page,
            limit: limit,
            isActive: isActive,
            startTime: last7DaysStartTime,
            endTime: last7DaysEndTime,
          })
        );
        break;
      case "ChargingStations":
        dispatch(fetchAllStations(timeFilter));
        break;
      case "ChargingPoints":
        dispatch(fetchChargingPoints(timeFilter));

        break;
      case "AllPayments":
        dispatch(getAllPayments(timeFilter));

        break;
      case "CompanyManagement":
        dispatch(getAllOrg(timeFilter));

        break;

      case "TariffManagement":
        dispatch(getTariffs(timeFilter));

        break;

      case "AdminManagement":
        dispatch(fetchAllUsers(timeFilter));
        break;

      case "CompanyChargingStations":
        dispatch(getCMChargingStation({ orgId }));
        break;

      case "ChargingPointSessions":
        dispatch(fetchOneSessionData({ chargingPointId, isActive }));
        break;

      case "Last7DaysChargingPointSessions":
        dispatch(fetchOneSessionData({ chargingPointId, isActive }));
        break;

      case "ChargingStationSessions":
        dispatch(fetchStationSessions({ stationId, isActive: isActive }));
        break;

      case "Last7DaysChargingStationSessions":
        dispatch(
          fetchStationSessions({
            stationId: stationId,
            isActive: isActive,
            startTime: last7DaysStartTime,
            endTime: last7DaysEndTime,
          })
        );
        break;

      case "StationChargingPoints":
        dispatch(fetchStationChargingPoint({ stationId, ...timeFilter }));
        break;

      default:
        console.log("Unknown tab title.");
    }
  };

  // Handles the 'APPLY' button click
  const handleApply = () => {
    const startMillis = startTime ? startTime.valueOf() : null;
    const endMillis = endTime ? endTime.valueOf() : null;

    if (startMillis && endMillis) {
      fetchData(startMillis, endMillis); // Make API call with filters
      setFiltersApplied(true); // Mark filters as applied
    } else {
      console.log("Please select both start and end dates.");
    }
  };

  // Handles the 'RESET' button click
  const handleReset = () => {
    setStartTime(null);
    setEndTime(null);
    setFiltersApplied(false);
    resetData(); // Fetch data without any filters
  };

  // Effect to reset the filters when the tab title changes
  React.useEffect(() => {
    if (filtersApplied) {
      // Reset time filters and the applied state
      setStartTime(null);
      setEndTime(null);
      setFiltersApplied(false);
    }

    // If filters have not been applied, fetch data for the tab without filters
    if (!filtersApplied) {
      fetchData(); // Fetch without filters
    }
  }, [tabTitle, dispatch, isActive]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="From"
          value={startTime}
          maxDate={maxDate}
          onChange={(newValue) => {
            console.log("New Start Time:", newValue); // Log new start time
            setStartTime(newValue);
            // If the new start date is after the current end date, reset it
            if (newValue && endTime && newValue.isAfter(endTime)) {
              setEndTime(null);
            }
          }}
          sx={{
            width: "144px",
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiInputAdornment-root": {
              height: "48px",
            },
          }}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="To"
          value={endTime}
          maxDate={maxDate}
          onChange={(newValue) => {
            console.log("New End Time:", newValue); // Log new end time
            setEndTime(newValue);
          }}
          minDate={startTime} // Set minimum date for the end date
          sx={{
            width: "144px",
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiInputAdornment-root": {
              height: "48px",
            },
          }}
        />
      </LocalizationProvider>

      {/* Show Reset Button only when filters are applied */}
      {filtersApplied && (
        <Slide direction="down" in={filtersApplied} mountOnEnter unmountOnExit>
          <Button
            sx={{
              padding: "8px 12px",
              width: "70px",
              borderRadius: "4px",
              fontSize: "12px",
              color: "#790000",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleReset} // Reset filters on click
          >
            <RestartAltIcon />
            RESET
          </Button>
        </Slide>
      )}

      <Button
        sx={{
          background: "#222",
          padding: "8px 24px",
          width: "120px",
          height: "42px",
          borderRadius: "4px",
          color: "#FCFCFC",
          fontFamily: "mySecondFont",
          letterSpacing: "1.12px",
          ":hover": {
            background: "#222",
          },
          "&.Mui-disabled": {
            backgroundColor: "#555",
            color: "#999",
            cursor: "not-allowed",
            opacity: 0.6,
          },
        }}
        onClick={handleApply}
      >
        APPLY
      </Button>
    </Box>
  );
};

export default TableTimeFilter;
