import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import StatusChip from "../../Chips/StatusChip";

const CustomersDoughnutChart = ({
  chartType,
  data,
  dataSet,
  centerValue,
  centerText,
  iconText,
}) => {
  // Default gray chart data for empty or invalid data
  const defaultChartData = [
    { name: "No Data", value: 100, color: "#D3D3D3" }, // Gray color for no data
  ];

  // Function to map data for pie chart segments
  const mapDataToSegmentsCustomersData = (data) => {
    if (!data || !data.appCustomers || !data.directCustomers) {
      return defaultChartData;
    }

    // Existing mobile app and direct pay data
    const appCustomers = data.appCustomers;
    const directCustomers = data.directCustomers;

    // Return the chart segments, including new plan data
    return [
      {
        name: "Direct Pay",
        value: directCustomers.count,
        value2: directCustomers.percentage,
        color: "#FF9F69",
      },
      {
        name: "Mobile App",
        value: appCustomers.count,
        value2: appCustomers.percentage,
        color: "#69A5FF",
      },
    ];
  };

  // Function to map data for pie chart segments
  const mapDataToSegmentsWalletData = (data) => {
    // if (!data || !data?.data?.stats?.plan30 || !data?.data?.stats?.plan180 || !data?.data?.stats?.plan365) {
    //   return defaultChartData;
    // }

    // New plan data (plan30, plan180, plan365)
    const plan30 = data?.stats?.plan30 || 0;
    const plan180 = data?.stats?.plan180 || 0;
    const plan365 = data?.stats?.plan365 || 0;

    // Total of all plans for percentage calculation
    const totalPlans = plan30 + plan180 + plan365;

    if (totalPlans === 0) {
      return defaultChartData;
    }

    // Return the chart segments, including new plan data
    return [
      {
        name: "30 Day Plan",
        value: plan30,
        value2: totalPlans > 0 ? (plan30 / totalPlans) * 100 : 0,
        color: "#EF7559",
      },
      {
        name: "180 Day Plan",
        value: plan180,
        value2: totalPlans > 0 ? (plan180 / totalPlans) * 100 : 0,
        color: "#F3B79F",
      },
      {
        name: "365 Day Plan",
        value: plan365,
        value2: totalPlans > 0 ? (plan365 / totalPlans) * 100 : 0,
        color: "#240D66",
      },
    ];
  };

  // Function to map data for pie chart segments (OCPI Sessions)
  const mapDataToSegmentsOcpiSessions = (data) => {
    const totalOcpiSessionPercentage =
      data?.graphData?.totalOcpiSessionPercentage || 0;
    const totalOcppSessionPercentage =
      data?.graphData?.totalOcppSessionPercentage || 0;

    const totalOcpiSessions = data?.graphData?.totalOcpiSessions || 0;
    const totalOcppSessions = data?.graphData?.totalOcppSessions || 0;

    // Check if data is available for OCPI and OCPP
    if (totalOcpiSessionPercentage === 0 && totalOcppSessionPercentage === 0) {
      return defaultChartData;
    }

    return [
      {
        name: "OCPI Sessions",
        value: totalOcpiSessions,
        value2: totalOcpiSessionPercentage,
        color: "#69A5FF",
      },
      {
        name: "OCPP Sessions",
        value: totalOcppSessions,
        value2: totalOcppSessionPercentage,
        color: "#E41E26",
      },
    ];
  };

  // Map the passed data into chart segmentsconst chartData =
  dataSet === "totalUsers"
    ? mapDataToSegmentsCustomersData(data) // If the dataset is "totalUsers", use this function
    : dataSet === "ocpiSessions" // If the dataset is "ocpiSessions", use this function
    ? mapDataToSegmentsOcpiSessions(data)
    : mapDataToSegmentsWalletData(data); // Default fallback function

  // Determine the chart data based on the dataset
  const chartData =
    dataSet === "totalUsers"
      ? mapDataToSegmentsCustomersData(data)
      : dataSet === "ocpiSessions"
      ? mapDataToSegmentsOcpiSessions(data)
      : mapDataToSegmentsWalletData(data);

  // Filter out chart data if `dataSet` is "totalUsers" to show only Direct Pay and Mobile App
  const filteredChartData =
    dataSet === "totalUsers"
      ? chartData.filter(
          (item) => item.name === "Direct Pay" || item.name === "Mobile App"
        )
      : chartData;

  const isDefaultChartData =
    chartData.length === 1 && chartData[0].name === "No Data";

  return (
    <Box
      sx={{
        width: "100%",
        padding: "12px 16px 24px 12px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ height: 200, position: "relative" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={90}
              innerRadius={70}
              stroke="none"
              startAngle={0}
              endAngle={360}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {/* Center text display */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Box sx={{ fontWeight: 300 }} className="material-symbols-outlined">
            {iconText}
          </Box>
          <Typography
            color={"#666"}
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
          >
            {centerText}
          </Typography>
          <Typography
            color={"#2F2F2F"}
            fontFamily={"mySecondFont"}
            fontSize={"24px"}
          >
            {centerValue}
          </Typography>
        </Box>
      </Box>
      {/* Legend Section for Dashboard*/}
      {dataSet === "totalUsers" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            mt: "8px",
          }}
        >
          {filteredChartData.map((item, index) => (
            <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: item.color,
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontSize: "14px",
                  fontFamily: "myThirdFont",
                }}
              >
                {item.name}{" "}
                <span
                  style={{
                    color: "#2f2f2f",
                    fontFamily: "mySecondFont",
                    fontSize: "16px",
                  }}
                >
                  ({item.value}/{item.value2}%)
                </span>
              </Typography>
            </Box>
          ))}
          <Typography
            sx={{
              color: "#2271E8",
              fontSize: "14px",
              textAlign: "left",
              fontFamily: "myThirdFont",
            }}
          >
            Direct to app{" "}
            <span
              style={{
                color: "#2271E8",
                fontFamily: "mySecondFont",
                fontSize: "16px",
              }}
            >
              ({data?.directToApp?.count}/{data?.directToApp?.percentage}%)
            </span>
          </Typography>
        </Box>
      ) : // <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      //   {filteredChartData.map((item, index) => (
      //     <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
      //       <Box
      //         sx={{
      //           width: "12px",
      //           height: "12px",
      //           borderRadius: "50%",
      //           backgroundColor: item.color,
      //           marginRight: "8px",
      //         }}
      //       />
      //       <Typography sx={{ color: "#2F2F2F", fontSize: "14px" }}>
      //         {item.name} ({item.value}/{item.value2}%)
      //       </Typography>
      //     </Box>
      //   ))}
      // </Box>
      null}
      {/* Optionally render the "Direct to App" data */}
    </Box>
  );
};

export default CustomersDoughnutChart;
