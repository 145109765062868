import { Box, Grid } from "@mui/material";
import React from "react";
import TransactionsCards from "../../../cards/Accounts/TransactionsCards";
import TableBox from "./TableBox";
import {
  accountTransactionColumns,
  accountTransactionData,
} from "../../../../utils/DummyData";

const AllTransaction = () => {
  const transactionsCardsData = [
    {
      title: "Total Number of Transactions",
      value: 100,
    },
    {
      title: "Total Positive Amount",
      value: 5000,
      positive: true,
    },
    {
      title: "Total Refund Amount (Negative)",
      value: -300,
      positive: false,
    },
    {
      title: "Unsettled Amount at Paytm",
      value: 1500,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Grid container spacing={2}>
        {transactionsCardsData.map((card, index) => (
          <Grid key={index} item md={3} sx={{ display: "flex" }}>
            <TransactionsCards
              title={card?.title}
              value={card?.value}
              positive={card?.positive}
            />
          </Grid>
        ))}
      </Grid>

      {/* Table - Box */}
      <TableBox
        column={accountTransactionColumns()}
        row={accountTransactionData}
      />
    </Box>
  );
};

export default AllTransaction;
