import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import SuperSubscription from "../../customer_management/overview/SuperSubscription";
import TotalEnergyBox from "./TotalEnergyBox";
import OcpiSessionTotalBox from "./OcpiSessionTotalBox";
import OcpiSessionInfoBox from "./OcpiSessionInfoBox";

const OcpiSessionDetailsBox = ({ data }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            page_info
          </span>
          <Heading text={"Ocpi Session Details"} />
        </Box>
        <Divider sx={{ mt: "8px", mb: "16px" }} />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <OcpiSessionTotalBox data={data} />
        </Grid>
        <Grid item md={4}>
          <TotalEnergyBox data={data}/>
        </Grid>
        <Grid item md={4}>
          <OcpiSessionInfoBox data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcpiSessionDetailsBox;
