import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";

const ReviewTableBox = ({ columns, row, isRowClickable }) => {
  const [filteredStations, setFilteredStations] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  useEffect(() => {
    if (row.length > 0) {
      setFilteredStations(row);
    }
  }, [row]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredStations(filteredData);
  };

  const myFilters = [
    {
      value: "createdAt",
      title: "Request date",
    },
  ];
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {/* filters */}
      <TableFilters
        data={row}
        onFilterChange={handleFilterChange}
        filters={myFilters}
      />

      {/* Table */}
      <CustomTable
        columns={columns}
        rows={filteredStations}
        modalHeading={"Feedbacks"}
        sortColumn={sortColumn}
        isRowClickable={isRowClickable}
        clickableColumns={["sessionId"]}
        columnClickPaths={{
          sessionId: "/charging_sessions",
        }}
        columnKeyMappings={{
          sessionId: "sessionId",
        }}
        actionType={"view"}
        feedbackHeading={true}
      />
    </Box>
  );
};

export default ReviewTableBox;
