import { Box, Grid } from "@mui/material";
import React from "react";
import OnlineSplitDataBox from "./OnlineSplitDataBox";
import FinanceDoughnutChart from "../../Graphs/FinanceManager/FinanceDoughnutChart";
import AccountDataCard from "../../cards/Accounts/AccountDataCard";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

const TopGraphAccountBox = () => {

  const currencySymbol = GetCurrencySymbol();


  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={5} sx={12}>
          <Box sx={{}}>
            <FinanceDoughnutChart
              dataSet={"cashVsOnline"}
              centerText={"Total Payments"}
              centerValue={`${currencySymbol} 344`}
            />
          </Box>
        </Grid>
        <Grid item md={3} sx={12}>
          {/* value cards */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <AccountDataCard
              onlineStatus={true}
              title={"Online"}
              isBorder={true}
            />
            <AccountDataCard
              onlineStatus={false}
              title={"Cash"}
              icon={"currency_rupee_circle"}
              isBorder={true}
            />
          </Box>
        </Grid>
        <Grid item md={4} sx={12}>
          {/* value cards */}
          <Box>
            <OnlineSplitDataBox />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopGraphAccountBox;
