import React from "react";
import AlertsOrderComponent from "./AlertsOrderComponent";
import { Box, Divider } from "@mui/material";
import Heading from "../../../utils/Heading";

const CMSAlertsBox = ({ totalType, alertsByGroup }) => {
  const cmsData = alertsByGroup?.CMS || {};
  console.log("1111qqqq", cmsData);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/ev_charger_icon.png" alt="" />
            <Heading text={"CMS Alerts"} />
          </Box>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>
      <AlertsOrderComponent totalType={totalType} data={cmsData} />
    </Box>
  );
};

export default CMSAlertsBox;
