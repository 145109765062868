// customerWalletSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

const initialState = {
  stats: null,
  rfidUsers: null,
  walletRecharge: null,
  allCustomers: [],
  totalUserStats: null,
  superChargerCustomer: null,
  customerRfidUsers: null,
  customerStatsData: null,
  customerWalletData: [],
  feedback: [],
  customerAppUsersForSubscribersData: null,
  sessionDetails: [],
  paymentDetails: null,
  reviewDetails: [],
  customerAllDetails: null,
  activeInactiveCustomers: null,
  customerRange: null,
  loading: false,
  error: null,
};

// Thunk for fetching customer wallet stats
export const fetchCustomerWalletStats = createAsyncThunk(
  "customerWallet/fetchCustomerWalletStats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endPoints.customersWalletStats}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching customers' current RFID data
export const fetchCustomersCurrentRFID = createAsyncThunk(
  "customerWallet/fetchCustomersCurrentRFID",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endPoints.customersCurrentRFID}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching wallet recharge data
export const fetchCustomerWalletRecharge = createAsyncThunk(
  "customerManagement/fetchCustomerWalletRecharge",
  async ({ durationType, startTime, endTime }, { rejectWithValue }) => {
    try {
      let response;

      if (durationType === "customRange" && startTime && endTime) {
        response = await axios.get(
          `${endPoints.customerWalletRecharge}/${durationType}?startTime=${startTime}&endTime=${endTime}`
        );
      } else {
        response = await axios.get(
          `${endPoints.customerWalletRecharge}/${durationType}`
        );
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all customers
export const fetchCustomerManagementAllCustomers = createAsyncThunk(
  "customerManagement/fetchCustomerManagementAllCustomers",
  async ({ limit = 1200, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.customerManagementAllCustomers}?limit=${limit}&offset=${offset}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Thunk for fetching customers' current RFID data
export const fetchCustomersTotalUserStats = createAsyncThunk(
  "customerWallet/fetchCustomersTotalUserStats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endPoints.customersTotalUserStats}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching customers' current RFID data
export const fecthAppUserStatsForSubscribers = createAsyncThunk(
  "customerWallet/fecthAppUserStatsForSubscribers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endPoints.customerAppUserStats}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching customers' current RFID data
export const fetchCustomerSuperChargerStats = createAsyncThunk(
  "customerWallet/fetchCustomerSuperChargerStats",
  async ({ durationType, startTime, endTime }, { rejectWithValue }) => {
    try {
      let response;

      if (durationType === "customRange" && startTime && endTime) {
        response = await axios.get(
          `${endPoints.customerSuperCharger}/${durationType}?startTime=${startTime}&endTime=${endTime}`
        );
      } else {
        response = await axios.get(
          `${endPoints.customerSuperCharger}/${durationType}`
        );
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching customers' current RFID data
export const fetchCustomersRFIDUsers = createAsyncThunk(
  "customerWallet/fetchCustomersRFIDUsers",
  async ({ durationType, startTime, endTime }, { rejectWithValue }) => {
    try {
      let response;

      if (durationType === "customRange" && startTime && endTime) {
        response = await axios.get(
          `${endPoints.customerRfidUsers}/${durationType}?startTime=${startTime}&endTime=${endTime}`
        );
      } else {
        response = await axios.get(
          `${endPoints.customerRfidUsers}/${durationType}`
        );
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

// Thunk for fetching customer stats data
export const fetchCustomerStatsData = createAsyncThunk(
  "customerWallet/fetchCustomerStatsData",
  async ({ durationType, startTime, endTime }, { rejectWithValue }) => {
    try {
      let response;

      if (durationType === "customRange" && startTime && endTime) {
        response = await axios.get(
          `${endPoints.customerStatsData}/${durationType}?startTime=${startTime}&endTime=${endTime}`
        );
      } else {
        response = await axios.get(
          `${endPoints.customerStatsData}/${durationType}`
        );
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching customer wallet data
export const fetchCustomerWalletData = createAsyncThunk(
  "customerManagement/fetchCustomerWalletData",
  async ({ limit = 1000, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.customerWalletData}?limit=${limit}&offset=${offset}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Define the thunk
export const fetchCustomerFeedbackData = createAsyncThunk(
  "customerManagement/fetchCustomerFeedbackData",
  async ({ limit = 100, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints?.customerFeedbackData}?limit=${limit}&offset=${offset}`
      );
      return response.data.data; // Assuming `data.data` contains the feedback list
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Thunk for fetching customer session details
export const fetchCustomerSessionDetails = createAsyncThunk(
  "customerManagement/fetchCustomerSessionDetails",
  async ({ customerId, limit = 100, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.customerSessionDetails}/${customerId}?limit=${limit}&offset=${offset}`
      );
      return response.data.data; // Assuming the API response has the required data
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Thunk for fetching customer payment details
export const fetchCustomerPaymentDetails = createAsyncThunk(
  "customerManagement/fetchCustomerPaymentDetails",
  async ({ customerId, limit = 100, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.customerPaymentDetails}/${customerId}?limit=${limit}&offset=${offset}`
      );
      return response.data.data; // Assuming the API response has the required data
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Thunk for fetching customer review details
export const fetchCustomerReviewDetails = createAsyncThunk(
  "customerManagement/fetchCustomerReviewDetails",
  async ({ customerId, limit = 100, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.customerReviewDetails}/${customerId}?limit=${limit}&offset=${offset}`
      );
      return response.data.data; // Assuming the API response has the required data
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Thunk for fetching all customer details
export const fetchCustomerAllDetails = createAsyncThunk(
  "customerWallet/fetchCustomerAllDetails",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.customerAllDetails}/${customerId}`
      );
      return response.data.data; // Assuming the API response contains the customer details in `data`
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const disableCustomerReview = createAsyncThunk(
  "customerWallet/disableCustomerReview",
  async ({ reviewId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token is missing");
      }

      const response = await axios.post(
        `${endPoints.customerDisableReview}/${reviewId}`,
        {}, // Empty body for the POST request
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Assuming the API returns some data on success
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "Something went wrong"
      );
    }
  }
);

// Fetch active and inactive customers
export const fetchActiveInactiveCustomers = createAsyncThunk(
  "customers/fetchActiveInactive",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${endPoints?.activeInactiveCustomers}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch Android and iOS customer range data
export const fetchCustomerRangeAndroidIOS = createAsyncThunk(
  "customers/fetchCustomerRange",
  async ({ durationType, startTime, endTime }, { rejectWithValue }) => {
    try {
      let response;

      if (durationType === "customRange" && startTime && endTime) {
        response = await axios.get(
          `${endPoints.customerRangeAndroindISO}/${durationType}?startTime=${startTime}&endTime=${endTime}`
        );
      } else {
        response = await axios.get(
          `${endPoints.customerRangeAndroindISO}/${durationType}`
        );
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const customerWalletSlice = createSlice({
  name: "customerWallet",
  initialState,
  reducers: {
    // Add synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      // Fetch customer wallet stats
      .addCase(fetchCustomerWalletStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerWalletStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload;
      })
      .addCase(fetchCustomerWalletStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      // Fetch customers' current RFID data
      .addCase(fetchCustomersCurrentRFID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomersCurrentRFID.fulfilled, (state, action) => {
        state.loading = false;
        state.rfidUsers = action.payload;
      })
      .addCase(fetchCustomersCurrentRFID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      // Fetch wallet recharge data
      .addCase(fetchCustomerWalletRecharge.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerWalletRecharge.fulfilled, (state, action) => {
        state.loading = false;
        state.walletRecharge = action.payload;
      })
      .addCase(fetchCustomerWalletRecharge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchCustomerManagementAllCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // .addCase(
      //   fetchCustomerManagementAllCustomers.fulfilled,
      //   (state, action) => {
      //     state.loading = false;
      //     state.allCustomers = action.payload; // Update the state with API response
      //   }

      // )
      .addCase(
        fetchCustomerManagementAllCustomers.fulfilled,
        (state, action) => {
          state.loading = false;
          state.allCustomers = action.payload;
        }
      )
      .addCase(
        fetchCustomerManagementAllCustomers.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload; // Store the error message
        }
      );
    builder
      // Fetch customer wallet stats
      .addCase(fetchCustomersTotalUserStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomersTotalUserStats.fulfilled, (state, action) => {
        state.loading = false;
        state.totalUserStats = action.payload;
      })
      .addCase(fetchCustomersTotalUserStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      // Fetch customer wallet stats
      .addCase(fecthAppUserStatsForSubscribers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fecthAppUserStatsForSubscribers.fulfilled, (state, action) => {
        state.loading = false;
        state.customerAppUsersForSubscribersData = action.payload;
      })
      .addCase(fecthAppUserStatsForSubscribers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      // Fetch customer wallet stats
      .addCase(fetchCustomerSuperChargerStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerSuperChargerStats.fulfilled, (state, action) => {
        state.loading = false;
        state.superChargerCustomer = action.payload;
      })
      .addCase(fetchCustomerSuperChargerStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      // Fetch customers' RFID data
      .addCase(fetchCustomersRFIDUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomersRFIDUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.customerRfidUsers = action.payload;
      })
      .addCase(fetchCustomersRFIDUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchCustomerStatsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerStatsData.fulfilled, (state, action) => {
        state.loading = false;
        state.customerStatsData = action.payload;
      })
      .addCase(fetchCustomerStatsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchCustomerWalletData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerWalletData.fulfilled, (state, action) => {
        state.loading = false;
        state.customerWalletData = action.payload; // Update the state with API response
      })
      .addCase(fetchCustomerWalletData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    builder
      .addCase(fetchCustomerFeedbackData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerFeedbackData.fulfilled, (state, action) => {
        state.loading = false;
        state.feedback = action.payload; // Save the feedback data to the state
      })
      .addCase(fetchCustomerFeedbackData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // Add case for fetchCustomerSessionDetails
    builder
      .addCase(fetchCustomerSessionDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerSessionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionDetails = action.payload; // Store session details
      })
      .addCase(fetchCustomerSessionDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error if any
      });
    // Add cases for fetchCustomerPaymentDetails
    builder
      .addCase(fetchCustomerPaymentDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerPaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentDetails = action.payload;
      })
      .addCase(fetchCustomerPaymentDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Add cases for fetchCustomerReviewDetails
    builder
      .addCase(fetchCustomerReviewDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerReviewDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewDetails = action.payload;
      })
      .addCase(fetchCustomerReviewDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchCustomerAllDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerAllDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.customerAllDetails = action.payload; // Update the state with API response
      })
      .addCase(fetchCustomerAllDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    builder
      .addCase(disableCustomerReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(disableCustomerReview.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the state if needed, e.g., removing the disabled review
        const reviewId = action.meta.arg.reviewId;
        state.reviewDetails = state.reviewDetails.filter(
          (review) => review.id !== reviewId
        );
      })
      .addCase(disableCustomerReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    builder
      .addCase(fetchActiveInactiveCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActiveInactiveCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.activeInactiveCustomers = action.payload;
      })
      .addCase(fetchActiveInactiveCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle customerRangeAndroidIOS API
    builder
      .addCase(fetchCustomerRangeAndroidIOS.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerRangeAndroidIOS.fulfilled, (state, action) => {
        state.loading = false;
        state.customerRange = action.payload;
      })
      .addCase(fetchCustomerRangeAndroidIOS.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customerWalletSlice.reducer;
