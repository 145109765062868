import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";
import { formatDateTime } from "../../../utils/FormatDateTime";
import Heading from "../../../utils/Heading";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const SessionDetailsBox = ({ data }) => {
  const firstSessionDate = data?.chargingStats?.stats?.firstSessionDate
    ? formatDateTime(data?.chargingStats?.stats?.firstSessionDate)
    : {};

  const lastSessionDate = data?.chargingStats?.stats?.lastSessionDate
    ? formatDateTime(data?.chargingStats?.stats?.lastSessionDate)
    : {};
  const updatedAt = data?.updatedAt ? formatDateTime(data.updatedAt) : {};
  const createdBy = data?.createdBy || {};
  return (
    <Box
      sx={{
        padding: "16px 16px 24px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span className="material-symbols-outlined">battery_charging_90</span>
          <Heading text={"Sessions details"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="No. of Sessions "
              value={data?.chargingStats?.stats?.totalSessions}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Total Energy "
              value={`${(
                data?.chargingStats?.stats?.totalEnergyConsumed / 1000
              ).toFixed(2)} kWh`}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Avg. duration"
              value={`${data?.chargingStats?.stats?.averageSessionDuration?.toFixed(
                3
              )}`}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Avg Frequency"
              value={
                data?.chargingStats?.stats?.averageChargingFrequency != null &&
                typeof data.chargingStats.stats.averageChargingFrequency ===
                  "number"
                  ? `${data.chargingStats.stats.averageChargingFrequency?.toFixed(
                      2
                    )} days`
                  : "N/A" // Fallback value when the data is null, undefined, or not a number
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="First session" value={firstSessionDate.date} />
            <Typography
              fontSize={"12px"}
              fontFamily={"myThirdFont"}
              color={"#666"}
              textAlign={"start"}
            >
              {firstSessionDate.time}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Latest session" value={lastSessionDate.date} />
            <Typography
              fontSize={"12px"}
              fontFamily={"myThirdFont"}
              color={"#666"}
              textAlign={"start"}
            >
              {lastSessionDate.time}
            </Typography>
          </Box>
        </Box>
        <InfoBox
          label="Frequently used station"
          value={data?.chargingStats?.stats?.mostFrequentStation?.name}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Longest session"
              value={`${(
                data?.chargingStats?.stats?.longestSession?.duration /
                (60 * 60 * 1000)
              )?.toFixed(2)} hrs`}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox
              label="Highest energy session"
              value={`${(
                data?.chargingStats?.stats?.highestEnergySession
                  ?.energyConsumed / 1000
              ).toFixed(2)} kWh`}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionDetailsBox;
