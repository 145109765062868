import { Box } from "@mui/material";
import React from "react";

const GalleryCard = ({ item, onClick }) => {
  // Provide a fallback image if item is undefined
  const imageUrl = item || "https://via.placeholder.com/150";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f0f0f0",
        borderRadius: "4px",
        overflow: "hidden",
        height: "100%",
        cursor: "pointer", // Make it clear that the image is clickable
      }}
      onClick={() => onClick(imageUrl)} // Trigger the onClick handler passed from GalleryBox
    >
      <img
        src={imageUrl} // Use the image URL passed from GalleryBox
        alt="Gallery"
        style={{
          maxWidth: "170px",
          width: "100%",
          height: "160px",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};

export default GalleryCard;
