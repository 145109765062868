import {
  Box,
  CircularProgress,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import StatusChip from "../Chips/StatusChip";
import ActionIcons from "../../utils/ActionIcons";
import { useDispatch } from "react-redux";
import { getOneOrg } from "../../redux/action/companyManagement/companyManagementSlice";
import { FormatTimestamp } from "../../utils/FormatDateTime";
import PaymentModal from "../Modals/PaymentModal";
import TablePaginationActions from "../../utils/TablePagination";

export const DateTimeDisplay = ({ dateTime }) => {
  const date = new Date(dateTime);
  const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  }); // Format: hh:mm AM/PM

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        color={"#2F2F2F"}
        fontSize={"14px"}
        fontFamily={"mySecondFont"}
        textAlign={"start"}
      >
        {formattedDate}
      </Typography>
      <Typography
        color={"#666"}
        fontSize={"14px"}
        fontFamily={"myThirdFont"}
        textAlign={"start"}
      >
        {formattedTime}
      </Typography>
    </Box>
  );
};

const AlertsCustomTable = ({
  columns,
  liveLogs,
  logsCount,
  rows,
  // page,
  // limit,
  // setPage,
  // setLimit,
  isRowClickable,
  actionType,
  clickableColumns = [],
  rowClickPath,
  columnClickPaths,
  onRowClick,
  selectedRow,
  columnKeyMappings,
  specialColumns = [],
  modalHeading,
  statsHeading,
  loading,
  alertType,
  alertCountsForTable
}) => {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(limit);
  // const [tableData, setTableData] = React.useState(rows);

  const [page, setPage] = React.useState(0);
  // const { page, setPage } = useTablePage();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, setTableData] = React.useState(rows);

  const [selectedRowData, setSelectedRowData] = React.useState(null); // State for the selected row data
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Sort rows by the most recent timestamp among sessionStart, sessionStop, and createdAt
    // const sortedRows = [...rows].sort((a, b) => {
    //   // Handle missing timestamps
    //   const aSessionStart = a.sessionStart
    //     ? new Date(a.sessionStart).getTime()
    //     : 0;
    //   const aSessionStop = a.sessionStop
    //     ? new Date(a.sessionStop).getTime()
    //     : 0;
    //   const aCreatedAt = a.createdAt ? new Date(a.createdAt).getTime() : 0;

    //   const bSessionStart = b.sessionStart
    //     ? new Date(b.sessionStart).getTime()
    //     : 0;
    //   const bSessionStop = b.sessionStop
    //     ? new Date(b.sessionStop).getTime()
    //     : 0;
    //   const bCreatedAt = b.createdAt ? new Date(b.createdAt).getTime() : 0;

    //   // Determine the latest timestamp for each row
    //   const aLatest = Math.max(aSessionStart, aSessionStop, aCreatedAt);
    //   const bLatest = Math.max(bSessionStart, bSessionStop, bCreatedAt);

    //   return bLatest - aLatest; // Sort in descending order
    // });
    setTableData([...rows]);
  }, [rows]);

  // const handleChangePage = (event, nextPage) => {
  //   setPage(nextPage + 1);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(limit);
  //   setPage(page);
  // };

  // // Calculate the rows to be displayed on the current page
  // const displayRows = tableData?.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the rows to be displayed on the current page
  const displayRows = tableData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (row, rowIndex) => {
    if (!row) {
      console.error("Row is undefined or null");
      return;
    }
    // if (
    //   !row ||
    //   (modalHeading === "Payments" &&
    //     clickableColumns.includes("paymentId") &&
    //     !row.paymentId)
    // ) {
    //   console.error("Row or paymentId is undefined");
    //   return;
    // }

    // if (modalHeading === "Payments" && clickableColumns.includes("paymentId")) {
    //   setSelectedRowData(row); // Set selected row data for modal
    //   setIsModalOpen(true); // Open the modal
    // }
    if (modalHeading === "Payments") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setIsModalOpen(true);
    } else {
      // Existing navigation logic
      const clickableColumnIds = clickableColumns.filter((col) => row[col]);

      if (clickableColumnIds.length === 1) {
        const column = clickableColumnIds[0];
        const path = columnClickPaths[column];
        const key = columnKeyMappings[column] || column;
        navigate(`${path}/${row[key]}`, { replace: true });
      } else if (clickableColumnIds.length > 1) {
        const firstColumn = clickableColumnIds[0];
        const path = columnClickPaths[firstColumn];
        const key = columnKeyMappings[firstColumn] || firstColumn;
        navigate(`${path}/${row[key]}`);
      } else if (isRowClickable && rowClickPath) {
        navigate(`${rowClickPath}/${row?.code}`, { replace: true });
      }
    }

    // Call the onRowClick callback if provided
    if (onRowClick) {
      onRowClick(row);
    }
  };

  // const handleColumnClick = (column, row) => {
  //   const path = columnClickPaths[column.id];
  //   const key = columnKeyMappings[column.id] || column.id;
  //   if (path) {
  //     navigate(`${path}/${row?.[key]}`, { replace: true });
  //   }
  // };
  const handleColumnClick = (column, row) => {
    const path = columnClickPaths[column.id];
    const key = columnKeyMappings[column.id] || column.id;

    if (path) {
      // Extract the ID from nested objects
      const value = row[key];
      const idToNavigate =
        (key === "station" && value ? value._id : row[key]) || "";
      navigate(`${path}/${idToNavigate}`, { replace: true });
    }
  };

  // const handleConditionalDispatch = (columnId, key) => {
  //   if (modalHeading === "Companies Management" || columnId === "name") {
  //     dispatch(getOneOrg(key));
  //   }
  // };

  const getSpecialBackground = (columnId, row) => {
    if (columnId === "message") {
      return row?.direction === "Incoming" ? "#027900" : "#E50000";
    }
    if (columnId === "_id") {
      return row.status === true ? "#027900" : "#790000";
    }
    if (columnId === "paymentId") {
      return row.status === "Success" ? "#027900" : "#790000";
    }
    if (columnId === "paymentID") {
      return row.paymentStatus === "credited" ? "#027900" : "#790000";
    }
    if (columnId === "name") {
      return row.adminStatus === "active" ? "#027900" : "#790000";
    }
    return "transparent";
  };

  // Format value to two decimal places if it has more than two decimal places
  const formatValue = (value, columnId, row) => {
    if (typeof value === "object" && value !== null) {
      // Handle specific cases for nested objects
      if (columnId === "org" || "station") {
        return value.name || ""; // Assuming you want to display the name field
      }
      if (columnId === "createdBy") {
        return value.name || ""; // Assuming you want to display the name field
      }
    }

    // Handle special case for energyConsumed
    if (columnId === "energyConsumed") {
      if (row.energyConsumed !== undefined) {
        return (parseFloat(row.energyConsumed) || 0).toFixed(2);
      }
      const stopValue = parseFloat(row.stopMeterValue) || 0;
      const startValue = parseFloat(row.startMeterValue) || 0;
      return ((stopValue - startValue) / 1000).toFixed(2);
    }

    // Check if value is a number or a string that represents a number
    if (
      typeof value === "number" ||
      (!isNaN(parseFloat(value)) && isFinite(value))
    ) {
      const numberValue = parseFloat(value);

      // Only format if the number has more than two decimal places
      if (
        numberValue.toString().includes(".") &&
        numberValue.toString().split(".")[1].length > 2
      ) {
        return numberValue.toFixed(2);
      }
      return numberValue.toString(); // Return the value as is if no formatting is needed
    }

    // Return non-numeric values unchanged
    return value;
  };

  const formatSpecialValue = (value, columnId) => {
    if (columnId === "sessionStart" || columnId === "sessionStop") {
      // Ensure value is treated as a number (milliseconds) and convert to Date
      const date = new Date(parseInt(value, 10)); // Convert milliseconds to Date
      const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }); // Format: hh:mm AM/PM
      return (
        <Box>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
          >
            {formattedDate}
          </Typography>
          <Typography
            color={"#666"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
          >
            {formattedTime}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const formatRating = (rating) => {
    switch (rating) {
      case 5:
        return "Ok";
      case 10:
        return "Great";
      case 3:
        return "Bad";
      default:
        return ""; // Return an empty string for other ratings
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign={"start"}
          fontSize={"14px"}
          fontFamily={"myThirdFont"}
          color="#2F2F2F"
        >
          Showing {alertCountsForTable} {modalHeading}
        </Typography>
        {statsHeading === "true" ? (
          <Typography
            textAlign={"start"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
            color="#2F2F2F"
          >
            *stats from created date to present
          </Typography>
        ) : (
          ""
        )}
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#292D33", padding: "8px 16px" }}>
              {columns.map((item) => {
                return (
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontFamily: "myFirstFont",
                      textWrap: "nowrap",
                    }}
                    key={item.id}
                    align={item.align}
                  >
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              displayRows.map((row, rowIndex) => (
                <TableRow
                  key={row.stationName || rowIndex}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: isRowClickable ? "pointer" : "default",
                    backgroundColor:
                      selectedRow === rowIndex ? "red" : "transparent",
                    "&:hover": {
                      backgroundColor: isRowClickable ? "#f5f5f5" : "inherit",
                    },
                  }}
                  onClick={
                    isRowClickable ? () => handleRowClick(row) : undefined
                  }
                >
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        color: "#2F2F2F",
                        padding:
                          column.id === "createdAt" ||
                          column.id === "created_at" ||
                          column.id === "sessionStart" ||
                          column.id === "sessionStop" ||
                          column.id === "updatedAt" ||
                          column.id === "updated_at" ||
                          
                          column.id === "purchaseDate" ||
                          column.id === "validTill" ||
                          column.id === "vehicle_entry_time" ||
                          column.id === "purchase_date"
                            ? "4px 16px"
                            : "8px 16px",
                        fontFamily: "mySecondFont",
                        textTransform: "capitalize",
                        textWrap: "nowrap",
                        textDecoration:
                          isRowClickable &&
                          clickableColumns.includes(column.id) &&
                          column.id !== "action"
                            ? "underline"
                            : "none",
                        cursor:
                          isRowClickable &&
                          clickableColumns.includes(column.id) &&
                          column.id !== "action"
                            ? "pointer"
                            : "default",
                      }}
                      key={column.id}
                      align={column.align}
                      onClick={(e) => {
                        if (
                          isRowClickable &&
                          clickableColumns.includes(column.id) &&
                          column.id !== "action"
                        ) {
                          e.stopPropagation();
                          handleColumnClick(column, row);
                        }
                      }}
                    >
                      {column.id === "action" ? (
                        <ActionIcons
                          actions={actionType}
                          row={row}
                          heading={modalHeading}
                          setTableData={setTableData}
                        />
                      ) : column.id === "sr" ? (
                        <Typography
                          sx={{
                            textAlign: "start",
                            fontFamily: "mySecondFont",
                            color: "#2F2F2F",
                            fontSize: "14px",
                          }}
                        >
                          {/* {(page - 1) * rowsPerPage + rowIndex + 1} */}
                          {page * rowsPerPage + rowIndex + 1}
                        </Typography>
                      ) : column.id === "alertName" ? (
                        <Box
                          sx={{
                            backgroundColor:
                              alertType === "critical"
                                ? "#790000"
                                : alertType === "high"
                                ? "#D31818"
                                : alertType === "medium"
                                ? "#E86100"
                                : alertType === "low"
                                ? "#E29B00"
                                : null,

                            padding: "4px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: alertType === "low" ? "#382600": "#FFF",
                              fontFamily: "mySecondFont",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {row[column.id]}
                          </Typography>
                        </Box>
                      ) : column.id === "status" ? (
                        <Box>
                          <StatusChip text={row[column.id]} />
                        </Box>
                      ) : column.id === "rating" ? (
                        <Box>
                          <StatusChip text={formatRating(row[column.id])} />
                        </Box>
                      ) : specialColumns.includes(column.id) ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            sx={{
                              width: "4px",
                              height: "16px",
                              borderRadius: "50px",
                              background: getSpecialBackground(column.id, row),
                              marginRight: "8px",
                            }}
                          />
                          {formatSpecialValue(row[column.id], column.id) ||
                            formatValue(row[column.id])}
                        </Box>
                      ) : column.id === "createdAt" || column.id === "created_at" ||
                        column.id === "updatedAt" ? (
                        <DateTimeDisplay dateTime={row[column.id]} />
                      ) : column.id === "sessionStart" ||
                        column.id === "sessionStop" ? (
                        <FormatTimestamp timestamp={row[column.id]} />
                      ) : (
                        formatValue(row[column.id], column.id, row)
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {logsCount > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={logsCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ "& .MuiTablePagination-toolbar": { fontSize: "0.8rem" } }}
        />
      )} */}

{rows.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ "& .MuiTablePagination-toolbar": { fontSize: "0.8rem" } }}
        />
      )}

      {/* Modal to display row details */}
      {selectedRowData && isModalOpen && (
        <PaymentModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          row={selectedRowData}
        />
      )}
    </Box>
  );
};

const handleEdit = (row) => {
  console.log("Edit action for:", row);
};

const handleDelete = (row) => {
  console.log("Delete action for:", row);
};

export default AlertsCustomTable;
