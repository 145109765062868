import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  sessions: [],
  sessionsCount: 0,
  sessionDetail: null,
  sessionLogs: [],
  invoice: null,
  loading: false,
  error: null,
};

// Create async thunks for API calls
export const fetchAllSessions = createAsyncThunk(
  "sessions/fetchAllSessions",
  async (
    {
      page = 1,
      limit = 10,
      search: deviceId,
      isActive,
      startTime,
      endTime,
      filters,
    },
    { rejectWithValue }
  ) => {
    try {
      // Prepare payload with only defined values
      const payload = Object.fromEntries(
        Object.entries({
          deviceId,
          isActive,
          startTime,
          endTime,
          filters,
        }).filter(([_, value]) => value != null) // Exclude null and undefined
      );

      // Call API
      const response = await fetchAllSessionsApi({
        page,
        limit,
        payload,
      });

      // Return response data
      return response;
    } catch (error) {
      console.error("Error fetching sessions:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// API Call Function
export const fetchAllSessionsApi = async ({ page, limit, payload, token }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${endPoints.getAllSessionsWithFilters}?offset=${page}&limit=${limit}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error; // Re-throw the error for higher-level handling
  }
};

export const fetchOneSession = createAsyncThunk(
  "sessions/fetchOneSession",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneSession}/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOneSessionLogs = createAsyncThunk(
  "sessions/fetchOneSessionLogs",
  async ({ sessionId, page, limit, logsFilter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneSessionLogs}/${sessionId}?page=${page}&limit=${limit}&filter=${logsFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateInvoice = createAsyncThunk(
  "sessions/generateInvoice",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.invoiceGenerate}/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming the API response contains the generated invoice data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload?.data || [];
        state.sessionsCount = action.payload?.totalCounts || 0;
      })
      .addCase(fetchAllSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionDetail = action.payload;
      })
      .addCase(fetchOneSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneSessionLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneSessionLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionLogs = action.payload; // Store session logs in state
      })
      .addCase(fetchOneSessionLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(generateInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.invoice = action.payload; // Store the generated invoice data
      })
      .addCase(generateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default sessionsSlice.reducer;
