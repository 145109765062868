import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";

// LeftText Component
const AlertCountTextBox = ({ label, value, bgColor }) => {
  return (
    <Box
      sx={{
        background: bgColor || "#292D33", // Default background color if not passed
        padding: "6px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <Typography
        sx={{
          color: label === "Low" ? "#382600" : "#FCFCFC",
          fontFamily: "myThirdFont",
          fontSize: "12px",
          lineHeight: "12px", // 150% of the font size
          textTransform: "capitalize",
        }}
      >
        {label}
      </Typography>

      <Typography
        sx={{
          color: label === "Low" ? "#382600" : "#FCFCFC",
          fontFamily: "myFirstFont",
          fontSize: "14px",
          lineHeight: "14px",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export { AlertCountTextBox };

const AlertCountBoxDashboard = ({ data, isDash }) => {
  const navigate = useNavigate();

  const handleNavigate = (tab) => {
    navigate(`${MyRoutes?.alerts_and_faults}?tab=${tab}`);
  };

  const getAlertCount = (type) => {
    if (!data?.data || !Array.isArray(data.data)) {
      return 0;
    }
    const alert = data.data.find((item) => item._id === type);
    return alert ? alert.count : 0;
  };

  // Calculate total alerts
  const getTotalAlerts = () => {
    if (!data?.data || !Array.isArray(data.data)) {
      return 0;
    }
    return data.data.reduce((total, item) => total + item.count, 0);
  };

  const alertConfigs = [
    { label: "Critical", type: "CRITICAL", bgColor: "#790000", tab: 1 },
    { label: "High", type: "HIGH", bgColor: "#D31818", tab: 2 },
    { label: "Medium", type: "MEDIUM", bgColor: "#E86100", tab: 3 },
    { label: "Low", type: "LOW", bgColor: "#E29B00", tab: 4 },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container spacing={0.5}>
        {alertConfigs.map(({ label, type, bgColor, tab }) => (
          <Grid item md={3} key={label}>
            <Box sx={{ cursor: "pointer" }} onClick={() => handleNavigate(tab)}>
              <AlertCountTextBox
                label={label}
                value={getAlertCount(type)}
                bgColor={bgColor}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AlertCountBoxDashboard;
