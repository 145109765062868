import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

// Dummy data to be used if no data is provided via props
const dummyData = {
  refundAmount: 4500,
  refundPercentage: 15,
  totalPaidAmount: 4211,
  totalPaidAmountPercentage: 85,
};

const FinanceSemiDoughnutChart = ({ data = dummyData, dataSet, centerText, centerValue }) => {
  // Predefined colors: Yellow for refund, Gray for total paid amount
  const preDefinedColors = ["#790000", "#D3D3D3"];

  // Function to map data for the refund and total paid amounts
  const mapRefundTotalAmountData = (data) => {
    const refundAmountPercentage = data?.refundPercentage || 0;
    const totalPaidAmountPercentage = data?.totalPaidAmountPercentage || 0;

    // Return the chart data array
    return [
      { name: "Refund Amount", value: refundAmountPercentage },
      { name: "Total Paid Amount", value: totalPaidAmountPercentage },
    ];
  };

  // Generate chart data based on the passed `dataKey`
  const chartData =
    dataSet === "refundData" ? mapRefundTotalAmountData(data) : [];

  return (
    <Box>
      <Box sx={{ width: "100%", height: "160px", position:"relative" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              cx="50%"
              cy="90%"
              outerRadius={135}
              innerRadius={120}
              startAngle={180}
              endAngle={0}
              stroke="none"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === 0 ? preDefinedColors[0] : preDefinedColors[1]} // Dynamic color based on index
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

           {/* Center text display */}
      <Box
        sx={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Box sx={{ fontWeight: 300 }} className="material-symbols-outlined">
          {/* {iconText} */}bolt
        </Box>
        <Typography color={"#666"} fontFamily={"myThirdFont"} fontSize={"14px"}>
          {centerText}
        </Typography>
        <Typography
          color={"#2F2F2F"}
          fontFamily={"mySecondFont"}
          fontSize={"24px"}
        >
          {centerValue}
        </Typography>
      </Box>
      </Box>
   
    </Box>
  );
};

export default FinanceSemiDoughnutChart;
