import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

// Custom Tooltip Content
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <Typography variant="body2" sx={{ color: "#666" }}>
          {label}
        </Typography>
        {payload.map((entry, index) => {
          // Use `fill` instead of `stroke` for the color
          const color = entry.fill || "#000"; // Default to black if no fill is provided

          return (
            <Typography key={index} sx={{ color, fontSize: "14px" }}>
              <strong>{getCustomLabel(entry.dataKey)}: </strong>
              {entry.value}
            </Typography>
          );
        })}
      </Box>
    );
  }

  return null;
};

// Function to return custom labels for line chart, legend, and tooltip
const getCustomLabel = (dataKey) => {
  switch (dataKey) {
    case "appUsers":
      return "App Users";
    case "directUsers":
      return "Direct Users";

    default:
      return dataKey;
  }
};

const CustomerDoubleStackedBarGraph = ({
  data,
  selectedTime,
  startTime,
  endTime,
}) => {
  console.log("lllfff", data);
  // Generate date range based on selectedTime
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];

    // Extract the year dynamically from the data
    const extractedYear = (() => {
      if (data?.appUserGraphData?.length > 0) {
        const time = data.appUserGraphData[0]?.time;
        // Ensure time exists and is a string
        if (typeof time === "string" && time.includes("-")) {
          const parts = time.split("-");
          return parts[2]; // Assuming the year is the third part
        }
      }
      // Default to current year (last two digits)
      return dayjs().format("YY");
    })();

    if (selectedTime === "last7Days") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "currentMonth") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;
      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "currentYear") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    }
    // else if (selectedTime === "customRange" && startTime && endTime) {
    //   let currentDate = dayjs(startTime);
    //   const end = dayjs(endTime);
    //   while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    //     dates.push({
    //       date: currentDate.format("YYYY-MM-DD"),
    //       label: currentDate.format("YYYY-MM-DD"),
    //     });
    //     currentDate = currentDate.add(1, "day");
    //   }
    // }
    else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format(`DD-MM-${extractedYear}`),
          label: currentDate.format(`DD-MM-${extractedYear}`),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .add(i, "hour")
          .format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    // Handle "today" or "yesterday" data
    if (selectedTime === "today" || selectedTime === "yesterday") {
      return dates.map(({ date, label }) => {
        // Extract the hour from the label (HH:00)
        const hour = parseInt(label.split(":")[0]);

        // Find the data for the given hour in both appUserGraphData and directUserGraphData
        const hourAppData =
          data?.appUserGraphData.find((item) => item.time === hour) || {};
        const hourDirectData =
          data?.directUserGraphData.find((item) => item.time === hour) || {};

        // Get the counts for appUsers and directUsers (default to 0 if not found)
        const appUsers = hourAppData.count || 0;
        const directUsers = hourDirectData.count || 0;

        return {
          date: label,
          appUsers: appUsers, // Map appUser count
          directUsers: directUsers, // Map directUser count
        };
      });
    }

    // Handle "last7Days" data
    if (selectedTime === "last7Days") {
      return dates.map(({ date, label }) => {
        // Find the data for the given day (label is the day of the week)
        const hourAppData =
          data?.appUserGraphData.find((item) => item.time === label) || {};
        const hourDirectData =
          data?.directUserGraphData.find((item) => item.time === label) || {};

        // Get the counts for appUsers and directUsers (default to 0 if not found)
        const appUsers = hourAppData.count || 0;
        const directUsers = hourDirectData.count || 0;

        return {
          date: label, // The label will be the day of the week (Mon, Tue, etc.)
          appUsers: appUsers,
          directUsers: directUsers,
        };
      });
    }
    if (selectedTime === "currentMonth") {
      return dates.map(({ date, label }) => {
        // Parse the day number from the label (1 to 31)
        const day = parseInt(label);

        // Find the data for the specific day
        const dayAppData =
          data?.appUserGraphData.find((item) => item.time === day) || {};
        const dayDirectData =
          data?.directUserGraphData.find((item) => item.time === day) || {};

        // Get the counts for appUsers and directUsers (default to 0 if not found)
        const appUsers = dayAppData.count || 0;
        const directUsers = dayDirectData.count || 0;

        return {
          date: label, // Day of the month (1, 2, ..., 31)
          appUsers: appUsers,
          directUsers: directUsers,
        };
      });
    }

    // Handle "currentYear" data
    if (selectedTime === "currentYear") {
      return dates.map(({ date, label }) => {
        // Find the data for the specific month (e.g., "Jan", "Feb", ..., "Dec")
        const monthAppData =
          data?.appUserGraphData.find((item) => item.time === label) || {};
        const monthDirectData =
          data?.directUserGraphData.find((item) => item.time === label) || {};

        // Get the counts for appUsers and directUsers (default to 0 if not found)
        const appUsers = monthAppData.count || 0;
        const directUsers = monthDirectData.count || 0;

        return {
          date: label, // Month name (e.g., "Jan", "Feb", ..., "Dec")
          appUsers: appUsers,
          directUsers: directUsers,
        };
      });
    }

    // Handle "customRange" data
    if (selectedTime === "customRange") {
      return dates.map(({ date, label }) => {
        const dateAppData =
          data?.appUserGraphData.find((item) => item.time === label) || {};
        const dateDirectData =
          data?.directUserGraphData.find((item) => item.time === label) || {};

        const appUsers = dateAppData.count || 0;
        const directUsers = dateDirectData.count || 0;

        return {
          date: label,
          appUsers: appUsers,
          directUsers: directUsers,
        };
      });
    }
    return [];
  };

  const chartData = getData();

  console.log("fnfnjf", chartData);
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData} // Pass the mapped data
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date" // Display the time on the X-axis (hours in HH:00 format)
            tickFormatter={(time) => `${time}`} // Display time (e.g., "12:00")
            tick={{ fontSize: 14, fontFamily: "mySecondFont", fill: "#666" }}
          />
          <YAxis
            tick={{ fontSize: 14, fontFamily: "mySecondFont", fill: "#666" }}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Legend formatter={(value) => getCustomLabel(value)} />
          <Bar dataKey="appUsers" stackId="a" fill="#69A5FF" />
          <Bar dataKey="directUsers" stackId="a" fill="#FF9F69" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomerDoubleStackedBarGraph;
