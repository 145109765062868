import React, { useEffect } from "react";
import AlertsHeatMap from "../../Graphs/AlertsGraphs/AlertsHeatMap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllAlertsDataForHeatMap } from "../../../redux/action/alertsSlice/alertsSlice";
import { Box, Typography } from "@mui/material";
import Heading from "../../../utils/Heading";

const HeatMapBox = () => {
  const dispatch = useDispatch();

  // Access state from the Redux store
  const { alertStatsTotalHeatData, status, error, loading } = useSelector(
    (state) => state.alerts
  );
  console.log("alertsHistory", alertStatsTotalHeatData);

  // Fetch alert counts when the component mounts
  useEffect(() => {
    dispatch(fetchAllAlertsDataForHeatMap());
  }, [dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        gap: "24px",
        width: "100%",
      }}
    >
      {/* Header Box */}
      <Box sx={{}}>
        <Box sx={{}}>
          {/* Typography for title or description */}
          <Typography
            sx={{
              color: "#0C0C0C",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Total no. of alerts with respect to categories & alert severity
          </Typography>
        </Box>
      </Box>

      {/* AlertsHeatMap Component */}
      <AlertsHeatMap data={alertStatsTotalHeatData} />
    </Box>
  );
};

export default HeatMapBox;
