import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import CustomerDetailsBox from "./Details/CustomerDetailsBox";
import SessionTableBox from "./SessionTableBox/SessionTableBox";
import ReviewTableBox from "./ReviewTableBox/ReviewTableBox";
import AllPaymentBox from "./AllPaymentBox/AllPaymentBox";
import {
  customerDetailReview,
  customerDetailsReviewData,
  CustomerDetailsSessionColumns,
  customerManagementPaymentColumns,
  customerPaymentData,
  customerSessionDetailsData,
} from "../../utils/DummyData";
import {
  fetchCustomerAllDetails,
  fetchCustomerPaymentDetails,
  fetchCustomerReviewDetails,
  fetchCustomerSessionDetails,
} from "../../redux/action/customerManagement/customerManagementSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StatusChip from "../Chips/StatusChip";
import Breadcrumbs from "../breadcrumbs/BreadCrumbs";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = ({ org }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [customerId, setCustomerId] = useState(null);
  const dispatch = useDispatch();

  const {
    sessionDetails,
    paymentDetails,
    reviewDetails,
    customerAllDetails,
    loading,
    error,
  } = useSelector((state) => state.customerWallet);

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setCustomerId(id);
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(fetchCustomerSessionDetails({ customerId }));
      dispatch(fetchCustomerPaymentDetails({ customerId }));
      dispatch(fetchCustomerReviewDetails({ customerId }));
      dispatch(fetchCustomerAllDetails(customerId));
    }
  }, [dispatch, customerId]);

  const allSessionData = sessionDetails?.map((session, index) => {
    const station = session?.chargingPoint?.station?.name || "N/A"; // Extract nested station name
    const connector = session?.chargingPoint?.connectors
      ? `${session.chargingPoint.connectors.connectorId || "N/A"} (${
          session.chargingPoint.connectors.connectorType || "N/A"
        })`
      : "N/A";
    const mode = session.from || "N/A"; // Extract mode of charging
    return {
      _id: session._id || "N/A", // Session ID
      transactionId: session.transactionId || "N/A", // Transaction ID
      station: station, // Charging station name
      deviceId: session.deviceId || "N/A", // Device ID
      connectorId: connector, // Connector details
      energyConsumed: session?.energyConsumed, // Energy consumed in kWh
      from: mode, // Mode
      chargedAmount: session.chargedAmount || 0, // Amount charged
      refundAmount: session.refundAmount || 0, // Refund amount
      sessionStart: session?.sessionStart, // Start time
      sessionStop: session?.sessionStop, // End time
    };
  });

  const allReviewData = reviewDetails?.map((review) => {
    return {
      created_at: review?.created_at,
      rating: review?.ratingStatus,
      station: review?.stationName,
      reviewId: review?._id,
      isActive: review?.isActive,
      description: review?.description,
      title: review?.title,
      userDetails: review?.userDetails,
    };
  });

  const allPaymentData = paymentDetails?.map((paymet) => {
    return {
      transactionId: paymet?.transactionId,
      station: paymet?.station,
      amount_added: paymet?.amount_added,
      paymentType: paymet?.paymentType,
      plateform: paymet?.plateform,
      createdAt: paymet?.createdAt,
      stationId: paymet?.station?._id,
    };
  });

  const tabsData = [
    {
      label: "Details",
      content: (
        <Box>
          <CustomerDetailsBox data={customerAllDetails} />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Sessions",
      content: (
        <Box>
          <SessionTableBox
            columns={CustomerDetailsSessionColumns()}
            row={allSessionData}
            isRowClickable={true}
            loading={loading}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"info"} />,
    },
    {
      label: "All Payments",
      content: (
        <Box>
          <AllPaymentBox
            columns={customerManagementPaymentColumns()}
            row={allPaymentData}
            isRowClickable={true}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "Review",
      content: (
        <Box>
          <ReviewTableBox
            columns={customerDetailReview()}
            row={allReviewData}
            isRowClickable={true}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Breadcrumbs />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <MainHeading text={customerAllDetails?.name} />
            <StatusChip
              text={
                customerAllDetails?.is_super
                  ? "1C Super"
                  : customerAllDetails?.is_registered
                  ? "App customer"
                  : "Direct pay"
              }
            />
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
