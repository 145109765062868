import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../../routes/routes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "8px",
  outline: "none",
};

const ConfirmSettleModal = ({ open, handleClose, data }) => {
  const currencySymbol = GetCurrencySymbol();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    handleClose();
    navigate(MyRoutes.accounts_settlements);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <Box>
                <img src="/images/success-logo.png" alt="" />
              </Box>
              <Typography
                fontSize={"24px"}
                fontFamily={"mySecondFont"}
                textAlign={"center"}
              >
                Amount Settled
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "24px",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"center"}
                >
                  Settled Amount : -
                </Typography>
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"center"}
                >
                  {currencySymbol}
                  {data?.pendingSettlementAmount}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "24px",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"center"}
                >
                  Company Name : -
                </Typography>
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"center"}
                >
                  {data?.partnerCompanyName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "24px",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"center"}
                >
                  Date : -
                </Typography>
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"center"}
                >
                  {data?.lastSettlementDate}
                </Typography>
              </Box>

              {/* Button */}
              <Button
                sx={{
                  mt: "12px",
                  width: "150px",
                  background: "#333",
                  color: "#fff",
                  fontFamily: "mySecondFont",
                  ":hover": {
                    background: "#333",
                  },
                }}
                onClick={handleCloseModal}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ConfirmSettleModal;
