// import React from 'react';
// import Box from '@mui/material/Box';
// import HeatMap from 'react-heatmap-grid';

// const AlertsHeatMap = ({alertStatsTotalHeatData}) => {
//   console.log("jjhh", alertStatsTotalHeatData)
//   // New dummy data for the heatmap
//   const data = [
//     [12, 15, 10, 5, 20, 30, 5, 8, 2],  // Critical
//     [8, 10, 5, 15, 18, 25, 6, 10, 4],  // High
//     [5, 7, 3, 10, 12, 15, 3, 5, 2],   // Medium
//     [3, 4, 2, 6, 8, 10, 2, 4, 1],     // Low
//   ];

//   // X-axis labels
//   const xLabels = ['STATIONS', 'CHARGING_SESSIONS','OCPI', 'APP', 'OCPP', 'HUB', 'PAYMENTS', 'COMPANIES'];

//   // Y-axis labels
//   const yLabels = ['Critical', 'High', 'Medium', 'Low'];

//   // Define visibility for x-axis labels (every 2nd label visible in this example)
//   const xLabelsVisibility = new Array(xLabels.length).fill(false);
//   xLabelsVisibility[0] = true; // Show first label
//   xLabelsVisibility[1] = true; // Show third label
//   xLabelsVisibility[2] = true; // Show fifth label
//   xLabelsVisibility[3] = true; // Show seventh label
//   xLabelsVisibility[4] = true; // Show seventh label
//   xLabelsVisibility[5] = true; // Show seventh label
//   xLabelsVisibility[6] = true; // Show seventh label
//   xLabelsVisibility[8] = true; // Show seventh label
//   xLabelsVisibility[9] = true; // Show seventh label
//   xLabelsVisibility[10] = true; // Show seventh label
//   xLabelsVisibility[11] = true; // Show seventh label

//   return (
//     <Box sx={{ width: '100%', maxWidth: 1000, padding: 2 }}>
//       <HeatMap
//         xLabels={xLabels}
//         yLabels={yLabels}
//         data={data}
//         xLabelsLocation={"bottom"}  // Position labels at the bottom
//         xLabelsVisibility={xLabelsVisibility}  // Control which xLabels are visible
//         xLabelWidth={60}  // Width for each x-axis label
//         yLabelWidth={100}  // Width for each y-axis label
//         cellHeight={100}  // Height of each cell
//         cellWidth={40}  // Width of each cell
//         background={'#fff'}  // Background color
//         colorScheme={['#224181', '#1A84AD', '#0DCEBD', '#B7EBB2', '#E7FF91']}  // Custom color range for the cells
//         cellStyle={(background, value, min, max, data, x, y) => ({
//           background: `rgb(0, 151, 230, ${1 - (max - value) / (max - min)})`,  // Dynamic background color based on value
//           fontSize: '11.5px',
//           color: '#444',
//         })}
//         cellRender={value => value && <div>{value}</div>}  // Render the cell values inside the cells
//         onClick={(x, y) => alert(`Clicked on ${xLabels[x]}, ${yLabels[y]}: ${data[y][x]}`)}  // Handle cell clicks
//       />
//     </Box>
//   );
// };

// export default AlertsHeatMap;

import React from "react";

import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const AlertsHeatMap = ({ data }) => {
  // Early return if data is not available
  if (!data || !data.result || !data.result.combinedCount) {
    return (
      <Paper elevation={2} sx={{ width: "100%", maxWidth: "1200px", p: 3 }}>
        <Typography variant="body1">No data available</Typography>
      </Paper>
    );
  }

  // Extract unique categories and severities
  const categories = [
    ...new Set(data.result.combinedCount.map((item) => item.category)),
  ].sort();
  const severities = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];

  // Create a mapping object for easy lookup
  const countMap = {};
  data.result.combinedCount.forEach((item) => {
    countMap[`${item.severity}-${item.category}`] = item.count;
  });

  const getColor = (count) => {
    if (count > 50) return "#224181";
    if (count > 40) return "#1A84AD";
    if (count > 30) return "#0DCEBD";
    if (count > 20) return "#B7EBB2";
    return "#E7FF91";
  };

  const categoryTotals = data.result.categoryCount;
  const severityTotals = data.result.severityCount;

  return (
    <Box elevation={2} sx={{ width: "100%", maxWidth: "1200px" }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "none", fontWeight: "bold" }}
              ></TableCell>
              {categories.map((category) => (
                <TableCell
                  key={category}
                  align="center"
                  sx={{
                    backgroundColor: "none",
                    color: "#2F2F2F",
                    textAlign: "center",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    lineHeight: "16px",
                  }}
                >
                  {category}
                </TableCell>
              ))}
              {/* Add Total column */}
              <TableCell
                align="center"
                sx={{
                  backgroundColor: "none",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {severities.map((severity) => (
              <TableRow key={severity}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "none",
                    color: "#2F2F2F",
                    textAlign: "left",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    lineHeight: "16px",
                    width: "70px",
                  }}
                >
                  {severity}
                </TableCell>
                {categories.map((category) => {
                  const count = countMap[`${severity}-${category}`] || 0;
                  const backgroundColor = getColor(count);
                  const isBackgroundDark =
                    backgroundColor === "#224181" ||
                    backgroundColor === "#1A84AD";

                  return (
                    <TableCell
                      key={`${severity}-${category}`}
                      align="center"
                      sx={{
                        backgroundColor,
                        transition: "background-color 0.3s",
                        height: "6px",
                        p: 1,
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        lineHeight: "16px",
                        border: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {count >= 0 && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: isBackgroundDark ? "white" : "text.primary",
                          }}
                        >
                          {count}
                        </Typography>
                      )}
                    </TableCell>
                  );
                })}

                {/* Add Total cell for this severity row */}
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "none",
                    color: "#2F2F2F",
                    textAlign: "center",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    lineHeight: "16px",
                    width: "70px",
                  }}
                >
                  {categories.reduce((total, category) => {
                    return total + (countMap[`${severity}-${category}`] || 0);
                  }, 0)}
                </TableCell>
              </TableRow>
            ))}

            {/* Total row */}
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  backgroundColor: "none",
                  color: "#2F2F2F",
                  textAlign: "left",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "70px",
                }}
              >
                Total
              </TableCell>
              {categories.map((category) => {
                const total = categoryTotals[category] || 0;
                return (
                  <TableCell
                    key={`total-${category}`}
                    align="center"
                    sx={{
                      backgroundColor: "none",
                      color: "#2F2F2F",
                      textAlign: "center",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      lineHeight: "16px",
                      width: "70px",
                    }}
                  >
                    {total}
                  </TableCell>
                );
              })}
              {/* Add Total column for the totals */}
              <TableCell
                align="center"
                sx={{
                  backgroundColor: "none",
                  color: "#2F2F2F",
                  textAlign: "center",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "16px",
                  width: "70px",
                }}
              >
                {Object.values(severityTotals).reduce(
                  (sum, count) => sum + count,
                  0
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Color Legend */}
      <Box sx={{ mt: 4, display: "flex", gap: 2, flexWrap: "wrap", alignItems:"center" , justifyContent:"center"}}>
        <Typography
          sx={{
            backgroundColor: "none",
            color: "#2F2F2F",
            textAlign: "left",
            fontFamily: "myThirdFont",
            fontSize: "12px",
            lineHeight: "16px",
            width: "70px",
          }}
        >
          No Of Alerts
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div
            style={{ width: 20, height: 20, backgroundColor: "#224181" }}
          ></div>
          <Typography variant="caption">&gt; 50</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div
            style={{ width: 20, height: 20, backgroundColor: "#1A84AD" }}
          ></div>
          <Typography variant="caption">40-50</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div
            style={{ width: 20, height: 20, backgroundColor: "#0DCEBD" }}
          ></div>
          <Typography variant="caption">30-40</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div
            style={{ width: 20, height: 20, backgroundColor: "#B7EBB2" }}
          ></div>
          <Typography variant="caption">20-30</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div
            style={{ width: 20, height: 20, backgroundColor: "#E7FF91" }}
          ></div>
          <Typography variant="caption">0-20</Typography>
        </div>
      </Box>
    </Box>
  );
};

export default AlertsHeatMap;
