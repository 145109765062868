import React from 'react'

const AccountsCompanies = () => {
  return (
    <div>
      Companies Page
    </div>
  )
}

export default AccountsCompanies
