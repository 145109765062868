import { Box } from "@mui/material";
import React from "react";
import CustomerProgressBar from "../../Graphs/CustomerManagement/CustomerProgressBar.jsx";
import StatusChip from "../../Chips/StatusChip.jsx";

const AppSuperCustomers = ({ customerAppUsersForSubscribersData }) => {

  console.log("ghghhg",customerAppUsersForSubscribersData )
  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <span className="material-symbols-outlined">smartphone</span>
        <StatusChip text={"Live"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomerProgressBar
            dataType="appCustomers"
            data={customerAppUsersForSubscribersData}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomerProgressBar
            dataType="superCustomers"
            data={customerAppUsersForSubscribersData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AppSuperCustomers;
