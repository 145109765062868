import { Box, Typography } from "@mui/material";
import React from "react";
import CustomersDoughnutChart from "../../Graphs/CustomerManagement/CustomersDoughnutChart";
import StatusChip from "../../Chips/StatusChip";

const CustomerTotalUser = ({ totalUserStats }) => {
  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
        position: "relative",
      }}
    ><Box
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    }}
  >
    <StatusChip text={"Live"} />
  </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CustomersDoughnutChart
          data={totalUserStats}
          dataSet={"totalUsers"}
          centerText="Total Users"
          centerValue={totalUserStats?.totalUsers || 0}
          iconText={"groups"}
        />
      </Box>
    </Box>
  );
};

export default CustomerTotalUser;
