import { Box, Typography } from "@mui/material";
import React from "react";

const TransactionsCards = ({ title, value, positive }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        background:
          positive === true
            ? "rgba(2, 121, 0, 0.10)"
            : positive === false
            ? "rgba(138, 0, 0, 0.10)"
            : "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        width: "100%",
      }}
    >
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"16px"}
        color={
          positive === true ? "#027900" : positive === false ? "#8A0000" : ""
        }
      >
        {title}
      </Typography>
      <Typography
        fontFamily={"mySecondFont"}
        fontSize={"18px"}
        color={
          positive === true ? "#027900" : positive === false ? "#8A0000" : ""
        }
      >
        {value}
      </Typography>
    </Box>
  );
};

export default TransactionsCards;
