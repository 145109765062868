import React, { useCallback, useEffect, useState } from "react";
import { Box, MenuItem, FormControl, Select } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableTimeFilter from "../Filters/TableTimeFilter";
import TableSearchFilter from "../Filters/TableSearchFilter";
import TempTableTimeFilter from "../Filters/TempTableTimeFilter";

const TempTableFilters = ({
  data = [],
  tabTitle,
  isActive,
  orgId,
  chargingPointId,
  stationId,
  filters,
  setFilters,
}) => {
  return (
    <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
      {filters ? (
        <TableSearchFilter filters={filters} setFilters={setFilters} />
      ) : (
        ""
      )}
      {tabTitle && filters ? (
        <TempTableTimeFilter
          tabTitle={tabTitle}
          isActive={isActive}
          orgId={orgId}
          chargingPointId={chargingPointId}
          stationId={stationId}
          filters={filters}
          setFilters={setFilters}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default TempTableFilters;
