import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import AlertCountBoxDashboard from "../../dashboard/AlertCountBoxDashboard";
import Heading from "../../../utils/Heading";
import { MyRoutes } from "../../../routes/routes";

const PaymentAlertsBox = ({ data, alertCounts, isDash }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle "VIEW ALL" button click
  const handleViewButton = () => {
    navigate(MyRoutes.accounts_alerts);
  };

  console.log("fghfhf", data);

  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "8px",
        background: "#fff",
        height: "100%",
      }}
    >
      <Heading text={"Payment Alerts"} />
      <Divider sx={{ mt: "8px", color: "#ddd" }} />

      <AlertCountBoxDashboard data={alertCounts} isDash />

      {/* Alert cards */}
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "8px", mt: "8px" }}
      >
        {/* Loop through the first 3 alerts */}
        {data?.slice(0, 3).map((item, index) => {
          // Extract the necessary fields
          const { alertName, deviceId, station, severity } = item;
          const { name: stationName } = station;

          const isCritical = severity === "CRITICAL";

          const borderColor = isCritical ? "#e50000" : "#E86F00";
          const backgroundColor = isCritical
            ? "rgba(229, 0, 0, 0.05)"
            : "rgba(232, 111, 0, 0.05)";
          const icon = isCritical ? "warning" : "info";

          return (
            <Box
              key={index}
              sx={{
                borderRadius: "4px",
                borderLeft: `1px solid ${borderColor}`,
                background: backgroundColor,
                padding: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <span
                    style={{ color: borderColor, fontWeight: "200" }}
                    className="material-symbols-outlined"
                  >
                    {icon} {/* Dynamic icon based on severity */}
                  </span>
                  <Typography
                    fontFamily={"myFirstFont"}
                    fontSize={"14px"}
                    color={"#1C1C1C"}
                    textAlign={"left"}
                  >
                    {alertName} {/* Displaying the alert name */}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  gap: "4px",
                  flexDirection: "column",
                }}
              >
                <Typography
                  textAlign={"start"}
                  color={"#2F2F2F"}
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                >
                  {`Device: ${deviceId}`} {/* Displaying deviceId */}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontSize={"14px"}
                  fontFamily={"mySecondFont"}
                  pr={"4px"}
                >
                  {`Station: ${stationName}`} {/* Displaying station name */}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Typography
          color={"#222"}
          fontFamily="'Montserrat', sans-serif"
          fontWeight={500}
          mt={"16px"}
          letterSpacing={"0.96px"}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={handleViewButton}
        >
          VIEW ALL
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentAlertsBox;
