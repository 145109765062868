import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyRoutes } from "../../routes/routes";
import { defaultRoles } from "../../utils/Roles";
import TempCustomTable from "../custom/TempCustomTable";
import TempTableFilters from "../charging-sessions/TempTableFilters";
import { useDispatch } from "react-redux";
import { fetchStationChargingPoint } from "../../redux/action/stations/stationSlice";

const ChargingPointBox = ({
  columns,
  isRowClickable,
  stationDataGetOne,
  hubSession,
  stationId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredChPoints, setFilteredChPoints] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    limit: 10,
    startTime: null,
    endTime: null,
  });

  const user = useSelector((state) => state?.profile?.profile);

  const hubStationSession = location.pathname.includes("/hub_management");

  const { stationChargingPoint, loading, error } = useSelector(
    (state) => state.stations
  );

  const handlePageChange = () => {
    // navigate("/add_new_charging_point");
    navigate(MyRoutes.addNewChargingPoint, {
      state: {
        CpStationData: {
          id: stationDataGetOne?._id,
          name: stationDataGetOne?.name,
        },
      },
    });
  };

  useEffect(() => {
    if (stationId) {
      dispatch(
        fetchStationChargingPoint({
          stationId: stationId,
          ...filters,
        })
      );
    }
  }, [dispatch, stationId, filters]);

  if (!stationChargingPoint?.data) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {filters ? (
          <TempTableFilters
            data={stationChargingPoint?.data || []}
            filters={filters}
            setFilters={setFilters}
            tabTitle={"StationChargingPoints"}
            stationId={stationId}
          />
        ) : (
          ""
        )}

        {defaultRoles?.includes(user?.role) ? (
          <Button
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "120px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "'Montserrat', sans-serif",
              textWrap: "nowrap",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#222",
              },
            }}
            onClick={handlePageChange}
          >
            + ADD NEW
          </Button>
        ) : (
          ""
        )}
      </Box>

      {filters ? (
        <TempCustomTable
          columns={columns}
          rows={stationChargingPoint?.data || []}
          totalCounts={stationChargingPoint?.totalCounts || 0}
          filters={filters}
          setFilters={setFilters}
          isRowClickable={isRowClickable}
          actionType={
            defaultRoles?.includes(user?.role)
              ? // "copy-edit-delete"
                "copy-edit"
              : "copy"
          }
          rowClickPath={"/charging_stations"}
          clickableColumns={["name"]}
          modalHeading={"Charging Points"}
          loading={loading}
          columnClickPaths={{
            name: hubStationSession
              ? "/hub_management/charging_points"
              : "/charging_stations/charging_points",
          }}
          columnKeyMappings={{
            name: "_id", // Use 'sessionID' key for 'sessionID' column
          }}
          statsHeading={"true"}
          sortColumn={sortColumn}
          hubSession={hubSession}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default ChargingPointBox;
