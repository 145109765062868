import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ActiveSession from "./sessions/ActiveSession";
import AllSession from "./sessions/AllSession";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  ActiveSessionsColumns,
  AllSessionsColumns,
  chargingPointsAllSessionsColumns,
  chargingPointsDetailsActiveSessionsColumns,
  chargingPointsDetailsAllSessionsColumns,
  chargingPointsDetailsSevenDaysSessionsColumns,
  chargingPointsSevenDaysAndAllSessionsColumns,
  chargingPointsSevenDaysSessionsColumns,
  sessionDetailsSessionsColumns,
} from "../../utils/DummyData";
import CustomTabs from "../custom/CustomTabs";
import { fetchOneSessionData } from "../../redux/action/chargingPointSlice/chargingPointSlice";

const SessionsBox = ({ chargingPointId }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    limit: 10,
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    setFilters({
      search: "",
      page: 1,
      limit: 10,
      startTime: null,
      endTime: null,
    });
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === 1) {
      const now = new Date();
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - 6);
      const startTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
      const endTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

      setFilters((prev) => ({
        ...prev,
        startTime: startTime,
        endTime: endTime,
      }));
    }
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const { sessionDetail, loading } = useSelector(
    (state) => state.chargingPoints
  );
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const hubStationSession = location.pathname.includes("/hub_management");

  useEffect(() => {
    const activeCheck = selectedTab === 0 ? true : false;
    setIsActive(activeCheck);
    dispatch(
      fetchOneSessionData({
        chargingPointId,
        hubStationSession,
        isActive: activeCheck,
        ...filters,
      })
    );
  }, [dispatch, selectedTab, filters]);

  const tabsData = [
    {
      label: "Active Sessions",
      content: (
        <ActiveSession
          columns={chargingPointsDetailsActiveSessionsColumns()}
          rows={sessionDetail?.data || []}
          totalCounts={sessionDetail?.totalCounts || 0}
          filters={filters}
          setFilters={setFilters}
          isActive={isActive}
          hubStationSession={hubStationSession}
        />
      ),
    },
    {
      label: "Last 7 Days Sessions",
      content: (
        <AllSession
          chargingPointId={chargingPointId}
          columns={chargingPointsDetailsSevenDaysSessionsColumns()}
          rows={sessionDetail?.data || []}
          totalCounts={sessionDetail?.totalCounts || 0}
          filters={filters}
          setFilters={setFilters}
          isActive={isActive}
          hubStationSession={hubStationSession}
          tabTitle={"Last7DaysSessions"}
        />
      ),
    },
    {
      label: "All Sessions",
      content: (
        <AllSession
          chargingPointId={chargingPointId}
          columns={chargingPointsDetailsAllSessionsColumns()}
          rows={sessionDetail?.data || []}
          totalCounts={sessionDetail?.totalCounts || 0}
          filters={filters}
          setFilters={setFilters}
          isActive={isActive}
          hubStationSession={hubStationSession}
          tabTitle={"ChargingPointSessions"}
        />
      ),
    },
  ];

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      {/* Custom Tabs */}
      <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />

      {/* Tab content displayed below the tabs */}
      <Box sx={{ mt: 3 }}>{tabsData[selectedTab].content}</Box>
    </Box>
  );
};

export default SessionsBox;
