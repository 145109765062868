import React from "react";
import { Box, Grid } from "@mui/material";
import TimeUpCard from "../chargingPointsDetails/Overview/TimeUpCard";
import AlertBoxCard from "../chargingPointsDetails/Overview/AlertBoxCard";
import DirectSession from "../charging_stations_details/Overview/DirectSession";
import TopGraph from "../chargingPointsDetails/Overview/TopGraph";
import TopPerformStationBox from "../dashboard/TopPerformStationBox";

const OverviewBox = ({
  data,
  selectedTime,
  startTime,
  endTime,
  previousDashboardData,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item md={12} spacing={2}>
              <TopGraph
                data={data}
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            </Grid>
            <Grid item md={12}>
              <DirectSession
                data={data}
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
                tempProp={"Ocpi"}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TimeUpCard
                data={data}
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
              />
            </Grid>
            <Grid item md={12}>
              <AlertBoxCard />
            </Grid>
            <Grid item md={12}>
              <TopPerformStationBox  type={"ocpi"}/>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default OverviewBox;
