import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Divider, Grid } from "@mui/material";
import Heading from "../../../utils/Heading"; // Import the modal component
import ResolveAlertModal from "../../Modals/AlertsAndFaults/ResolveAlertModal";
import StatusChip from "../../Chips/StatusChip";
import { useScreenshot, createFileName } from "use-react-screenshot";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Active" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

// Utility function to format date and time
const formatDateTime = (isoDateString) => {
  const date = new Date(isoDateString);

  // Format date as "dd-mm-yyyy"
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Format time as "hh:mm:ss AM/PM"
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
};

const AlertsDetails = ({ logData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [alertData, setAlertData] = useState(logData);

  // Create ref to capture the component
  const detailsRef = useRef(null);

  // Screenshot hook
  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    setAlertData(logData);
  }, [logData]);

  const commentsToShow = logData?.commentedBy?.slice(0, 3) || [];

  // Open and close modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAlertData(null);
  };

  // Function to clear alert data after resolution
  const handleAlertResolved = () => {
    setAlertData(null); // Clear the data when the alert is resolved
  };

  // Download function for screenshot
  const download = (image) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName("jpg", "alert-details");
    a.click();
  };

  const handleDownload = () => {
    takeScreenshot(detailsRef.current).then(download);
  };

  if (!logData) {
    return (
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#666"}
        textAlign={"center"}
        mt={10}
      >
        Select an alert to view details
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: "16px" }} ref={detailsRef}>
      {" "}
      {/* Set ref here */}
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/ev_charger_icon.png" alt="" />
            <Heading text={"Alert details"} />
          </Box>
          <Button
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            onClick={handleDownload}
          >
            <span class="material-symbols-outlined">download</span>
          </Button>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <InfoBox label={"Device ID"} value={logData?.deviceId} />

        <InfoBox label={"Alert name"} value={logData?.alertName} />
        <InfoBox label={"Station Name"} value={logData?.station?.name} />
        <InfoBox
          label="Session ID"
          value={logData?.sessionId ? logData?.sessionId : "N/A"}
        />

        <Grid container spacing={2}>
          <Grid item md={6}>
            <InfoBox label={"Alert code"} value={logData?.alertCode} />
          </Grid>
          <Grid item md={6}>
            <InfoBox label={"Category"} value={logData?.category} />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Typography
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
            color={"#2F2F2F"}
            textAlign={"start"}
            mb={"8px"}
          >
            Last Resolution Comments
          </Typography>

          {commentsToShow.length > 0 ? (
            commentsToShow.map((comment, index) => (
              <Box key={index} sx={{ marginBottom: 1 }}>
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"16px"}
                  color={"#2F2F2F"}
                  textAlign={"start"}
                  mb={"8px"}
                  lineHeight={"20px"}
                >
                  {comment.comment}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    fontFamily={"myThirdFont"}
                    fontSize={"12px"}
                    color={"#2F2F2F"}
                    textAlign={"start"}
                    mb={"8px"}
                    lineHeight={"20px"}
                  >
                    {formatDateTime(comment?.createdAt)}
                  </Typography>
                  <Typography
                    fontFamily={"myThirdFont"}
                    fontSize={"12px"}
                    color={"#2F2F2F"}
                    textAlign={"start"}
                    mb={"8px"}
                    lineHeight={"20px"}
                  >
                    {comment?.user?.name}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography>No comments available.</Typography>
          )}
        </Box>

        <Button
          variant="contained"
          color="error"
          sx={{ marginTop: 2 }}
          onClick={handleOpenModal}
        >
          Resolve Alert
        </Button>
      </Box>
      {/* Pass logData to the modal and control its visibility */}
      <ResolveAlertModal
        open={openModal}
        onClose={handleCloseModal}
        row={logData}
        onAlertResolved={handleAlertResolved}
      />
    </Box>
  );
};

export default AlertsDetails;
