import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import TempCustomTable from "../custom/TempCustomTable";
import TempTableFilters from "./TempTableFilters";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  fetchAllSessions,
  fetchAllSessionsApi,
} from "../../redux/action/sessions/sessionsSlice";
import { useDispatch } from "react-redux";

const TableBox = ({
  columns,
  rows = [],
  isRowClickable,
  loading,
  totalCounts,
  selectedTab,
  isAllSessions,
  tabTitle,
  isActive,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();

  const myFiltersForExcel = [
    { value: "station", title: "Station Name" },
    { value: "sessionDuration", title: "Session Duration" },
    { value: "energyConsumed", title: "Energy (kWh)" },
    { value: "enteredAmount", title: "Entered Amount" },
    { value: "chargingAmount", title: "Charging Amount" },
    { value: "from", title: "Mode" },
    { value: "deviceId", title: "Device ID" },
    { value: "connectorId", title: "Connector ID" },
    { value: "transactionId", title: "Transaction ID" },
    { value: "_id", title: "Session ID" },
    { value: "sessionStart", title: "Start Time" },
  ];

  const downloadExcel = async () => {
    // Fetch all data if necessary
    const allData =
      totalCounts > rows.length
        ? await fetchAllSessionsApi({ page: 1, limit: totalCounts })
        : rows || [];

    console.log("allData", allData);

    // Dynamically extract all columns from the data
    const filteredData = allData?.data?.map((item, index) => ({
      sr: index + 1, // Add serial number
      ...item, // Spread all columns from the item
    }));

    // Create a workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "ActiveSessions");

    // Download the file
    XLSX.writeFile(wb, "activeSessions.xlsx");
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {filters ? (
          <TempTableFilters
            data={rows}
            tabTitle={tabTitle}
            isActive={isActive}
            filters={filters}
            setFilters={setFilters}
          />
        ) : (
          ""
        )}

        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Button
            sx={{
              padding: "8px 12px",
              border: "1px solid rgba(34, 34, 34, 0.50)",
              borderRadius: "4px",
              color: "#2F2F2F",
              fontFamily: "mySecondFont",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={downloadExcel}
          >
            <img
              style={{ marginRight: "4px" }}
              src="/images/excel.png"
              alt=""
            />
            Excel
            <span
              style={{
                fontSize: "16px",
                fontWeight: "400",
                marginLeft: "4px",
              }}
              className="material-symbols-outlined"
            >
              download
            </span>
          </Button>
        </Box>
      </Box>

      {filters ? (
        <TempCustomTable
          columns={columns}
          rows={rows}
          isRowClickable={isRowClickable}
          actionType={isAllSessions ? "download" : null}
          clickableColumns={["_id"]}
          columnClickPaths={{
            _id: "/charging_sessions",
          }}
          columnKeyMappings={{
            _id: "_id", // Use 'sessionID' key for 'sessionID' column
          }}
          modalHeading={"Charging Session"}
          loading={loading}
          totalCounts={totalCounts}
          filters={filters}
          setFilters={setFilters}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default TableBox;
