import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SemiDoughnutChart from "../Graphs/SemiDoughnutChart";
import ValueChip from "../Chips/ValueChip";
import { useSelector } from "react-redux";
import SessionProgressBar from "../Graphs/SessionProgressBar";

const TotalSession = ({
  data,
  previousDashboardData,
  selectedTime,
  loading,
}) => {
  const { profile } = useSelector((state) => state.profile);

  // Calculate comparison values
  const compareDirectSession =
    data?.totalSessionCount_Upi - previousDashboardData?.totalSessionCount_Upi;
  const compareAppSession =
    data?.totalSessionCount_App - previousDashboardData?.totalSessionCount_App;
  const compareHubSession =
    data?.totalSessionCount_Guard -
    previousDashboardData?.totalSessionCount_Guard;
  const compareRFIDSession =
    data?.totalSessionCount_Rfid -
    previousDashboardData?.totalSessionCount_Rfid;
  const compareOCPISession =
    data?.totalSessionCount_Ocpi -
    previousDashboardData?.totalSessionCount_Ocpi;

  // Calculate total sessions for SemiDoughnut chart
  const compareTotalSessionForSemiGraph =
    data?.totalSessionCount - previousDashboardData?.totalSessionCount;

  const totalSessions = data?.totalSessionCount;

  const userRole = profile?.role;

  const segments = [
    {
      name: "Direct",
      color: "#FF9F69",
      value: (data?.totalSessionCount_Upi / totalSessions) * 100,
    },
    {
      name: "App",
      color: "#69A5FF",
      value: (data?.totalSessionCount_App / totalSessions) * 100,
    },
    {
      name: "Hub",
      color: "#FF6969",
      value: (data?.totalSessionCount_Guard / totalSessions) * 100,
    },
    {
      name: "RFID",
      color: "#1AC95D",
      value: (data?.totalSessionCount_Rfid / totalSessions) * 100,
    },
    {
      name: "OCPI",
      color: "#AB6AF0",
      value: (data?.totalSessionCount_Ocpi / totalSessions) * 100,
    },
  ];

  // const basicSegments = [
  //   {
  //     name: "Direct",
  //     color: "#FF9F69",
  //     value: (data?.totalSessionCount_Upi / totalSessions) * 100,
  //   },
  //   {
  //     name: "App",
  //     color: "#69A5FF",
  //     value: (data?.totalSessionCount_App / totalSessions) * 100,
  //   },
  // ];

  const segmentData = segments.map((segment) => ({
    name: segment.name,
    value: segment.value || 0,
    color: segment.color,
  }));

  // const basicSegmentData = segments.map((segment) => ({
  //   name: segment.name,
  //   value: segment.value || 0,
  //   color: segment.color,
  // }));

  const formatValue = (value) => {
    if (value === null || value === undefined) {
      return "N/A";
    }
    const valueStr = String(value); // Convert value to string
    if (valueStr.startsWith("-")) {
      return valueStr;
    }
    return `+${valueStr}`;
  };

  const overallCompareColor =
    compareTotalSessionForSemiGraph < 0 ? "#790000" : "#027900";

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  return (
    <>
      {/* <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
          height: "100%", // Ensure parent container adapts to content
          position: "relative",
        }}
      >
        <SemiDoughnutChart data={segmentData} />

        {/* INSIDE DONUT GRAPH CONTENT */}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "50%",
          top: "22%",
          transform: "translate(-50%, -50%)",
          gap: "8px",
        }}
      >
        <ValueChip
          title={"Total Sessions"}
          data={data}
          previousDashboardData={previousDashboardData}
        />
        <Box>
          <Typography
            sx={{
              color: "#666",
              textAlign: "center",
              fontFamily: "myThirdFont",
              fontSize: { xs: "12px", sm: "14px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16px",
            }}
          >
            Total Sessions
          </Typography>
          <Typography
            sx={{
              color: "#2F2F2F",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: { xs: "20px", sm: "24px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "32px",
            }}
          >
            {totalSessions}
          </Typography>
        </Box>

        <Typography
          fontFamily={"myThirdFont"}
          color={"#666"}
          fontWeight={"400"}
          fontSize={"12px"}
        >
          <span
            style={{ color: overallCompareColor, fontFamily: "mySecondFont" }}
          >
            {formatValue(compareTotalSessionForSemiGraph)}
          </span>{" "}
          than {getComparisonText()}
        </Typography>
      </Box> */}
      <Grid
        container
        columnGap={5}
        sx={{
          padding: "24px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <Grid item={3} sx={{ width: "10%" }}>
          <SessionProgressBar ratingData={segments} data={data} />
        </Grid>
        <Grid item={9} sx={{ width: "70%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#FDDA0D", // Direct session color
                    borderRadius: "50%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    Direct Session
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                  >
                    {data?.totalSessionCount_Upi}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "4px",
                }}
              >
                <Typography
                  sx={{
                    color: "#666",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "myThirdFont",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  {selectedTime === "today" ||
                  selectedTime === "year" ||
                  selectedTime === "currentYear" ? (
                    ""
                  ) : (
                    <ValueChip
                      title={"Direct Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  )}
                </Typography>
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareDirectSession),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareDirectSession)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#69A5FF", // App session color
                    borderRadius: "50%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    App Session
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                  >
                    {data?.totalSessionCount_App}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "4px",
                }}
              >
                <Typography
                  sx={{
                    color: "#666",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "myThirdFont",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  {selectedTime === "today" ||
                  selectedTime === "year" ||
                  selectedTime === "currentYear" ? (
                    ""
                  ) : (
                    <ValueChip
                      title={"App Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  )}
                </Typography>
                {selectedTime === "today" ||
                selectedTime === "year" ||
                selectedTime === "currentYear" ? (
                  ""
                ) : (
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareAppSession),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareAppSession)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                )}
              </Box>
            </Box>

            {/* hub - management */}
            {(userRole === "Superadmin" ||
              userRole === "1C_Admin" ||
              userRole === "1C_User") && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#1AC95D",
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#666",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "myThirdFont",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        Hub Session
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F2F2F",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                          textAlign: "left",
                        }}
                      >
                        {data?.totalSessionCount_Guard}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      {selectedTime === "today" ||
                      selectedTime === "year" ||
                      selectedTime === "currentYear" ? (
                        ""
                      ) : (
                        <ValueChip
                          title={"Hub Sessions"}
                          data={data} // Passing the whole data object
                          previousDashboardData={previousDashboardData} // Pass previous data
                        />
                      )}
                    </Typography>
                    {selectedTime === "today" ||
                    selectedTime === "year" ||
                    selectedTime === "currentYear" ? (
                      ""
                    ) : (
                      <Typography
                        fontFamily={"myThirdFont"}
                        color={"#666"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        <span
                          style={{
                            color: getCompareColor(compareHubSession),
                            fontFamily: "mySecondFont",
                          }}
                        >
                          {formatValue(compareHubSession)}
                        </span>{" "}
                        than {getComparisonText()}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}

            {/* RFID Sesssion Box */}
            {(userRole === "Superadmin" || userRole === "1C_Admin") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#FF6969", // App session color
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      RFID
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_Rfid}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    {selectedTime === "today" ||
                    selectedTime === "year" ||
                    selectedTime === "currentYear" ? (
                      ""
                    ) : (
                      <ValueChip
                        title={"RFID"}
                        data={data} // Passing the whole data object
                        previousDashboardData={previousDashboardData} // Pass previous data
                      />
                    )}
                  </Typography>
                  {selectedTime === "today" ||
                  selectedTime === "year" ||
                  selectedTime === "currentYear" ? (
                    ""
                  ) : (
                    <Typography
                      fontFamily={"myThirdFont"}
                      color={"#666"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                    >
                      <span
                        style={{
                          color: getCompareColor(compareRFIDSession),
                          fontFamily: "mySecondFont",
                        }}
                      >
                        {formatValue(compareRFIDSession)}
                      </span>{" "}
                      than {getComparisonText()}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}

            {/* OCPI Sesssion Box */}
            {(userRole === "Superadmin" || userRole === "1C_Admin") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#AB6AF0", // App session color
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      OCPI
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_Ocpi}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    {selectedTime === "today" ||
                    selectedTime === "year" ||
                    selectedTime === "currentYear" ? (
                      ""
                    ) : (
                      <ValueChip
                        title={"OCPI"}
                        data={data} // Passing the whole data object
                        previousDashboardData={previousDashboardData} // Pass previous data
                      />
                    )}
                  </Typography>
                  {selectedTime === "today" ||
                  selectedTime === "year" ||
                  selectedTime === "currentYear" ? (
                    ""
                  ) : (
                    <Typography
                      fontFamily={"myThirdFont"}
                      color={"#666"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                    >
                      <span
                        style={{
                          color: getCompareColor(compareOCPISession),
                          fontFamily: "mySecondFont",
                        }}
                      >
                        {formatValue(compareOCPISession)}
                      </span>{" "}
                      than {getComparisonText()}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalSession;
