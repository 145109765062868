import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import CustomersDoughnutChart from "../../Graphs/CustomerManagement/CustomersDoughnutChart";

const OcpiSessionTotalBox = ({ superChargerCustomer, data }) => {
  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
        border: "1px solid #ddd",
      }}
    >
      <Grid container spacing={2} display={"flex"} alignItems={"center"}>
        <Grid item md={8}>
          <CustomersDoughnutChart
            data={data}
            centerText={"Total sessions"}
            dataSet={"ocpiSessions"}
            centerValue={data?.graphData?.totalSessions}
            iconText={"battery_charging_90"}
          />
        </Grid>
        <Grid item md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "12px",
                  background: "#69A5FF",
                }}
              ></Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  fontSize={"14px"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  OCPI ({data?.graphData?.totalOcpiSessions}/
                  {data?.graphData?.totalOcpiSessionPercentage}%)
                </Typography>
   
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "12px",
                  background: "#E41E26",
                }}
              ></Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  fontSize={"14px"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  OCPP ({data?.graphData?.totalOcppSessions}/
                  {data?.graphData?.totalOcppSessionPercentage}%)
                </Typography>
     
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcpiSessionTotalBox;
