import { Box, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const Dropbox = ({
  acceptedFiles,
  setFiles,
  setFormData,
  files = [],
  onDrop,
  acceptedFilesType,
  disabled,
  fieldName,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 6,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      // Check if there are any rejections due to file type
      if (fileRejections.length > 0) {
        toast("File type not accepted. Please upload an image.");
      } else {
        // Check the file size - if it's more than 5MB (5MB = 5 * 1024 * 1024 bytes)
        const maxSize = 5 * 1024 * 1024; // 5MB
        const largeFiles = acceptedFiles.filter((file) => file.size > maxSize);

        if (largeFiles.length > 0) {
          toast.error("File size exceeds 5MB. Please upload a smaller file.");
        } else {
          // Proceed with setting the files and form data
          if (setFiles) {
            setFiles(acceptedFiles);
          }
          if (setFormData) {
            setFormData((prevData) => ({
              ...prevData,
              [fieldName || "companyLogo"]: acceptedFiles[0],
            }));
          }
          if (onDrop) {
            onDrop(acceptedFiles);
          }
        }
      }
    },
    accept: { ...acceptedFilesType },
    disabled: disabled, // Disable dropzone if the condition is met
  });

  // Ensure files is always an array
  const safeFiles = Array.isArray(files) ? files : [files].filter(Boolean);

  // Render file list for multiple files
  const fileList =
    safeFiles.length > 0
      ? safeFiles.map((file) => (
          <li key={file.path}>
            {file.path} - {file.size} bytes
          </li>
        ))
      : "";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box
        sx={{
          width: "100%",
          height: "160px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px dashed #292D33",
          borderRadius: "4px",
          padding: "32px 18px",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <span
              style={{
                fontSize: "48px",
                color: disabled ? "#C0C0C0" : "#E50000",
                fontWeight: "300",
                display: "flex",
                justifyContent: "center",
              }}
              className="material-symbols-outlined"
            >
              cloud_upload
            </span>
            <Typography
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
              color={"#2f2f2f"}
              sx={{ textWrap: "wrap" }}
            >
              Drop your images here or select
            </Typography>
            <Typography
              color={"#E50000"}
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
              sx={{ textDecoration: "underline" }}
              textAlign={"center"}
            >
              click to browse
            </Typography>
          </div>
        </section>
      </Box>
      <Box>
        <aside>
          <ul style={{ textAlign: "left" }}>{fileList}</ul>
        </aside>
      </Box>
    </Box>
  );
};

export default Dropbox;
