import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import AlertsCustomTable from "../../custom/AlertsCustomTable";
import NewSearchFilterBox from "../../Filters/NewSearchFilterBox";

const SettlementsTableBox = ({ column, row, onRowClick }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData, rowIndex) => {
    setSelectedRow(rowIndex);
    if (onRowClick) {
      onRowClick(rowData);
    }
  };
  return (
    <Box sx={{ flex: 1, overflowY: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          justifyContent: "space-between",
          mb: "12px",
        }}
      >
        <NewSearchFilterBox />
        <Button
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            background: "#333",
            padding: "12px",
            borderRadius: "4px",
            height: "40px",
            fontFamily: "mySecondFont",
            fontSize: "14px",
            textTransform: "uppercase",
            cursor: "pointer",
            color: "#fff",
            ":hover": {
              background: "#333",
            },
          }}
        >
          Export<span className="material-symbols-outlined">file_export</span>
        </Button>
      </Box>
      <AlertsCustomTable
        columns={column}
        rows={row}
        isRowClickable={true}
        onRowClick={handleRowClick}
        selectedRow={selectedRow}
        modalHeading="Settlements"
      />
    </Box>
  );
};

export default SettlementsTableBox;
