import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import StatusChip from "../../Chips/StatusChip";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";
import CustomerProgressBar from "../../Graphs/CustomerManagement/CustomerProgressBar.jsx";

const WalletStats = ({ data }) => {
  const currencySymbol = GetCurrencySymbol();
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading text={"Wallet stats"} />
        <StatusChip text={"Live"} />
      </Box>
      <Divider sx={{ mt: "8px", mb: "12px" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              background: "rgba(41, 45, 51, 0.08);",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "300" }}
              className="material-symbols-outlined"
            >
              account_balance_wallet
            </span>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography color={"#666"} fontFamily={"myThirdFont"}>
              Current wallet balance
            </Typography>
            <Typography
              color={"#1C1C1C"}
              fontSize={"clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem)"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
              textAlign={"start"}
              textTransform={"capitalize"}
              sx={{ textWrap: "nowrap" }}
            >
              {currencySymbol}
              {data?.currentWalletBalance.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              background: "rgba(41, 45, 51, 0.08);",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "300" }}
              className="material-symbols-outlined"
            >
              account_balance_wallet
            </span>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography color={"#666"} fontFamily={"myThirdFont"}>
              Avg. balance per customer
            </Typography>
            <Typography
              color={"#1C1C1C"}
              fontSize={"18px"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
              textAlign={"start"}
              sx={{ textWrap: "nowrap" }}
            >
              {currencySymbol}
              {data?.avgBalancePerCustomer.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              background: "rgba(41, 45, 51, 0.08);",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "300" }}
              className="material-symbols-outlined"
            >
              avg_time
            </span>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography color={"#666"} fontFamily={"myThirdFont"}>
              Avg. recharge frequency
            </Typography>
            <Typography
              color={"#1C1C1C"}
              fontSize={"18px"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
              textAlign={"start"}
              sx={{ textWrap: "nowrap" }}
            >
              {data?.avgRechargeFrequency}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                background: "#79000026",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{ fontWeight: "300", color: "#790000" }}
                className="material-symbols-outlined"
              >
                remove
              </span>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography color={"#666"} fontFamily={"myThirdFont"}>
                Negative Wallets
              </Typography>
              <Typography
                color={"#1C1C1C"}
                fontSize={"18px"}
                fontFamily="'Montserrat', sans-serif"
                fontWeight={600}
                textAlign={"start"}
                sx={{ textWrap: "nowrap" }}
              >
                {data?.negetiveBalance?.count} ({currencySymbol}
                {data?.negetiveBalance?.amount.toFixed(2)})
              </Typography>
            </Box>
          </Box>
          {/* <span
            style={{ fontWeight: 300 }}
            className="material-symbols-outlined"
          >
            arrow_forward
          </span> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomerProgressBar dataType="walletStats" data={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default WalletStats;
