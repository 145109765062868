import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../../routes/routes";

const TableBox = ({ columns, rows, isRowClickable, orgCsData }) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState(null);
  const handlePageChange = () => {
    navigate(MyRoutes.addNewChargingStation, {
      state: {
        CsOrgData: {
          id: orgCsData?._id,
          name: orgCsData?.name,
        },
      },
    });
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          tabTitle={"CompanyChargingStations"}
          orgId={orgCsData?._id}
        />
        <Button
          onClick={handlePageChange}
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "142px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            textWrap: "nowrap",
            ":hover": {
              background: "#222",
            },
          }}
        >
          + ADD NEW
        </Button>
      </Box>
      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        clickableColumns={["name"]}
        columnClickPaths={{
          name: "/companies_management/charging_stations",
        }}
        columnKeyMappings={{
          name: "_id", // Use 'sessionID' key for 'sessionID' column
        }}
        // actionType={"edit-delete"}
        actionType={"edit"}
        statsHeading={"true"}
        modalHeading={"Charging Stations"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default TableBox;
