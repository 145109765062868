import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";


import { useDispatch, useSelector } from "react-redux";
import AlertsBox from "../../alerts_and_faults/AlertsBox";
import { alertsSevereColumnData, alertsSevereRowData } from "../../../utils/DummyData";

const PaymentsSevereAlertsBox = () => {
    const dispatch = useDispatch();
  
    return (
      <Box
  
      >
        <AlertsBox
          column={alertsSevereColumnData}
          data={alertsSevereRowData}
          alertType="critical"
          // alertCountsForTable={getAlertCount("CRITICAL")}
          modalHeading={"Critical Alerts"}
        />
      </Box>
    );
  };

export default PaymentsSevereAlertsBox

