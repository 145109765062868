import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import CustomerProgressBar from "../../Graphs/CustomerManagement/CustomerProgressBar.jsx";

const TotalEnergyBox = ({data}) => {

  console.log("hhhgggg", data)
  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
        border: "1px solid #ddd",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "4px", alignItems:"center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(41, 45, 51, 0.08)",
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              marginRight: "8px",
            }}
          >
            <span
              style={{ color: "#292D33", fontWeight: 300 }}
              className="material-symbols-outlined"
            >
              bolt
            </span>
          </Box>

          <Box sx={{ display: "flex", alignItems: "left" }}>
            <Typography
              sx={{
                color: "#2F2F2F",
                fontFamily: "myFourthFont",
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              Total Energy:{" "}
              <span
                style={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "18px",
                  lineHeight: "24px",
                }}
              >
                {data?.graphData?.totalEnergyConsumed?.toFixed(2)} kWh
              </span>
            </Typography>
          </Box>
        </Box>

        <CustomerProgressBar dataType="ocpiSessionStats" flagType={"remove"} data={data}/>
      </Box>
    </Box>
  );
};

export default TotalEnergyBox;
