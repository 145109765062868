import React from 'react'
import AlertsOrderComponent from './AlertsOrderComponent'
import { Box, Divider } from '@mui/material'
import Heading from '../../../utils/Heading'

const HubManagementAlerts = ({totalType, alertsByGroup}) => {

  const hubData = alertsByGroup?.HUB || {};
  console.log("1111qqqq", hubData);

    return (
        <Box  sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            padding: "24px",
            background: "#fff",
            borderRadius: "8px",
          }}>
              <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img src="/images/ev_charger_icon.png" alt="" />
                <Heading text={"Hub Management alerts"} />
              </Box>
            </Box>
    
            <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
          </Box>
          <AlertsOrderComponent totalType={totalType} data={hubData}/>
        </Box>
      )
}

export default HubManagementAlerts
