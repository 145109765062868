import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, Button, Slide } from "@mui/material";
import dayjs from "dayjs"; // Ensure you have dayjs installed
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  getAllChargingPasses,
  getAllGuards,
  getAllHubSessions,
  getAllPassHolders,
  getAllStationsHub,
  getAllTransaction,
  getShiftCollection,
} from "../../redux/action/hubManagement/hubManagementSlice";
import { useDispatch } from "react-redux";
import { useStationContext } from "../../Hooks/StationContext";
import { useTimeContext } from "../../Hooks/TimeContext";
import { fetchAllSessions } from "../../redux/action/sessions/sessionsSlice";
import {
  fetchAllStations,
  fetchStationChargingPoint,
  fetchStationSessions,
} from "../../redux/action/stations/stationSlice";
import {
  fetchChargingPoints,
  fetchOneSessionData,
} from "../../redux/action/chargingPointSlice/chargingPointSlice";
import { getAllPayments } from "../../redux/action/payments/paymentSlice";
import {
  getAllOrg,
  getCMChargingStation,
} from "../../redux/action/companyManagement/companyManagementSlice";
import { getTariffs } from "../../redux/action/tariff/createTariffSlice";
import { fetchAllUsers } from "../../redux/action/adminManagement/adminSlice";

const TempTableTimeFilter = ({
  tabTitle,
  isActive,
  orgId,
  chargingPointId,
  stationId,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const maxDate = dayjs();
  const last7DaysStartDate = maxDate.subtract(6, "day");

  // Function to dispatch API calls based on the current tab and time filters
  const resetData = () => {
    const startMillis = startTime ? startTime.valueOf() : null;
    const endMillis = endTime ? endTime.valueOf() : null;

    if (startMillis && endMillis) {
      if (tabTitle === "Last7DaysSessions") {
        const now = new Date();
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - 6);
        const startTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
        const endTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

        setFilters((prev) => ({
          ...prev,
          startTime: startTime,
          endTime: endTime,
        }));
      } else {
        setFilters((prev) => ({
          ...prev,
          startTime: null,
          endTime: null,
        }));
      }
    } else {
      console.log("Please select both start and end dates.");
    }

    // switch (tabTitle) {
    //   case "ChargingSessions":
    //     dispatch(
    //       fetchAllSessions({
    //         ...filters,
    //         isActive: isActive,
    //       })
    //     );
    //     break;
    //   case "Last7DaysSessions":
    //     dispatch(
    //       fetchAllSessions({
    //         ...filters,
    //         isActive: isActive,
    //       })
    //     );
    //     break;
    //   case "ChargingStations":
    //     dispatch(fetchAllStations(timeFilter));
    //     break;
    //   case "ChargingPoints":
    //     dispatch(fetchChargingPoints(timeFilter));

    //     break;
    //   case "AllPayments":
    //     dispatch(getAllPayments(timeFilter));

    //     break;
    //   case "CompanyManagement":
    //     dispatch(getAllOrg(timeFilter));

    //     break;

    //   case "TariffManagement":
    //     dispatch(getTariffs(timeFilter));

    //     break;

    //   case "AdminManagement":
    //     dispatch(fetchAllUsers(timeFilter));
    //     break;

    //   case "CompanyChargingStations":
    //     dispatch(getCMChargingStation({ orgId }));
    //     break;

    //   case "ChargingPointSessions":
    //     dispatch(fetchOneSessionData({ chargingPointId, isActive }));
    //     break;

    //   case "Last7DaysChargingPointSessions":
    //     dispatch(fetchOneSessionData({ chargingPointId, isActive }));
    //     break;

    //   case "ChargingStationSessions":
    //     dispatch(fetchStationSessions({ stationId, isActive: isActive }));
    //     break;

    //   case "Last7DaysChargingStationSessions":
    //     dispatch(
    //       fetchStationSessions({
    //         stationId: stationId,
    //         isActive: isActive,
    //         startTime: last7DaysStartTime,
    //         endTime: last7DaysEndTime,
    //       })
    //     );
    //     break;

    //   case "StationChargingPoints":
    //     dispatch(fetchStationChargingPoint({ stationId, ...timeFilter }));
    //     break;

    //   default:
    //     console.log("Unknown tab title.");
    // }
  };

  // Handles the 'APPLY' button click
  const handleApply = () => {
    const startMillis = startTime ? startTime.valueOf() : null;
    const endMillis = endTime ? endTime.valueOf() : null;

    if (startMillis && endMillis) {
      setFiltersApplied(true);
      setFilters((prev) => ({
        ...prev,
        startTime: startMillis,
        endTime: endMillis,
      }));
    } else {
      console.log("Please select both start and end dates.");
    }
  };

  // Handles the 'RESET' button click
  // const handleReset = () => {
  //   setStartTime(null);
  //   setEndTime(null);
  //   setFiltersApplied(false);

  //   if (tabTitle === "Last7DaysSessions") {
  //     setStartTime(last7DaysStartDate.startOf("day"));
  //     setEndTime(maxDate.endOf("day"));
  //   }
  //   setFilters((prev) => ({
  //     ...prev,
  //     startTime: null,
  //     endTime: null,
  //   }));

  //   resetData(); // Fetch data without any filters
  // };

  const handleReset = () => {
    // Clear start and end time
    setStartTime(null);
    setEndTime(null);
    setFiltersApplied(false);
  
    // Reset filters for Last7DaysSessions tab
    if (tabTitle === "Last7DaysSessions") {
      // Reset to the default date range for Last7DaysSessions
      setStartTime(null);
      setEndTime(null);
    }
  
    // Reset filters
    setFilters((prev) => ({
      ...prev,
      startTime: null,
      endTime: null,
    }));
  
    // Fetch data without any filters
    resetData();
  };
  

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="From"
          value={startTime}
          maxDate={tabTitle === "Last7DaysSessions" ? maxDate : undefined}
          minDate={
            tabTitle === "Last7DaysSessions" ? last7DaysStartDate : undefined
          }
          onChange={(newValue) => {
            console.log("New Start Time:", newValue); // Log new start time
            setStartTime(newValue);
            // If the new start date is after the current end date, reset it
            if (newValue && endTime && newValue.isAfter(endTime)) {
              setEndTime(null);
            }
          }}
          sx={{
            width: "144px",
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiInputAdornment-root": {
              height: "48px",
            },
          }}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="To"
          value={endTime}
          maxDate={maxDate}
          onChange={(newValue) => {
            console.log("New End Time:", newValue); // Log new end time
            setEndTime(newValue);
          }}
          minDate={startTime} // Set minimum date for the end date
          sx={{
            width: "144px",
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiInputAdornment-root": {
              height: "48px",
            },
          }}
        />
      </LocalizationProvider>

      {/* Show Reset Button only when filters are applied */}
      {filtersApplied && (
        <Slide direction="down" in={filtersApplied} mountOnEnter unmountOnExit>
          <Button
            sx={{
              padding: "8px 12px",
              width: "70px",
              borderRadius: "4px",
              fontSize: "12px",
              color: "#790000",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleReset} // Reset filters on click
          >
            <RestartAltIcon />
            RESET
          </Button>
        </Slide>
      )}

      <Button
        sx={{
          background: "#222",
          padding: "8px 24px",
          width: "120px",
          height: "42px",
          borderRadius: "4px",
          color: "#FCFCFC",
          fontFamily: "mySecondFont",
          letterSpacing: "1.12px",
          ":hover": {
            background: "#222",
          },
          "&.Mui-disabled": {
            backgroundColor: "#555",
            color: "#999",
            cursor: "not-allowed",
            opacity: 0.6,
          },
        }}
        onClick={handleApply}
      >
        APPLY
      </Button>
    </Box>
  );
};

export default TempTableTimeFilter;
