import { Box, Divider } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import VehicleDetailsTable from "./VehicleDetailsTable";

const VehicleDetailsBox = ({ data }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            page_info
          </span>
          <Heading text={"Vehicle Details"} />
        </Box>
        <Divider sx={{ mt: "8px", mb: "16px" }} />
      </Box>
      <Box>
        <VehicleDetailsTable data={data} />
      </Box>
    </Box>
  );
};

export default VehicleDetailsBox;
