import { Box } from "@mui/material";
import React from "react";
import DoughnutCharHub from "./DoughnutCharHub";
import CustomerProgressBar from "../CustomerManagement/CustomerProgressBar.jsx";

const VisitUserGraph = ({ data }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      {/* <DoughnutCharHub data={data} centerData="" title="Total User Visit" /> */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "56px" }}>
        <CustomerProgressBar dataType="totalUserVisit" data={data} />
        <CustomerProgressBar dataType="totalVehicleVisit" data={data} />
      </Box>
    </Box>
  );
};

export default VisitUserGraph;
