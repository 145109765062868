import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import CustomerValueCard from "../../cards/CustomerValueCard";
import ValueCard from "../../dashboard/ValueCard";
import LeftSideCustomerGraph from "./LeftSideCustomerGraph";
import DoubleStackedBarGraph from "../../Graphs/DoubleStackedBarGraph";
import CustomerDoubleStackedBarGraph from "../../Graphs/CustomerManagement/CustomerDoubleStackedBarGraph";

const CustomerTopGraphBox = ({
  data,
  selectedTime,
  customerManagement,
  startTime,
  endTime,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        background: "#fff",
        padding: "24px",
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={4} sx={12}>
          <LeftSideCustomerGraph
            data={data}
            selectedTime={selectedTime}
            customerManagement={customerManagement}
          />
        </Grid>
        <Grid item md={8} sx={12}>
          <CustomerDoubleStackedBarGraph
            data={data}
            selectedTime={selectedTime}
            startTime={startTime}
            endTime={endTime}
          />
          {/* <DoubleStackedBarGraph data={data} selectedTime={selectedTime} startTime={startTime} endTime={endTime} /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerTopGraphBox;
