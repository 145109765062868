import { Box, Grid } from "@mui/material";
import React from "react";
import AlertCountBox from "./OverviewAlerts/AlertCountBox";
import CMSAlertsBox from "./OverviewAlerts/CMSAlertsBox";
import EvAppAlerts from "./OverviewAlerts/EvAppAlerts";
import HubManagementAlerts from "./OverviewAlerts/HubManagementAlerts";
import ResolvedUnresolvedBox from "./OverviewAlerts/ResolvedUnresolvedBox";
import AlertsMiddleLineGraphBox from "./OverviewAlerts/AlertsMiddleLineGraphBox";
import AvgResolutionTimeAlerts from "./OverviewAlerts/AvgResolutionTimeAlerts";
import HeatMapBox from "./OverviewAlerts/HeatMapBox";

const OverviewBox = ({
  alertCounts,
  alertsByGroup,
  alertsByAvgResolution,
  data,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <AlertCountBox data={alertCounts} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <ResolvedUnresolvedBox />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <AlertsMiddleLineGraphBox />
        </Grid>
        <Grid item md={4}>
          <AvgResolutionTimeAlerts
            totalType={"Avg. resolution time"}
            alertsByAvgResolution={alertsByAvgResolution}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <CMSAlertsBox
            totalType={"CMS"}
            alertsByGroup={alertsByGroup}
          />
        </Grid>
        <Grid item md={4}>
          <EvAppAlerts
            totalType={"EV 1C App Stats"}
            alertsByGroup={alertsByGroup}
          />
        </Grid>
        <Grid item md={4}>
          <HubManagementAlerts
            totalType={"Hub Management"}
            alertsByGroup={alertsByGroup}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          
          <HeatMapBox/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBox;
