import { Box, Grid } from "@mui/material";
import React from "react";
import BasicDetailBox from "./BasicDetailBox";
import SessionDetailsBox from "./SessionDetailsBox";
import WalletDetailsBox from "./WalletDetailsBox";
import RFIDDetails from "./RFIDDetails";
import VehicleDetailsBox from "./VehicleDetailsBox";
import OcpiSessionDetailsBox from "./OcpiSessionDetailsBox";

const CustomerDetailsBox = ({ data }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Top - graph - component */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <BasicDetailBox data={data} />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <SessionDetailsBox data={data} />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <WalletDetailsBox data={data} />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <RFIDDetails data={data} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box
                sx={{
                  padding: "16px",
                  background: "#fff",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                <VehicleDetailsBox data={data} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box
                sx={{
                  padding: "16px",
                  background: "#fff",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                <OcpiSessionDetailsBox data={data} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerDetailsBox;
