import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeader from "../../components/FinanceManager/AcountsDashboard/TopHeader";
import AlertBox from "../../components/dashboard/AlertBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";
import PaymentAlertsBox from "../../components/FinanceManager/AcountsDashboard/PaymentAlertsBox";
import TopGraphAccountBox from "../../components/FinanceManager/AcountsDashboard/TopGraphAccountBox";
import RefundDataBox from "../../components/FinanceManager/AcountsDashboard/RefundDataBox";
import WalletBalanceBox from "../../components/FinanceManager/AcountsDashboard/WalletBalanceBox";
import RequiredActionBox from "../../components/FinanceManager/AcountsDashboard/RequiredActionBox";

const AccountsDashboard = () => {
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const { profile } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  useScrollToTop(navigate);

  // //added for previous time
  // const [prevStartTime, setPrevStartTime] = useState(null);
  // const [prevEndTime, setPrevEndTime] = useState(null);

  console.log("dhdhhdhdhd", startTime, endTime)

  return (
    <>
      <TopHeader
        setDurationType={setDurationType}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
      />

      {/* dashboard */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Top Graph and alert box */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopGraphAccountBox />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <PaymentAlertsBox 
              // data={topAlerts}
              // alertCounts={alertCounts}
              // isDash={true}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Device Status and Cards */}
        <Grid
          container
          columnSpacing={"16px"}
          rowSpacing={"16px"}
          sx={{ height: "100%" }}
        >
          <Grid item md={6} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <RefundDataBox />
            </Box>
          </Grid>

          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <WalletBalanceBox />
            </Box>
          </Grid>

          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <RequiredActionBox />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountsDashboard;
