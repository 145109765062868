import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const data = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const DoubleBarGraph = ({
  data,
  barGraphType,
  startTime,
  endTime,
  selectedTime,
}) => {
  console.log("ghhghghgg", data);

  //with today as distributed data
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];

    // Extract the year dynamically from the data
    const extractedYear = (() => {
      if (data?.appUserGraphData?.length > 0) {
        const time = data.appUserGraphData[0]?.time;
        // Ensure time exists and is a string
        if (typeof time === "string" && time.includes("-")) {
          const parts = time.split("-");
          return parts[2]; // Assuming the year is the third part
        }
      }
      // Default to current year (last two digits)
      return dayjs().format("YY");
    })();

    if (selectedTime === "last7Days") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "currentMonth") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "currentYear") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    }  else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format(`DD-MM-${extractedYear}`),
          label: currentDate.format(`DD-MM-${extractedYear}`),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "yesterday") {
      // Generate hour-wise data for yesterday
      for (let i = 0; i < 24; i++) {
        const hour = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .add(i, "hour")
          .format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    if (selectedTime === "today" || selectedTime === "yesterday") {
      return dates.map(({ date, label }) => {
        const hourData =
          data?.data.find(
            (item) => item.time === parseInt(label.split(":")[0])
          ) || {};

        const totalRevenue = hourData.total_revenue || 0;
        const avgDuration = hourData.avg_duration || 0;

        return {
          date: label,
          totalRevenue: totalRevenue === 0 ? 0 : totalRevenue?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }
    if (selectedTime === "last7Days") {
      return dates.map(({ date, label }) => {
        const dayData = data?.data.find((item) => item.time === label) || {};

        const totalRevenue = dayData.total_revenue || 0;
        const avgDuration = dayData.avg_duration || 0;

        return {
          date: label, // Day of the week label (Mon, Tue, etc.)
          totalRevenue: totalRevenue === 0 ? 0 : totalRevenue?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }
    if (selectedTime === "currentMonth") {
      return dates.map(({ date, label }) => {
        const dayData =
          data?.data.find((item) => item.time === parseInt(label)) || {};

        const totalRevenue = dayData.total_revenue || 0;
        const avgDuration = dayData.avg_duration || 0;

        return {
          date: label, // Day of the month as label
          totalRevenue: totalRevenue === 0 ? 0 : totalRevenue?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }
    if (selectedTime === "currentYear") {
      return dates.map(({ date, label }) => {
        const monthData = data?.data.find((item) => item.time === label) || {};

        const totalRevenue = monthData.total_revenue || 0;
        const avgDuration = monthData.avg_duration || 0;

        return {
          date: label, // Month name (e.g., "Jan", "Feb", ...)
          totalRevenue: totalRevenue === 0 ? 0 : totalRevenue?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }

    // Custom Range Logic
    if (selectedTime === "customRange") {
      return dates.map(({ date, label }) => {
        const dateData = data?.data.find((item) => item.time === label) || {};

        const totalRevenue = dateData.total_revenue || 0;
        const avgDuration = dateData.avg_duration || 0;

        return {
          date: label, // The specific date label (e.g., "21-11-24")
          totalRevenue: totalRevenue === 0 ? 0 : totalRevenue?.toFixed(2),
          avgDuration: avgDuration === 0 ? 0 : avgDuration?.toFixed(2),
        };
      });
    }

    return [];
  };

  const chartData = getData();

  return (
    <div style={{ width: "100%", height: "300px" }}>
      <ResponsiveContainer>
        <BarChart
          data={chartData} // Use the generated chartData here
          margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date" // Use 'date' for X-axis
            stroke="#AFAFAF"
            tick={{ fontSize: 14, fontFamily: "mySecondFont", fill: "#666" }}
          />
          <YAxis
            yAxisId="left"
            stroke="#AFAFAF"
            tick={{ fontSize: 14, fontFamily: "mySecondFont", fill: "#666" }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#AFAFAF"
            tick={{ fontSize: 14, fontFamily: "mySecondFont", fill: "#666" }}
          />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="totalRevenue" // Bar 1: totalRevenue
            fill={barGraphType === "chargingOne" ? "#7F73C6" : "#609CF6"}
            yAxisId="left"
          />
          <Bar
            dataKey="avgDuration" // Bar 2: avgDuration
            fill={barGraphType === "chargingOne" ? "#FF5454" : "#FF9254"}
            yAxisId="right"
            barSize={30}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DoubleBarGraph;
