import React, { useState } from "react";
import TimeFilter from "../../Filters/TimeFilter";
import { Box, Grid } from "@mui/material";
import AlertsCustomTimeFilter from "../../Filters/AlertsCustomTimeFilter";
import ValueCard from "../../dashboard/ValueCard";
import AlertsDataBox from "./AlertsDataBox";
import MiddleTopGraphBox from "./MiddleTopGraphBox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAlertsOverallData } from "../../../redux/action/alertsSlice/alertsSlice";
import { useEffect } from "react";

const ResolvedUnresolvedBox = () => {
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const dispatch = useDispatch();

    // Access state from the Redux store
    const { alertsOverallData, status, error, loading } = useSelector(
      (state) => state.alerts
    );
    console.log("alertsHis65454tory", alertsOverallData);

  useEffect(() => {
    if (durationType) {
      dispatch(fetchAlertsOverallData(durationType));
    }
  }, [durationType, dispatch]);

  console.log("tttttttttttttt", durationType);


  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={2.5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              gap:"16px",
              width:"100%"
            }}
          >
            <Box>
              <AlertsCustomTimeFilter
                setDurationType={setDurationType}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                setPrevStartTime={setPrevStartTime}
                setPrevEndTime={setPrevEndTime}
              />
            </Box>
            <Box>
              <ValueCard
                // data={data}
                // previousDashboardData={previousDashboardData}
                data={alertsOverallData}
                title="Alert count"
                topGraphBox="true"
                selectedTime={durationType}
                updatedComparisonText={true}
                chipType={"alerts"}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <MiddleTopGraphBox data={alertsOverallData}/>
        </Grid>
        <Grid item md={3.5}>
          <Box>
            <AlertsDataBox
            data={alertsOverallData} 
              selectedTime={durationType}
              // startTime={startTime}
              // endTime={endTime}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResolvedUnresolvedBox;
