// redux/action/chargingPoint/chargingPointSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  chargingPoints: [],
  chargingPointsCount: 0,
  chargingPointDetail: null,
  chargingPointHeartbeat: null,
  logs: [],
  connectors: [],
  liveLogs: [],
  connectorQrCode: null,
  sessionDetail: null,
  qrCode: null,
  loading: false,
  error: null,
  chargingPointStatsData: null,
  chargingPointStatus: null,
  getUpDownOneCharger: null,
};

// Create async thunks for API calls
export const createChargingPoint = createAsyncThunk(
  "chargingPoints/createChargingPoint",
  async (chargingPointData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.createChargingPoint,
        chargingPointData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChargingPoints = createAsyncThunk(
  "chargingPoints/fetchChargingPoints",
  async (
    {
      page = 1,
      limit = 10,
      search: chargingPointName,
      chargingPointType,
      startTime,
      endTime,
      filters,
    },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");

      const payload = Object.fromEntries(
        Object.entries({
          chargingPointName,
          chargingPointType,
          startTime,
          endTime,
          filters,
        }).filter(([_, value]) => value != null) // Exclude null and undefined
      );

      const formattedUrl = `${endPoints.getChargingPointWithFilters}?offset=${page}&limit=${limit}`;

      const response = await axios.post(formattedUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteChargingPoint = createAsyncThunk(
  "chargingPoints/deleteChargingPoint",
  async (chargingPointId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${endPoints.deleteChargingPoint}/${chargingPointId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return chargingPointId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateChargingPoint = createAsyncThunk(
  "chargingPoints/updateChargingPoint",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateChargingPoint}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// New async thunk for fetching a single charging point
export const fetchOneChargingPoint = createAsyncThunk(
  "chargingPoints/fetchOneChargingPoint",
  async (chargingPointId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneChargingPoint}/${chargingPointId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// New async thunk for fetching a single charging point heartbeat
export const fetchOneChargingPointHearthbeat = createAsyncThunk(
  "chargingPoints/fetchOneChargingPointHearthbeat",
  async (deviceId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneChargingPointHeartbeat}/${deviceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChargingPointConnectors = createAsyncThunk(
  "chargingPoints/fetchChargingPointConnectors",
  async (chargingPointId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getChargingPointConnectors}/${chargingPointId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startChargingPoint = createAsyncThunk(
  "chargingPoints/startChargingPoint",
  async (startChargingValue, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.startChargingConnector,
        startChargingValue,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// New async thunk for stopping charging connector
export const stopChargingConnector = createAsyncThunk(
  "chargingPoints/stopChargingConnector",
  async (stopChargingData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.stopChargingConnector,
        stopChargingData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update Connector
export const updateConnector = createAsyncThunk(
  "chargingPoints/updateConnector",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.connectorUpdate}/${payload?.deviceId}`,
        { availability: payload?.availability, status: payload?.status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOneSessionData = createAsyncThunk(
  "chargingPoints/fetchOneSessionData",
  async (
    {
      chargingPointId,
      page = 1,
      limit = 10,
      search: deviceId,
      isActive,
      startTime,
      endTime,
      filters,
      hubStationSession,
    },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");

      const payload = Object.fromEntries(
        Object.entries({
          deviceId,
          isActive,
          startTime,
          endTime,
          filters,
        }).filter(([_, value]) => value != null) // Exclude null and undefined
      );

      const formattedUrl = `${endPoints.getOneSessionData}/${chargingPointId}?offset=${page}&limit=${limit}`;

      const response = await axios.post(formattedUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOneChargingPointLogs = createAsyncThunk(
  "chargingPoints/fetchOneChargingPointLogs",
  async ({ chargingPointId, page, limit, logsFilter }, { rejectWithValue }) => {
    const filter = "createdAt";
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneChargingPointLogs}/${chargingPointId}?page=${page}&limit=${limit}&filter=${logsFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChargingPointQr = createAsyncThunk(
  "chargingPoints/fetchChargingPointQr",
  async (deviceId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getChargingPointQr}/${deviceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// New async thunk for fetching charging point statistics
export const fetchChargingPointStatistics = createAsyncThunk(
  "chargingPoints/fetchChargingPointStatistics",
  async ({ chargingPointId, durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.cpOverviewData}/${chargingPointId}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      // Improved error handling
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const createConnector = createAsyncThunk(
  "chargingPoints/createConnector",
  async (connectorData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.createConnector,
        connectorData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Or whatever data your API returns
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete Connector

export const deleteConnector = createAsyncThunk(
  "chargingPoints/deleteConnector",
  async ({ connectorId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${endPoints.deleteConnector}/${connectorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return connectorId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChargingPointStatus = createAsyncThunk(
  "chargingPoints/fetchChargingPointStatus",
  async (chargingPointId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStatus}/${chargingPointId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createQRCode = createAsyncThunk(
  "chargingPoints/createQRCode",
  async (qrCodeData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(endPoints.createQRCode, qrCodeData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConnectorQr = createAsyncThunk(
  "chargingPoints/fetchConnectorQr",
  async ({ deviceId, connectorId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getConnectorQr}?deviceId=${deviceId}&connectorId=${connectorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "chargingPoints/deleteReview",
  async (reviewId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${endPoints.reviewDelete}/${reviewId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return reviewId; // Return the reviewId to identify which review was deleted
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// New async thunk for fetching live logs
export const fetchLiveLogs = createAsyncThunk(
  "chargingPoints/fetchLiveLogs",
  async (deviceId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.getLiveLogs}/${deviceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Configuration - API
export const changeConfiguration = createAsyncThunk(
  "chargingPoints/changeConfiguration",
  async (configData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.changeConfiguration}`,
        configData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Change Availability Configuration
export const changeAvailabilityConfiguration = createAsyncThunk(
  "chargingPoints/changeAvailabilityConfiguration",
  async (configData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.changeAvailabilityConfiguration,
        configData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Configuration Reset
export const configurationReset = createAsyncThunk(
  "chargingPoints/configurationReset",
  async (resetData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.configurationReset,
        resetData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Configuration Message Trigger
export const configurationMsgTrigger = createAsyncThunk(
  "chargingPoints/configurationMsgTrigger",
  async (messageData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.configurationMsgTrigger,
        messageData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Configuration Unlock Connector
export const configurationUnlockConnector = createAsyncThunk(
  "chargingPoints/configurationUnlockConnector",
  async (connectorData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.configurationUnlockConnector,
        connectorData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Configuration Firmware Update
export const configurationFirmware = createAsyncThunk(
  "chargingPoints/configurationFirmware",
  async (firmwareData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.configurationFirmware,
        firmwareData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Configuration Get Diagnostic
export const configurationGetDiagnostic = createAsyncThunk(
  "chargingPoints/configurationGetDiagnostic",
  async (diagnosticData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.configurationGetDiagnostic,
        diagnosticData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Configuration Stop Charging
export const configurationStopCharging = createAsyncThunk(
  "chargingPoints/configurationStopCharging",
  async (configData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.configurationStopCharging,
        configData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// one charger Uptime downtime
export const fetchOneChargerUpDownTimeData = createAsyncThunk(
  "stations/fetchOneChargerUpDownTimeData",
  async (
    { chargingPointId, durationType, startTime, endTime },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getUpDowntimeOfOneCharger}/${chargingPointId}/?startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create the slice
const chargingPointSlice = createSlice({
  name: "chargingPoints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createChargingPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createChargingPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPoints.push(action.payload);
      })
      .addCase(createChargingPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChargingPoints.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChargingPoints.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPoints = action.payload.data;
        state.chargingPointsCount = action.payload.totalCounts;
      })
      .addCase(fetchChargingPoints.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteChargingPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteChargingPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPoints = state.chargingPoints.filter(
          (point) => point._id !== action.payload
        );
      })
      .addCase(deleteChargingPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateChargingPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateChargingPoint.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.chargingPoints.findIndex(
          (point) => point._id === action.payload._id
        );
        if (index !== -1) {
          state.chargingPoints[index] = action.payload;
        }
      })
      .addCase(updateChargingPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneChargingPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneChargingPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPointDetail = action.payload || null; // Handle undefined
      })
      .addCase(fetchOneChargingPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneChargingPointHearthbeat.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneChargingPointHearthbeat.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPointHeartbeat = action.payload || null; // Handle undefined
      })
      .addCase(fetchOneChargingPointHearthbeat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChargingPointConnectors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChargingPointConnectors.fulfilled, (state, action) => {
        state.loading = false;
        state.connectors = action.payload;
      })
      .addCase(fetchChargingPointConnectors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(startChargingPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startChargingPoint.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.chargingPoints.findIndex(
          (point) => point._id === action.payload._id
        );
        if (index !== -1) {
          state.chargingPoints[index] = action.payload;
        }
      })
      .addCase(startChargingPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(stopChargingConnector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(stopChargingConnector.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the response from the stopChargingConnector API
        // For example, you might want to update the state or handle specific logic
        // Optionally, you can update the state here based on the API response
      })
      .addCase(stopChargingConnector.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateConnector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateConnector.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload._id) {
          const index = state.connectors.findIndex(
            (connector) => connector._id === action.payload._id
          );
          if (index !== -1) {
            state.connectors[index] = action.payload;
          }
        } else {
          console.error("Update connector payload is invalid:", action.payload);
        }
      })
      .addCase(updateConnector.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneSessionData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneSessionData.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionDetail = action.payload;
      })
      .addCase(fetchOneSessionData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneChargingPointLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneChargingPointLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.logs = action.payload;
      })
      .addCase(fetchOneChargingPointLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChargingPointQr.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChargingPointQr.fulfilled, (state, action) => {
        state.loading = false;
        state.qrCode = action.payload; // Store QR code data
      })
      .addCase(fetchChargingPointQr.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChargingPointStatistics.fulfilled, (state, action) => {
        state.chargingPointStatsData = action.payload;
        state.loading = false;
      })
      .addCase(fetchChargingPointStatistics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChargingPointStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createConnector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createConnector.fulfilled, (state, action) => {
        state.loading = false;
        state.connectors.push(action.payload); // Add the newly created connector to the state
      })
      .addCase(createConnector.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChargingPointStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChargingPointStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPointStatus = action.payload; // Adjust as needed
      })
      .addCase(fetchChargingPointStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createQRCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createQRCode.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the QR code creation response as needed
        // For example, store the QR code data in the state or update another part of the state
      })
      .addCase(createQRCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchConnectorQr.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConnectorQr.fulfilled, (state, action) => {
        state.loading = false;
        state.connectorQrCode = action.payload; // Store QR code data
      })
      .addCase(fetchConnectorQr.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming reviews are part of the state; adjust accordingly
        state.chargingPoints = state.chargingPoints.map((point) => {
          if (point._id === action.payload.chargingPointId) {
            return {
              ...point,
              reviews: point.reviews.filter(
                (review) => review._id !== action.payload.reviewId
              ),
            };
          }
          return point;
        });
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchLiveLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLiveLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.liveLogs = action.payload; // Assuming you add a `liveLogs` field to your state
      })
      .addCase(fetchLiveLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from the changeConfiguration API
        // For example, you might want to update some part of your state based on the response
      })
      .addCase(changeConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeAvailabilityConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeAvailabilityConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from changeAvailabilityConfiguration API
      })
      .addCase(changeAvailabilityConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(configurationReset.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurationReset.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from configurationReset API
      })
      .addCase(configurationReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(configurationMsgTrigger.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurationMsgTrigger.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from configurationMsgTrigger API
      })
      .addCase(configurationMsgTrigger.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(configurationUnlockConnector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurationUnlockConnector.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from configurationUnlockConnector API
      })
      .addCase(configurationUnlockConnector.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(configurationFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurationFirmware.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from configurationFirmware API
      })
      .addCase(configurationFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(configurationGetDiagnostic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurationGetDiagnostic.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from configurationGetDiagnostic API
      })
      .addCase(configurationGetDiagnostic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(configurationStopCharging.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurationStopCharging.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful response from configurationStopCharging API
      })
      .addCase(configurationStopCharging.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneChargerUpDownTimeData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneChargerUpDownTimeData.fulfilled, (state, action) => {
        state.loading = false;
        state.getUpDownOneCharger = action.payload;
      })
      .addCase(fetchOneChargerUpDownTimeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default chargingPointSlice.reducer;
