import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import AlertsBox from './AlertsBox'
import { alertsLowColumnData, alertsLowRowData } from '../../utils/DummyData'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { fetchAlertsOfSeverity } from '../../redux/action/alertsSlice/alertsSlice'

const LowAlertsBox = ({alertCounts}) => {

  const dispatch = useDispatch();
  const { lowAlerts, status, error, loading } = useSelector(
    (state) => state.alerts
  );

  console.log("khkhkhkhkh",lowAlerts )

  useEffect(() => {
    if (!lowAlerts) {
      dispatch(fetchAlertsOfSeverity("LOW"));
    }
  }, [dispatch, lowAlerts]); // Only re-run when criticalAlerts or status change

  console.log("aszzzz", alertCounts);
  const getAlertCount = (type) => {
    if (!alertCounts?.data || !Array.isArray(alertCounts.data)) {
      return 0;
    }
    const alert = alertCounts.data.find((item) => item._id === type);
    return alert ? alert.count : 0;
  };

  if (loading && !lowAlerts) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;

  return (
    <Box sx={{}}>
    <AlertsBox
      column={alertsLowColumnData}
      data={lowAlerts || []}
      alertType="low"
      alertCountsForTable={getAlertCount("LOW")}
      modalHeading={"Low Alerts"}
    />
  </Box>
  )
}

export default LowAlertsBox
