import { Box } from "@mui/material";
import React from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";

const FeedbackTableBox = ({ columns, rows, isRowClickable }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters />
      </Box>
      <CustomTable
        columns={columns}
        rows={rows}
        clickableColumns={["customer", "station"]}
        columnClickPaths={{
          customer: "/customer_management",
          station: "/charging_stations",
        }}
        columnKeyMappings={{
          customer: "userId", // Use 'sessionID' key for 'sessionID' column
          station: "stationId",
        }}
        actionType={"view"}
        isRowClickable={isRowClickable}
        modalHeading={"Feedbacks"}
        feedbackHeading={true}
      />
    </Box>
  );
};

export default FeedbackTableBox;
