import { Box, Grid } from "@mui/material";
import React from "react";
import RegisterInfoBox from "./Overview/RegisterInfoBox";
import AddTariffSnackBar from "../OcpiUiComponents/AddTariffSnackBar";

const OverviewBox = ({
  handshakeStatus,
  networkDetail,
  operatorId,
  deviceTimeEnergyStatsOneNetwork,
  get1cTimeEnergyStatsOneNetwork,
  deviceRevenueEnergyStatsOneNetwork,
  get1cRevenueEnergyStatsOneNetwork,
  selectedTime,
  startTime,
  endTime
}) => {
  console.log("zzzzaa", handshakeStatus);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}

      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12}>
          <RegisterInfoBox
            handshakeStatus={handshakeStatus}
            operatorId={operatorId}
            deviceTimeEnergyStatsOneNetwork={deviceTimeEnergyStatsOneNetwork}
            get1cTimeEnergyStatsOneNetwork={get1cTimeEnergyStatsOneNetwork}
            deviceRevenueEnergyStatsOneNetwork={deviceRevenueEnergyStatsOneNetwork}
            get1cRevenueEnergyStatsOneNetwork={get1cRevenueEnergyStatsOneNetwork}
            selectedTime={selectedTime}
            startTime={startTime}
            endTime={endTime}
            networkDetail={networkDetail}

          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBox;
