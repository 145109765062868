import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Heading from "../../../utils/Heading";
import StatusChip from "../../Chips/StatusChip";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import {
  disableCustomerReview,
  fetchCustomerFeedbackData,
  fetchCustomerReviewDetails,
} from "../../../redux/action/customerManagement/customerManagementSlice";
import { toast } from "react-toastify";
import { formatDateTime } from "../../../utils/FormatDateTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const labelStyle = {
  fontFamily: "myThirdFont",
  fontSize: "14px",
  color: "#2F2F2F", // Gray color
};

const valueStyle = {
  fontFamily: "mySecondFont",
  fontSize: "14px",
  color: "#2F2F2F", // Dark gray color
};

const label = { inputProps: { "aria-label": "Switch demo" } };

const CustomerFeedback = ({ open, handleClose, data }) => {
  const createdAt = data?.created_at ? formatDateTime(data.created_at) : {};
  console.log("checkkkkkkkkkkkkk", data);

  const [customerId, setCustomerId] = useState(null);
  const dispatch = useDispatch();

  // Local state for the switch
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setCustomerId(id);
    }
  }, []);

  // Initialize the switch state based on `isActive`
  useEffect(() => {
    if (data?.isActive === false) {
      setIsSwitchOn(true);
    } else {
      setIsSwitchOn(false);
    }
  }, [data?.isActive]);

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;

    setIsSwitchOn(isChecked);

    // Call the disableCustomerReview API on every toggle
    dispatch(
      disableCustomerReview({
        reviewId: data?._id || data?.reviewId,
      })
    )
      .unwrap()
      .then((response) => {
        // Show success toast based on the state of the switch
        if (isChecked) {
          toast.success("Review successfully disabled!");
        } else {
          toast.info("Review successfully enabled!");
        }
      })
      .catch((error) => {
        console.error("API call failed:", error); // Log the full error
        toast.error("Failed to update review status!");
      })
      .finally(() => {
        // Refetch the data in the finally block to make sure it's always called
        dispatch(fetchCustomerFeedbackData());
        dispatch(fetchCustomerReviewDetails({ customerId }));
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} onClick={(event) => event.stopPropagation()}>
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <span className="material-symbols-outlined">shield_person</span>
            <Heading text={"Review details"} />
          </Box>
          <span
            onClick={handleClose}
            style={{ cursor: "pointer" }}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>

        <Divider sx={{ mt: "8px" }} />

        {/* Details Section */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
            mt: 2,
            mb: 3,
          }}
        >
          <Box>
            <Typography sx={labelStyle}>Customer</Typography>
            <Typography sx={valueStyle}>{data?.userDetails?.name}</Typography>
          </Box>
          <Box>
            <Typography sx={labelStyle}>Contact No.</Typography>
            <Typography sx={valueStyle}>{data?.userDetails?.phone}</Typography>
          </Box>
          <Box>
            <Typography sx={labelStyle}>Reviewed on</Typography>
            <Box>
              <Typography
                fontSize={"16px"}
                fontFamily={"mySecondFont"}
                color={"#2F2F2F"}
                textAlign={"start"}
                textTransform={"capitalize"}
              >
                {createdAt.date}
              </Typography>
              <Typography
                fontSize={"12px"}
                fontFamily={"myThirdFont"}
                color={"#666"}
                textAlign={"start"}
              >
                {createdAt.time}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography sx={labelStyle}>Station</Typography>
            <Typography sx={valueStyle}>{data?.station}</Typography>
          </Box>
          {/* <Box>
            <Typography sx={labelStyle}>Transaction ID</Typography>
            <Typography sx={valueStyle}>dummy</Typography>
          </Box>
          <Box>
            <Typography sx={labelStyle}>Device ID</Typography>
            <Typography sx={valueStyle}>dummy</Typography>
          </Box> */}
        </Box>

        {/* Review Section */}
        <Box sx={{ mb: 2 }}>
          <Typography sx={labelStyle}>Review</Typography>
          <Box sx={{ width: "112px", mt: "16px", mb: "8px" }}>
            <StatusChip text={data?.rating} />
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              mt: 2,
              color: "#2F2F2F",
              fontFamily: "mySecondFont",
            }}
          >
            {data?.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              mt: "4px",
              color: "#2F2F2F",
              fontFamily: "myThirdFont",
            }}
          >
            {data?.description}
          </Typography>
        </Box>

        {/* Disable Button */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            mt: "80px",
          }}
        >
          <Typography fontFamily={"mySecondFont"}>Disable Button</Typography>
          <Switch
            {...label}
            checked={isSwitchOn}
            onChange={handleSwitchChange}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerFeedback;
