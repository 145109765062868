import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import OcpiCustomTable from "../../custom/OcpiCustomTable";
import {
  ocpiManageStationColumns,
  ocpiManageStationData,
} from "../../../utils/DummyData";
import OcpiAddNewModal from "../../Modals/OCPI/OcpiAddNewModal";
import { useDispatch } from "react-redux";
import {
  getAllOcpiLocationsList,
  getAssignedOcpiEvses,
} from "../../../redux/action/ocpiManagement/ocpiSlice";
import { useSelector } from "react-redux";

const OcpiManageMainTable = ({ operatorId, networkDetail, defaultTariffs }) => {
  const [filteredCmOrgs, setFilteredCmOrgs] = useState([]);
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddNewOcpiModal, setOpenAddNewOcpiModal] = useState(false);
  const [locations, setLocations] = useState([]); // Local state for locations

  const dispatch = useDispatch();

  const { assignedEvses, loading, error } = useSelector((state) => state.ocpi);

  console.log("object", assignedEvses);

  const fetchLocations = () => {
    if (operatorId) {
      dispatch(getAllOcpiLocationsList(operatorId))
        .unwrap()
        .then((data) => {
          setLocations(data); // Store the fetched locations
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
        });
    }
  };

  const fetchAssignedEvses = () => {
    if (operatorId) {
      dispatch(getAssignedOcpiEvses(operatorId));
    }
  };

  // Update the useEffect to fetch assigned EVSEs when operatorId changes
  // useEffect(() => {
  //   if (operatorId && !assignedEvses?.length) {
  //     // Only dispatch if `assignedEvses` is empty
  //     dispatch(getAssignedOcpiEvses(operatorId));
  //   }
  // }, [dispatch, operatorId, assignedEvses]);

  useEffect(() => {
    fetchAssignedEvses();
  }, [dispatch, operatorId]);

  const handleDeleteButton = () => {
    // Handle delete action
    isMultiDelete && alert("Delete selected stations");
  };

  const handleFilterChange = (filteredData) => {
    setFilteredCmOrgs(filteredData);
  };

  const handleMultiDeleteChange = (event) => {
    setIsMultiDelete(event.target.checked);
  };

  const handleRowSelectionChange = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
  };

  const myFilters = [
    {
      value: "name",
      title: "Company Name",
    },
    {
      value: "owner",
      title: "Session ID",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];

  const handleOpenAddNewOcpiModal = (e) => {
    // Handle add action
    e.stopPropagation();
    fetchLocations(); // Fetch locations when modal is opened
    setOpenAddNewOcpiModal(true);
  };


  const handleCloseAddNewOcpiModal = (e) => {
    e?.stopPropagation();
    setOpenAddNewOcpiModal(false);
  };
  return (
    <Box sx={{ borderRadius: "8px", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "12px",
        }}
      >
        {/* <TableFilters onFilterChange={handleFilterChange} filters={myFilters} /> */}
        {!isMultiDelete ? (
          <Button
            sx={{
              background: "#222",
              padding: "8px 16px",
              width: "147px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              fontSize: "14px",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#222",
              },
            }}
            onClick={handleOpenAddNewOcpiModal}
          >
            Add Stations
          </Button>
        ) : (
          <Button
            sx={{
              background: "#FFF",
              padding: "8px 16px",
              width: "156px",
              borderRadius: "4px",
              color: "#790000",
              fontFamily: "mySecondFont",
              fontSize: "14px",
              border: "1px solid #790000",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#222",
              },
            }}
            onClick={handleDeleteButton}
            disabled={selectedRows.length === 0} // Disable if no rows are selected
          >
            Delete Access
          </Button>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              color: "#2F2F2F",
              fontFamily: "myThirdFont",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Permitted Stations:{" "}
            <span style={{ fontFamily: "mySecondFont" }}>
              {networkDetail?.ocpiDetails?.stationsAssigned}
            </span>
          </Typography>
          <Typography
            sx={{
              color: "#2F2F2F",
              fontFamily: "myThirdFont",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Permitted Chargers:{" "}
            <span style={{ fontFamily: "mySecondFont" }}>
              {networkDetail?.ocpiDetails?.evsesAssigned}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "24px",
          }}
        >
          {/* <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMultiDelete}
                  onChange={handleMultiDeleteChange}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    lineHeight: "16px",
                  }}
                >
                  Multi-Delete
                </Typography>
              }
            />
          </Box> */}
        </Box>
      </Box>

      {/* Table */}
      <OcpiCustomTable
        columns={ocpiManageStationColumns}
        rows={assignedEvses}
        // rows={ocpiManageStationData}
        actionType={"edit"}
        isRowClickable={true}
        modalHeading={"OCPI Management"}
        columnKeyMappings={{
          name: "_id",
        }}
        specialColumns={["OcpiStationName"]}
        statsHeading={"false"}
        showCheckbox={isMultiDelete} // Pass prop to indicate whether to show checkbox
        onRowSelectionChange={handleRowSelectionChange}
        selectedRows={selectedRows} // Pass selected rows
        isSameHeading={true}
        operatorId={operatorId}
      />

      <OcpiAddNewModal
        open={openAddNewOcpiModal}
        handleClose={handleCloseAddNewOcpiModal}
        heading={"Ocpi Management"}
        locations={locations}
        operatorId={operatorId}
        onUpdateSuccess={fetchAssignedEvses}
        defaultTariffs={defaultTariffs}
      />
    </Box>
  );
};

export default OcpiManageMainTable;
