import React from "react";
import { Treemap, ResponsiveContainer } from "recharts";

// Function to determine box color based on size
const getColor = (size, maxSize) => {
  const minOpacity = 0.5; // Minimum opacity for the lightest box
  const maxOpacity = 1; // Maximum opacity for the darkest box
  const opacity = minOpacity + (size / maxSize) * (maxOpacity - minOpacity);
  return `rgba(104, 183, 249, ${opacity})`; // Progressive fade
};

// Function to determine text size based on size
const getTextSize = (size, maxSize) => {
  const minFontSize = 8;
  const maxFontSize = 16;
  return Math.max(
    minFontSize,
    Math.min(maxFontSize, (size / maxSize) * maxFontSize)
  );
};

const OCPITreeMap = ({ data }) => {
  const maxSize = Math.max(...data.map((item) => item.size)); // Get the maximum size for scaling

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <Treemap
          data={data}
          dataKey="size"
          nameKey="name"
          aspectRatio={4 / 3}
          stroke="#fff"
          content={({ depth, x, y, width, height, name, value }) =>
            depth === 1 ? (
              <g>
                {/* Rectangle with dynamic color */}
                <rect
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                  style={{
                    fill: getColor(value, maxSize),
                    stroke: "#fff",
                    strokeWidth: 4,
                  }}
                />
                {/* Name text in the top-left corner with dynamic size and color */}
                <text
                  x={x + 8}
                  y={y + 20}
                  textAnchor="start"
                  fontSize={getTextSize(value, maxSize)}
                  fill="#ffffff"
                  fontFamily="myFourthFont"
                >
                  {name}
                </text>
                {/* Value text in the center with dynamic size and color */}
                <text
                  x={x + width / 2}
                  y={y + height / 2 + 10}
                  textAnchor="middle"
                  fontSize="16"
                  fill="#fff"
                  fontFamily="myFourthFont"
                >
                  {value}%
                </text>
              </g>
            ) : null
          }
        />
      </ResponsiveContainer>
    </div>
  );
};

export default OCPITreeMap;
