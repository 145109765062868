import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { formatDateTime } from "../../../utils/FormatDateTime";
import OrgDocModal from "../../Modals/OrgDocModal";

const VehicleDetailsTable = ({ data }) => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenModal = (row) => {
    setOpenViewModal(true);
    setSelectedRow(row);
  };

  const handleCloseViewModal = (e) => {
    e.stopPropagation();
    setOpenViewModal(false);
    setSelectedRow(null);
  };

  // Dummy data for the orgDocs table
  const orgDocs = [
    {
      createdAt: "2024-01-15T14:30:00Z",
      name: "Car",
      brand: "Toyota",
      model: "Corolla",
    },
    {
      createdAt: "2024-02-10T09:00:00Z",
      name: "Truck",
      brand: "Ford",
      model: "F-150",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Table */}
      <Grid container>
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              padding: "8px 16px",
              borderRadius: "4px",
              background: "rgba(41, 45, 51, 0.10)",
            }}
          >
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"10%"}
            >
              S.No
            </Typography>

            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Vehicle type
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Brand
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Model
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Added On
            </Typography>
          </Box>

          {data?.userVehicleData?.map((orgDoc, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  padding: "12px 16px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModal(orgDoc)}
              >
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"10%"}
                >
                  {index + 1}
                </Typography>

                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  {orgDoc.vehicleData?.charging_slots?.length > 0
                    ? orgDoc.vehicleData.charging_slots.map((slot, index) => (
                        <span key={index}>
                          {slot}
                          {index <
                            orgDoc.vehicleData.charging_slots.length - 1 &&
                            ", "}
                        </span>
                      ))
                    : "No charging slots available"}
                </Typography>

                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  {orgDoc?.vehicleData?.brand}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  {orgDoc.model}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  {formatDateTime(orgDoc?.vehicleData?.createdAt).date}
                </Typography>
              </Box>
              <Divider />
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehicleDetailsTable;
