import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import GlobalTimeFilter from "../../Filters/GlobalTimeFilter";

const TopHeader = ({
  setDurationType,
  setStartTime,
  setEndTime,

}) => {
  const profile = useSelector((state) => state?.profile?.profile);
  return (
    <>
      <Box
        sx={{
          padding: "16px 32px",
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Typography
          fontFamily={"mySecondFont"}
          fontSize={"20px"}
          textTransform={"capitalize"}
        >
          Hi, {profile ? profile.name : "Guest"}
        </Typography>
        {/* Time - filter */}
        <GlobalTimeFilter
          setDurationType={setDurationType}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
      </Box>
    </>
  );
};

export default TopHeader;
