import { Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SevereAlertsBox from "../../alerts_and_faults/SevereAlertsBox";
import CustomTabs from "../../custom/CustomTabs";
import PaymentsSevereAlertsBox from "./PaymentsSevereAlertsBox";
import PaymentsMediumAlertsBox from "./PaymentsMediumAlertsBox";
import PaymentsLowAlertsBox from "./PaymentsLowAlertsBox";
import PaymentsHighAlertsBox from "./PaymentsHighAlertsBox";
import AlertsHistory from "../../alerts_and_faults/AlertsHistory";
import PaymentsAlertsHistory from "./PaymentsAlertsHistory";
import {
  getAlertsHistoryColumnData,
  getAlertsHistoryRowData,
} from "../../../utils/DummyData";
import NewCustomTabs from "../../custom/NewCustomTabs";

const calculateDaysAndHoursDifference = (timestamp1, timestamp2) => {
  // Parse the timestamps into Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Calculate the difference in milliseconds
  const diffInMillis = Math.abs(date1 - date2);

  // Calculate the difference in days (divide by milliseconds in a day)
  const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));

  // Calculate the remaining hours after removing the full days
  const diffInHours = Math.floor(
    (diffInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  // Return the difference as a formatted string like "6d 4h"
  return `${diffInDays}d ${diffInHours}h`;
};

const TopBar = () => {
  const [activeTabContent, setActiveTabContent] = useState(null);

  const dispatch = useDispatch();

  const tabsData = React.useMemo(
    () => [
      // {
      //   label: `Overview`,
      //   content: (
      //     <Box>
      //       <OverviewBox
      //         alertCounts={alertCounts}
      //         alertsByGroup={alertsByGroup}
      //         alertsByAvgResolution={alertsByAvgResolution}
      //       />
      //     </Box>
      //   ),
      // },
      // {
      //   label: "Informative Alerts",
      //   content: (
      //     <Box>
      //       <AlertsHistory
      //       // columns={getAlertsHistoryColumnData()}
      //       // // row={alertsHistory}
      //       // row={nestedAlertsHistory}
      //       />
      //     </Box>
      //   ),
      // },

      //     {
      //   label: `Overview`,
      //   content: (
      //     <Box>
      //       <OverviewBox
      //         alertCounts={alertCounts}
      //         alertsByGroup={alertsByGroup}
      //         alertsByAvgResolution={alertsByAvgResolution}
      //       />
      //     </Box>
      //   ),
      // },

      {
        label: `Critical (123)`,
        content: (
          <Box>
            <PaymentsSevereAlertsBox />
          </Box>
        ),
      },
      {
        label: `High ()`,
        content: (
          <Box>
            <PaymentsHighAlertsBox />
          </Box>
        ),
      },
      {
        label: `Medium ()`,
        content: (
          <Box>
            <PaymentsMediumAlertsBox />
          </Box>
        ),
      },
      {
        label: `Low (33)`,
        content: (
          <Box>
            <PaymentsLowAlertsBox />
          </Box>
        ),
      },
      {
        label: "Alert History",
        content: (
          <Box>
            <PaymentsAlertsHistory
              columns={getAlertsHistoryColumnData()}
              row={getAlertsHistoryRowData()}
            />
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        {/* Tabs */}
        <NewCustomTabs
          tabs={tabsData}
          renderContentExternally={true} // Ensure content is NOT rendered inside the tabs
          onTabContentChange={(content) => setActiveTabContent(content)} // Capture active tab content
        />
        {tabsData[0] && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            /> */}
            hiii
          </Box>
        )}
      </Box>
      <Box>{activeTabContent || "No Content Available"}</Box>
    </React.Fragment>
  );
};

export default TopBar;
