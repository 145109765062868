import { Box, Grid } from "@mui/material";
import React from "react";
import TopGraphBox from "../dashboard/TopGraphBox";
import LoadBalance from "./Overview/LoadBalance";
import DirectSession from "./Overview/DirectSession";
import DeviceStatus from "./Overview/DeviceStatus";
import RatingBox from "./Overview/RatingBox";
import ValueCardBox from "./Overview/ValueCardBox";
import { useSelector } from "react-redux";
import TopPerformingSessionEnergy from "../dashboard/TopPerformingSessionEnergy";
import TopPerformingSessionDuration from "../dashboard/TopPerformingSessionDuration";
import ValueCardBoxUpDownTime from "../dashboard/ValueCardBoxUpDownTime";

const OverviewBox = ({
  data,
  selectedTime,
  startTime,
  endTime,
  liveStationData,
  previousDashboardData,
  getUpDownTimeOneStationData
}) => {
  const user = useSelector((state) => state?.profile?.profile);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}

      {user?.role === "CPO_Admin" ? (
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopGraphBox
                //Current data
                data={data}
                //Previous data
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopGraphBox
                //Current data
                data={data}
                //Previous data
                previousDashboardData={previousDashboardData}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            </Box>
          </Grid>
          {/* <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <LoadBalance />
            </Box>
          </Grid> */}
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <DeviceStatus data={liveStationData} />
            </Box>
          </Grid>
        </Grid>
      )}

      {/* mid - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <DirectSession
              // data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
              //Current data
              data={data}
              //Previous data
              previousDashboardData={previousDashboardData}
            />
          </Box>
        </Grid>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>
            <>
              <ValueCardBoxUpDownTime
                data={getUpDownTimeOneStationData}
                selectedTime={selectedTime}
                cardTypeProp={"dashboard"}
                showCardInRow={true}
              />
            </>
          </Box>
        </Grid>
        {/* <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <DeviceStatus data={liveStationData} />
          </Box>
        </Grid> */}
      </Grid>

      {/* below - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          {/* <Box sx={{ flexGrow: 1, display: "flex" }}>
            <ValueCardBox //Current data
              data={data}
              //Previous data
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
            />
          </Box> */}
          {user?.role === "Superadmin" ? (
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <RatingBox data={data} />
            </Box>
          ) : (
            ""
          )}
        </Grid>

        {user?.role === "Superadmin" ? (
          <>
            <Grid
              item
              md={4.5}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <TopPerformingSessionEnergy
                topPerformData={data}
                dataFrom={"stationDetail"}
              />
            </Grid>
            <Grid
              item
              md={4.5}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <TopPerformingSessionDuration
                topPerformData={data}
                dataFrom={"stationDetail"}
              />
            </Grid>
          </>
        ) : (
          ""
        )}

        {user?.role !== "Superadmin" ? (
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <RatingBox data={data} />
            </Box>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Box>
  );
};

export default OverviewBox;
