import { Box, Grid } from "@mui/material";
import React from "react";
import ValueCard from "./ValueCard";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import SkeletonValueCard from "../Skeleton/SkeletonValueCard";

const ValueCardBoxUpDownTime = ({
  data,
  customers,
  previousDashboardData,
  selectedTime,
  customerData,
  previousCustomerData,
  cardTypeProp,
  walletData,
  previousWalletData,
  hubSession,
  loading,
  showCardInRow, // Controls layout direction
}) => {
  const currencySymbol = GetCurrencySymbol();

  return showCardInRow ? (
    // Grid layout for row display
    <Grid
      container
      spacing={2}
      sx={{ flexGrow: 1, height: "100%", width: "100%" }}
    >
      <Grid item xs={12} md={4}>
        {loading ? (
          <SkeletonValueCard />
        ) : (
          <ValueCard
            title={"Total Idle Hours"}
            data={data}
            previousDashboardData={previousDashboardData}
            selectedTime={selectedTime}
            cardTypeProp={cardTypeProp}
            hubSession={hubSession}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {loading ? (
          <SkeletonValueCard />
        ) : (
          <ValueCard
            title={"Total Downtime Hours"}
            data={data}
            previousDashboardData={previousDashboardData}
            selectedTime={selectedTime}
            cardTypeProp={cardTypeProp}
            hubSession={hubSession}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {loading ? (
          <SkeletonValueCard />
        ) : (
          <ValueCard
            title={"Total Utilization Hours"}
            data={data}
            previousDashboardData={previousDashboardData}
            selectedTime={selectedTime}
            cardTypeProp={cardTypeProp}
            hubSession={hubSession}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    // Box layout for column display
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "100%",
        width: "100%",
      }}
    >
      {loading ? (
        <SkeletonValueCard />
      ) : (
        <ValueCard
          title={"Total Idle Hours"}
          data={data}
          previousDashboardData={previousDashboardData}
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
      {loading ? (
        <SkeletonValueCard />
      ) : (
        <ValueCard
          title={"Total Downtime Hours"}
          data={data}
          previousDashboardData={previousDashboardData}
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
      {loading ? (
        <SkeletonValueCard />
      ) : (
        <ValueCard
          title={"Total Utilization Hours"}
          data={data}
          previousDashboardData={previousDashboardData}
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
    </Box>
  );
};

export default ValueCardBoxUpDownTime;
