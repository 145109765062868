import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import {
  paymentColumns,
  paymentColumnsForCPO,
  paymentData,
} from "../../utils/DummyData";

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable"; // AutoTable plugin for jsPDF

const AllPaymentBox = ({ payments, userRoleProp, tabTitle }) => {
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);

  useEffect(() => {
    if (payments.length > 0) {
      setFilteredPayments(payments);
    }
  }, [payments]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredPayments(filteredData);
  };

  const myFilters = [
    {
      value: "paymentId",
      title: "Payment ID",
    },
    {
      value: "mode",
      title: "Payment Mode",
    },
    {
      value: "type",
      title: "Type",
    },
    {
      value: "createdAt",
      title: "Date",
    },
  ];

  const myFiltersForPDF = [
    {
      value: "paymentId",
      title: "Payment ID",
    },
    {
      value: "deviceId",
      title: "Device ID",
    },
    {
      value: "amount",
      title: "Amount",
    },
    {
      value: "mode",
      title: "Mode",
    },
    {
      value: "type",
      title: "Type",
    },
    {
      value: "userId",
      title: "Customer ID",
    },

    {
      value: "createdAt",
      title: "Date",
    },
  ];

  const downloadExcel = () => {
    const filteredData = filteredPayments.map((item, index) => ({
      ...item,
      sr: index + 1, // Adding serial number
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "Payments");

    XLSX.writeFile(wb, "payments.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const filteredData = filteredPayments.map((item, index) => ({
      ...item,
      sr: index + 1, // Adding serial number
    }));

    const columns = myFiltersForPDF.map((filter) => ({
      header: filter.title,
      dataKey: filter.value,
    }));

    doc.autoTable({
      columns,
      body: filteredData,
      margin: { top: 10 },
    });

    doc.save("payments.pdf");
  };

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={payments}
          onFilterChange={handleFilterChange}
          filters={myFilters}
          tabTitle={tabTitle}
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Button
            sx={{
              padding: "8px 12px",
              border: "1px solid rgba(34, 34, 34, 0.50)",
              borderRadius: "4px",
              color: "#2F2F2F",
              fontFamily: "mySecondFont",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={downloadExcel}
          >
            <img
              style={{ marginRight: "4px" }}
              src="/images/excel.png"
              alt=""
            />
            Excel
            <span
              style={{
                fontSize: "16px",
                fontWeight: "400",
                marginLeft: "4px",
              }}
              className="material-symbols-outlined"
            >
              download
            </span>
          </Button>
          <Button
            sx={{
              padding: "8px 12px",
              border: "1px solid rgba(34, 34, 34, 0.50)",
              borderRadius: "4px",
              color: "#2F2F2F",
              fontFamily: "mySecondFont",
              fontSize: "14px",
            }}
            onClick={downloadPDF}
          >
            <img style={{ marginRight: "4px" }} src="/images/pdf.png" alt="" />
            PDF
            <span
              style={{
                fontSize: "16px",
                fontWeight: "400",
                marginLeft: "4px",
              }}
              className="material-symbols-outlined"
            >
              download
            </span>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "16px",
          mt: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#027900",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
            Credited
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#790000",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
            Refund
          </Typography>
        </Box>
      </Box>

      {/* Table */}
      <CustomTable
        columns={
          userRoleProp === "CPO_Admin"
            ? paymentColumnsForCPO()
            : paymentColumns()
        }
        rows={filteredPayments}
        actionType={"edit-delete"}
        specialColumns={["paymentId"]}
        isRowClickable={userRoleProp === "CPO_Admin" ? true : false}
        clickableColumns={["sessionID"]}
        columnClickPaths={{
          sessionID: "/charging_sessions",
        }}
        columnKeyMappings={{
          _id: "sessionID", // Use 'sessionID' key for 'sessionID' column
        }}
        // clickableColumns={["paymentId"]}
        modalHeading="Payments"
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default AllPaymentBox;
