import { Box, Button } from "@mui/material";
import React from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import { useNavigate } from "react-router-dom";
import AddTariffSnackBar from "../OcpiUiComponents/AddTariffSnackBar";

const OcpiLocationBox = ({ columns, rows, isRowClickable, networkDetail }) => {
    return (
        <Box
          sx={{
            padding: "24px",
            borderRadius: "8px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >     
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TableFilters />
          </Box>
    
          <CustomTable
            columns={columns}
            rows={rows}
            isRowClickable={isRowClickable}
            
            clickableColumns={["operatorName", "nestedOperatorId"]}
            rowClickPath="/ocpi_management_details"
            columnClickPaths={{
              network: "/ocpi_management_details",
              name: "/ocpi_management_details",
              nestedOperatorId: "/ocpi_management_details",
             
            }}
            modalHeading={"Locations"}
            columnKeyMappings={{
              name: "deviceId",
              station: "deviceId",
            }}
          />
    
        </Box>
      );
}

export default OcpiLocationBox
