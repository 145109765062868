import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import OCPITreeMap from "../../Graphs/CustomerManagement/OCPITreeMap";
import Heading from "../../../utils/Heading";
import OCPICustomerTreeData from "./OCPICustomerTreeData";

const OCPICustomers = () => {
  const data = [
    { name: "Company 1", size: 25 },
    { name: "Company 2", size: 20 },
    { name: "Company 3", size: 15 },
    { name: "Company 4", size: 10 },
    { name: "Company 5", size: 5 },
    { name: "Company 6", size: 2.25 },
    { name: "Company 7", size: 1.25 },
    { name: "Company 8", size: 1 },
    { name: "Company 9", size: 1.2 },
    { name: "Company 10", size: 2 },
  ];

  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <span
          style={{ fontSize: "24px", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          ad_units
        </span>
        <Heading text={"OCPI customers"} />
      </Box>
      <Divider sx={{ mt: "8px", mb: "16px" }} />

      {/* Grid */}
      <Grid container spacing={2} columnSpacing={2}>
        <Grid item xs={8}>
          <OCPITreeMap data={data} />
        </Grid>
        <Grid item xs={4}>
          <OCPICustomerTreeData data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OCPICustomers;
