import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Dropbox from "../dropbox/Dropbox";
import Heading from "../../utils/Heading";
import { useDropzone } from "react-dropzone";
import {
  createStationImg,
  deleteOneStationImg,
  updateOneStationImg,
} from "../../redux/action/stations/stationSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SelectField = ({ label, value, handleChange, menuItems }) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <FormControl fullWidth>
      <InputLabel sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>
        {label}
      </InputLabel>
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        sx={{ height: "48px", fontFamily: "myThirdFont !important" }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const Section = ({ icon, title, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
        <Heading text={title} />
      </Box>
      <Divider sx={{ mt: "8px" }} />
    </Box>
    {children}
  </Box>
);

const TextareaField = ({ label, placeholder, value, onChange }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <textarea
      value={value}
      onChange={onChange}
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "100px",
        outline: "none",
        resize: "none",
      }}
      placeholder={placeholder}
    />
  </Box>
);

const GalleryBox = ({
  files,
  images,
  setImages,
  setFiles,
  imgTagsItem,
  stationId = null,
  acceptedFilesType,
}) => {
  const dispatch = useDispatch();
  const [urlInput, setUrlInput] = useState("");
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: true,
  });

  const handleAddImage = (files) => {
    if (files.length === 0) return;

    const newImage = {
      order: images.length + 1,
      image: files[0], // Use the first file in the array
      preview: URL.createObjectURL(files[0]), // Create an object URL for preview
      imageTag: "", // Initial imageTag value
    };

    setImages([...images, newImage]);
    setFiles([]); // Clear files after adding
  };

  const handleAddImageByUrl = () => {
    if (!urlInput) return;

    // Regular expression to check for .jpg or .png extensions
    const validExtensions = /\.(jpg|png)$/i;

    // Validate the URL
    if (!validExtensions.test(urlInput)) {
      toast.error("Please enter a valid image URL ending with .jpg or .png");
      return;
    }

    const newImage = {
      order: images.length + 1,
      image: { url: urlInput }, // Use URL directly
      preview: urlInput, // URL for preview
      imageTag: "", // Initial imageTag value
    };

    setImages([...images, newImage]);
    setUrlInput(""); // Clear URL input after adding
  };

  const handleImgTags = (event, index) => {
    const newValue = event.target.value;
    setImages((prevImages) =>
      prevImages.map((item, idx) =>
        idx === index ? { ...item, imageTag: newValue } : item
      )
    );

    let updatedImage = images.filter((_, i) => i === index)[0];

    if (updatedImage?._id) {
      const payload = { imageTag: newValue };
      dispatch(
        updateOneStationImg({ stationImgId: updatedImage?._id, payload })
      );
    }
  };

  // const handleDeleteImage = (index) => {
  //   const newImages = images.filter((_, i) => i !== index);
  //   setImages(newImages);
  //   let deletedImg = images.filter((_, i) => i === index)[0];

  //   if (deletedImg?._id && stationId !== null) {
  //     dispatch(
  //       deleteOneStationImg({ stationImgId: deletedImg?._id, stationId })
  //     );
  //   }
  // };
  const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    
    // Update order numbers
    const updatedImages = newImages.map((item, idx) => ({
      ...item,
      order: idx + 1, // Reassign order starting from 1
    }));
  
    setImages(updatedImages);
    let deletedImg = images[index];
  
    if (deletedImg?._id && stationId !== null) {
      dispatch(
        deleteOneStationImg({ stationImgId: deletedImg?._id, stationId })
      );
    }
  };
  const isMaxImagesReached = images.length >= 6;

  return (
    <Section icon="gallery_thumbnail" title="Gallery">
      <Typography
        fontSize={"14px"}
        fontFamily={"myThirdFont"}
        color={"#666"}
        textAlign={"start"}
      >
        You can add up to 6 images (file format - jpeg & png)*
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container>
          <Grid item md={8}>
            <Box
              sx={{
                display: "flex",
                padding: "8px 16px",
                borderRadius: "4px",
                background: "rgba(41, 45, 51, 0.10)",
              }}
            >
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"start"}
                width={"10%"}
              >
                S.No
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"start"}
                width={"20%"}
              >
                Image Preview
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"start"}
                width={"40%"}
              >
                Image Tag
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"14px"}
                textAlign={"center"}
                width={"30%"}
              >
                Action
              </Typography>
            </Box>
            {/* gallery - list */}
            {images.map((item, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    padding: "12px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    color={"#2F2F2F"}
                    fontFamily={"mySecondFont"}
                    fontSize={"14px"}
                    textAlign={"start"}
                    width={"10%"}
                  >
                    {index+1}
                  </Typography>
                  <Box sx={{ width: "20%", textAlign: "start" }}>
                    <img
                      style={{
                        maxWidth: "128px",
                        width: "100%",
                        height: "64px",
                        objectFit: "scale-down",
                      }}
                      src={item?.image?.url ? item?.image?.url : item?.preview}
                      alt=""
                    />
                  </Box>
                  <Box sx={{ width: "40%", textAlign: "start" }}>
                    <SelectField
                      value={item.imageTag}
                      handleChange={(event) => handleImgTags(event, index)}
                      menuItems={imgTagsItem}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "30%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "4px",
                        border: "1px solid #C0C0C0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteImage(index)}
                    >
                      <span
                        style={{ color: "#790000", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        delete
                      </span>
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "4px",
                        border: "1px solid #C0C0C0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{ color: "#292D33", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        reorder
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </React.Fragment>
            ))}

            {/* dropBox */}
            <Box sx={{ mt: "24px" }}>
              <Grid container columnSpacing={2}>
                <Grid item md={4}>
                  <Dropbox
                    acceptedFiles={acceptedFiles}
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    files={files}
                    setFiles={setFiles}
                    onDrop={handleAddImage}
                    acceptedFilesType={acceptedFilesType}
                    disabled={isMaxImagesReached} 
                  />
                </Grid>
                <Grid item md={8}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <TextareaField
                      label="Image URLs"
                      placeholder="Add image links here"
                      value={urlInput}
                      onChange={(e) => setUrlInput(e.target.value)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                      border={isMaxImagesReached? "none":"1px solid #e50000" }
                        sx={{
                          padding: "4px 16px",
                          border: "1px solid #e50000",
                          borderRadius: "4px",
                          color: "#E50000",
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        onClick={handleAddImageByUrl}
                        disabled={isMaxImagesReached}
                      >
                        + ADD
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

export default GalleryBox;

// import {
//   Box,
//   Button,
//   Divider,
//   FormControl,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Select,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";
// import Dropbox from "../dropbox/Dropbox";
// import Heading from "../../utils/Heading";
// import { useDropzone } from "react-dropzone";
// import {
//   createStationImg,
//   deleteOneStationImg,
//   updateOneStationImg,
// } from "../../redux/action/stations/stationSlice";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



// const SelectField = ({ label, value, handleChange, menuItems }) => (
//   <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
//     <Typography
//       textAlign="start"
//       fontSize="14px"
//       color="#2f2f2f"
//       fontFamily="myThirdFont"
//       outline="none"
//     >
//       {label}
//     </Typography>
//     <FormControl fullWidth>
//       <InputLabel sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>{label}</InputLabel>
//       <Select
//         value={value}
//         label={label}
//         onChange={handleChange}
//         sx={{ height: "48px", fontFamily: "myThirdFont !important" }}
//       >
//         {menuItems.map((item, index) => (
//           <MenuItem key={index} value={item.value}>
//             {item.label}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   </Box>
// );

// const Section = ({ icon, title, children }) => (
//   <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
//     <Box sx={{ display: "flex", flexDirection: "column" }}>
//       <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
//         <span style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }} className="material-symbols-outlined">
//           {icon}
//         </span>
//         <Heading text={title} />
//       </Box>
//       <Divider sx={{ mt: "8px" }} />
//     </Box>
//     {children}
//   </Box>
// );

// const TextareaField = ({ label, placeholder, value, onChange }) => (
//   <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
//     <Typography
//       textAlign="start"
//       fontSize="14px"
//       color="#2f2f2f"
//       fontFamily="myThirdFont"
//       outline="none"
//     >
//       {label}
//     </Typography>
//     <textarea
//       value={value}
//       onChange={onChange}
//       style={{
//         padding: "12px 16px",
//         borderRadius: "4px",
//         border: "1px solid #cbcbcb",
//         color: "rgba(47, 47, 47, 0.50)",
//         fontFamily: "myThirdFont",
//         fontSize: "16px",
//         height: "100px",
//         outline: "none",
//         resize: "none",
//       }}
//       placeholder={placeholder}
//     />
//   </Box>
// );

// const GalleryBox = ({
//   files,
//   images,
//   setImages,
//   setFiles,
//   imgTagsItem,
//   stationId = null,
//   acceptedFilesType,
// }) => {
//   const dispatch = useDispatch();
//   const [urlInput, setUrlInput] = useState("");
//   const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: true });

//   const handleAddImage = (files) => {
//     if (files.length === 0) return;

//     const newImage = {
//       order: images.length + 1,
//       image: files[0], // Use the first file in the array
//       preview: URL.createObjectURL(files[0]), // Create an object URL for preview
//       imageTag: "", // Initial imageTag value
//     };

//     setImages([...images, newImage]);
//     setFiles([]); // Clear files after adding
//   };

//   const handleAddImageByUrl = () => {
//     if (!urlInput) return;

//     const validExtensions = /\.(jpg|png)$/i;

//     if (!validExtensions.test(urlInput)) {
//       toast.error("Please enter a valid image URL ending with .jpg or .png");
//       return;
//     }

//     const newImage = {
//       order: images.length + 1,
//       image: { url: urlInput }, // Use URL directly
//       preview: urlInput, // URL for preview
//       imageTag: "", // Initial imageTag value
//     };

//     setImages([...images, newImage]);
//     setUrlInput(""); // Clear URL input after adding
//   };

//   const handleImgTags = (event, index) => {
//     const newValue = event.target.value;
//     setImages((prevImages) =>
//       prevImages.map((item, idx) =>
//         idx === index ? { ...item, imageTag: newValue } : item
//       )
//     );

//     let updatedImage = images[index];

//     if (updatedImage?._id) {
//       const payload = { imageTag: newValue };
//       dispatch(updateOneStationImg({ stationImgId: updatedImage?._id, payload }));
//     }
//   };

//   const handleDeleteImage = (index) => {
//     const newImages = images.filter((_, i) => i !== index);

//     const updatedImages = newImages.map((item, idx) => ({
//       ...item,
//       order: idx + 1,
//     }));

//     setImages(updatedImages);
//     let deletedImg = images[index];

//     if (deletedImg?._id && stationId !== null) {
//       dispatch(deleteOneStationImg({ stationImgId: deletedImg?._id, stationId }));
//     }
//   };

//   const handleDragEnd = (e) => {
//     if (!e.destination) return;
//     let tempData = Array.from(images);
//     let [source_data] = tempData.splice(e.source.index, 1);
//     tempData.splice(e.destination.index, 0, source_data);
//     setImages(tempData);
//   };


//   const isMaxImagesReached = images.length >= 6;

//   return (
//     <Section icon="gallery_thumbnail" title="Gallery">
//       <Typography
//         fontSize={"14px"}
//         fontFamily={"myThirdFont"}
//         color={"#666"}
//         textAlign={"start"}
//       >
//         You can add up to 6 images (file format - jpeg & png)
//       </Typography>

//       <Box sx={{ display: "flex", flexDirection: "column" }}>
//         <Grid container>
//           <Grid item md={8}>
//             <Box
//               sx={{
//                 display: "flex",
//                 padding: "8px 16px",
//                 borderRadius: "4px",
//                 background: "rgba(41, 45, 51, 0.10)",
//               }}
//             >
//               <Typography width={"10%"}>S.No</Typography>
//               <Typography width={"20%"}>Image Preview</Typography>
//               <Typography width={"40%"}>Image Tag</Typography>
//               <Typography width={"30%"} textAlign={"center"}>Action</Typography>
//             </Box>
//             <DragDropContext onDragEnd={handleDragEnd}>
//               <Droppable droppableId="droppable-1">
//                 {(provider) => (
//                   <Box
//                     ref={provider.innerRef}
//                     {...provider.droppableProps}
//                     sx={{ display: "flex", flexDirection: "column" }}
//                   >
//                     {images.map((item, index) => (
//                       <Draggable key={item.preview} draggableId={item.preview} index={index}>
//                         {(provider) => (
//                           <Box
//                             ref={provider.innerRef}
//                             {...provider.draggableProps}
//                             {...provider.dragHandleProps}
//                             sx={{ padding: "12px 16px", display: "flex", alignItems: "center", borderBottom: "1px solid #ccc" }}
//                           >
//                             <Typography width={"10%"}>{index + 1}</Typography>
//                             <Box sx={{ width: "20%", textAlign: "start" }}>
//                               <img
//                                 style={{
//                                   maxWidth: "128px",
//                                   width: "100%",
//                                   height: "64px",
//                                   objectFit: "scale-down",
//                                 }}
//                                 src={item?.image?.url ? item?.image?.url : item?.preview}
//                                 alt=""
//                               />
//                             </Box>
//                             <Box sx={{ width: "40%", textAlign: "start" }}>
//                               <SelectField
//                                 value={item.imageTag}
//                                 handleChange={(event) => handleImgTags(event, index)}
//                                 menuItems={imgTagsItem}
//                               />
//                             </Box>
//                             <Box
//                               sx={{
//                                 width: "30%",
//                                 textAlign: "center",
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 gap: "4px",
//                               }}
//                             >
//                               <Box
//                                 sx={{
//                                   borderRadius: "4px",
//                                   border: "1px solid #C0C0C0",
//                                   display: "flex",
//                                   alignItems: "center",
//                                   justifyContent: "center",
//                                   padding: "4px",
//                                   cursor: "pointer",
//                                 }}
//                                 onClick={() => handleDeleteImage(index)}
//                               >
//                                 <span style={{ color: "#790000", fontWeight: "200" }} className="material-symbols-outlined">delete</span>
//                               </Box>
//                               <Box
//                                 sx={{
//                                   borderRadius: "4px",
//                                   border: "1px solid #C0C0C0",
//                                   display: "flex",
//                                   alignItems: "center",
//                                   justifyContent: "center",
//                                   padding: "4px",
//                                   cursor: "pointer",
//                                 }}
//                               >
//                                 <span style={{ color: "#292D33", fontWeight: "200" }} className="material-symbols-outlined">reorder</span>
//                               </Box>
//                             </Box>
//                           </Box>
//                         )}
//                       </Draggable>
//                     ))}
//                     {provider.placeholder}
//                   </Box>
//                 )}
//               </Droppable>
//             </DragDropContext>

//             {/* DropBox */}
//             <Box sx={{ mt: "24px" }}>
//               <Grid container columnSpacing={2}>
//                 <Grid item md={4}>
//                   <Dropbox
//                     acceptedFiles={acceptedFiles}
//                     getInputProps={getInputProps}
//                     getRootProps={getRootProps}
//                     files={files}
//                     setFiles={setFiles}
//                     onDrop={handleAddImage}
//                     acceptedFilesType={acceptedFilesType}
//                     disabled={isMaxImagesReached}
//                   />
//                 </Grid>
//                 <Grid item md={8}>
//                   <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
//                     <TextareaField
//                       label="Image URLs"
//                       placeholder="Add image links here"
//                       value={urlInput}
//                       onChange={(e) => setUrlInput(e.target.value)}
//                     />
//                     <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//                       <Button
//                         border={isMaxImagesReached ? "none" : "1px solid #e50000"}
//                         sx={{
//                           padding: "4px 16px",
//                           borderRadius: "4px",
//                           color: "#E50000",
//                           fontFamily: "'Montserrat', sans-serif",
//                           fontSize: "14px",
//                           fontWeight: "600",
//                         }}
//                         onClick={handleAddImageByUrl}
//                         disabled={isMaxImagesReached}
//                       >
//                         + ADD
//                       </Button>
//                     </Box>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </Section>
//   );
// };

// export default GalleryBox;

