import React, { useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import dayjs from "dayjs";

// Custom Tooltip Content for BarChart
const CustomTooltip = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <Typography variant="body2" sx={{ color: "#666" }}>
          {label}
        </Typography>
        {payload.map((entry, index) => (
          <Typography
            key={index}
            sx={{ color: type === "line" ? entry.stroke :entry.fill, fontSize: "14px" }} // Use `entry.fill` for Bar color
          >
            <strong>{getCustomLabel(entry.dataKey)}: </strong>
            {entry.value}
          </Typography>
        ))}
      </Box>
    );
  }

  return null;
};


// Function to return custom labels for line chart, legend, and tooltip
const getCustomLabel = (dataKey) => {
  switch (dataKey) {
    case "appAmount":
      return "App Amount";
      case "hubAmount":
        return "Hub Amount";
    case "directAmount":
      return "Direct Amount";
    case "ocpiAmount":
      return "OCPI Amount";
    case "rfidAmount":
      return "RFID Amount";
    case "directSessions":
      return "Direct Session";
    case "refundAmount":
      return "Refund Amount";
      case "revenue":
        return "Revenue";
  

    default:
      return dataKey;
  }
};

const DoubleLineGraphPayment = ({
  graphTitle,
  gr_type,
  data,
  selectedTime,
  startTime,
  endTime,
  user,
}) => {
  const [selectedValue, setSelectedValue] = useState("op1");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  console.log("ggggggga", data);

  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "week") {
      for (let i = 0; i < 7; i++) {
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: dayjs().subtract(i, "day").format("ddd"),
        });
      }
      dates.reverse();
    } else if (selectedTime === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      dates.push({
        date: dayjs().format("YYYY-MM-DD"),
        label: "Today",
      });
    } else if (selectedTime === "yesterday") {
      dates.push({
        date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        label: "Yesterday",
      });
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !Array.isArray(data)) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    return dates.map(({ date, label }) => {
      const dailyData = data.find((item) => item._id === date) || {};

      if (selectedValue === "op1") {
        const appAmount = parseFloat(dailyData.chargingAmount_App) || 0;
        const directAmount = parseFloat(dailyData.chargingAmount_Upi) || 0;
        const hubAmount = parseFloat(dailyData.chargingAmount_Guard) || 0;
        const rfidAmount = parseFloat(dailyData.chargingAmount_Rfid) || 0;
        const ocpiAmount = parseFloat(dailyData.chargingAmount_Ocpi) || 0;

        // If the user is either a CPO_Admin or CPO_User, remove hub, rfid, and ocpi amounts
        if (user?.role === "CPO_Admin" || user?.role === "CPO_User") {
          return {
            date: label,
            appAmount: appAmount?.toFixed(2),
            directAmount: directAmount?.toFixed(2),
          };
        } else if (user?.role === "1C_User") {
          return {
            date: label,
            appAmount: appAmount?.toFixed(2),
            directAmount: directAmount?.toFixed(2),
            hubAmount: hubAmount?.toFixed(2),
          };
        } else {
          return {
            date: label,
            appAmount: appAmount?.toFixed(2),
            directAmount: directAmount?.toFixed(2),
            hubAmount: hubAmount?.toFixed(2),
            rfidAmount: rfidAmount?.toFixed(2),
            ocpiAmount: ocpiAmount?.toFixed(2),
          };
        }
      } else if (selectedValue === "op2") {
        const refundAmount = parseFloat(dailyData.refundAmount) || 0;
        const revenue = parseFloat(dailyData.chargingAmount) || 0;

        return {
          date: label,
          refundAmount: refundAmount.toFixed(2),
          revenue: revenue.toFixed(2),
        };
      }

      return {
        date: label,
        appAmount: 0,
        directAmount: 0,
        hubAmount: 0,
        rfidAmount: 0,
        ocpiAmount: 0,
        refundAmount: 0,
        revenue: 0,
      };
    });
  };

  const computeYAxisDomain = () => {
    const chartData = getData();
    let min = 0;
    let max = 0;
  
    const getMinMax = (fields) => {
      const values = chartData.flatMap((data) => fields.map(field => parseFloat(data[field]) || 0));
      return [Math.min(...values), Math.max(...values)];
    };
  
    if (selectedValue === "op1") {
      // For "op1", check all possible fields like directAmount, appAmount, hubAmount, rfidAmount, ocpiAmount
      const fields = ["directAmount", "appAmount", "hubAmount", "rfidAmount", "ocpiAmount"];
      [min, max] = getMinMax(fields);
    } else if (selectedValue === "op2") {
      // For "op2", check refundAmount and revenue
      const fields = ["refundAmount", "revenue"];
      [min, max] = getMinMax(fields);
    }
  
    // Ensure the Y-axis doesn't go below 0, and apply a small scaling factor to the max value
    return [Math.min(0, min), max * 1.1];
  };
  

  const yAxisDomain = computeYAxisDomain();
  const chartData = getData();

  return (
    <Box>
      <Box>
        {gr_type === "paymentTopGraph" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              sx={{
                width: "220px",
                height: "32px",
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
                marginLeft: "40px",
              }}
            >
              <MenuItem value="op1">Session Breakdown</MenuItem>
              <MenuItem value="op2">Revenue vs Refund</MenuItem>
            </Select>

            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "8px",
                fontFamily: "myThirdFont",
                fontWeight: "600",
                fontSize: "14px",
                color: "#666",
                lineHeight: "16px",
              }}
            >
              {selectedValue === "op2"
                ? "Revenue Amount vs Refund Amount"
                : "All Sessions' Breakdown"}
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%", height: gr_type === "paymentTopGraph" ? 314 : 312 }}>
  <ResponsiveContainer width="100%" height="100%">
    {selectedTime === "today" || selectedTime === "yesterday" ? (
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="5 3" vertical={false} />
        <YAxis
          tick={{
            fontSize: "12px",
            fontFamily: "myThirdFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
          domain={yAxisDomain}
        />
        <XAxis
          dataKey="date"
          tick={{
            fontSize: "14px",
            fontFamily: "mySecondFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />
        <Tooltip content={<CustomTooltip  />} />
        <Legend formatter={(value) => getCustomLabel(value)} />
        {selectedValue === "op1" && (
          <>
            <Bar dataKey="directAmount" fill="#F8610C" barSize={40} />
            <Bar dataKey="appAmount" fill="#2271E8" barSize={40} />

            {/* Conditionally render bars based on user role */}
            {user?.role === "1C_User" && (
              <Bar dataKey="hubAmount" fill="#00C49F" barSize={40} />
            )}

            {/* Conditionally render these bars based on user role */}
            {user?.role !== "CPO_Admin" &&
              user?.role !== "CPO_User" &&
              user?.role !== "1C_User" && (
                <>
                  <Bar dataKey="hubAmount" fill="#00C49F" barSize={40} />
                  <Bar dataKey="rfidAmount" fill="#bc07b4" barSize={40} />
                  <Bar dataKey="ocpiAmount" fill="#d9ce29" barSize={40} />
                </>
              )}
          </>
        )}
        {selectedValue === "op2" && (
          <>
            <Bar dataKey="refundAmount" fill="#FF5454" barSize={40} />
            <Bar dataKey="revenue" fill="#7F73C6" barSize={40} />
          </>
        )}
      </BarChart>
    ) : (
      <LineChart
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="5 3" vertical={false} />
        <YAxis
          tick={{
            fontSize: "12px",
            fontFamily: "myThirdFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
          domain={yAxisDomain}
        />
        <XAxis
          dataKey="date"
          tick={{
            fontSize: "14px",
            fontFamily: "mySecondFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />
   <Tooltip content={<CustomTooltip type={"line"} />} />
   <Legend formatter={(value) => getCustomLabel(value)} />
        {selectedValue === "op1" && (
          <>
            <Line
              type="monotone"
              dataKey="directAmount"
              stroke="#F8610C"
              activeDot={{ r: 3 }}
            />
            <Line type="monotone" dataKey="appAmount" stroke="#2271E8" />

            {/* Conditionally render lines based on user role */}
            {user?.role === "1C_User" && (
              <Line
                type="monotone"
                dataKey="hubAmount"
                stroke="#00C49F"
              />
            )}

            {/* For all other roles except "1c_User", render all these lines */}
            {user?.role !== "CPO_Admin" &&
              user?.role !== "CPO_User" &&
              user?.role !== "1C_User" && (
                <>
                  <Line
                    type="monotone"
                    dataKey="hubAmount"
                    stroke="#00C49F"
                  />
                  <Line
                    type="monotone"
                    dataKey="rfidAmount"
                    stroke="#bc07b4"
                  />
                  <Line
                    type="monotone"
                    dataKey="ocpiAmount"
                    stroke="#d9ce29"
                  />
                </>
              )}
          </>
        )}

        {selectedValue === "op2" && (
          <>
            <Line
              type="monotone"
              dataKey="revenue"
              stroke="#7F73C6"
              activeDot={{ r: 3 }}
            />
            <Line
              type="monotone"
              dataKey="refundAmount"
              stroke="#FF5454"
            />
          </>
        )}
        {/* Conditionally render the legend based on user role */}
        {user?.role !== "CPO_Admin" && user?.role !== "CPO_User" && (
          <Legend verticalAlign="top" />
        )}
      </LineChart>
    )}
  </ResponsiveContainer>
</Box>

    </Box>
  );
};

export default DoubleLineGraphPayment;
