import React, { useState } from "react";

import { formatDateTime } from "../../../utils/FormatDateTime";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
          textTransform={"capitalize"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const OcpiSessionInfoBox = ({ data }) => {
  const createdAt = data?.ocpiDetails?.lastOcpiSession
    ? formatDateTime(data?.ocpiDetails?.lastOcpiSession)
    : {};
  return (
    <Box
      sx={{
        padding: "16px 16px 24px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            {" "}
            <InfoBox
              label="Last OCPI Network"
              value={data?.ocpiDetails?.lastOcpiNetwork}
            />
          </Grid>
          <Grid item md={6}>
            {" "}
            <InfoBox
              label="Frequent OCPI network"
              value={`${data?.ocpiDetails?.frequentOcpiNetwork?.name || ''} (${data?.ocpiDetails?.frequentOcpiNetwork?.count || ''})`}
            />
          </Grid>
        </Grid>

        <InfoBox
          label="Last OCPI station"
          value={data?.ocpiDetails?.lastOcpiStation}
        />
        <Box sx={{ width: "50%" }}>
          <InfoBox label="Latest OCPI session" value={createdAt.date} />
          <Typography
            fontSize={"12px"}
            fontFamily={"myThirdFont"}
            color={"#666"}
            textAlign={"start"}
          >
            {createdAt.time}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OcpiSessionInfoBox;
