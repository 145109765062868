import React, { useEffect } from "react";

import { Box } from "@mui/material";

import { useDispatch } from "react-redux";
import AlertsBox from "../../alerts_and_faults/AlertsBox";
import {
  alertsHighColumnData,
  alertsHighRowData,
} from "../../../utils/DummyData";
import PaymentAlertsBox from "../AcountsDashboard/PaymentAlertsBox";

const PaymentsHighAlertsBox = ({ alertCounts }) => {
  const dispatch = useDispatch();

  return (
    <Box sx={{}}>
      <AlertsBox
        column={alertsHighColumnData}
        data={alertsHighRowData}
        alertType="high"
        // alertCountsForTable={getAlertCount("HIGH")}
        modalHeading={"High Alerts"}
      />

    </Box>
  );
};

export default PaymentsHighAlertsBox;
