import { Box, Typography } from "@mui/material";
import React from "react";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import { useLocation } from "react-router-dom";

const ValueChip = ({
  data,
  previousDashboardData,
  title,
  previousPaymentWalletData,
  orgPaymentData,
  previousOrgPaymentData,
  customers,
  previousCustomersData,
  chipType,
  fixSize,
  customerManagement,
  valuChipData,
}) => {
  const location = useLocation();
  const hubStatsOverview = location.pathname.includes("/hub_management");

  // Helper function to convert duration string to seconds
  const convertToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const newCustomersVariable = data?.data?.percentageChange;

  const convertDurationToHours = (duration) => {
    if (!duration || typeof duration !== "string" || !duration.includes(":")) {
      return "0.00";
    }

    const [hours, minutes, seconds] = duration.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return "0.00";
    }

    const totalHours = hours + minutes / 60 + seconds / 3600;

    return totalHours.toFixed(2); // Round to two decimal places
  };

  // Helper function to calculate percentage change
  const calculatePercentageChange = (current, previous, fallbackValue = 0) => {
    // Convert values to numbers to avoid issues with type coercion
    const currentValue = Number(current);
    const previousValue = Number(previous);

    // Check if previous data is missing or zero
    if (isNaN(previousValue) || previousValue === 0) {
      if (isNaN(currentValue) || currentValue === 0) {
        return "0.00%"; // Both are zero
      }
      return "N/A"; // Previous data is missing or zero but current data is available
    }

    // Calculate percentage change
    const percentageChange =
      ((currentValue - previousValue) / previousValue) * 100;

    // Return formatted result
    return percentageChange.toFixed(2) + "%";
  };

  // Calculate results using the helper function
  const resultTotalRevenue = calculatePercentageChange(
    data?.totalRevenue,
    previousDashboardData?.totalRevenue
  );

  const resultTotalEnergy = calculatePercentageChange(
    data?.totalEnergy,
    previousDashboardData?.totalEnergy
  );

  const resultDirectSession = calculatePercentageChange(
    data?.totalSessionCount_Upi,
    previousDashboardData?.totalSessionCount_Upi
    // Fallback value for Direct Session
  );

  const resultTotalAppSession = calculatePercentageChange(
    data?.totalSessionCount_App,
    previousDashboardData?.totalSessionCount_App
  );

  const resultTotalDirectHubSession = calculatePercentageChange(
    data?.totalSessionCount_Guard,
    previousDashboardData?.totalSessionCount_Guard
  );

  const resultTotalDirectRFIDSession = calculatePercentageChange(
    data?.totalSessionCount_Rfid,
    previousDashboardData?.totalSessionCount_Rfid
  );

  const resultTotalDirectOCPISession = calculatePercentageChange(
    data?.totalSessionCount_Ocpi,
    previousDashboardData?.totalSessionCount_Ocpi
  );

  const resultTotalDuration = calculatePercentageChange(
    convertDurationToHours(data?.totalDuration),
    convertDurationToHours(previousDashboardData?.totalDuration)
  );

  const resultTotalSession = calculatePercentageChange(
    data?.totalSessionCount,
    previousDashboardData?.totalSessionCount
  );
  const resultTotalWalletRecharge = calculatePercentageChange(
    data?.totalWalletRecharge,
    previousPaymentWalletData?.totalWalletRecharge
  );

  const resultTotalWalletRechargeDashboard = calculatePercentageChange(
    data?.totalWalletRecharge,
    previousDashboardData?.totalWalletRecharge
  );

  const resultTotalWalletRechargePayment = calculatePercentageChange(
    data?.totalWalletAmountAdded,
    previousPaymentWalletData?.totalWalletAmountAdded
  );

  const resultTotalCashback = calculatePercentageChange(
    data?.totalWalletCashbackAmount,
    previousPaymentWalletData?.totalWalletCashbackAmount
  );

  const resultTotalRevenuePayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );
  const resultTotalRfundPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultTotalAmountFromAppPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultTotalAmountFromHubPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );
  const resultTotalAmountFromRFIDPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );
  const resultTotalAmountFromOCPIPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultNewUsers = calculatePercentageChange(
    customers?.customersCount,
    previousCustomersData?.customersCount
  );

  // Hub - Management

  const resultHubSession = calculatePercentageChange(
    data?.totalSessionCount_Guard,
    previousDashboardData?.totalSessionCount_Guard
  );

  const resultHubPassSold = calculatePercentageChange(
    data?.totalPassCount,
    previousDashboardData?.totalPassCount
  );

  const resultHubPassValue = calculatePercentageChange(
    data?.totalPassValue,
    previousDashboardData?.totalPassValue
  );

  const resultRFIDSession = calculatePercentageChange(
    data?.totalSessionCount_Rfid,
    previousDashboardData?.totalSessionCount_Rfid
  );

  const resultOCPISession = calculatePercentageChange(
    data?.totalSessionCount_Ocpi,
    previousDashboardData?.totalSessionCount_Ocpi
  );

  const resultPassCount = calculatePercentageChange(
    data?.hubCashTransactionStats?.overallPassDetails?.totalPassCount,
    previousDashboardData?.hubCashTransactionStats?.overallPassDetails
      ?.totalPassCount
  );

  const resultPassValue = calculatePercentageChange(
    data?.hubCashTransactionStats?.overallPassDetails?.totalPassValue,
    previousDashboardData?.hubCashTransactionStats?.overallPassDetails
      ?.totalPassValue
  );

  const resultHubEnergy = calculatePercentageChange(
    data?.hubChargingSessionsStats?.overallChargingSessionsDetails
      ?.energyConsumed,
    previousDashboardData?.hubChargingSessionsStats
      ?.overallChargingSessionsDetails?.energyConsumed
  );

  const resultTotalHubSession = calculatePercentageChange(
    data?.hubChargingSessionsStats?.overallChargingSessionsDetails
      ?.completedSessionsCount,
    previousDashboardData?.hubChargingSessionsStats
      ?.overallChargingSessionsDetails?.completedSessionsCount
  );

  const resultTotalActiveHubSession = calculatePercentageChange(
    data?.totalHubLiveSessionsCount,
    previousDashboardData?.totalHubLiveSessionsCount
  );

  const currencySymbol = GetCurrencySymbol();

  // Ensure data exists and has a profit field
  const getValueByTitle = (title) => {
    switch (title) {
      case "Total Billed Amount":
        return resultTotalRevenue;
      case "Hub Sessions":
        return resultHubSession;
      case "Total Hub Sessions":
        return resultTotalHubSession;
      case "Total Hub Passes Sold":
        return resultHubPassSold;
      case "Total Value of Passes Sold":
        return resultHubPassValue;
      case "Total Energy Consumed (kWh)":
        return hubStatsOverview ? resultHubEnergy : resultTotalEnergy;
      case "Direct Session":
        return resultDirectSession;
      case "App Session":
        return resultTotalAppSession;
      case "Hub Session":
        return resultTotalDirectHubSession;
      case "Active Hub Sessions":
        return resultTotalActiveHubSession;
      case "RFID Session":
        return resultTotalDirectRFIDSession;
      case "OCPI Session":
        return resultTotalDirectOCPISession;
      case "Total uptime (hrs)":
        return resultTotalDuration;
      case "Total Utilization (hrs)":
        return resultTotalDuration;
      case "Total Sessions":
        return resultTotalSession;
      case "Wallet Recharge":
        // return (
        //   `${data?.data?.walletRecharge?.percentageChange?.toFixed(2)}%` || resultTotalWalletRecharge ||
        //   `${data?.data?.walletRecharge?.percentageChange?.touFixed(2)}%` ||
        //   resultTotalWalletRecharge
        // );
        return customerManagement
          ? `${data?.data?.walletRecharge?.percentageChange?.toFixed(2)}%`
          : resultTotalWalletRecharge;
      case `Wallet Recharge (${currencySymbol})`:
        return resultTotalWalletRechargeDashboard;
      case "Cashbacks":
        // return (
        //   `${data?.data?.cashbackData?.percentageChange?.toFixed(2)}%` ||
        //   resultTotalCashback ||
        //   `${data?.data?.cashbackData?.percentageChange?.toFixed(2)}%` ||
        //   resultTotalCashback
        // );

        return customerManagement
          ? `${data?.data?.cashbackData?.percentageChange?.toFixed(2)}%`
          : resultTotalCashback;
      case "New customers":
        return resultNewUsers || data?.data?.percentageChange;
      case "New Customers":
        return data?.data?.percentageChange === 0
          ? "Nil"
          : `${data?.data?.percentageChange?.toFixed(2)}%`;
      // case "New Customers":
      //   return data?.data?.percentageChange;
      //payment
      case "Refund Amount":
        return resultTotalRfundPayment;
      case "Total Billed Amount (INR)":
        return resultTotalRevenuePayment;
      case "Direct Amount (QR)":
        return resultTotalRfundPayment;
      case "Amount from App":
        return resultTotalAmountFromAppPayment;
      case "Hub Amount":
        return resultTotalAmountFromHubPayment;
      case "RFID Amount":
        return resultTotalAmountFromRFIDPayment;
      case "OCPI Amount":
        return resultTotalAmountFromOCPIPayment;
      case "RFID":
        return resultRFIDSession;
      case "OCPI":
        return resultOCPISession;
      case "Total Sold Pass Count":
        return resultPassCount;
      case "Total Sold Pass Value":
        return resultPassValue;
      case "Alert count":
        return data?.percentageChange == 0
          ? "Nil"
          : `${data?.percentageChange?.toFixed(2)}%`;
      case "LOW":
        return data?.percentageChange
          ? data?.percentageChange.toFixed(2) + "%"
          : "Nil";
      case "MEDIUM":
        return data?.percentageChange
          ? data?.percentageChange.toFixed(2) + "%"
          : "Nil";
      case "HIGH":
        return data?.percentageChange
          ? data?.percentageChange.toFixed(2) + "%"
          : "Nil";
      case "CRITICAL":
        return data?.percentageChange
          ? data?.percentageChange.toFixed(2) + "%"
          : "Nil";
      case "RFID customers":
        // return `${data?.data?.percentageChange}%`;
        return data?.data?.percentageChange === 0
          ? "Nil"
          : `${data?.data?.percentageChange}%`;
      case "Direct pay customers":
        return valuChipData === 0 ? "Nil" : `${valuChipData?.toFixed(2)}%`;
      case "Through app":
        return valuChipData === 0 ? "Nil" : `${valuChipData?.toFixed(2)}%`;
      case "superOneMonth":
        return valuChipData === 0 ? "Nil" : `${valuChipData?.toFixed(2)}%`;
      case "superSixMonth":
        return valuChipData === 0 ? "Nil" : `${valuChipData?.toFixed(2)}%`;
      case "superYear":
        return valuChipData === 0 ? "Nil" : `${valuChipData?.toFixed(2)}%`;
      case "Total Downtime Hours":
        return `${data?.offlinePercentageChange?.toFixed(2) ?? 0}%`;
      case "Total Utilization Hours":
        return `${data?.busyPercentageChange?.toFixed(2) ?? 0}%`;
      case "Total Idle Hours":
        return `${data?.idlePercentageChange?.toFixed(2) ?? 0}%`;
      case "Total Users":
        return data?.data?.totalUsersPercentageChange === 0
          ? "Nil"
          : data?.data?.totalUsersPercentageChange?.toFixed(2);

      case "IOS Users":
        return `${data?.data?.totalAndroidPercentageChange?.toFixed(2) ?? 0}%`;

      case "Android Users":
        return `${data?.data?.totalAndroidPercentageChange?.toFixed(2) ?? 0}%`;

      default:
        return data?.value || "N/A";
    }
  };

  // Get the profit value based on the title
  const profitValue = getValueByTitle(title);

  const getProfitColor = (profit) => {
    if (typeof profit === "string") {
      // If profit is a string and starts with a '-' for negative profit
      return profit.startsWith("-") ? "#790000" : "#027900"; // Red for negative, green for positive
    }

    if (typeof profit === "number") {
      // If profit is a number, check if it's negative or positive
      return profit < 0 ? "#790000" : "#027900"; // Red for negative, green for positive
    }

    // Default to green for any other case (null, undefined, etc.)
    return "#027900";
  };

  const getAlertsProfitColor = (profit) => {
    if (typeof profit === "string") {
      return profit.startsWith("-") ? "#027900" : "#790000";
    }
    return "#027900"; // Default color if profit is not a string
  };

  // Determine the color based on the profit value
  const profitColor =
    chipType === "alerts"
      ? getAlertsProfitColor(profitValue)
      : getProfitColor(profitValue);

  // Handle zero percentage differently
  const displayValue =
    profitValue === "0.00%" || profitValue === "0" ? "Nil" : profitValue;

  //  const displayValue = profitValue === "0.00%" || "0" ? "Nil" : profitValue;

  return (
    <Box
      sx={{
        padding: "2px 8px",
        background: `rgba(${
          profitColor === "#790000" ? "121, 0, 0, 0.10" : "2, 121, 0, 0.10"
        })`,
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
        // width: fixSize ? "100px" : "auto",
        width: "auto",
      }}
    >
      <Typography
        fontFamily={"mySecondFont"}
        color={profitColor}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {displayValue}
        <span
          style={{
            fontSize: "16px",
            marginLeft: "4px",
            fontWeight: "600",
          }}
          className="material-symbols-outlined"
        >
          {typeof profitValue === "string" && profitValue.startsWith("-")
            ? "trending_down"
            : "trending_up"}
        </span>
      </Typography>
    </Box>
  );
};

export default ValueChip;
