import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewBox from "./OverviewBox";
import AllPaymentBox from "./AllPaymentBox";
import CustomTabs from "../custom/CustomTabs";
import TimeFilter from "../Filters/TimeFilter";
import MainHeading from "../../utils/MainHeading";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/action/dashboard/dashboardSlice";
import {
  fetchOrgStats,
  fetchWalletDetails,
} from "../../redux/action/statistics/statisticsSlice";
import { getAllPayments } from "../../redux/action/payments/paymentSlice";
import Collection from "../hub_management/collection/Collection";
import { formatPhoneNumber } from "../../utils/countryCode";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopHeader = () => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const { walletDetails, orgStats, status, error } = useSelector(
    (state) => state.statistics
  );

  const { transactions, shiftCollections } = useSelector(
    (state) => state.hubManagement // Ensure this matches your slice name
  );

  const user = useSelector((state) => state?.profile?.profile);

  const [currentOrgStats, setCurrentOrgStats] = useState(null);
  const [currentWalletDetails, setCurrentWalletDetails] = useState(null);

  const { payments, laoding } = useSelector((state) => state.payment);

  // UseEffect for Time Filter to get the data
  useEffect(() => {
    const fetchData = async () => {
      if (durationType && startTime && endTime) {
        try {
          const dummyData1 = await dispatch(
            fetchOrgStats({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();
          setCurrentOrgStats(dummyData1);

          const dummyData2 = await dispatch(
            fetchWalletDetails({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();
          setCurrentWalletDetails(dummyData2);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    };
    fetchData();
  }, [durationType, startTime, endTime, dispatch]);

  // UseEffect for Time Filter to get the data of Previous Time
  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentOrgStats &&
      currentWalletDetails
    ) {
      dispatch(
        fetchOrgStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
      dispatch(
        fetchWalletDetails({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    currentOrgStats,
    currentWalletDetails,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getAllPayments({}));
  }, [dispatch]);

  const getShiftData = shiftCollections.map((form) => {
    return {
      _id: form?._id,
      stationId: form?.stationId.name,
      hubUser: form?.hubUser?.name,
      collectedAmount: form?.collectedAmount,
      passesCount: form?.passesCount,
      passValue: form?.passValue,
      city: form?.stationId?.city,
      createdAt: form?.createdAt,
    };
  });

  const getAllTransactionData = transactions.map((form) => {
    const rawPhone = form?.passHolder?.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form?._id,
      cashAmount: form?.cashAmount,
      createdAt: form?.createdAt,
      createdBy: form?.createdBy,
      hubPassPlans: form?.hubPassPlans,
      passHolder: form?.passHolder,
      station: form?.station,
      updatedAt: form?.updatedAt,
      passId: form?.hubPassPlans?.passId,
      passType: form?.hubPassPlans?.passType,
      passHolderName: form?.passHolder?.name,
      passHolderPhone: formattedPhone,
      city: form?.station?.city,
      guardName: form?.createdBy?.name,
    };
  });

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverviewBox
            // data={currentWalletDetails}
            // orgData={currentOrgStats}

            //wallte data
            data={currentWalletDetails}
            previousPaymentWalletData={walletDetails}
            //org data
            orgData={currentOrgStats}
            previousPaymentOrgData={orgStats}
            selectedTime={durationType}
            startTime={startTime}
            endTime={endTime}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      // label: "all Payments",
      label: "All Transactions",
      content: (
        <Box>
          <AllPaymentBox
            payments={payments}
            userRoleProp={user?.role}
            tabTitle={"AllPayments"}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"sync_alt"} />,
    },
    ...(["Superadmin", "1C_Admin", "1C_User"].includes(user?.role)
      ? [
          {
            label: "Hub collections",
            content: (
              <Collection
                shiftCollection={getShiftData}
                data={getAllTransactionData}
              />
            ),
          },
        ]
      : []),
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "8px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "20px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box sx={{ display: "none" }}>
              <MainHeading text={"Payments"} />
            </Box>
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <TimeFilter /> */}
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopHeader;
