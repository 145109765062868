const BASE_URL = "https://backend.1charging.com";
// const BASE_URL = "http://localhost:3000";
//Chirag
// const BASE_URL = "http://localhost:3000";

// For Prod
const BASE_INVOICE_URL = "https://new-cms-uploads.s3.ap-south-1.amazonaws.com";

// For Test
// const BASE_INVOICE_URL = "https://gomassive-stage.s3.ap-south-1.amazonaws.com";

// const BASE_URL = "http://10.5.51.161:3000";
// const BASE_URL = "http://52.66.42.244:3000";

export const endPoints = {
  // Auth - Api y
  login: BASE_URL + "/auth/login",
  loginWithOtp: BASE_URL + "/auth/loginWithOtp",
  getProfile: BASE_URL + "/users/me",
  sendOTP: BASE_URL + "/auth/send-otp",
  verfyOTP: BASE_URL + "/auth/verify-otp",
  verfyEmailOTP: BASE_URL + "/auth/verifyEmailOtp",
  resendOTP: BASE_URL + "/auth/resend-otp",
  forgetPassword: BASE_URL + "/users/forgotPassword",
  resendEmailOTP: BASE_URL + "/auth/resendEmailOtp",

  // tariff - Api
  createTariff: BASE_URL + "/tariff/create",
  getTariff: BASE_URL + "/tariff/getAll",
  deleteTariff: BASE_URL + "/tariff/deleteOne",
  updateTariff: BASE_URL + "/tariff/updateOne",

  // admin management - api
  adminRoles: BASE_URL + "/roles/getRolesByUsedId",
  adminOrgs: BASE_URL + "/orgs/getAll",
  createUser: BASE_URL + "/users/create",
  updateUser: BASE_URL + "/users/updateOne",

  // company management - api
  createOrg: BASE_URL + "/orgs/create",
  getAllOrg: BASE_URL + "/orgs/getAll",
  getAllOrgWithFilters: BASE_URL + "/orgs/getAllWithFilters",
  getOneOrg: BASE_URL + "/orgs/getOne",
  updateOrg: BASE_URL + "/orgs/updateOne",
  deleteOrg: BASE_URL + "/orgs/deleteOne",
  createOrgDoc: BASE_URL + "/orgDocs/create",
  deleteOneOrgDoc: BASE_URL + "/orgDocs/deleteOne",
  allUsers: BASE_URL + "/users/getAll",
  oneUser: BASE_URL + "/users/getOne",
  deleteUser: BASE_URL + "/users/deleteOne",
  overviewData: BASE_URL + "/orgs/stats",
  getCMChargingStation: BASE_URL + "/orgs/stations",
  cmOverviewData: BASE_URL + "/orgs/stats",

  // stations api
  getAllStationsWithFilters: BASE_URL + "/stations/getAllWithFilters",
  getAllMapStations: BASE_URL + "/stations/getAllMapStations",
  getOneStationDetail: BASE_URL + "/stations/getOne",
  deleteOneStation: BASE_URL + "/stations/deleteOne",
  updateOneStation: BASE_URL + "/stations/updateOne",
  getOneStationOverview: BASE_URL + "/stations/stats",
  getStationChargingPoint: BASE_URL + "/stations/chargingPoints",
  createStation: BASE_URL + "/stations/create",
  createStationImg: BASE_URL + "/stationImgs/create",
  deleteOneStationImg: BASE_URL + "/stationImgs/deleteOne",
  updateOneStationImg: BASE_URL + "/stationImgs/updateOne",
  getStationSessions: BASE_URL + "/stations/chargingSessions",
  getOneReviews: BASE_URL + "/reviews/getAll",
  getOneStationOneRating: BASE_URL + "/reviews/getOne",
  getQrByStation: BASE_URL + "/qr-code/getQrCodeByStationId",
  getLiveStationPointStatus: BASE_URL + "/stations/chargingPointsCount",
  getNearbyStations: BASE_URL + "/stations/getNearByStaions",

  //dashboard-stopreaso
  getStopReasonAnalysis: BASE_URL + "/chargingSessions/stopreason/stats",

  // chargingPoint
  createChargingPoint: BASE_URL + "/charging-point/create",
  getChargingPointWithFilters: BASE_URL + "/charging-point/getAllWithFilters",
  deleteChargingPoint: BASE_URL + "/charging-point/deleteOne",
  updateChargingPoint: BASE_URL + "/charging-point/updateOne",
  getOneChargingPoint: BASE_URL + "/charging-point/getOne",
  getOneChargingPointHeartbeat: BASE_URL + "/charging-point/getHeartbeat",
  getChargingPointConnectors: BASE_URL + "/charging-point/connectors",
  // startChargingPoint: BASE_URL + "/api/charging/start",
  connectorUpdate: BASE_URL + "/connectors/updateOne",
  getOneSessionData: BASE_URL + "/charging-point/sessions",
  getOneChargingPointLogs: BASE_URL + "/device-logs/getAllDeviceLogs",
  getChargingPointQr: BASE_URL + "/qr-code/getQrCodeByDeviceId",
  cpOverviewData: BASE_URL + "/charging-point/stats",
  createConnector: BASE_URL + "/connectors/create",
  deleteConnector: BASE_URL + "/connectors/deleteOne",
  getOneStatus: BASE_URL + "/charging-point/getOneStatus",
  createQRCode: BASE_URL + "/qr-code/create",
  getConnectorQr: BASE_URL + "/qr-code",
  reviewDelete: BASE_URL + "/reviews/deleteOne",
  getLiveLogs: BASE_URL + "/device-logs/getAllLiveDeviceLogs",
  // connector Api
  startChargingConnector: BASE_URL + "/charging/start",
  stopChargingConnector: BASE_URL + "/charging/stop",
  // configuration - API
  changeConfiguration: BASE_URL + "/device/change/config",
  changeAvailabilityConfiguration: BASE_URL + "/device/change/availablity",
  configurationReset: BASE_URL + "/device/reset",
  configurationMsgTrigger: BASE_URL + "/device/message/trigger",
  configurationUnlockConnector: BASE_URL + "/device/unlock/connector",
  configurationFirmware: BASE_URL + "/device/update/firmware",
  configurationGetDiagnostic: BASE_URL + "/device/getdiagnostic",
  configurationStopCharging: BASE_URL + "/device/stop/charging",

  // sessions api
  getAllSessions: BASE_URL + "/chargingSessions/getAll",
  getAllSessionsWithFilters: BASE_URL + "/chargingSessions/getAllWithFilters",
  getOneSession: BASE_URL + "/chargingSessions/getOne",
  getOneSessionLogs: BASE_URL + "/device-logs/getAllSessionLogs",
  overViewData: BASE_URL + "/stations/stats",
  invoiceGenerate: BASE_URL + "/api/invoice-generation",

  //statistis
  getOrgStats: BASE_URL + "/statistics/orgs",
  getChargerStats: BASE_URL + "/statistics/devices",
  getStationStats: BASE_URL + "/statistics/stations",
  getWalletDetails: BASE_URL + "/statistics/wallet",

  // dashboard -api
  getDashboardData: BASE_URL + "/chargingSessions/stats",
  getAllCustomers: BASE_URL + "/users/getAllCustomers",
  getLiveDeviceStatus: BASE_URL + "/charging-point/getChargingPointCounts",

  // payments - api
  getAllPayments: BASE_URL + "/payments/getAll",

  // finance - manager api
  getAllFinanceStation: BASE_URL + "/stations/finance",
  financeAmountAdd: BASE_URL + "/finance/wallet/add",
  allWalletHistory: BASE_URL + "/finance/wallet",
  getOneStationBalance: BASE_URL + "/finance/wallet/balance",
  getOneStationPaymentHistory: BASE_URL + "/finance/wallet/history",
  getDailySheet: BASE_URL + "/finance/wallet/history/passbook",

  // RFID - APIs
  getAllRFIDRequests: BASE_URL + "/api/rfid/requests",
  getAllRFIDRejectRequests: BASE_URL + "/api/rfid/requests/rejected",
  RFIDAccept: BASE_URL + "/api/rfid/accepted",
  assignRFID: BASE_URL + "/api/rfid/update",
  rejectRFID: BASE_URL + "/api/rfid/form/reject",
  rfidDetails: BASE_URL + "/api/rfid/details",

  //OCPI

  getAllOcpiNetworks: BASE_URL + "/ocpi/ocpi-org",
  getOneOcpiNetworkDetail: BASE_URL + "/ocpi/ocpi-org",

  getOcpiVersionsList: BASE_URL + "/ocpi/credentials/supported-versions",
  getHandshakeStatus: BASE_URL + "/ocpi/credentials",

  getOcpiTokenA: BASE_URL + "/ocpi/credentials/generate-token-a",

  getOcpiVersionsFromTokenA: BASE_URL + "/ocpi/credentials/",

  getOcpiTokenB: BASE_URL + "/ocpi/credentials/generate-token-b",
  getAllOcpiLocations: BASE_URL + "/ocpi-cms/location/all",

  getEvseOfOneLocation: BASE_URL + "/ocpi-cms/location",

  getOcpiAddAndUpdateOcpiChargers: BASE_URL + "/ocpi-cms/location",
  getAssignedOcpiEvses: BASE_URL + "/ocpi-cms/location/assigned",
  getAllOcpiTariffs: BASE_URL + "/ocpi-cms/tariffs/all",
  getAllThirdPartyChargers: BASE_URL + "/ocpi-cms/location/all/evses",
  getOneNetworksAllEvsesChargers:
    BASE_URL + "/ocpi-cms/location/evses/assigned-by",
  getOneNetworkAllTariffs: BASE_URL + "/ocpi-cms/tariffs/all",
  //get locations-stations
  getAllOcpiLocationsList: BASE_URL + "/ocpi-cms/location",
  getOneNetworkAllLocations: BASE_URL + "/ocpi-cms/location/assigned-by",
  getOneNetworkHandshakeLogs: BASE_URL + "/ocpi-cms/logs",

  createOcpiTariff: BASE_URL + "/ocpi-cms/tariffs/create",
  getDefaultTariffList: BASE_URL + "/ocpi-cms/tariffs/default",
  rfidAvailableCheck: BASE_URL + "/api/rfid/check",
  rfidAssign: BASE_URL + "/api/rfid/accepted",

  getDeviceTimeVsEnergyGraphOneNetwork:
    BASE_URL + "/ocpi-cms/sessions/device-time-vs-energy",

  get1cDeviceTimeVsEnergyGraphOneNetwork:
    BASE_URL + "/ocpi-cms/sessions/1c-device-time-vs-energy",

  getDeviceRevenueVsEnergyGraphOneNetwork:
    BASE_URL + "/ocpi-cms/sessions/device-revenue-vs-energy",

  get1cDeviceRevenueVsEnergyGraphOneNetwork:
    BASE_URL + "/ocpi-cms/sessions/1c-device-revenue-vs-energy",

  // Hub Management
  getAllHubStation: BASE_URL + "/stations/getAll/hubStationsList",
  createChargingPass: BASE_URL + "/hub-pass-plans/create",
  getAllChargingPasses: BASE_URL + "/hub-pass-plans/getAll",
  getOneChargingPass: BASE_URL + "/hub-pass-plans/getOne",
  getAllPassHolders: BASE_URL + "/hub-customers/getAll",
  createGuard: BASE_URL + "/hub-user/create",
  getAllGuards: BASE_URL + "/hub-user/getAll",
  getOneGuardAttendance: BASE_URL + "/hub-user/attendance",
  getAllTransaction: BASE_URL + "/hub-transactions/getAll",
  getShiftCollection: BASE_URL + "/hub-user/shiftCollection",
  getAllStationsHub: BASE_URL + "/hub-stations/getAll",
  getOneGuardDetails: BASE_URL + "/hub-user/getOne",
  updateOneGuard: BASE_URL + "/hub-user/updateOne",
  updateChargingPass: BASE_URL + "/hub-pass-plans/updateOne",
  createHubStation: BASE_URL + "/hub-stations/create",
  updateHubStation: BASE_URL + "/hub-stations/updateOne",
  getAllStationList: BASE_URL + "/stations/getAll/1CstationsList",
  getAllStationChargers: BASE_URL + "/hub-stations/connectors",
  getAllHubSessions: BASE_URL + "/chargingSessions/hub/getAll",
  getOneHubSessions: BASE_URL + "/chargingSessions/hub/getOne",
  getOneStationHubGuardDetails: BASE_URL + "/hub-stations/hubUsers",
  getShiftAllTransaction: BASE_URL + "/hub-user/hubUserTransaction",
  updateReward: BASE_URL + "/stations/update-reward",

  // hub - stats
  getHubStats: BASE_URL + "/hub-stats/getAll",

  // other stations
  bulkUploadOtherStations: BASE_URL + "/other-stations/bulkCreate",
  getAllOtherStations: BASE_URL + "/other-stations/getAll",

  //alerts endpoints
  getalertCounts: BASE_URL + "/alerts/counts",
  getAlertsOfEachSeverityLevel: BASE_URL + "/alerts",
  getAlertsHistory: BASE_URL + "/alerts/history/?limit=2000",
  resolveAlertPost: BASE_URL + "/alerts/resolve",
  getByGroupAlerts: BASE_URL + "/alerts-statistics/bygroup",
  getAlertsStatsOverall: BASE_URL + "/alerts-statistics/overall",

  getAverageResolutionAlerts: BASE_URL + "/alerts-statistics/resolve-avg",
  getNewAlertsStatsWithTime:
    BASE_URL + "/alerts-statistics/alert-graph-time-period",
  getTopAlerts: BASE_URL + "/alerts/top-alerts",
  getAlertStatisticsTotalHeatMap: BASE_URL + "/alerts-statistics/total-graph",
  // body {
  // comment: comment,
  // }

  // Customer - management api
  customersWalletStats: BASE_URL + "/customers/wallet-stats",
  customersCurrentRFID: BASE_URL + "/customers/rfid-users",
  customerWalletRecharge: BASE_URL + "/customers/wallet-recharge",
  customerManagementAllCustomers: BASE_URL + "/customers/customers-list",
  customerSuperCharger: BASE_URL + "/customers/super-users",
  customersTotalUserStats: BASE_URL + "/customers/total-user-stats",
  customerRfidUsers: BASE_URL + "/customers/rfid-users",
  customerStatsData: BASE_URL + "/customers/customers-stat",
  customerWalletData: BASE_URL + "/customers/wallet-list",
  customerFeedbackData: BASE_URL + "/customers/review-list",
  customerAppUserStats: BASE_URL + "/customers/app-user-stats",
  customerSessionDetails: BASE_URL + "/customers/sessions",
  customerPaymentDetails: BASE_URL + "/customers/payments",
  customerReviewDetails: BASE_URL + "/customers/review-list",
  customerAllDetails: BASE_URL + "/customers/details",
  customerDisableReview: BASE_URL + "/reviews/disable",
  activeInactiveCustomers: BASE_URL + "/customers/inactive-users",
  customerRangeAndroindISO: BASE_URL + "/customers/app-users",

  getUptimeDowntimeData: BASE_URL + "/charging-point/updowntime",
  getUptimeDowntimeOfOneStation:
    BASE_URL + "/charging-point/updowntime/station",
  getUpDowntimeOfOneCharger: BASE_URL + "/charging-point/updowntime/device",
  getUpDowntimeOfOneCompany: BASE_URL + "/charging-point/updowntime/org",
  invoiceDownloadUrl: BASE_INVOICE_URL, 
};
