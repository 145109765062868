import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import SettlementsBoxDetails from "./SettlementsBoxDetails";
import SettlementsTableBox from "./SettlementsTableBox";

const SettlementsBox = ({ column, row }) => {
  const [selectedSettlement, setSelectedSettlement] = useState(null);
  return (
    <Box>
      <Grid container spacing={2} sx={{ flex: 1, overflow: "hidden" }}>
        <Grid item md={8}>
          <Box
            sx={{
              flexGrow: 1,
              padding: "24px",
              borderRadius: "8px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              height: "100%",
            }}
          >
            <SettlementsTableBox
              column={column}
              row={row}
              onRowClick={setSelectedSettlement}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderRadius: "8px",
              padding: "24px",
              height: "100%",
            }}
          >
            <SettlementsBoxDetails settlementDetails={selectedSettlement} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettlementsBox;
