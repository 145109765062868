import { Box, Button } from "@mui/material";
import React from "react";
import CustomTable from "../../custom/CustomTable";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../../routes/routes";
import { defaultRoles } from "../../../utils/Roles";
import { useSelector } from "react-redux";

const GuardData = ({ columns, row }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.profile?.profile);

  const handleAddBtn = () => {
    navigate(MyRoutes.add_new_guard);
  };
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {/* <Box sx={{ display: "flex", alignItems: "center", gap: "36px" }}>
          <HubTableFilter tabTitle={"Guard"} />
          <HubTimeFilter tabTitle={"Guard"} />
        </Box> */}
        <Button
          onClick={handleAddBtn}
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "120px",
            borderRadius: "4px",
            color: "#FCFCFC",
            mb: "12px",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            textWrap: "nowrap",
            ":hover": {
              background: "#222",
            },
          }}
        >
          + ADD NEW
        </Button>
      </Box>

      {/* Table */}
      <CustomTable
        columns={columns}
        rows={row}
        modalHeading={"Guards"}
        statsHeading={"true"}
        isRowClickable={true}
        actionType={defaultRoles?.includes(user?.role) ? "edit" : ""}
        clickableColumns={["name"]}
        columnClickPaths={{
          name: "/hub_management/guard_details",
        }}
        columnKeyMappings={{
          name: "_id",
        }}
      />
    </Box>
  );
};

export default GuardData;
