import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import { defaultRoles } from "../../utils/Roles";
import { useSelector } from "react-redux";

const ReviewBox = ({ columns, rows, isRowClickable }) => {
  console.log("gggggggggggg", rows);

  const user = useSelector((state) => state?.profile?.profile);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const filteredRows = rows.filter(
    (row) =>
      row.overallRating !== "" &&
      row.overallRating !== null &&
      row.overallRating !== undefined
  );

  // useEffect(() => {
  //   if (filteredRows.length > 0) {
  //     setFilteredReviews(filteredRows);
  //   }
  // }, [filteredRows]);

  // const handleFilterChange = (filteredData, sortColumn) => {
  //   setSortColumn(sortColumn);
  //   setFilteredReviews(filteredData);
  // };

  const myFilters = [
    {
      value: "overallRating",
      title: "Review",
    },
    {
      value: "rating",
      title: "Rating",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          // data={filteredRows}
          // onFilterChange={handleFilterChange}
          // filters={myFilters}
        />
      </Box>

      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        // actionType={defaultRoles?.includes(user?.role) ? "delete" : ""}
        actionType={""}
        modalHeading={"Reviews"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default ReviewBox;
