import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

// Dummy data (replace with actual data if needed)
const dummyData = {
  wallet: { amount: 12000, count: 150 },
  directPayment: { amount: 4000, count: 80 },
  ocpi: { amount: 5000, count: 120 },
  super1cCount: { amount: 8000, count: 200 },
};

const dataConfig = [
  { key: "directPayment", label: "Direct Payment", color: "#F06292" },
  { key: "wallet", label: "Wallet", color: "#E91E63" },
  { key: "ocpi", label: "OCPI", color: "#CE93D8" },
  { key: "super1cCount", label: "1C Super Count", color: "#AB47BC" },
];

// OnlineSplitDataBox Component
const OnlineSplitDataBox = ({ onlineSplitData = dummyData }) => {

  const currencySymbol = GetCurrencySymbol();


  return (
    <Grid
      container
      columnGap={5}
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: "100%",
          }}
        >
          {dataConfig.map(({ key, label, color }) => {
            const data = onlineSplitData[key];

            return (
              <Box
                key={key}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: color, // Dynamic color for each item
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      {label}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      
                      {currencySymbol} {data.amount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    {/* Empty placeholder for spacing */}
                  </Typography>

                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    {data.count}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnlineSplitDataBox;
