import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import { Divider, Grid, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getAllChargingPasses,
  updateChargingPass,
} from "../../../redux/action/hubManagement/hubManagementSlice";
import { toast } from "react-toastify";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";
import { defaultRoles } from "../../../utils/Roles";
import { useSelector } from "react-redux";

// Define the style for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "8px",
  outline: "none",
  boxShadow: 24,
  p: 4,
};

// InfoBox component as defined previously
const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = React.useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(value); // Using navigator API for clipboard
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
      >
        {label}
      </Typography>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: icon ? "8px" : "" }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={value === "Active" ? "#027900" : "#2F2F2F"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              onClick={handleCopy}
              style={{ fontSize: "24px", cursor: "pointer" }}
              className="material-symbols-outlined"
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

// ChargingPassDetailModal component
const ChargingPassDetailModal = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const currencySymbol = GetCurrencySymbol();

  const user = useSelector((state) => state?.profile?.profile);

  const [name, setName] = React.useState(data?.name || "");
  const [passPrice, setPassPrice] = React.useState(data?.passPrice || "");
  const [energyCap, setEnergyCap] = React.useState(data?.energyCap || "");
  const [timeCap, setTimeCap] = React.useState(data?.timeCap || "");

  // Formatting for createdAt
  const formattedDate = data?.createdAt
    ? new Date(data.createdAt).toLocaleDateString("en-GB") // "en-GB" gives you dd/mm/yyyy format
    : "N/A";
  const formattedTime = data?.createdAt
    ? new Date(data.createdAt).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true, // This gives the am/pm format without seconds
      })
    : "N/A";

  // Formatting for updatedAt
  const formattedDateUpdate = data?.updatedAt
    ? new Date(data.updatedAt).toLocaleDateString("en-GB")
    : "N/A";
  const formattedTimeUpdate = data?.updatedAt
    ? new Date(data.updatedAt).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "N/A";

  const passId = data?._id;

  const handleUpdate = () => {
    const formData = {
      name,
      passPrice,
      energyCap,
      timeCap,
    };

    dispatch(updateChargingPass({ passId, formData }))
      .unwrap() // This allows catching errors from the thunk
      .then(() => {
        toast.success("Charging Pass Updated Successfully");
        dispatch(getAllChargingPasses()); // Optionally refetch all passes
      })
      .catch((error) => {
        toast.error(error); // Handle errors
        // Optionally revert the optimistic update if needed
      });

    handleClose(); // Close the modal after the update
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>

        {/* Heading - Modal */}
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/add-new-user.png" alt="" />
            <Heading text={"Charging Pass Details"} />
          </Box>
          <Divider sx={{ background: "#ddd", marginTop: "8px" }} />
        </Box>

        {/* Details Section */}

        {defaultRoles?.includes(user?.role) ? (
          <>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      padding: "24px 0",
                      background: "#fff",
                      borderRadius: "8px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                        color={"#2F2F2F"}
                      >
                        Pass Name
                      </Typography>
                      <input
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          width: "250px",
                          outline: "none",
                          fontFamily: "mySecondFont",
                          fontSize: "16px",
                        }}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Box>
                    <InfoBox label="Pass ID" value={data?.passId || "N/A"} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                        color={"#2F2F2F"}
                      >
                        {`Pass Price (${currencySymbol})`}
                      </Typography>
                      <input
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          width: "250px",
                          outline: "none",
                          fontFamily: "mySecondFont",
                          fontSize: "16px",
                        }}
                        type="text"
                        value={passPrice}
                        onChange={(e) => setPassPrice(e.target.value)}
                      />
                    </Box>
                    <InfoBox
                      label="Pass Type"
                      value={data?.passType || "N/A"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "48px",
                      }}
                    >
                      <Box>
                        <InfoBox label="Created At" value={formattedDate} />
                        <Typography
                          fontSize={"12px"}
                          color={"#666"}
                          fontFamily={"myThirdFont"}
                        >
                          {formattedTime}
                        </Typography>
                      </Box>
                      <Box>
                        <InfoBox
                          label="Updated At"
                          value={formattedDateUpdate}
                        />
                        <Typography
                          fontSize={"12px"}
                          color={"#666"}
                          fontFamily={"myThirdFont"}
                        >
                          {formattedTimeUpdate}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      padding: "24px 16px",
                      background: "#fff",
                      borderRadius: "8px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <InfoBox
                      label="Station Name"
                      value={data?.stationId?.name || "N/A"}
                    />
                    <InfoBox
                      label="Created By"
                      value={data?.createdBy?.name || "N/A"}
                    />
                    <InfoBox label="Vehicle Type" value={data?.vehicleType} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                        color={"#2F2F2F"}
                      >
                        Energy Cap (kWh)
                      </Typography>
                      <input
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          width: "250px",
                          outline: "none",
                          fontFamily: "mySecondFont",
                          fontSize: "16px",
                        }}
                        type="text"
                        value={energyCap}
                        onChange={(e) => setEnergyCap(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                        color={"#2F2F2F"}
                      >
                        Time Cap (hrs)
                      </Typography>
                      <input
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          width: "250px",
                          outline: "none",
                          fontFamily: "mySecondFont",
                          fontSize: "16px",
                        }}
                        type="text"
                        value={timeCap}
                        onChange={(e) => setTimeCap(e.target.value)}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Button
                sx={{
                  background: "#e50000",
                  color: "#fff",
                  textTransform: "capitalize",
                  padding: "10px 16px",
                  fontFamily: "mySecondFont",
                  ":hover": {
                    background: "#e50000",
                  },
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    padding: "24px 0",
                    background: "#fff",
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <InfoBox label="Pass Name" value={data?.name || "N/A"} />
                  <InfoBox label="Pass ID" value={data?.passId || "N/A"} />
                  <InfoBox
                    label={`Pass Price (${currencySymbol})`}
                    value={data?.passPrice || "N/A"}
                  />

                  <InfoBox label="Pass Type" value={data?.passType || "N/A"} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "48px",
                    }}
                  >
                    <Box>
                      <InfoBox label="Created At" value={formattedDate} />
                      <Typography
                        fontSize={"12px"}
                        color={"#666"}
                        fontFamily={"myThirdFont"}
                      >
                        {formattedTime}
                      </Typography>
                    </Box>
                    <Box>
                      <InfoBox label="Updated At" value={formattedDateUpdate} />
                      <Typography
                        fontSize={"12px"}
                        color={"#666"}
                        fontFamily={"myThirdFont"}
                      >
                        {formattedTimeUpdate}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    padding: "24px 16px",
                    background: "#fff",
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <InfoBox
                    label="Station Name"
                    value={data?.stationId?.name || "N/A"}
                  />
                  <InfoBox
                    label="Created By"
                    value={data?.createdBy?.name || "N/A"}
                  />
                  <InfoBox label="Vehicle Type" value={data?.vehicleType} />
                  <InfoBox label="Energy Cap (kWh)" value={data?.energyCap} />
                  <InfoBox label="Time Cap (hrs)" value={data?.timeCap} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ChargingPassDetailModal;
