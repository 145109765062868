import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Heading from "../../../../utils/Heading";
import { GetCurrencySymbol } from "../../../../utils/currency/currencyChangeFunction";
import SettleModal from "../../../Modals/Accounts/SettleModal";
import ConfirmSettleModal from "../../../Modals/Accounts/ConfirmSettleModal";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Active" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const SettledAmountBox = () => {
  const location = useLocation();
  const { data: settlementDetails } = location.state || {};
  const currencySymbol = GetCurrencySymbol();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openConfirmSettle, setOpenConfirmSettle] = React.useState(false);
  const handleOpenConfirmSettle = () => setOpenConfirmSettle(true);
  const handleCloseConfirmSettle = () => setOpenConfirmSettle(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          background: "#fff",
          padding: "24px",
          borderRadius: "12px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span className="material-symbols-outlined">description</span>
              <Heading text={"Settle Management"} />
            </Box>
          </Box>

          <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            mt: "12px",
          }}
        >
          <InfoBox
            label={"Company Name"}
            value={settlementDetails?.partnerCompanyName}
          />

          <InfoBox
            label={"Total Energy"}
            value={`${settlementDetails?.totalEnergyConsumed} kWh`}
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <InfoBox
                label={"Total Revenue Generate"}
                value={`${currencySymbol}${settlementDetails?.totalRevenueGenerated}`}
              />
            </Grid>
            <Grid item md={6}>
              <InfoBox
                label="Total 1C Share"
                value={`${currencySymbol}${settlementDetails?.total1CShare}`}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <InfoBox
                label={"Software Cost (Per Unit) "}
                value={`${currencySymbol}${settlementDetails?.softwareCostPerUnit}`}
              />
            </Grid>
            <Grid item md={6}>
              <InfoBox
                label={"Hardware Cost (Per Unit) "}
                value={`${currencySymbol}${settlementDetails?.hardwareCostPerUnit}`}
              />
            </Grid>
          </Grid>
          <InfoBox
            label="Pending Settlement Amount "
            value={`${currencySymbol}${settlementDetails?.pendingSettlementAmount}`}
          />

          <InfoBox
            label="Last Settlement Date"
            value={settlementDetails?.lastSettlementDate}
          />
          <InfoBox
            label="Settlement Status"
            value={settlementDetails?.settlementStatus}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "4px 16px",
                borderRadius: "8px",
                background: "#0279001A",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  background: "rgba(41, 45, 51, 0.08)",
                  borderRadius: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{ color: "#292D33" }}
                  className="material-symbols-outlined"
                >
                  account_balance_wallet
                </span>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  color={"#666"}
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                >
                  Settle Amount
                </Typography>
                <Typography
                  color={"#1C1C1C"}
                  fontSize={"18px"}
                  fontFamily="'Montserrat', sans-serif"
                  fontWeight={600}
                  textAlign={"start"}
                >
                  ₹{10000}
                </Typography>
              </Box>
            </Box>
            <Button
              sx={{
                width: "250px",
                background: "#333",
                color: "#fff",
                fontFamily: "mySecondFont",
                ":hover": {
                  background: "#333",
                },
              }}
              onClick={handleOpen}
            >
              Settle
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Settle Modal */}
      <SettleModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpenConfirmSettle}
        data={settlementDetails}
      />

      {/* confirm modal */}
      <ConfirmSettleModal
        open={openConfirmSettle}
        handleClose={handleCloseConfirmSettle}
        data={settlementDetails}
      />
    </>
  );
};

export default SettledAmountBox;
