import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, Slide, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const GlobalTimeFilter = ({
    setDurationType,
    setStartTime,
    setEndTime,
    isNewDurationType,
  }) => {
    const [selectedValue, setSelectedValue] = React.useState("today");
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [startTime, setStartTimeState] = React.useState(null);
    const [endTime, setEndTimeState] = React.useState(null);
    const [showResetButton, setShowResetButton] = useState(false);
  
    useEffect(() => {
      const now = new Date();
      const defaultStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
      const defaultEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
  
      setStartTime(defaultStartTime);
      setEndTime(defaultEndTime);
      setDurationType("today");
    }, [setStartTime, setEndTime, setDurationType]);
  
    const handleChange = (event) => {
      const value = event.target.value;
      setSelectedValue(value);
      setDurationType(value);
  
      const now = new Date();
      let newStartTime, newEndTime;
  
      if (value === "today") {
        newStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
        newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
      } else if (value === "yesterday") {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        newStartTime = new Date(yesterday.setHours(0, 0, 0, 0)).valueOf();
        newEndTime = new Date(yesterday.setHours(23, 59, 59, 999)).valueOf();
      } else if (value === "last7Days" || value === "week") {
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - 6);
        newStartTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
        newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
      } else if (value === "month") {
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
        newStartTime = startOfMonth.valueOf();
        newEndTime = endOfMonth.valueOf();
      } else if (value === "year") {
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
        newStartTime = startOfYear.valueOf();
        newEndTime = endOfYear.valueOf();
      } else if (value === "customRange") {
        newStartTime = startDate ? startDate.valueOf() : null;
        if (endDate) {
          const endOfDay = new Date(endDate);
          newEndTime = endDate ? endDate.valueOf() : endOfDay.setHours(23, 59, 59, 999);
        } else {
          newEndTime = null;
        }
      }
  
      if (newStartTime !== undefined && newEndTime !== undefined) {
        setStartTime(newStartTime);
        setEndTime(newEndTime);
      }
    };
  
    const handleApply = () => {
      if (selectedValue === "customRange") {
        const customStartTime = startDate ? startDate.valueOf() : null;
        let customEndTime = null;
  
        if (endDate) {
          const endOfDay = new Date(endDate);
          customEndTime = endDate ? endDate.valueOf() : endOfDay.setHours(23, 59, 59, 999);
        }
  
        setStartTime(customStartTime);
        setEndTime(customEndTime);
      } else if (selectedValue === "today") {
        const selectedStartTime = startTime ? startTime.valueOf() : null;
        const selectedEndTime = endTime ? endTime.valueOf() : null;
  
        setStartTime(selectedStartTime);
        setEndTime(selectedEndTime);
      }
  
      console.log("Applied Duration Type:", selectedValue);
      console.log("Applied Start Time:", startDate ? startDate.valueOf() : startTime);
      console.log("Applied End Time:", endDate ? endDate.valueOf() : endTime);
  
      if (startTime && endTime) {
        setShowResetButton(true);
      } else {
        console.log("Please select both start and end times.");
      }
    };
  
    const handleReset = () => {
      const now = new Date();
      const defaultStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
      const defaultEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
  
      setStartTime(defaultStartTime);
      setEndTime(defaultEndTime);
      setStartDate(null);
      setEndDate(null);
      setStartTimeState(null);
      setEndTimeState(null);
      setSelectedValue("today");
      setDurationType("today");
  
      setShowResetButton(false);
    };
  
    const isApplyButtonEnabled = selectedValue === "today" || selectedValue === "customRange";
    const isToday = selectedValue === "today";
  
    const todayMaxDate = dayjs();
  
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <Box>
          <FormControl>
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              IconComponent={FilterListIcon}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "120px",
                height: "48px",
                color: "#1C1C1C",
                fontFamily: "mySecondFont",
                textAlign: "left",
              }}
            >
              <MenuItem sx={{ fontFamily: "myThirdFont", fontSize: "16px" }} value="today">
                Today
              </MenuItem>
              <MenuItem sx={{ fontFamily: "myThirdFont", fontSize: "16px" }} value="yesterday">
                Yesterday
              </MenuItem>
              <MenuItem sx={{ fontFamily: "myThirdFont", fontSize: "16px" }} value={isNewDurationType ? "last7Days" : "week"}>
                Last 7 Days
              </MenuItem>
              <MenuItem sx={{ fontFamily: "myThirdFont", fontSize: "16px" }} value={isNewDurationType ? "currentMonth" : "month"}>
                Current Month
              </MenuItem>
              <MenuItem sx={{ fontFamily: "myThirdFont", fontSize: "16px" }} value={isNewDurationType ? "currentYear" : "year"}>
                Current Year
              </MenuItem>
              <MenuItem sx={{ fontFamily: "myThirdFont", fontSize: "16px" }} value="customRange">
                Custom Range
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
  
        {(selectedValue === "customRange" || selectedValue === "today") && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {isToday && !isNewDurationType && (
              <>
                <Box sx={{ width: "144px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Start Time"
                      value={startTime}
                      onChange={(newValue) => setStartTimeState(newValue)}
                      timeSteps={{ minutes: 1 }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ width: "144px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="End Time"
                      value={endTime}
                      onChange={(newValue) => setEndTimeState(newValue)}
                      minTime={startTime ? startTime : null}
                      timeSteps={{ minutes: 1 }}
                    />
                  </LocalizationProvider>
                </Box>
                {showResetButton && (
                  <Slide direction="down" in={showResetButton} mountOnEnter unmountOnExit>
                    <Button
                      sx={{
                        padding: "8px 12px",
                        width: "70px",
                        borderRadius: "4px",
                        fontSize: "12px",
                        color: "#790000",
                        fontFamily: "mySecondFont",
                        letterSpacing: "1.12px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleReset}
                    >
                      <RestartAltIcon />
                      RESET
                    </Button>
                  </Slide>
                )}
              </>
            )}
            {selectedValue === "customRange" && (
              <>
                <Box sx={{ width: "144px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start Date & Time"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      maxDateTime={todayMaxDate}
                      timeSteps={{ minutes: 1 }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ width: "144px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="End Date & Time"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      minDateTime={startDate ? startDate.add(0, "minute") : null}
                      maxDateTime={todayMaxDate}
                      timeSteps={{ minutes: 1 }}
                    />
                  </LocalizationProvider>
                </Box>
              </>
            )}
          </Box>
        )}
  
        {isNewDurationType && selectedValue !== "customRange" ? (
          ""
        ) : (
          <Button
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "120px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#222",
              },
              "&.Mui-disabled": {
                backgroundColor: "#555",
                color: "#999",
                cursor: "not-allowed",
                opacity: 0.6,
              },
            }}
            disabled={!isApplyButtonEnabled}
            onClick={handleApply}
          >
            APPLY
          </Button>
        )}
      </Box>
    );
  };
  
export default GlobalTimeFilter;
