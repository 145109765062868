import { Box, Typography } from "@mui/material";
import React from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";
import Heading from "../../../utils/Heading";

const WalletDataTableBox = ({
  columns,
  rows,
  isRowClickable,
  data,
  loading,
}) => {
  if (loading) {
    return <Typography>loading</Typography>;
  }
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters />
        <Box
          sx={{
            display: "flex",
            padding: "4px 16px",
            borderRadius: "8px",
            background: "#0279001A",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Box
            sx={{
              width: "32px",
              height: "32px",
              background: "rgba(41, 45, 51, 0.08)",
              borderRadius: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ color: "#292D33" }}
              className="material-symbols-outlined"
            >
              account_balance_wallet
            </span>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              color={"#666"}
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
            >
              Current wallet balance
            </Typography>
            <Typography
              color={"#1C1C1C"}
              fontSize={"18px"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
              textAlign={"start"}
            >
              ₹{data?.totalBalance?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <CustomTable
        columns={columns}
        rows={Array.isArray(rows) ? rows : []}
        isRowClickable={isRowClickable}
        clickableColumns={["customerName"]}
        columnClickPaths={{
          customerName: "/customer_management",
        }}
        columnKeyMappings={{
          customerName: "customerId", // Use 'sessionID' key for 'sessionID' column
        }}
        actionType={"edit-delete"}
        modalHeading={"Wallet Data"}
        customerWallet={true}
        loading={loading}
      />
    </Box>
  );
};

export default WalletDataTableBox;
