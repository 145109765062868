import React from "react";
import { Box } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const CistomersSemiDoughnutChart = ({ data }) => {
    // Safely access nested data
    const totalAndroidUsers = data?.data?.totalAndroid || 0;
    const totalIosUsers = data?.data?.totalIos || 0;
    const totalUsers = data?.data?.totalUsers || 0;
  
    // Prepare chart data with more meaningful structure
    let chartData = [
      { 
        name: "Android", 
        value: totalAndroidUsers, 
        color: "#68B7F9" // A more appealing green for Android
      },
      { 
        name: "iOS", 
        value: totalIosUsers, 
        color: "#1D4F9A" // A blue color for iOS
      }
    ];
  
    console.log("Chart Data: ", chartData);
  
    // Handle case when both Android and iOS users are zero
    if (totalAndroidUsers === 0 && totalIosUsers === 0) {
      chartData = [
        { 
          name: "No Users", 
          value: 1, 
          color: "#D3D3D3"  // Gray color when no users
        }
      ];
    }
  
    return (
      <Box sx={{ width: "100%", height: "160px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              cx="50%"
              cy="90%"
              outerRadius={135}
              innerRadius={120}
              startAngle={180}
              endAngle={0}
              stroke="none"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
            <Tooltip 
              formatter={(value, name) => [value, name]}
              contentStyle={{ borderRadius: '8px' }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  };

export default CistomersSemiDoughnutChart;

