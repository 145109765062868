import { Box } from "@mui/material";
import React from "react";
import SettledAmountBox from "../../components/FinanceManager/Settlements/SettlementDetailsComponent/SettledAmountBox";

const SettledAmountPage = () => {
  return (
    <>
      <Box>
        <SettledAmountBox />
      </Box>
    </>
  );
};

export default SettledAmountPage;
