import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import { Box, Button } from "@mui/material";
import CustomTable from "../custom/CustomTable";
import { fetchAllStations } from "../../redux/action/stations/stationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";
import BulkUploadStationModal from "../Modals/BulkUploadStationModal";
import {
  bulkUploadOtherStations,
  fetchAllOtherStations,
} from "../../redux/action/stations/otherStationSlice";
import { toast } from "react-toastify";
import { defaultRoles } from "../../utils/Roles";
import TempCustomTable from "../custom/TempCustomTable";
import TempTableFilters from "../charging-sessions/TempTableFilters";

const TableBox = ({
  rows,
  columns,
  totalCounts,
  page,
  setPage,
  limit,
  search,
  setSearch,
  isRowClickable,
  showAddButton,
  showBulkUploadButton = false,
  loading,
  tabTitle,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stations, stationsCount } = useSelector((state) => state.stations);
  const user = useSelector((state) => state?.profile?.profile);

  const [filteredStations, setFilteredStations] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [bulkUploadModelOpen, setBulkUploadModelOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAllStations({}));
  }, [dispatch]);

  const handlePageChange = () => {
    navigate(MyRoutes.addNewChargingStation);
  };

  useEffect(() => {
    if (stations.length > 0) {
      setFilteredStations(stations);
    }
  }, [stations]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredStations(filteredData);
  };

  const myFilters = [
    {
      value: "stationName",
      title: "Station Name",
    },
    {
      value: "_id",
      title: "Station ID",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];

  const handleUpload = async (files) => {
    const response = await dispatch(
      bulkUploadOtherStations({ file: files[0] })
    ).unwrap();

    toast(response?.message);
    setBulkUploadModelOpen(false);
    dispatch(fetchAllOtherStations());
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {filters ? (
          <TempTableFilters
            data={stations}
            filters={filters}
            setFilters={setFilters}
            tabTitle={tabTitle}
          />
        ) : (
          ""
        )}

        {showBulkUploadButton && defaultRoles.includes(user?.role) && (
          <Button
            onClick={() => setBulkUploadModelOpen(true)}
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "160px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              textWrap: "nowrap",
              ":hover": {
                background: "#222",
              },
            }}
          >
            + Bulk Upload
          </Button>
        )}

        {showAddButton && defaultRoles.includes(user?.role) && (
          <Button
            onClick={handlePageChange}
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "120px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              textWrap: "nowrap",
              ":hover": {
                background: "#222",
              },
            }}
          >
            + ADD NEW
          </Button>
        )}
      </Box>

      {filters ? (
        <TempCustomTable
          columns={columns}
          rows={rows}
          totalCounts={totalCounts}
          filters={filters}
          setFilters={setFilters}
          isRowClickable={isRowClickable}
          modalHeading={"Charging Stations"}
          actionType={
            defaultRoles?.includes(user?.role)
              ? // "edit-delete"
                "edit"
              : ""
          }
          rowClickPath={"/charging_stations"}
          clickableColumns={["stationName"]}
          columnClickPaths={{
            stationName: "/charging_stations",
          }}
          columnKeyMappings={{
            stationName: "_id",
          }}
          statsHeading={"true"}
          loading={loading}
          sortColumn={sortColumn}
        />
      ) : (
        ""
      )}

      <BulkUploadStationModal
        open={bulkUploadModelOpen}
        handleUpload={handleUpload}
        handleClose={() => setBulkUploadModelOpen(false)}
      />
    </Box>
  );
};

export default TableBox;
