export const getDisplayLabel = (columnId, value) => {
  const enums = {
    mode: {
      Upi: "UPI",
      APP_CHARGING: "APP",
      GUARD_CHARGING: "HUB",
      WEB_CHARGING: "WEB",
    },
    from: {
      Upi: "UPI",
      APP_CHARGING: "APP",
      DIRECT_PAYMENT: "DIRECT",
      GUARD_CHARGING: "HUB",
      WEB_CHARGING: "WEB",
      RFID: "RFID",
      OCPI: "OCPI",
    },
    type: {
      AddMoney: "Add Money", // Map `AddMoney` to `Add Money`
    },
    passType: {
      "1week": "Weekly", // Map `1week` to `Weekly`
    },
  };

  if (!columnId || !value) return value; // Handle undefined/null inputs
  return enums[columnId]?.[value] || value; // Default to original value if no match
};
