import React from "react";
import { Box, Divider, Typography, Grid, Button } from "@mui/material";
import Heading from "../../utils/Heading";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import ProgressBar from "../Graphs/ProgressBar";
import StatusChip from "../Chips/StatusChip";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";

const DeviceStatus = ({ data }) => {
  const navigate = useNavigate();

  const deviceStatusDataDashboard = [
    {
      id: 1,
      status: "online",
      noOfDevices: data?.data?.availableCharingPoints,
      // totalDeviceNo: "240",
      // activeSessionNo: "24",
      // cardStatus: "live",
      // ratingStatus: "good",
    },
    {
      id: 2,
      status: "faulted",
      noOfDevices: data?.data?.faultedCharingPoints,
      // totalDeviceNo: "240",
      // activeSessionNo: "24",
      // cardStatus: "live",
      // ratingStatus: "ok",
    },
    {
      id: 3,
      status: "offline",
      noOfDevices: data?.data?.offlineCharingPoints,
      // totalDeviceNo: "240",
      // activeSessionNo: "24",
      // cardStatus: "live",
      // ratingStatus: "bad",
    },
  ];

  const handleActiveSessionButton = () => {
    navigate(MyRoutes.chargingSessions);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/ev_charger_icon.png" alt="" />
            <Heading text={"Device Status"} />
          </Box>
          <Box>
            <StatusChip text={"Live"} chipStatus={"Live"} />
          </Box>
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      {/* Progress Bar */}
      <Box sx={{ marginTop: "24px", marginBottom: "12px" }}>
        <ProgressBar dashboardProgBar="true" data={data} />
      </Box>

      {/* Device Status Cards - offline, online, faulted */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginBottom: "20px",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: "8px",
        }}
      >
        {deviceStatusDataDashboard.map((item, index) => {
          return (
            <Box
              key={item?.id}
              sx={{
                flex: 1,
                padding: "16px 24px",
                boxShadow: " 0px 2px 4px 0px rgba(41, 45, 51, 0.25);",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor:
                      item.status === "online"
                        ? "#64D95A"
                        : item.status === "faulted"
                        ? "#FF974B"
                        : "#D95A5A",
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                />
                <Typography fontFamily={"myThirdFont"} fontSize={"16px"}>
                  {item.status === "online"
                    ? "Online"
                    : item.status === "faulted"
                    ? "Faulted"
                    : "Offline"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily={"Montserrat"}
                  fontSize={"18px"}
                  fontWeight={"600"}
                  lineFeight={"24px"}
                  color={"#2F2F2F"}
                >
                  {item.noOfDevices}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>

      {/* Active session Button */}
      <Box
        onClick={handleActiveSessionButton} // Handle click if needed
        sx={{
          width: "100%",
          padding: "16px 0px 16px 0px",
          display: "flex", // Align items in a row (horizontally)
          alignItems: "center", // Center vertically
          borderRadius: "4px",
          boxShadow: "0px 2px 4px 0px rgba(41, 45, 51, 0.25)",
          background:
            "linear-gradient(0deg, rgba(2, 121, 0, 0.05) 0%, rgba(2, 121, 0, 0.05) 100%)",
          "&:hover": {
            backgroundImage:
              "linear-gradient(0deg, rgba(2, 121, 0, 0.1) 0%, rgba(2, 121, 0, 0.1) 100%)",
          },
          cursor: "pointer",
          justifyContent:"center"
        }}
      >
        <BoltOutlinedIcon fontSize="medium" sx={{ color: "#027900" }} />
        <Typography
          fontFamily={"Montserrat"}
          textTransform={"none"}
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"} // Fixed typo: lineFeight -> lineHeight
          color={"#1C1C1C"}
        >
          Total Active sessions:
        </Typography>
        <Typography
          fontFamily={"Montserrat"}
          textTransform={"none"}
          fontSize={"18px"}
          fontWeight={"600"}
          lineHeight={"24px"} // Fixed typo: lineFeight -> lineHeight
          color={"#027900"}
          sx={{ marginLeft: "4px" }}
        >
          {data?.data?.totalLiveSessionsCount}
        </Typography>
      </Box>
    </Box>
  );
};

export default DeviceStatus;
