import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TableBox from "./TableBox";
import { chargingPointColumns, chargingPointData } from "../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import { fetchChargingPoints } from "../../redux/action/chargingPointSlice/chargingPointSlice";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    limit: 10,
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    setFilters({
      search: "",
      page: 1,
      limit: 10,
      startTime: null,
      endTime: null,
    });
  }, [selectedTab]);

  const dispatch = useDispatch();
  const { chargingPoints, chargingPointsCount, loading, error } = useSelector(
    (state) => state.chargingPoints
  );
  const { profile } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(
      fetchChargingPoints({
        ...filters,
      })
    );
  }, [dispatch, filters]);

  const tabsData = [
    {
      label: "1C Chargers",
      content: (
        <Box>
          <TableBox
            columns={chargingPointColumns()}
            rows={chargingPoints}
            totalCounts={chargingPointsCount}
            filters={filters}
            setFilters={setFilters}
            isRowClickable={true}
            loading={loading}
            tabTitle={"ChargingPoints"}
          />
        </Box>
      ),
    },
    ...(profile?.role !== "CPO_Admin"
      ? [
          {
            label: "OCPI Chargers",
            content: (
              <Box>
                <TableBox
                  columns={chargingPointColumns()}
                  rows={chargingPoints}
                  totalCounts={chargingPointsCount}
                  filters={filters}
                  setFilters={setFilters}
                  isRowClickable={true}
                  loading={loading}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "8px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "20px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box sx={{ display: "none" }}>
          <MainHeading text={"Charging Points"} />
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
